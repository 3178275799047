// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function TermTemplateActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    selectedTermTemplate,
    showTermTemplateActionMenu,
    // Set States
    setShowTermTemplateActionMenu,
    // Functions
    displayCopyTermTemplateActionModal,
    displayTermTemplateActionDialog,
  } = props;

  // Redux Store
  const typeOfTerms = useSelector(
    (state) => state.termsAndCurrenciesPage.typeOfTerms
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowTermTemplateActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      // 複製
      case "Copy":
        displayCopyTermTemplateActionModal();
        break;
      // 刪除
      case "Delete":
        displayTermTemplateActionDialog("DeleteTermTemplate");
        break;
      // 設為一般選項
      case "RemoveDefault":
        displayTermTemplateActionDialog("RemoveTermTemplateDefault");
        break;
      // 還原
      case "Restore":
        displayTermTemplateActionDialog("RestoreTermTemplate");
        break;
      // 設為預設選項
      case "SetDefault":
        displayTermTemplateActionDialog("SetTermTemplateDefault");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showTermTemplateActionMenu}
      open={Boolean(showTermTemplateActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Copy")}>
        <ContentCopyRoundedIcon />
        {t("複製")}
      </MenuItem>
      {typeOfTerms === "Current" &&
        selectedTermTemplate &&
        (selectedTermTemplate.is_default ? (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("RemoveDefault")}
          >
            <ReplayRoundedIcon />
            {t("設為一般選項")}
          </MenuItem>
        ) : (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("SetDefault")}
          >
            <PushPinRoundedIcon />
            {t("設為預設選項")}
          </MenuItem>
        ))}
      {typeOfTerms === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default TermTemplateActionMenu;
