// Actions
import {
  onOrderChange,
  onTabItemChange,
} from "../../../redux/pages/orderProfilePage/orderProfilePageActions";
import {
  onClientChange,
  onFormSubmitCountIncrease,
  onIsQuotationEditableChange,
} from "../../../redux/pages/quotationProfilePage/quotationProfilePageActions";

// Components
// Boxes
import ClientIntroBasicInfoBox from "./clientIntroBasicInfoBox";
import ClientIntroClientLocationInfoBox from "./clientIntroClientLocationInfoBox";
import ClientIntroContactPersonInfoBox from "./clientIntroContactPersonInfoBox";
import QuotationIntroBasicInfoBox from "./quotationIntroBasicInfoBox";
import QuotationIntroConfirmationInfoBox from "./quotationIntroConfirmationInfoBox";
import QuotationIntroPriceInfoBox from "./quotationIntroPriceInfoBox";
import SalespersonIntroBasicInfoBox from "./salespersonIntroBasicInfoBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Modals
import ConfirmQuotationActionModal from "../../modals/quotationProfilePage/confirmQuotationActionModal";
import CreateOrderFromQuotationActionModal from "../../modals/quotationProfilePage/createOrderFromQuotationActionModal";
import CreatePdfActionModal from "../../modals/quotationProfilePage/createPdfActionModal";
import FileViewModal from "../../modals/quotationProfilePage/fileViewModal";
import ModalContainer from "../../modals/modalContainer";
import PdfViewModal from "../../modals/quotationProfilePage/pdfViewModal";
import QuotationActionModal from "../../modals/quotationProfilePage/quotationActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  cancelQuotationConfirmationFetch,
  getQuotationDetailsByIdFetch,
} from "../../../fetches/quotationFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { roundNumber } from "../../../helperFunctions/roundNumber";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  containerSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function QuotationIntroBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const clientId = useSelector((state) => state.quotationProfilePage.clientId);
  const formSubmitCount = useSelector(
    (state) => state.quotationProfilePage.formSubmitCount
  );
  const isQuotationEditable = useSelector(
    (state) => state.quotationProfilePage.isQuotationEditable
  );
  const language = useSelector((state) => state.language.language);
  const quotationId = useSelector(
    (state) => state.quotationProfilePage.quotationId
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfPrices = useSelector(
    (state) => state.quotationProfilePage.typeOfPrices
  );

  // States
  // Data
  const [orderId, setOrderId] = useState(null);
  const [quotationDetails, setQuotationDetails] = useState(null);
  // Dialog
  const [quotationActionDialogText, setQuotationActionDialogText] =
    useState("");
  const [quotationActionDialogType, setQuotationActionDialogType] =
    useState(null);
  const [showQuotationActionDialog, setShowQuotationActionDialog] =
    useState(false);
  const [pdfTypeCode, setPdfTypeCode] = useState(null);
  // Modals
  const [showConfirmQuotationActionModal, setShowConfirmQuotationActionModal] =
    useState(false);
  const [
    showCreateOrderFromQuotationActionModal,
    setShowCreateOrderFromQuotationActionModal,
  ] = useState(false);
  const [showCreatePdfActionModal, setShowCreatePdfActionModal] =
    useState(false);
  const [showFileViewModal, setShowFileViewModal] = useState(false);
  const [showQuotationActionModal, setShowQuotationActionModal] =
    useState(false);
  const [showPdfViewModal, setShowPdfViewModal] = useState(false);

  // Handle States
  const item = quotationDetails;
  const isQuPdfs = item && item.numOfQuPdfs && parseInt(item.numOfQuPdfs) > 0;
  const numOfFiles = item && item.numOfFiles ? parseInt(item.numOfFiles) : 0;

  // Events
  // Events - Box
  const onQuotationItemClicked = () => {
    displayQuotationActionModal();
  };

  // Events - Dialogs
  const onQuotationActionDialogCanceled = () => {
    // Set States
    setShowQuotationActionDialog(false);
  };

  const onQuotationActionDialogConfirmed = () => {
    switch (quotationActionDialogType) {
      case "CancelQuotationConfirmation":
        cancelQuotationConfirmation();
        break;
      default:
        break;
    }

    // Set States
    setShowQuotationActionDialog(false);
  };

  // Events - Forms
  const onCancelQuotationConfirmationBtnClicked = () => {
    displayQuotationActionDialog("CancelQuotationConfirmation");
  };

  const onConfirmQuotationBtnClicked = () => {
    displayConfirmQuotationActionModal();
  };

  const onCreateOrderFromQuotationBtnClicked = () => {
    displayCreateOrderFromQuotationActionModal();
  };

  // Events - Modal
  const onConfirmQuotationActionModalClosed = () => {
    // Set States
    setShowConfirmQuotationActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onCreateOrderFromQuotationActionModalClosed = () => {
    // Set States
    setShowCreateOrderFromQuotationActionModal(false);
  };

  const onCreatePdfActionModalClosed = () => {
    // Set States
    setShowCreatePdfActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onFileViewModalClosed = () => {
    // Set States
    setShowFileViewModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onQuotationActionModalClosed = () => {
    // Set States
    setShowQuotationActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onPdfViewModalClosed = () => {
    // Set States
    setShowPdfViewModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onViewDocumentariesBtnClicked = () => {
    displayFileViewModal();
  };

  const onViewOrderBtnClicked = () => {
    if (orderId) {
      // Update Redux Store
      dispatch(onOrderChange(orderId));
      dispatch(onTabItemChange("BasicInfo"));

      // Navigate
      navigate("/orderProfile");
    }
  };

  const onViewPdfsBtnClicked = (pdfTypeCodeStr) => {
    displayPdfViewModal(pdfTypeCodeStr);
  };

  // Functions
  // Functions - Normal
  const displayConfirmQuotationActionModal = () => {
    setShowConfirmQuotationActionModal(true);
  };

  const displayCreateOrderFromQuotationActionModal = () => {
    setShowCreateOrderFromQuotationActionModal(true);
  };

  const displayCreatePdfActionModal = () => {
    setShowCreatePdfActionModal(true);
  };

  const displayFileViewModal = () => {
    setShowFileViewModal(true);
  };

  const displayQuotationActionDialog = (quotationActionType) => {
    // Set States
    setQuotationActionDialogType(quotationActionType);

    switch (quotationActionType) {
      case "CancelQuotationConfirmation":
        setQuotationActionDialogText(t("確認要取消 確認報價 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowQuotationActionDialog(true);
  };

  const displayQuotationActionModal = () => {
    setShowQuotationActionModal(true);
  };

  const displayPdfViewModal = (pdfTypeCodeStr) => {
    setShowPdfViewModal(true);
    setPdfTypeCode(pdfTypeCodeStr);
  };

  // Functions - Mutations
  const cancelQuotationConfirmation = async () => {
    const results = await cancelQuotationConfirmationFetch(token, quotationId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  // Functions - Queries
  const getQuotationDetailsById = async () => {
    const results = await getQuotationDetailsByIdFetch(
      token,
      quotationId,
      typeOfPrices
    );

    if (results.quotationDetails) {
      results.quotationDetails.requestDateDisplay = results.quotationDetails
        .requestDate
        ? moment(results.quotationDetails.requestDate).format(t("M 月 D 日"))
        : null;

      results.quotationDetails.timeRequestedDisplay = results.quotationDetails
        .time_requested
        ? moment(results.quotationDetails.time_requested).format(t("a h:mm"))
        : null;

      results.quotationDetails.quotationDateDisplay = results.quotationDetails
        .quotationDate
        ? moment(results.quotationDetails.quotationDate).format(t("M 月 D 日"))
        : null;

      results.quotationDetails.timeQuotedDisplay = results.quotationDetails
        .time_quoted
        ? moment(results.quotationDetails.time_quoted).format(t("a h:mm"))
        : null;

      results.quotationDetails.subTotalDisplay = results.quotationDetails
        .sub_total
        ? `${
            typeOfPrices === "InTransactionCurrency"
              ? results.quotationDetails.currency_symbol
              : "HKD"
          } ${separateComma(
            Number(results.quotationDetails.sub_total).toFixed(1)
          )}`
        : null;

      results.quotationDetails.fxRateToHKDDisplay = results.quotationDetails
        .fxRateToHKD
        ? separateComma(
            roundNumber(Number(results.quotationDetails.fxRateToHKD), 6)
          )
        : null;
    }

    // Set States
    setQuotationDetails(
      results.quotationDetails ? results.quotationDetails : null
    );
    setOrderId(results.orderId ? results.orderId : null);

    // Update Redux Store
    dispatch(
      onClientChange(
        results.quotationDetails.client_id
          ? results.quotationDetails.client_id
          : null
      )
    );
    dispatch(
      onIsQuotationEditableChange(
        results.isEditable ? results.isEditable : false
      )
    );
  };

  // Life Cycle
  useEffect(() => {
    if (quotationId) {
      getQuotationDetailsById();
    }
  }, [quotationId, typeOfPrices, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onQuotationActionDialogCanceled}
        onDialogConfirmed={onQuotationActionDialogConfirmed}
        // States
        dialogText={quotationActionDialogText}
        showDialog={showQuotationActionDialog}
      />
      {/* Modals */}
      {quotationId && (
        <ModalContainer
          // Events
          onModalClosed={onConfirmQuotationActionModalClosed}
          // States
          showModal={showConfirmQuotationActionModal}
        >
          <ConfirmQuotationActionModal
            // Events
            onModalClosed={onConfirmQuotationActionModalClosed}
            // States
            quotationId={quotationId}
          />
        </ModalContainer>
      )}
      {quotationId && (
        <ModalContainer
          // Events
          onModalClosed={onCreateOrderFromQuotationActionModalClosed}
          // States
          showModal={showCreateOrderFromQuotationActionModal}
        >
          <CreateOrderFromQuotationActionModal
            // Events
            onModalClosed={onCreateOrderFromQuotationActionModalClosed}
            // States
            quotationId={quotationId}
          />
        </ModalContainer>
      )}
      {quotationId && (
        <ModalContainer
          // Events
          onModalClosed={onCreatePdfActionModalClosed}
          // States
          showModal={showCreatePdfActionModal}
        >
          <CreatePdfActionModal
            // Events
            onModalClosed={onCreatePdfActionModalClosed}
            // States
            pdfTypeCode={pdfTypeCode}
            quotationId={quotationId}
          />
        </ModalContainer>
      )}
      {quotationId && (
        <ModalContainer
          // Events
          onModalClosed={onFileViewModalClosed}
          // States
          showModal={showFileViewModal}
        >
          <FileViewModal
            // States
            quotationId={quotationId}
          />
        </ModalContainer>
      )}
      {clientId && (
        <ModalContainer
          // Events
          onModalClosed={onQuotationActionModalClosed}
          // States
          showModal={showQuotationActionModal}
        >
          <QuotationActionModal
            // Events
            onModalClosed={onQuotationActionModalClosed}
            // States
            clientId={clientId}
            isQuotationEditable={isQuotationEditable}
            quotationId={quotationId}
          />
        </ModalContainer>
      )}
      {quotationId && (
        <ModalContainer
          // Events
          onModalClosed={onPdfViewModalClosed}
          // States
          showModal={showPdfViewModal}
        >
          <PdfViewModal
            // Functions
            displayCreatePdfActionModal={displayCreatePdfActionModal}
            // States
            pdfTypeCode={pdfTypeCode}
            quotationId={quotationId}
          />
        </ModalContainer>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("報價")}
          </Typography>
          <Tooltip placement="left" title={t("查看相關文件s")}>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={numOfFiles}
              color="primary"
            >
              <IconButton
                color={"primary"}
                onClick={onViewDocumentariesBtnClicked}
              >
                <AttachFileRoundedIcon />
              </IconButton>
            </Badge>
          </Tooltip>
          {orderId && (
            <Tooltip placement="right" title={t("查看訂單資料")}>
              <IconButton color={"primary"} onClick={onViewOrderBtnClicked}>
                <AssignmentRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div>
          <Tooltip placement="top" title={t("查看報價單 (Pdf)s")}>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="success"
              invisible={!isQuPdfs}
              variant="dot"
            >
              <Button onClick={() => onViewPdfsBtnClicked("QU")} variant="text">
                QU
              </Button>
            </Badge>
          </Tooltip>
        </div>
      </div>
      {/* Content */}
      {item ? (
        <>
          <QuotationIntroBasicInfoBox
            // States
            item={item}
            // Events
            onItemClicked={onQuotationItemClicked}
          />
          <QuotationIntroPriceInfoBox
            // States
            item={item}
            // Events
            onItemClicked={onQuotationItemClicked}
          />
          {item.quotation_day_id && (
            <QuotationIntroConfirmationInfoBox
              // States
              item={item}
              // Events
              onItemClicked={onQuotationItemClicked}
            />
          )}
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有報價資料")}
          </Typography>
        </div>
      )}
      {item && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("客戶")}
              </Typography>
            </div>
          </div>
          {/* Content */}
          <ClientIntroBasicInfoBox
            // States
            item={item}
            // Events
            onItemClicked={onQuotationItemClicked}
          />
          {item.contact_person_id && (
            <ClientIntroContactPersonInfoBox
              // States
              item={item}
              language={language}
              // Events
              onItemClicked={onQuotationItemClicked}
            />
          )}
          {item.client_location_id && (
            <ClientIntroClientLocationInfoBox
              // States
              item={item}
              // Events
              onItemClicked={onQuotationItemClicked}
            />
          )}
        </>
      )}
      {item && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("銷售員")}
              </Typography>
            </div>
          </div>
          {/* Content */}
          {item.salespersonBasicInfo ? (
            <SalespersonIntroBasicInfoBox
              // States
              item={item}
              // Events
              onItemClicked={onQuotationItemClicked}
            />
          ) : (
            <div className={classes.emptyDataContainer}>
              <Typography align={"center"} color={stylesConfig.greyTextColor}>
                {t("未有銷售員資料")}
              </Typography>
            </div>
          )}
        </>
      )}
      {item && (
        <>
          {item.quotation_status_type_id === 1 && (
            <div className={classes.containerSubmitBtnBox}>
              <Button
                variant="contained"
                onClick={onConfirmQuotationBtnClicked}
                sx={containerSubmitBtnStyles}
              >
                {t("確認報價")}
              </Button>
            </div>
          )}
          {item.quotation_status_type_id === 2 && (
            <div className={classes.containerSubmitBtnBox}>
              <Button
                variant="contained"
                onClick={onCreateOrderFromQuotationBtnClicked}
                sx={containerSubmitBtnStyles}
              >
                {t("確認訂單")}
              </Button>
            </div>
          )}
          {item.quotation_status_type_id === 2 && (
            <div className={classes.containerSubmitBtnBox}>
              <Button
                onClick={onCancelQuotationConfirmationBtnClicked}
                sx={containerSubmitBtnStyles}
              >
                {t("取消確認報價")}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default QuotationIntroBoxesContainer;
