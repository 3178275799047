// Actions
import {
  onConditionPdfTypeChange,
  onTermTemplateChange,
} from "../../../redux/pages/termsAndCurrenciesPage/termsAndCurrenciesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getTermTemplateDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  copyTermTemplateFetch,
  getTermTemplateDetailsByIdFetch,
} from "../../../fetches/termTemplateFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CopyTermTemplateActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    termTemplateId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    copyTermTemplateActionDialogText,
    setCopyTermTemplateActionDialogText,
  ] = useState("");
  const [
    copyTermTemplateActionDialogType,
    setCopyTermTemplateActionDialogType,
  ] = useState(null);
  const [
    showCopyTermTemplateActionDialog,
    setShowCopyTermTemplateActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [pdfTypeField, setPdfTypeField] = useState(null);
  // Options
  const [pdfTypeOptions, setPdfTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [termTemplateName, setTermTemplateName] = useState("");

  // Events
  // Events - Dialogs
  const onCopyTermTemplateActionDialogCanceled = () => {
    // Set States
    setShowCopyTermTemplateActionDialog(false);
  };

  const onCopyTermTemplateActionDialogConfirmed = () => {
    switch (copyTermTemplateActionDialogType) {
      case "CopyTermTemplate":
        copyTermTemplate();
        break;
      default:
        break;
    }

    // Set States
    setShowCopyTermTemplateActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "pdfTypeField":
        setPdfTypeField(value);
        break;
      case "termTemplateName":
        setTermTemplateName(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!pdfTypeField) {
      addToErrorFields("pdfTypeField", t("請先填寫 條款類型"));
      isError = true;
    }

    if (!termTemplateName) {
      addToErrorFields("termTemplateName", t("請先填寫 模板名稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayCopyTermTemplateActionDialog("CopyTermTemplate");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const displayCopyTermTemplateActionDialog = (copyTermTemplateActionType) => {
    // Set States
    setCopyTermTemplateActionDialogType(copyTermTemplateActionType);

    switch (copyTermTemplateActionType) {
      case "CopyTermTemplate":
        setCopyTermTemplateActionDialogText(t("確認要複製 條款模板 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCopyTermTemplateActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const copyTermTemplate = async () => {
    const results = await copyTermTemplateFetch(
      token,
      termTemplateId,
      pdfTypeField ? pdfTypeField.id : null,
      termTemplateName
    );

    if (results.success) {
      onModalClosed();

      if (results.termTemplateId) {
        // Update Redux Store
        dispatch(onConditionPdfTypeChange(pdfTypeField.id));
        dispatch(onTermTemplateChange(results.termTemplateId));
      }
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 模板 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions - Queries
  const getTermTemplateDetailFormOptions = async () => {
    const results = await getTermTemplateDetailFormOptionsFetch(token);

    // Set States
    setPdfTypeOptions(results.pdfTypes ? results.pdfTypes : []);
  };

  const getTermTemplateDetailsById = async () => {
    const results = await getTermTemplateDetailsByIdFetch(
      token,
      termTemplateId
    );

    if (results.termTemplateDetails) {
      const { pdf_type_id, term_template_name } = results.termTemplateDetails;

      // Set States
      setPdfTypeField(
        pdf_type_id
          ? pdfTypeOptions.find((item) => item.id === pdf_type_id)
          : null
      );
      setTermTemplateName(term_template_name ? term_template_name : "");
    }
  };

  // Life Cycle
  useEffect(() => {
    getTermTemplateDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && pdfTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [pdfTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (termTemplateId) {
        getTermTemplateDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, termTemplateId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCopyTermTemplateActionDialogCanceled}
        onDialogConfirmed={onCopyTermTemplateActionDialogConfirmed}
        // States
        dialogText={copyTermTemplateActionDialogText}
        showDialog={showCopyTermTemplateActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("複製條款模板")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={6}>
            <TextField
              error={checkIsFieldError("termTemplateName")}
              fullWidth
              helperText={getErrorFieldMessage("termTemplateName")}
              label={t("模板名稱")}
              margin="dense"
              name="termTemplateName"
              onChange={(event) =>
                onInputFieldChange("termTemplateName", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={termTemplateName}
              variant="standard"
            />
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            {pdfTypeOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("pdf_type_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("pdfTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={pdfTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("pdfTypeField")}
                    helperText={getErrorFieldMessage("pdfTypeField")}
                    label={t("條款類型")}
                    variant="standard"
                  />
                )}
                value={pdfTypeField}
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default CopyTermTemplateActionModal;
