// Configs
import apisConfig from "../configs/apisConfig";

export const createClientLocationFetch = async (
  token,
  clientId,
  districtId,
  locationName,
  address,
  isActive
) => {
  try {
    const queryRoute = "/clientLocation/createClientLocation";

    const reqBody = {
      clientId,
      districtId,
      locationName,
      address,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreClientLocationFetch = async (
  token,
  clientLocationId,
  typeOfClientLocations
) => {
  try {
    const queryRoute = `/clientLocation/deleteOrRestoreClientLocation/${clientLocationId}`;

    const reqBody = { typeOfClientLocations };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editClientLocationFetch = async (
  token,
  clientLocationId,
  districtId,
  locationName,
  address,
  isActive
) => {
  try {
    const queryRoute = `/clientLocation/editClientLocation/${clientLocationId}`;

    const reqBody = {
      districtId,
      locationName,
      address,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllClientLocationsByClientIdFetch = async (
  token,
  clientId,
  typeOfClientLocations
) => {
  try {
    const queryRoute = `/clientLocation/getAllClientLocationsByClientId/${clientId}`;

    const reqBody = { typeOfClientLocations };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getClientLocationDetailsByIdFetch = async (
  token,
  clientLocationId
) => {
  try {
    const queryRoute = `/clientLocation/getClientLocationDetailsById/${clientLocationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
