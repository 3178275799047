// Actions
import { onClientChange } from "../../../redux/pages/clientProfilePage/clientProfilePageActions";

// Components
// Boxes
import ClientBox from "./clientBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import ClientActionMenu from "../../menus/clientsPage/clientActionMenu";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import {
  deleteOrRestoreClientFetch,
  getAllClientsWithPaginationFetch,
} from "../../../fetches/clientFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function ClientBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const keyword = useSelector((state) => state.clientsPage.keyword);
  const token = useSelector((state) => state.staff.token);
  const typeOfClients = useSelector((state) => state.clientsPage.typeOfClients);

  // States
  // Data
  const [clients, setClients] = useState(null);
  // Dialog
  const [clientActionDialogText, setClientActionDialogText] = useState("");
  const [clientActionDialogType, setClientActionDialogType] = useState(null);
  const [showClientActionDialog, setShowClientActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showClientActionMenu, setShowClientActionMenu] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [selectedClientId, setSelectedClientId] = useState(null);

  // Handle States
  const currentItemId = selectedClientId;
  const itemsArr = clients;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onClientChange(itemId));

    // Navigate
    navigate("/clientProfile");
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setSelectedClientId(itemId);
    setShowClientActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setSelectedClientId(itemId);
    setShowClientActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onClientActionDialogCanceled = () => {
    // Set States
    setShowClientActionDialog(false);
  };

  const onClientActionDialogConfirmed = () => {
    switch (clientActionDialogType) {
      case "DeleteClient":
        deleteOrRestoreClient();
        break;
      case "RestoreClient":
        deleteOrRestoreClient();
        break;
      default:
        break;
    }

    // Set States
    setShowClientActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Navigate
    navigate("/createClient");
  };

  // Functions
  // Functions - Normal
  const displayClientActionDialog = (clientActionType) => {
    // Set States
    setClientActionDialogType(clientActionType);

    switch (clientActionType) {
      case "DeleteClient":
        setClientActionDialogText(t("確認要刪除 客戶 嗎？"));
        break;
      case "RestoreClient":
        setClientActionDialogText(t("確認要還原 客戶 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowClientActionDialog(true);
  };

  const getClientsDebounce = debounce((keywordStr) => {
    getAllClientsWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  const resetPage = () => {
    // Set States
    setPageNum(1);
  };

  // Functions - Mutations
  const deleteOrRestoreClient = async () => {
    const results = await deleteOrRestoreClientFetch(
      token,
      currentItemId,
      typeOfClients
    );

    if (results.success) {
      getAllClientsWithPagination(keyword);
    }
  };

  // Functions - Queries
  const getAllClientsWithPagination = async (keywordStr) => {
    const results = await getAllClientsWithPaginationFetch(
      token,
      typeOfClients,
      keywordStr,
      pageNum,
      limitNum
    );

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setClients(results.clients ? results.clients : null);

    if (results.pageCount && !results.clients) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    resetPage();
  }, [typeOfClients, keyword]);

  useEffect(() => {
    getClientsDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getAllClientsWithPagination(keyword);
  }, [typeOfClients, pageNum]);

  return (
    <div className={classes.contentBox}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onClientActionDialogCanceled}
        onDialogConfirmed={onClientActionDialogConfirmed}
        // States
        dialogText={clientActionDialogText}
        showDialog={showClientActionDialog}
      />
      {/* Menu */}
      <ClientActionMenu
        // States
        showClientActionMenu={showClientActionMenu}
        // Set States
        setShowClientActionMenu={setShowClientActionMenu}
        // Fucntions
        displayClientActionDialog={displayClientActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("客戶s")}
          </Typography>
          <Tooltip placement="right" title={t("新增客戶")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <ClientBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有客戶")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ClientBoxesContainer;
