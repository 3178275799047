// Configs
import apisConfig from "../configs/apisConfig";

export const cancelProgressCompletionFetch = async (token, progressId) => {
  try {
    const queryRoute = `/progress/cancelProgressCompletion/${progressId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const completeProgressNowFetch = async (token, progressId) => {
  try {
    const queryRoute = `/progress/completeProgressNow/${progressId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editProgressFetch = async (
  token,
  progressId,
  startDate,
  endDate,
  startTime,
  endTime,
  departmentIdsArr,
  staffIdsArr
) => {
  try {
    const queryRoute = `/progress/editProgress/${progressId}`;

    const reqBody = {
      startDate,
      endDate,
      startTime,
      endTime,
      departmentIdsArr,
      staffIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllProgressesByOrderIdFetch = async (token, orderId) => {
  try {
    const queryRoute = `/progress/getAllProgressesByOrderId/${orderId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllProgressesWithPaginationFetch = async (
  token,
  departmentId,
  staffId,
  procedureId,
  progressStatusTypeId,
  startDate,
  endDate,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/progress/getAllProgressesWithPagination";

    const reqBody = {
      departmentId,
      staffId,
      procedureId,
      progressStatusTypeId,
      startDate,
      endDate,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getProgressDetailsByIdFetch = async (token, progressId) => {
  try {
    const queryRoute = `/progress/getProgressDetailsById/${progressId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const startProgressNowFetch = async (token, progressId) => {
  try {
    const queryRoute = `/progress/startProgressNow/${progressId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
