const initialState = {
  conditionPdfTypeId: null,
  conditionPdfTypeItem: null,
  currencyId: null,
  formSubmitCount: 0,
  keyword: "",
  tabItem: "Terms",
  termTemplateId: null,
  typeOfContent: "TermContent",
  typeOfCurrencies: "Current",
  typeOfTerms: "Current",
};

export const termsAndCurrenciesPageReducers = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "ON_TERMS_AND_CURRENCIES_PAGE_CONDITION_PDF_TYPE_CHANGE":
      const { conditionPdfTypeId } = action;
      return {
        ...state,
        conditionPdfTypeId,
      };
    case "ON_TERMS_AND_CURRENCIES_PAGE_CONDITION_PDF_TYPE_ITEM_CHANGE":
      const { conditionPdfTypeItem } = action;
      return {
        ...state,
        conditionPdfTypeItem,
      };
    case "ON_TERMS_AND_CURRENCIES_PAGE_CURRENCY_CHANGE":
      const { currencyId } = action;
      return {
        ...state,
        currencyId,
      };
    case "ON_TERMS_AND_CURRENCIES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_TERMS_AND_CURRENCIES_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_TERMS_AND_CURRENCIES_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_TERMS_AND_CURRENCIES_PAGE_TERM_TEMPLATE_CHANGE":
      const { termTemplateId } = action;
      return {
        ...state,
        termTemplateId,
      };
    case "ON_TERMS_AND_CURRENCIES_PAGE_TYPE_OF_CONTENT_CHANGE":
      const { typeOfContent } = action;
      return {
        ...state,
        typeOfContent,
      };
    case "ON_TERMS_AND_CURRENCIES_PAGE_TYPE_OF_CURRENCIES_CHANGE":
      const { typeOfCurrencies } = action;
      return {
        ...state,
        typeOfCurrencies,
      };
    case "ON_TERMS_AND_CURRENCIES_PAGE_TYPE_OF_TERMS_CHANGE":
      const { typeOfTerms } = action;
      return {
        ...state,
        typeOfTerms,
      };
    default:
      return state;
  }
};
