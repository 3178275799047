// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseQuotedDetailFormDiscountInfoContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    actualCost,
    actualUnitPrice,
    currencyDetails,
    formAlertText,
    formAlertType,
    isQuotationEditable,
    merchandiseQuotedId,
    quotationDiscountDisplayTypeField,
    quotationDiscountDisplayTypeOptions,
    shouldShowFormAlert,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  // Handle States
  const currencySymbol = currencyDetails
    ? currencyDetails.currency_symbol
    : null;
  const fxRateToHKD = currencyDetails ? currencyDetails.fxRateToHKD : null;
  const isCurrencyHKD = currencySymbol && currencySymbol === "HKD";

  return (
    <>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("優惠資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            disabled={!isQuotationEditable}
            error={checkIsFieldError("actualUnitPrice")}
            fullWidth
            helperText={
              getErrorFieldMessage("actualUnitPrice")
                ? getErrorFieldMessage("actualUnitPrice")
                : fxRateToHKD &&
                  actualUnitPrice &&
                  !isNaN(actualUnitPrice) &&
                  !isCurrencyHKD
                ? `HKD ${separateComma(
                    Number(actualUnitPrice * fxRateToHKD).toFixed(1)
                  )}`
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currencySymbol}
                </InputAdornment>
              ),
            }}
            label={t("價錢")}
            margin="dense"
            name="actualUnitPrice"
            onChange={(event) =>
              onInputFieldChange("actualUnitPrice", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={actualUnitPrice}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={!isQuotationEditable}
            error={checkIsFieldError("actualCost")}
            fullWidth
            helperText={
              getErrorFieldMessage("actualCost")
                ? getErrorFieldMessage("actualCost")
                : fxRateToHKD &&
                  actualCost &&
                  !isNaN(actualCost) &&
                  !isCurrencyHKD
                ? `HKD ${separateComma(
                    Number(actualCost * fxRateToHKD).toFixed(1)
                  )}`
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currencySymbol}
                </InputAdornment>
              ),
            }}
            label={t("總價")}
            margin="dense"
            name="actualCost"
            onChange={(event) =>
              onInputFieldChange("actualCost", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={actualCost}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={12}>
          {quotationDiscountDisplayTypeOptions && (
            <Autocomplete
              disabled={!isQuotationEditable}
              disablePortal
              getOptionLabel={(option) =>
                option[t("quotation_discount_display_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("quotationDiscountDisplayTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={quotationDiscountDisplayTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("quotationDiscountDisplayTypeField")}
                  helperText={getErrorFieldMessage(
                    "quotationDiscountDisplayTypeField"
                  )}
                  label={t("優惠顯示類別")}
                  variant="standard"
                />
              )}
              value={quotationDiscountDisplayTypeField}
            />
          )}
        </Grid>
        {isQuotationEditable && (
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {merchandiseQuotedId ? t("修改") : t("新增")}
            </Button>
          </Grid>
        )}
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default MerchandiseQuotedDetailFormDiscountInfoContent;
