// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createPdfForInvoiceFetch,
  createPdfForProformaInvoiceFetch,
  createPdfForPurchaseOrderFetch,
} from "../../../fetches/pdfFetches";
import { getAllAvailableTermTemplatesByOrderIdFetch } from "../../../fetches/termTemplateFetches";

// Helper Functions
import { getOrderPdfTypeNameCh } from "../../../helperFunctions/getOrderPdfTypeName";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CreatePdfActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    pdfTypeCode,
    orderId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [pdfActionDialogText, setPdfActionDialogText] = useState("");
  const [pdfActionDialogType, setPdfActionDialogType] = useState(null);
  const [showPdfActionDialog, setShowPdfActionDialog] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [termTemplateField, setTermTemplateField] = useState(null);
  // Options
  const [termTemplateOptions, setTermTemplateOptions] = useState([]);
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Handle States
  const pdfTypeNameCh = getOrderPdfTypeNameCh(pdfTypeCode);

  // Events
  // Events - Dialogs
  const onPdfActionDialogCanceled = () => {
    // Set States
    setShowPdfActionDialog(false);
  };

  const onPdfActionDialogConfirmed = () => {
    switch (pdfActionDialogType) {
      case "CreatePdfForOrder":
        createPdfForOrder();
        break;
      default:
        break;
    }

    // Set States
    setShowPdfActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "termTemplateField":
        setTermTemplateField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!termTemplateField) {
      addToErrorFields("termTemplateField", t("請先填寫 條款模板"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayPdfActionDialog("CreatePdfForOrder");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayPdfActionDialog = (pdfActionType) => {
    // Set States
    setPdfActionDialogType(pdfActionType);

    switch (pdfActionType) {
      case "CreatePdfForOrder":
        setPdfActionDialogText(t(`確認要 創建${pdfTypeNameCh} (Pdf) 嗎？`));
        break;
      default:
        break;
    }

    // Set States
    setShowPdfActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createPdfForInvoice = async () => {
    // Set States
    setIsLoading(true);

    const results = await createPdfForInvoiceFetch(
      token,
      orderId,
      termTemplateField ? termTemplateField.id : null
    );
    if (results.success) {
      onModalClosed();
    } else if (results.notAllDetailFilled) {
      showFormAlert("warning", t("請先填寫 銷售員 / 聯絡人 / 地點"));
    } else if (results.notAllMerchandiseQuoted) {
      showFormAlert("warning", t("請先完成所有 商品報價"));
    } else {
      showFormAlert("error", t("未能提交"));
    }

    // Set States
    setIsLoading(false);
  };

  const createPdfForOrder = async () => {
    switch (pdfTypeCode) {
      case "IN":
        createPdfForInvoice();
        break;
      case "PI":
        createPdfForProformaInvoice();
        break;
      case "PO":
        createPdfForPurchaseOrder();
        break;
      default:
        break;
    }
  };

  const createPdfForProformaInvoice = async () => {
    // Set States
    setIsLoading(true);

    const results = await createPdfForProformaInvoiceFetch(
      token,
      orderId,
      termTemplateField ? termTemplateField.id : null
    );
    if (results.success) {
      onModalClosed();
    } else if (results.notAllDetailFilled) {
      showFormAlert("warning", t("請先填寫 銷售員 / 聯絡人 / 地點"));
    } else if (results.notAllMerchandiseQuoted) {
      showFormAlert("warning", t("請先完成所有 商品報價"));
    } else {
      showFormAlert("error", t("未能提交"));
    }

    // Set States
    setIsLoading(false);
  };

  const createPdfForPurchaseOrder = async () => {
    // Set States
    setIsLoading(true);

    const results = await createPdfForPurchaseOrderFetch(
      token,
      orderId,
      termTemplateField ? termTemplateField.id : null
    );
    if (results.success) {
      onModalClosed();
    } else if (results.notAllDetailFilled) {
      showFormAlert("warning", t("請先填寫 銷售員 / 聯絡人 / 地點"));
    } else if (results.notAllMerchandiseQuoted) {
      showFormAlert("warning", t("請先完成所有 商品報價"));
    } else {
      showFormAlert("error", t("未能提交"));
    }

    // Set States
    setIsLoading(false);
  };

  // Functions - Queries
  const getAllAvailableTermTemplatesByOrderId = async () => {
    const results = await getAllAvailableTermTemplatesByOrderIdFetch(
      token,
      orderId,
      pdfTypeCode
    );

    // Set States
    setTermTemplateField(
      results.suggestedTermTemplateId &&
        results.termTemplates.some(
          (item) => item.id === results.suggestedTermTemplateId
        )
        ? results.termTemplates.find(
            (item) => item.id === results.suggestedTermTemplateId
          )
        : null
    );
    setTermTemplateOptions(results.termTemplates ? results.termTemplates : []);

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (orderId && pdfTypeCode) {
      getAllAvailableTermTemplatesByOrderId();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [orderId, pdfTypeCode]);

  return (
    <div className={classes.modalContainerSmall}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPdfActionDialogCanceled}
        onDialogConfirmed={onPdfActionDialogConfirmed}
        // States
        dialogText={pdfActionDialogText}
        showDialog={showPdfActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t(`創建${pdfTypeNameCh} (Pdf)`)}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          {termTemplateOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.term_template_name}
                onChange={(event, value) =>
                  onInputFieldChange("termTemplateField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={termTemplateOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("termTemplateField")}
                    helperText={getErrorFieldMessage("termTemplateField")}
                    label={t("條款模板")}
                    variant="standard"
                  />
                )}
                value={termTemplateField}
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default CreatePdfActionModal;
