// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createContactPersonFetch,
  editContactPersonFetch,
  getContactPersonDetailsByIdFetch,
} from "../../../fetches/contactPersonFetches";
import { getContactPersonDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ContactPersonActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    contactPersonId,
  } = props;

  // Redux Store
  const clientId = useSelector((state) => state.clientProfilePage.clientId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [contactPersonActionDialogText, setContactPersonActionDialogText] =
    useState("");
  const [contactPersonActionDialogType, setContactPersonActionDialogType] =
    useState(null);
  const [showContactPersonActionDialog, setShowContactPersonActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [personTitleField, setPersonTitleField] = useState(null);
  // Options
  const [personTitleOptions, setPersonTitleOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [contactNum, setContactNum] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [email, setEmail] = useState("");

  // Events
  // Events - Dialogs
  const onContactPersonActionDialogCanceled = () => {
    // Set States
    setShowContactPersonActionDialog(false);
  };

  const onContactPersonActionDialogConfirmed = () => {
    switch (contactPersonActionDialogType) {
      case "CreateContactPerson":
        createContactPerson();
        break;
      case "EditContactPerson":
        editContactPerson();
        break;
      default:
        break;
    }

    // Set States
    setShowContactPersonActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "contactNum":
        setContactNum(value);
        break;
      case "contactPersonName":
        setContactPersonName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "personTitleField":
        setPersonTitleField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!contactPersonName) {
      addToErrorFields("contactPersonName", t("請先填寫 聯絡人名稱"));
      isError = true;
    }

    if (!personTitleField) {
      addToErrorFields("personTitleField", t("請先填寫 稱謂"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayContactPersonActionDialog(
      contactPersonId ? "EditContactPerson" : "CreateContactPerson"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayContactPersonActionDialog = (contactPersonActionType) => {
    // Set States
    setContactPersonActionDialogType(contactPersonActionType);

    switch (contactPersonActionType) {
      case "CreateContactPerson":
        setContactPersonActionDialogText(t("確認要新增 聯絡人 嗎？"));
        break;
      case "EditContactPerson":
        setContactPersonActionDialogText(t("確認要編輯 聯絡人 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowContactPersonActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createContactPerson = async () => {
    const results = await createContactPersonFetch(
      token,
      clientId,
      personTitleField ? personTitleField.id : null,
      contactPersonName,
      contactNum,
      email,
      isItemActive
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 聯絡人 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editContactPerson = async () => {
    const results = await editContactPersonFetch(
      token,
      contactPersonId,
      personTitleField ? personTitleField.id : null,
      contactPersonName,
      contactNum,
      email,
      isItemActive
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 聯絡人 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getContactPersonDetailFormOptions = async () => {
    const results = await getContactPersonDetailFormOptionsFetch(token);

    // Set States
    setPersonTitleOptions(results.personTitles ? results.personTitles : []);
  };

  const getContactPersonDetailsById = async () => {
    const results = await getContactPersonDetailsByIdFetch(
      token,
      contactPersonId
    );

    if (results.contactPersonDetails) {
      const {
        contact_person_name,
        person_title_id,
        contact_num,
        email,
        is_active,
      } = results.contactPersonDetails;

      // Set States
      setContactPersonName(contact_person_name ? contact_person_name : "");
      setPersonTitleField(
        person_title_id
          ? personTitleOptions.find((item) => item.id === person_title_id)
          : null
      );
      setContactNum(contact_num ? contact_num : "");
      setEmail(email ? email : "");
      setIsItemActive(is_active);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getContactPersonDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && personTitleOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [personTitleOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (contactPersonId) {
        getContactPersonDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, contactPersonId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onContactPersonActionDialogCanceled}
        onDialogConfirmed={onContactPersonActionDialogConfirmed}
        // States
        dialogText={contactPersonActionDialogText}
        showDialog={showContactPersonActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {contactPersonId ? t("編輯聯絡人") : t("新增聯絡人")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("contactPersonName")}
              fullWidth
              helperText={getErrorFieldMessage("contactPersonName")}
              label={t("聯絡人名稱")}
              margin="dense"
              name="contactPersonName"
              onChange={(event) =>
                onInputFieldChange("contactPersonName", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={contactPersonName}
              variant="standard"
            />
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            {personTitleOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("person_title_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("personTitleField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={personTitleOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("personTitleField")}
                    helperText={getErrorFieldMessage("personTitleField")}
                    label={t("稱謂")}
                    variant="standard"
                  />
                )}
                value={personTitleField}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              error={checkIsFieldError("contactNum")}
              fullWidth
              helperText={getErrorFieldMessage("contactNum")}
              label={t("電話號碼")}
              margin="dense"
              name="contactNum"
              onChange={(event) =>
                onInputFieldChange("contactNum", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={contactNum}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("email")}
              fullWidth
              helperText={getErrorFieldMessage("email")}
              label={t("電郵地址")}
              margin="dense"
              name="email"
              onChange={(event) =>
                onInputFieldChange("email", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={email}
              variant="standard"
            />
          </Grid>
          <Grid className={classes.formSwitchContainer} item xs={12}>
            <Typography align={"left"} variant="body1">
              {t("有效")}
            </Typography>
            <Switch
              checked={isItemActive}
              onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default ContactPersonActionModal;
