// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function ClientLocationActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showClientLocationActionMenu,
    // Set States
    setShowClientLocationActionMenu,
    // Functions
    displayClientLocationActionDialog,
    displayClientLocationActionModal,
  } = props;

  // Redux Store
  const typeOfClientLocations = useSelector(
    (state) => state.clientProfilePage.typeOfClientLocations
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowClientLocationActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayClientLocationActionDialog("DeleteClientLocation");
        break;
      case "Edit":
        displayClientLocationActionModal();
        break;
      case "Restore":
        displayClientLocationActionDialog("RestoreClientLocation");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showClientLocationActionMenu}
      open={Boolean(showClientLocationActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        {t("編輯")}
      </MenuItem>
      {typeOfClientLocations === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default ClientLocationActionMenu;
