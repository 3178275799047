// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import OrderBoxesContainer from "../components/boxes/ordersPage/orderBoxesContainer";
import OrderConditionBoxesContainer from "../components/boxes/ordersPage/orderConditionBoxesContainer";
import ProcedureBoxesContainer from "../components/boxes/ordersPage/procedureBoxesContainer";
// Forms
import ProcedureDetailForm from "../components/forms/ordersPage/procedureDetailForm";
// Headers
import OrdersPageHeader from "../components/headers/ordersPage/ordersPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function OrdersPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.ordersPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Orders"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <OrdersPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "Orders" && <OrderConditionBoxesContainer />}
          {tabItem === "Procedures" && <ProcedureBoxesContainer />}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "Orders" && <OrderBoxesContainer />}
          {tabItem === "Procedures" && <ProcedureDetailForm />}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default OrdersPage;
