export const createInitialClientLocationItem = () => {
  return {
    id: 1,
    areaField: null,
    districtField: null,
    locationName: "",
    address: "",
  };
};

export const createClientLocationItem = (currentClientLocations) => {
  const itemId = currentClientLocations[0]
    ? currentClientLocations[currentClientLocations.length - 1].id + 1
    : 1;

  return {
    id: itemId,
    areaField: null,
    districtField: null,
    locationName: "",
    address: "",
  };
};
