// Actions
import { closeDrawer } from "../../redux/shared/drawer/drawerActions";

// Components
// List Item
import LeftDrawerListItem from "./leftDrawerListItem";

// Configs
import stylesConfig from "../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../customHooks/getLanguage";

// Helper Functions
import { getPagePath } from "../../helperFunctions/getPagePath";

// Material UI
// Components
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// Icons
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import BarChartIcon from "@mui/icons-material/BarChart";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import RequestPageRoundedIcon from "@mui/icons-material/RequestPageRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import WorkHistoryRoundedIcon from "@mui/icons-material/WorkHistoryRounded";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useLocation, useNavigate } from "react-router-dom";

// Styles
import { dividerStyles } from "../../styles/componentStyles/drawerStyles/leftDrawerStyles";

function LeftDrawerList() {
  // Hooks
  // Languages
  const t = useLanguage();
  // React-Router
  const location = useLocation();
  const navigate = useNavigate();
  // Redux
  const dispatch = useDispatch();

  // Events
  // Events - Drawer Items
  const onDrawerItemClicked = (key) => {
    const path = getPagePath(key);

    // Navigate
    navigate(path);

    // Update Redux Store
    dispatch(closeDrawer());
  };

  return (
    <List>
      <ListItem sx={{ height: stylesConfig.leftDrawerHeight }}>
        <ListItemText primary={t("客戶關係管理系統")} />
      </ListItem>
      <Divider sx={dividerStyles} />
      {/* 銷售報告 */}
      <LeftDrawerListItem
        // Render
        icon={<PointOfSaleIcon />}
        itemKey={"SalesReports"}
        location={location}
        pathnamesArr={["/salesReports"]}
        listItemText={t("銷售報告s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 商品銷量 */}
      <LeftDrawerListItem
        // Render
        icon={<BarChartIcon />}
        itemKey={"MerchandiseSales"}
        location={location}
        pathnamesArr={["/merchandiseSales"]}
        listItemText={t("商品銷量s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 工作 */}
      {/* <LeftDrawerListItem
        // Render
        icon={<WorkHistoryRoundedIcon />}
        itemKey={"Jobs"}
        location={location}
        pathnamesArr={["/jobs"]}
        listItemText={t("工作s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      /> */}
      {/* 訂單 */}
      <LeftDrawerListItem
        // Render
        icon={<ListAltRoundedIcon />}
        itemKey={"Orders"}
        location={location}
        pathnamesArr={["/orders", "/orderProfile"]}
        listItemText={t("訂單s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 報價 */}
      <LeftDrawerListItem
        // Render
        icon={<RequestPageRoundedIcon />}
        itemKey={"Quotations"}
        location={location}
        pathnamesArr={["/quotations", "/quotationProfile"]}
        listItemText={t("報價s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 客戶 */}
      <LeftDrawerListItem
        // Render
        icon={<AssignmentIndRoundedIcon />}
        itemKey={"Clients"}
        location={location}
        pathnamesArr={["/clients", "/clientProfile", "/createClient"]}
        listItemText={t("客戶s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 商品 */}
      <LeftDrawerListItem
        // Render
        icon={<ShoppingCartIcon />}
        itemKey={"Merchandises"}
        location={location}
        pathnamesArr={["/merchandises"]}
        listItemText={t("商品s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 類別 / 品牌 */}
      <LeftDrawerListItem
        // Render
        icon={<ShoppingBagRoundedIcon />}
        itemKey={"CategoriesAndBrands"}
        location={location}
        pathnamesArr={["/categoriesAndBrands"]}
        listItemText={t("類別 / 品牌s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 條款 / 貨幣 */}
      <LeftDrawerListItem
        // Render
        icon={<AttachMoneyRoundedIcon />}
        itemKey={"TermsAndCurrencies"}
        location={location}
        pathnamesArr={["/termsAndCurrencies"]}
        listItemText={t("條款 / 貨幣s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
    </List>
  );
}

export default LeftDrawerList;
