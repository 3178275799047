// Actions
import {
  onTabItemChange,
  onTypeOfClientLocationsChange,
  onTypeOfContactPersonsChange,
} from "../../../redux/pages/clientProfilePage/clientProfilePageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function ClientProfilePageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.clientProfilePage.tabItem);
  const typeOfClientLocations = useSelector(
    (state) => state.clientProfilePage.typeOfClientLocations
  );
  const typeOfContactPersons = useSelector(
    (state) => state.clientProfilePage.typeOfContactPersons
  );

  // Events
  // Events - Select Box
  const onTypeOfClientLocationsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfClientLocationsChange(value));
  };

  const onTypeOfContactPersonsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfContactPersonsChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"BasicInfo"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("概要")}
                </Typography>
              }
              value={"BasicInfo"}
            />
          </Tabs>
        )}
      </div>
      {/* Select Boxes */}
      <div>
        {tabItem === "BasicInfo" && (
          <>
            <FormControl variant="standard" sx={selectBoxContainerStyles}>
              <Select
                value={typeOfContactPersons}
                onChange={(event) =>
                  onTypeOfContactPersonsSelectBoxValueChanged(
                    event.target.value
                  )
                }
              >
                <MenuItem value={"Current"}>{t("顯示有效聯絡人")}</MenuItem>
                <MenuItem value={"Deleted"}>{t("顯示已刪除聯絡人")}</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {tabItem === "BasicInfo" && (
          <>
            <FormControl variant="standard" sx={selectBoxContainerStyles}>
              <Select
                value={typeOfClientLocations}
                onChange={(event) =>
                  onTypeOfClientLocationsSelectBoxValueChanged(
                    event.target.value
                  )
                }
              >
                <MenuItem value={"Current"}>{t("顯示有效地點")}</MenuItem>
                <MenuItem value={"Deleted"}>{t("顯示已刪除地點")}</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
      </div>
    </Box>
  );
}

export default ClientProfilePageHeader;
