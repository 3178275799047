const initialState = {
  clientId: null,
  formSubmitCount: 0,
  isOrderEditable: false,
  merchandiseOrderedId: null,
  orderId: null,
  progressId: null,
  selectedMerchandiseId: null,
  selectedUnitId: null,
  tabItem: "BasicInfo",
  typeOfPrices: "InTransactionCurrency",
};

export const orderProfilePageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_ORDER_PROFILE_PAGE_CLIENT_CHANGE":
      const { clientId } = action;
      return {
        ...state,
        clientId,
      };
    case "ON_ORDER_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_ORDER_PROFILE_PAGE_IS_ORDER_EDITABLE_CHANGE":
      const { isOrderEditable } = action;
      return {
        ...state,
        isOrderEditable,
      };
    case "ON_ORDER_PROFILE_PAGE_MERCHANDISE_ORDERED_CHANGE":
      const { merchandiseOrderedId } = action;
      return {
        ...state,
        merchandiseOrderedId,
      };
    case "ON_ORDER_PROFILE_PAGE_ORDER_CHANGE":
      const { orderId } = action;
      return {
        ...state,
        orderId,
      };
    case "ON_ORDER_PROFILE_PAGE_PROGRESS_CHANGE":
      const { progressId } = action;
      return {
        ...state,
        progressId,
      };
    case "ON_ORDER_PROFILE_PAGE_SELECTED_MERCHANDISE_CHANGE":
      const { selectedMerchandiseId } = action;
      return {
        ...state,
        selectedMerchandiseId,
      };
    case "ON_ORDER_PROFILE_PAGE_SELECTED_UNIT_CHANGE":
      const { selectedUnitId } = action;
      return {
        ...state,
        selectedUnitId,
      };
    case "ON_ORDER_PROFILE_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_ORDER_PROFILE_PAGE_TYPE_OF_PRICES_CHANGE":
      const { typeOfPrices } = action;
      return {
        ...state,
        typeOfPrices,
      };
    default:
      return state;
  }
};
