// Actions
import { onPaymentTermSettingChange } from "../../../redux/pages/clientsPage/clientsPageActions";

// Components
// Boxes
import PaymentTermSettingBox from "./paymentTermSettingBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import PaymentTermSettingActionMenu from "../../menus/clientsPage/paymentTermSettingActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestorePaymentTermSettingFetch,
  editPaymentTermSettingSetIsDefaultFetch,
  getAllPaymentTermSettingsFetch,
} from "../../../fetches/paymentTermSettingFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PaymentTermSettingBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const paymentTermSettingId = useSelector(
    (state) => state.clientsPage.paymentTermSettingId
  );
  const formSubmitCount = useSelector(
    (state) => state.clientsPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfClientSettings = useSelector(
    (state) => state.clientsPage.typeOfClientSettings
  );

  // States
  // Data
  const [paymentTermSettings, setPaymentTermSettings] = useState(null);
  // Dialog
  const [
    paymentTermSettingActionDialogText,
    setPaymentTermSettingActionDialogText,
  ] = useState("");
  const [
    paymentTermSettingActionDialogType,
    setPaymentTermSettingActionDialogType,
  ] = useState(null);
  const [
    showPaymentTermSettingActionDialog,
    setShowPaymentTermSettingActionDialog,
  ] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [
    showPaymentTermSettingActionMenu,
    setShowPaymentTermSettingActionMenu,
  ] = useState(null);
  // Render
  const [selectedPaymentTermSetting, setSelectedPaymentTermSetting] =
    useState(null);

  // Handle States
  const currentItemId = paymentTermSettingId;
  const itemsArr = paymentTermSettings;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onPaymentTermSettingChange(itemId));
  };

  const onItemRightClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedPaymentTermSetting(item);
    setShowPaymentTermSettingActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedPaymentTermSetting(item);
    setShowPaymentTermSettingActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onPaymentTermSettingActionDialogCanceled = () => {
    // Set States
    setShowPaymentTermSettingActionDialog(false);
  };

  const onPaymentTermSettingActionDialogConfirmed = () => {
    switch (paymentTermSettingActionDialogType) {
      // 刪除
      case "DeletePaymentTermSetting":
        deleteOrRestorePaymentTermSetting();
        break;
      // 設為一般選項
      case "RemovePaymentTermSettingDefault":
        editPaymentTermSettingSetIsDefault();
        break;
      // 還原
      case "RestorePaymentTermSetting":
        deleteOrRestorePaymentTermSetting();
        break;
      // 設為預設選項
      case "SetPaymentTermSettingDefault":
        editPaymentTermSettingSetIsDefault();
        break;
      default:
        break;
    }

    // Set States
    setShowPaymentTermSettingActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onPaymentTermSettingChange(null));
  };

  // Functions
  // Functions - Normal
  const displayPaymentTermSettingActionDialog = (
    paymentTermSettingActionType
  ) => {
    // Set States
    setPaymentTermSettingActionDialogType(paymentTermSettingActionType);

    switch (paymentTermSettingActionType) {
      // 刪除
      case "DeletePaymentTermSetting":
        setPaymentTermSettingActionDialogText(
          t("確認要刪除 付款期限設定 嗎？")
        );
        break;
      // 設為一般選項
      case "RemovePaymentTermSettingDefault":
        setPaymentTermSettingActionDialogText(t("確認要設為 一般選項 嗎？"));
        break;
      // 還原
      case "RestorePaymentTermSetting":
        setPaymentTermSettingActionDialogText(
          t("確認要還原 付款期限設定 嗎？")
        );
        break;
      // 設為預設選項
      case "SetPaymentTermSettingDefault":
        setPaymentTermSettingActionDialogText(t("確認要設為 預設選項 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowPaymentTermSettingActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestorePaymentTermSetting = async () => {
    const results = await deleteOrRestorePaymentTermSettingFetch(
      token,
      currentItemId,
      typeOfClientSettings
    );

    if (results.success) {
      getAllPaymentTermSettings(true);
    }
  };

  const editPaymentTermSettingSetIsDefault = async () => {
    const results = await editPaymentTermSettingSetIsDefaultFetch(
      token,
      currentItemId,
      !selectedPaymentTermSetting.is_default
    );

    if (results.success) {
      getAllPaymentTermSettings(false);
    }
  };

  // Functions - Queries
  const getAllPaymentTermSettings = async (
    shoulddUpdateCurrentPaymentTermSettingId
  ) => {
    const results = await getAllPaymentTermSettingsFetch(
      token,
      typeOfClientSettings
    );

    // Set States
    setPaymentTermSettings(
      results.paymentTermSettings ? results.paymentTermSettings : null
    );

    // Update Redux Store
    if (shoulddUpdateCurrentPaymentTermSettingId) {
      dispatch(
        onPaymentTermSettingChange(
          results.paymentTermSettings ? results.paymentTermSettings[0].id : null
        )
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllPaymentTermSettings(paymentTermSettingId ? false : true);
  }, [formSubmitCount, typeOfClientSettings]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPaymentTermSettingActionDialogCanceled}
        onDialogConfirmed={onPaymentTermSettingActionDialogConfirmed}
        // States
        dialogText={paymentTermSettingActionDialogText}
        showDialog={showPaymentTermSettingActionDialog}
      />
      {/* Menu */}
      <PaymentTermSettingActionMenu
        // States
        selectedPaymentTermSetting={selectedPaymentTermSetting}
        showPaymentTermSettingActionMenu={showPaymentTermSettingActionMenu}
        // Set States
        setSelectedPaymentTermSetting={setSelectedPaymentTermSetting}
        setShowPaymentTermSettingActionMenu={
          setShowPaymentTermSettingActionMenu
        }
        setPaymentTermSettingActionDialogType={
          setPaymentTermSettingActionDialogType
        }
        // Functions
        displayPaymentTermSettingActionDialog={
          displayPaymentTermSettingActionDialog
        }
      />
      {/* PaymentTermSetting */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("付款期限設定s")}
          </Typography>
          <Tooltip placement="right" title={t("新增付款期限設定")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <PaymentTermSettingBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有付款期限設定資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PaymentTermSettingBoxesContainer;
