export function onClientChange(clientId) {
  return {
    type: "ON_SALES_REPORTS_PAGE_CLIENT_CHANGE",
    clientId,
  };
}

export function onComparisonRecordDateChange(comparisonRecordDate) {
  return {
    type: "ON_SALES_REPORTS_PAGE_COMPARISON_RECORD_DATE_CHANGE",
    comparisonRecordDate,
  };
}

export function onConditionUnitChange(conditionUnitId) {
  return {
    type: "ON_SALES_REPORTS_PAGE_CONDITION_UNIT_CHANGE",
    conditionUnitId,
  };
}

export function onConditionUnitItemChange(conditionUnitItem) {
  return {
    type: "ON_SALES_REPORTS_PAGE_CONDITION_UNIT_ITEM_CHANGE",
    conditionUnitItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_SALES_REPORTS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onIsComparisonShownChange(isComparisonShown) {
  return {
    type: "ON_SALES_REPORTS_PAGE_IS_COMPARISON_CHANGE",
    isComparisonShown,
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_SALES_REPORTS_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onOverallSalesNumberTypeChange(overallSalesNumberType) {
  return {
    type: "ON_SALES_REPORTS_PAGE_OVERALL_SALES_NUMBER_TYPE_CHANGE",
    overallSalesNumberType,
  };
}

export function onRankingsSalesNumberTypeChange(rankingsSalesNumberType) {
  return {
    type: "ON_SALES_REPORTS_PAGE_RANKINGS_SALES_NUMBER_TYPE_CHANGE",
    rankingsSalesNumberType,
  };
}

export function onRecordDateChange(recordDate) {
  return {
    type: "ON_SALES_REPORTS_PAGE_RECORD_DATE_CHANGE",
    recordDate,
  };
}

export function onSalespersonChange(salespersonId) {
  return {
    type: "ON_SALES_REPORTS_PAGE_SALESPERSON_CHANGE",
    salespersonId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_SALES_REPORTS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
