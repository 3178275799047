// Actions
import {
  onDepositSettingChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/clientsPage/clientsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getDepositSettingFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createDepositSettingFetch,
  editDepositSettingFetch,
  getDepositSettingDetailsByIdFetch,
} from "../../../fetches/depositSettingFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DepositSettingForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const depositSettingId = useSelector(
    (state) => state.clientsPage.depositSettingId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [depositSettingActionDialogText, setDepositSettingActionDialogText] =
    useState("");
  const [depositSettingActionDialogType, setDepositSettingActionDialogType] =
    useState(null);
  const [showDepositSettingActionDialog, setShowDepositSettingActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [depositTypeField, setDepositTypeField] = useState(null);
  // Options
  const [depositTypeOptions, setDepositTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isDefault, setIsDefault] = useState(false);
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [depositNum, setDepositNum] = useState("");

  // Handle States
  const availableDepositTypeOptions = depositTypeOptions[0]
    ? // 需即時付清
      depositTypeField && depositTypeField.id === 1
      ? depositTypeOptions.filter((item) => item.id === 1)
      : depositTypeOptions.filter((item) => item.id !== 1)
    : [];
  const isDepositNumDisabled = depositTypeField && depositTypeField.id === 1;
  // 定額
  const shouldShowDollarSign = depositTypeField && depositTypeField.id === 3;
  // 比例
  const shouldShowPercentageSign =
    depositTypeField && depositTypeField.id === 2;

  // Events
  // Events - Dialogs
  const onDepositSettingActionDialogCanceled = () => {
    // Set States
    setShowDepositSettingActionDialog(false);
  };

  const onDepositSettingActionDialogConfirmed = () => {
    switch (depositSettingActionDialogType) {
      case "CreateDepositSetting":
        createDepositSetting();
        break;
      case "EditDepositSetting":
        editDepositSetting();
        break;
      default:
        break;
    }

    // Set States
    setShowDepositSettingActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "depositNum":
        setDepositNum(value);
        break;
      case "depositTypeField":
        setDepositTypeField(value);
        break;
      case "isDefault":
        setIsDefault(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!depositNum) {
      addToErrorFields("depositNum", t("請先填寫 訂金"));
      isError = true;
    } else {
      if (isNaN(depositNum)) {
        addToErrorFields("depositNum", t("訂金 必須為數字"));
        isError = true;
      } else if (depositNum < 1) {
        addToErrorFields("depositNum", t("訂金 必須大於 0"));
        isError = true;
      }
    }

    if (!depositTypeField) {
      addToErrorFields("depositTypeField", t("請先填寫 訂金類型"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayDepositSettingActionDialog(
      depositSettingId ? "EditDepositSetting" : "CreateDepositSetting"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setDepositTypeField(null);
    setDepositNum("");
    setIsDefault(false);
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayDepositSettingActionDialog = (depositSettingActionType) => {
    // Set States
    setDepositSettingActionDialogType(depositSettingActionType);

    switch (depositSettingActionType) {
      case "CreateDepositSetting":
        setDepositSettingActionDialogText(t("確認要新增 訂金設定 嗎？"));
        break;
      case "EditDepositSetting":
        setDepositSettingActionDialogText(t("確認要編輯 訂金設定 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDepositSettingActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createDepositSetting = async () => {
    const results = await createDepositSettingFetch(
      token,
      depositTypeField ? depositTypeField.id : null,
      depositNum,
      isDefault,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onDepositSettingChange(results.depositSettingId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 設定 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editDepositSetting = async () => {
    if (depositSettingId) {
      const results = await editDepositSettingFetch(
        token,
        depositSettingId,
        depositTypeField ? depositTypeField.id : null,
        depositNum,
        isDefault,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 設定 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getDepositSettingFormOptions = async () => {
    const results = await getDepositSettingFormOptionsFetch(token);

    // Set States
    setDepositTypeOptions(results.depositTypes ? results.depositTypes : []);
  };

  const getDepositSettingDetailsById = async () => {
    const results = await getDepositSettingDetailsByIdFetch(
      token,
      depositSettingId
    );

    if (results.depositSettingDetails) {
      const { deposit_type_id, deposit_num, is_default, is_active } =
        results.depositSettingDetails;

      // Set States
      setDepositTypeField(
        deposit_type_id
          ? depositTypeOptions.find((item) => item.id === deposit_type_id)
          : null
      );
      setDepositNum(deposit_num ? deposit_num : "");
      setIsDefault(is_default);
      setIsItemActive(is_active);
    } else {
      // Set States
      setDepositTypeField(null);
      setDepositNum("");
      setIsDefault(false);
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getDepositSettingFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && depositTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [depositTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (depositSettingId) {
        getDepositSettingDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, depositSettingId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDepositSettingActionDialogCanceled}
        onDialogConfirmed={onDepositSettingActionDialogConfirmed}
        // States
        dialogText={depositSettingActionDialogText}
        showDialog={showDepositSettingActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("訂金設定")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            disabled={isDepositNumDisabled}
            error={checkIsFieldError("depositNum")}
            fullWidth
            helperText={getErrorFieldMessage("depositNum")}
            InputProps={{
              endAdornment: shouldShowPercentageSign ? (
                <InputAdornment position="start">%</InputAdornment>
              ) : null,
              startAdornment: shouldShowDollarSign ? (
                <InputAdornment position="start">$</InputAdornment>
              ) : null,
            }}
            label={t("訂金")}
            margin="dense"
            name="depositNum"
            onChange={(event) =>
              onInputFieldChange("depositNum", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={depositNum}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {depositTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("deposit_type_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("depositTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={availableDepositTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("depositTypeField")}
                  helperText={getErrorFieldMessage("depositTypeField")}
                  label={t("訂金類型")}
                  variant="standard"
                />
              )}
              value={depositTypeField}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("預設選項")}
          </Typography>
          <Switch
            checked={isDefault}
            onChange={() => onInputFieldChange("isDefault", !isDefault)}
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {depositSettingId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default DepositSettingForm;
