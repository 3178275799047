// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/ordersPage/ordersPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getProcedureDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  editProcedureFetch,
  getProcedureDetailsByIdFetch,
} from "../../../fetches/procedureFetches";
import { getAllStaffsByDepartmentIdsArrFetch } from "../../../fetches/staffFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ProcedureDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const procedureId = useSelector((state) => state.ordersPage.procedureId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [procedureDetailActionDialogText, setProcedureDetailActionDialogText] =
    useState("");
  const [procedureDetailActionDialogType, setProcedureDetailActionDialogType] =
    useState(null);
  const [showProcedureDetailAction, setShowProcedureDetailAction] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [departmentField, setDepartmentField] = useState([]);
  const [staffField, setStaffField] = useState([]);
  // Options
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Events
  // Events - Dialogs
  const onProcedureDetailActionCanceled = () => {
    // Set States
    setShowProcedureDetailAction(false);
  };

  const onProcedureDetailActionConfirmed = () => {
    switch (procedureDetailActionDialogType) {
      case "EditProcedure":
        editProcedure();
        break;
      default:
        break;
    }

    // Set States
    setShowProcedureDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "departmentField":
        setDepartmentField(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "staffField":
        setStaffField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!departmentField[0]) {
      addToErrorFields("departmentField", t("請先填寫 部門"));
      isError = true;
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayProcedureDetailActionDialog("EditProcedure");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setNameCh("");
    setNameEn("");
    setDepartmentField([]);
    setStaffField([]);

    clearErrorFields();
  };

  const displayProcedureDetailActionDialog = (procedureDetailActionType) => {
    // Set States
    setProcedureDetailActionDialogType(procedureDetailActionType);
    switch (procedureDetailActionType) {
      case "EditProcedure":
        setProcedureDetailActionDialogText(t("確認要編輯 流程資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowProcedureDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const editProcedure = async () => {
    if (procedureId) {
      const results = await editProcedureFetch(
        token,
        procedureId,
        departmentField[0] ? departmentField.map((item) => item.id) : null,
        staffField[0] ? staffField.map((item) => item.id) : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 流程 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getAllStaffsByDepartmentIdsArr = async () => {
    const results = await getAllStaffsByDepartmentIdsArrFetch(
      token,
      departmentField[0] ? departmentField.map((item) => item.id) : null
    );

    // Set States
    setStaffField((currentState) =>
      currentState.filter((currentStateItem) =>
        results.staffs.some(
          (resultStaffItem) => resultStaffItem.id === currentStateItem.id
        )
      )
    );
    setStaffOptions(results.staffs ? results.staffs : []);
  };

  const getProcedureDetailFormOptions = async () => {
    const results = await getProcedureDetailFormOptionsFetch(token);

    // Set States
    setDepartmentOptions(results.departments ? results.departments : []);
  };

  const getProcedureDetailsById = async () => {
    const results = await getProcedureDetailsByIdFetch(token, procedureId);

    if (results.procedureDetails) {
      const { procedure_name_ch, procedure_name_en, dependencies } =
        results.procedureDetails;

      const { departments, staffs } = dependencies;

      // Set States
      setNameCh(procedure_name_ch ? procedure_name_ch : null);
      setNameEn(procedure_name_en ? procedure_name_en : null);
      setDepartmentField(departments ? departments : []);
      setStaffField(staffs ? staffs : []);
    } else {
      // Set States
      setNameCh("");
      setNameEn("");
      setDepartmentField([]);
      setStaffField([]);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getProcedureDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && departmentOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [departmentOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (procedureId) {
        getProcedureDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, procedureId]);

  useEffect(() => {
    if (departmentField[0]) {
      getAllStaffsByDepartmentIdsArr();
    }
  }, [departmentField]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onProcedureDetailActionCanceled}
        onDialogConfirmed={onProcedureDetailActionConfirmed}
        // States
        dialogText={procedureDetailActionDialogText}
        showDialog={showProcedureDetailAction}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("流程資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            disabled
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
        {departmentField && departmentOptions && (
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("department_name_ch")]
                  ? `${option.department_name_en_short} - ${
                      option[t("department_name_ch")]
                    }`
                  : option.department_name_en_short
              }
              multiple
              onChange={(event, value) =>
                onInputFieldChange("departmentField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={departmentOptions.filter((optionItem) =>
                departmentField[0]
                  ? !departmentField.some(
                      (fieldItem) => fieldItem.id === optionItem.id
                    )
                  : departmentOptions
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("departmentField")}
                  helperText={getErrorFieldMessage("departmentField")}
                  label={t("部門")}
                  variant="standard"
                />
              )}
              value={departmentField}
            />
          </Grid>
        )}
        {departmentField[0] && staffField && staffOptions && (
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option.staff_code
                  ? `${option.staff_code} - ${option.full_name_en}`
                  : option.full_name_en
              }
              multiple
              onChange={(event, value) =>
                onInputFieldChange("staffField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={staffOptions.filter((optionItem) =>
                staffField[0]
                  ? !staffField.some(
                      (fieldItem) => fieldItem.id === optionItem.id
                    )
                  : staffOptions
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("staffField")}
                  helperText={getErrorFieldMessage("staffField")}
                  label={t("員工")}
                  variant="standard"
                />
              )}
              value={staffField}
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {t("修改")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ProcedureDetailForm;
