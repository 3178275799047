// Configs
import apisConfig from "../configs/apisConfig";

export const editProcedureFetch = async (
  token,
  procedureId,
  departmentIdsArr,
  staffIdsArr
) => {
  try {
    const queryRoute = `/procedure/editProcedure/${procedureId}`;

    const reqBody = {
      departmentIdsArr,
      staffIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllProceduresFetch = async (token) => {
  try {
    const queryRoute = "/procedure/getAllProcedures";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getProcedureDetailsByIdFetch = async (token, procedureId) => {
  try {
    const queryRoute = `/procedure/getProcedureDetailsById/${procedureId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
