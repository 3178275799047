// Configs
import apisConfig from "../configs/apisConfig";

export const createUnitFetch = async (
  token,
  unitTypeId,
  unitCode,
  unitNameCh,
  unitNameEn,
  baseUnitRatio,
  isActive
) => {
  try {
    const queryRoute = "/unit/createUnit";

    const reqBody = {
      unitTypeId,
      unitCode,
      unitNameCh,
      unitNameEn,
      baseUnitRatio,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreUnitFetch = async (token, unitId, typeOfUnits) => {
  try {
    const queryRoute = `/unit/deleteOrRestoreUnit/${unitId}`;

    const reqBody = { typeOfUnits };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editUnitFetch = async (
  token,
  unitId,
  unitTypeId,
  unitCode,
  unitNameCh,
  unitNameEn,
  baseUnitRatio,
  isActive
) => {
  try {
    const queryRoute = `/unit/editUnit/${unitId}`;

    const reqBody = {
      unitTypeId,
      unitCode,
      unitNameCh,
      unitNameEn,
      baseUnitRatio,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllCustomUnitsByMerchandiseIdFetch = async (
  token,
  merchandiseId
) => {
  try {
    const queryRoute = `/unit/getAllCustomUnitsByMerchandiseId/${merchandiseId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllUnitsFetch = async (token, typeOfUnits) => {
  try {
    const queryRoute = "/unit/getAllUnits";

    const reqBody = { typeOfUnits };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllUnitsByUnitTypeIdFetch = async (
  token,
  unitTypeId,
  typeOfUnits
) => {
  try {
    const queryRoute = `/unit/getAllUnitsByUnitTypeId/${unitTypeId}`;

    const reqBody = { typeOfUnits };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getBaseUnitDetailsByUnitTypeIdFetch = async (
  token,
  unitTypeId
) => {
  try {
    const queryRoute = `/unit/getBaseUnitDetailsByUnitTypeId/${unitTypeId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getUnitDetailsByIdFetch = async (token, unitId) => {
  try {
    const queryRoute = `/unit/getUnitDetailsById/${unitId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
