// Actions
import { onDepositSettingChange } from "../../../redux/pages/clientsPage/clientsPageActions";

// Components
// Boxes
import DepositSettingBox from "./depositSettingBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import DepositSettingActionMenu from "../../menus/clientsPage/depositSettingActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreDepositSettingFetch,
  editDepositSettingSetIsDefaultFetch,
  getAllDepositSettingsFetch,
} from "../../../fetches/depositSettingFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DepositSettingBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const depositSettingId = useSelector(
    (state) => state.clientsPage.depositSettingId
  );
  const formSubmitCount = useSelector(
    (state) => state.clientsPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfClientSettings = useSelector(
    (state) => state.clientsPage.typeOfClientSettings
  );

  // States
  // Data
  const [depositSettings, setDepositSettings] = useState(null);
  // Dialog
  const [depositSettingActionDialogText, setDepositSettingActionDialogText] =
    useState("");
  const [depositSettingActionDialogType, setDepositSettingActionDialogType] =
    useState(null);
  const [showDepositSettingActionDialog, setShowDepositSettingActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showDepositSettingActionMenu, setShowDepositSettingActionMenu] =
    useState(null);
  // Render
  const [selectedDepositSetting, setSelectedDepositSetting] = useState(null);

  // Handle States
  const currentItemId = depositSettingId;
  const itemsArr = depositSettings;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onDepositSettingChange(itemId));
  };

  const onItemRightClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedDepositSetting(item);
    setShowDepositSettingActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedDepositSetting(item);
    setShowDepositSettingActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onDepositSettingActionDialogCanceled = () => {
    // Set States
    setShowDepositSettingActionDialog(false);
  };

  const onDepositSettingActionDialogConfirmed = () => {
    switch (depositSettingActionDialogType) {
      // 刪除
      case "DeleteDepositSetting":
        deleteOrRestoreDepositSetting();
        break;
      // 設為一般選項
      case "RemoveDepositSettingDefault":
        editDepositSettingSetIsDefault();
        break;
      // 還原
      case "RestoreDepositSetting":
        deleteOrRestoreDepositSetting();
        break;
      // 設為預設選項
      case "SetDepositSettingDefault":
        editDepositSettingSetIsDefault();
        break;
      default:
        break;
    }

    // Set States
    setShowDepositSettingActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onDepositSettingChange(null));
  };

  // Functions
  // Functions - Normal
  const displayDepositSettingActionDialog = (depositSettingActionType) => {
    // Set States
    setDepositSettingActionDialogType(depositSettingActionType);

    switch (depositSettingActionType) {
      // 刪除
      case "DeleteDepositSetting":
        setDepositSettingActionDialogText(t("確認要刪除 訂金設定 嗎？"));
        break;
      // 設為一般選項
      case "RemoveDepositSettingDefault":
        setDepositSettingActionDialogText(t("確認要設為 一般選項 嗎？"));
        break;
      // 還原
      case "RestoreDepositSetting":
        setDepositSettingActionDialogText(t("確認要還原 訂金設定 嗎？"));
        break;
      // 設為預設選項
      case "SetDepositSettingDefault":
        setDepositSettingActionDialogText(t("確認要設為 預設選項 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDepositSettingActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreDepositSetting = async () => {
    const results = await deleteOrRestoreDepositSettingFetch(
      token,
      currentItemId,
      typeOfClientSettings
    );

    if (results.success) {
      getAllDepositSettings(true);
    }
  };

  const editDepositSettingSetIsDefault = async () => {
    const results = await editDepositSettingSetIsDefaultFetch(
      token,
      currentItemId,
      !selectedDepositSetting.is_default
    );

    if (results.success) {
      getAllDepositSettings(false);
    }
  };

  // Functions - Queries
  const getAllDepositSettings = async (
    shoulddUpdateCurrentDepositSettingId
  ) => {
    const results = await getAllDepositSettingsFetch(
      token,
      typeOfClientSettings
    );

    // Set States
    setDepositSettings(
      results.depositSettings ? results.depositSettings : null
    );

    // Update Redux Store
    if (shoulddUpdateCurrentDepositSettingId) {
      dispatch(
        onDepositSettingChange(
          results.depositSettings ? results.depositSettings[0].id : null
        )
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllDepositSettings(depositSettingId ? false : true);
  }, [formSubmitCount, typeOfClientSettings]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDepositSettingActionDialogCanceled}
        onDialogConfirmed={onDepositSettingActionDialogConfirmed}
        // States
        dialogText={depositSettingActionDialogText}
        showDialog={showDepositSettingActionDialog}
      />
      {/* Menu */}
      <DepositSettingActionMenu
        // States
        selectedDepositSetting={selectedDepositSetting}
        showDepositSettingActionMenu={showDepositSettingActionMenu}
        // Set States
        setSelectedDepositSetting={setSelectedDepositSetting}
        setShowDepositSettingActionMenu={setShowDepositSettingActionMenu}
        setDepositSettingActionDialogType={setDepositSettingActionDialogType}
        // Functions
        displayDepositSettingActionDialog={displayDepositSettingActionDialog}
      />
      {/* DepositSetting */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("訂金設定s")}
          </Typography>
          <Tooltip placement="right" title={t("新增訂金設定")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <DepositSettingBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有訂金設定資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DepositSettingBoxesContainer;
