// Actions
import {
  onPaymentTermSettingChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/clientsPage/clientsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getPaymentTermSettingFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createPaymentTermSettingFetch,
  editPaymentTermSettingFetch,
  getPaymentTermSettingDetailsByIdFetch,
} from "../../../fetches/paymentTermSettingFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PaymentTermSettingForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const paymentTermSettingId = useSelector(
    (state) => state.clientsPage.paymentTermSettingId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    paymentTermSettingActionDialogText,
    setPaymentTermSettingActionDialogText,
  ] = useState("");
  const [
    paymentTermSettingActionDialogType,
    setPaymentTermSettingActionDialogType,
  ] = useState(null);
  const [
    showPaymentTermSettingActionDialog,
    setShowPaymentTermSettingActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [paymentPeriodUnitField, setPaymentPeriodUnitField] = useState(null);
  // Options
  const [paymentPeriodUnitOptions, setPaymentPeriodUnitOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isDefault, setIsDefault] = useState(false);
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [paymentTermNum, setPaymentTermNum] = useState("");

  // Handle States
  const availablePaymentPeriodUnitOptions = paymentPeriodUnitOptions[0]
    ? // 需即時付清
      paymentPeriodUnitField && paymentPeriodUnitField.id === 1
      ? paymentPeriodUnitOptions.filter((item) => item.id === 1)
      : paymentPeriodUnitOptions.filter((item) => item.id !== 1)
    : [];
  const isPaymentTermNumDisabled =
    paymentPeriodUnitField && paymentPeriodUnitField.id === 1;

  // Events
  // Events - Dialogs
  const onPaymentTermSettingActionDialogCanceled = () => {
    // Set States
    setShowPaymentTermSettingActionDialog(false);
  };

  const onPaymentTermSettingActionDialogConfirmed = () => {
    switch (paymentTermSettingActionDialogType) {
      case "CreatePaymentTermSetting":
        createPaymentTermSetting();
        break;
      case "EditPaymentTermSetting":
        editPaymentTermSetting();
        break;
      default:
        break;
    }

    // Set States
    setShowPaymentTermSettingActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "paymentTermNum":
        setPaymentTermNum(value);
        break;
      case "paymentPeriodUnitField":
        setPaymentPeriodUnitField(value);
        break;
      case "isDefault":
        setIsDefault(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!paymentTermNum) {
      addToErrorFields("paymentTermNum", t("請先填寫 付款期限"));
      isError = true;
    } else {
      if (isNaN(paymentTermNum)) {
        addToErrorFields("paymentTermNum", t("付款期限 必須為數字"));
        isError = true;
      } else if (paymentTermNum < 1) {
        addToErrorFields("paymentTermNum", t("付款期限 必須大於 0"));
        isError = true;
      }
    }

    if (!paymentPeriodUnitField) {
      addToErrorFields("paymentPeriodUnitField", t("請先填寫 單位"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayPaymentTermSettingActionDialog(
      paymentTermSettingId
        ? "EditPaymentTermSetting"
        : "CreatePaymentTermSetting"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setPaymentPeriodUnitField(null);
    setPaymentTermNum("");
    setIsDefault(false);
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayPaymentTermSettingActionDialog = (
    paymentTermSettingActionType
  ) => {
    // Set States
    setPaymentTermSettingActionDialogType(paymentTermSettingActionType);

    switch (paymentTermSettingActionType) {
      case "CreatePaymentTermSetting":
        setPaymentTermSettingActionDialogText(
          t("確認要新增 付款期限設定 嗎？")
        );
        break;
      case "EditPaymentTermSetting":
        setPaymentTermSettingActionDialogText(
          t("確認要編輯 付款期限設定 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowPaymentTermSettingActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createPaymentTermSetting = async () => {
    const results = await createPaymentTermSettingFetch(
      token,
      paymentPeriodUnitField ? paymentPeriodUnitField.id : null,
      paymentTermNum,
      isDefault,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onPaymentTermSettingChange(results.paymentTermSettingId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 設定 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editPaymentTermSetting = async () => {
    if (paymentTermSettingId) {
      const results = await editPaymentTermSettingFetch(
        token,
        paymentTermSettingId,
        paymentPeriodUnitField ? paymentPeriodUnitField.id : null,
        paymentTermNum,
        isDefault,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 設定 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getPaymentTermSettingFormOptions = async () => {
    const results = await getPaymentTermSettingFormOptionsFetch(token);

    // Set States
    setPaymentPeriodUnitOptions(
      results.paymentPeriodUnits ? results.paymentPeriodUnits : []
    );
  };

  const getPaymentTermSettingDetailsById = async () => {
    const results = await getPaymentTermSettingDetailsByIdFetch(
      token,
      paymentTermSettingId
    );

    if (results.paymentTermSettingDetails) {
      const {
        payment_period_unit_id,
        payment_term_num,
        is_default,
        is_active,
      } = results.paymentTermSettingDetails;

      // Set States
      setPaymentPeriodUnitField(
        payment_period_unit_id
          ? paymentPeriodUnitOptions.find(
              (item) => item.id === payment_period_unit_id
            )
          : null
      );
      setPaymentTermNum(payment_term_num ? payment_term_num : "");
      setIsDefault(is_default);
      setIsItemActive(is_active);
    } else {
      // Set States
      setPaymentPeriodUnitField(null);
      setPaymentTermNum("");
      setIsDefault(false);
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getPaymentTermSettingFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && paymentPeriodUnitOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [paymentPeriodUnitOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (paymentTermSettingId) {
        getPaymentTermSettingDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, paymentTermSettingId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPaymentTermSettingActionDialogCanceled}
        onDialogConfirmed={onPaymentTermSettingActionDialogConfirmed}
        // States
        dialogText={paymentTermSettingActionDialogText}
        showDialog={showPaymentTermSettingActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("付款期限設定")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            disabled={isPaymentTermNumDisabled}
            error={checkIsFieldError("paymentTermNum")}
            fullWidth
            helperText={getErrorFieldMessage("paymentTermNum")}
            label={t("付款期限")}
            margin="dense"
            name="paymentTermNum"
            onChange={(event) =>
              onInputFieldChange("paymentTermNum", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={paymentTermNum}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {paymentPeriodUnitOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("payment_period_unit_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("paymentPeriodUnitField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={availablePaymentPeriodUnitOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("paymentPeriodUnitField")}
                  helperText={getErrorFieldMessage("paymentPeriodUnitField")}
                  label={t("單位")}
                  variant="standard"
                />
              )}
              value={paymentPeriodUnitField}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("預設選項")}
          </Typography>
          <Switch
            checked={isDefault}
            onChange={() => onInputFieldChange("isDefault", !isDefault)}
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {paymentTermSettingId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default PaymentTermSettingForm;
