// Configs
import apisConfig from "../configs/apisConfig";

export const getRankedMerchandisesWithPaginationFetch = async (
  token,
  salesNumberType,
  unitId,
  categoryId,
  subCategoryId,
  brandId,
  startDate,
  endDate,
  keyword,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute =
      "/merchandiseSalesNumber/getRankedMerchandisesWithPagination";

    const reqBody = {
      salesNumberType,
      unitId,
      categoryId,
      subCategoryId,
      brandId,
      startDate,
      endDate,
      keyword,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalesNumbersForCategoriesFetch = async (
  token,
  salesNumberType,
  unitId,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate
) => {
  try {
    const queryRoute = "/merchandiseSalesNumber/getSalesNumbersForCategories";

    const reqBody = {
      salesNumberType,
      unitId,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalesNumbersForSubCategoriesByCategoryIdFetch = async (
  token,
  categoryId,
  salesNumberType,
  unitId,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate
) => {
  try {
    const queryRoute = `/merchandiseSalesNumber/getSalesNumbersForSubCategoriesByCategoryId/${categoryId}`;

    const reqBody = {
      salesNumberType,
      unitId,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
