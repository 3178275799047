// Actions
import {
  onFormSubmitCountIncrease,
  onMerchandiseQuotedChange,
  onSelectedMerchandiseChange,
  onSelectedUnitChange,
} from "../../../redux/pages/quotationProfilePage/quotationProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import MerchandiseQuotedDetailFormBasicInfoContent from "./merchandiseQuotedDetailFormBasicInfoContent";
import MerchandiseQuotedDetailFormDiscountInfoContent from "./merchandiseQuotedDetailFormDiscountInfoContent";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getCurrencyDetailsByQuotationIdFetch } from "../../../fetches/currencyFetches";
import { getMerchandiseQuotedFormOptionsFetch } from "../../../fetches/formOptionFetches";
import { getMerchandiseDetailsByIdFetch } from "../../../fetches/merchandiseFetches";
import { getMerchandiseToUnitDetailsByMerchandiseAndUnitIdFetch } from "../../../fetches/merchandiseToUnitFetches";
import {
  createMerchandiseQuotedFetch,
  editMerchandiseQuotedFetch,
  getMerchandiseQuotedDetailsByIdFetch,
} from "../../../fetches/merchandiseQuotedFetches";

// Helper Functions
import { roundNumber } from "../../../helperFunctions/roundNumber";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseQuotedDetailFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.quotationProfilePage.formSubmitCount
  );
  const isQuotationEditable = useSelector(
    (state) => state.quotationProfilePage.isQuotationEditable
  );
  const language = useSelector((state) => state.language.language);
  const merchandiseId = useSelector(
    (state) => state.quotationProfilePage.selectedMerchandiseId
  );
  const merchandiseQuotedId = useSelector(
    (state) => state.quotationProfilePage.merchandiseQuotedId
  );
  const quotationId = useSelector(
    (state) => state.quotationProfilePage.quotationId
  );
  const token = useSelector((state) => state.staff.token);
  const unitId = useSelector(
    (state) => state.quotationProfilePage.selectedUnitId
  );

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Data
  const [currencyDetails, setCurrencyDetails] = useState(null);
  const [isPurchasable, setIsPurchasable] = useState(false);
  const [merchandiseDetails, setMerchandiseDetails] = useState(null);
  const [minPurchasableUnit, setMinPurchasableUnit] = useState(null);
  // Dialog
  const [
    merchandiseQuotedDetailActionDialogText,
    setMerchandiseQuotedDetailActionDialogText,
  ] = useState("");
  const [
    merchandiseQuotedDetailActionDialogType,
    setMerchandiseQuotedDetailActionDialogType,
  ] = useState(null);
  const [
    showMerchandiseQuotedDetailActionDialog,
    setShowMerchandiseQuotedDetailActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [
    quotationDiscountDisplayTypeField,
    setQuotationDiscountDisplayTypeField,
  ] = useState(null);
  const [unitTypeField, setUnitTypeField] = useState(null);
  const [unitField, setUnitField] = useState(null);
  // Options
  const [
    quotationDiscountDisplayTypeOptions,
    setQuotationDiscountDisplayTypeOptions,
  ] = useState([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isDiscounted, setIsDiscounted] = useState(false);
  // Text Fields
  const [actualCost, setActualCost] = useState("");
  const [actualUnitPrice, setActualUnitPrice] = useState("");
  const [cost, setCost] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unitPrice, setUnitPrice] = useState("");

  // Events
  // Events - Dialogs
  const onMerchandiseQuotedDetailActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseQuotedDetailActionDialog(false);
  };

  const onMerchandiseQuotedDetailActionDialogConfirmed = () => {
    switch (merchandiseQuotedDetailActionDialogType) {
      case "CreateMerchandiseQuoted":
        createMerchandiseQuoted();
        break;
      case "EditMerchandiseQuoted":
        editMerchandiseQuoted();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseQuotedDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "actualCost":
        setActualCost(value);
        setActualUnitPrice(
          quantity && value && !isNaN(quantity) && !isNaN(value)
            ? roundNumber(Number(value) / Number(quantity), 1)
            : ""
        );

        break;
      case "actualUnitPrice":
        setActualCost(
          value && quantity && !isNaN(value) && !isNaN(quantity)
            ? roundNumber(Number(value) * Number(quantity), 1)
            : ""
        );
        setActualUnitPrice(value);

        break;
      case "cost":
        setCost(value);
        setUnitPrice(
          quantity && value && !isNaN(quantity) && !isNaN(value)
            ? roundNumber(Number(value) / Number(quantity), 1)
            : ""
        );

        break;
      case "isDiscounted":
        setIsDiscounted(value);
        break;
      case "quantity":
        setActualCost(
          actualUnitPrice && value && !isNaN(actualUnitPrice) && !isNaN(value)
            ? roundNumber(Number(actualUnitPrice) * Number(value), 1)
            : ""
        );
        setCost(
          unitPrice && value && !isNaN(unitPrice) && !isNaN(value)
            ? roundNumber(Number(unitPrice) * Number(value), 1)
            : ""
        );
        setQuantity(value);

        break;
      case "quotationDiscountDisplayTypeField":
        setQuotationDiscountDisplayTypeField(value);
        break;
      case "unitField":
        setUnitField(value);
        break;
      case "unitTypeField":
        setUnitTypeField(value);
        setUnitField(
          value
            ? unitOptions.find((item) => item.unitType_id === value.id)
            : null
        );

        removeErrorField("unitField");

        break;
      case "unitPrice":
        setCost(
          value && quantity && !isNaN(value) && !isNaN(quantity)
            ? roundNumber(Number(value) * Number(quantity), 1)
            : ""
        );
        setUnitPrice(value);

        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    if (!isQuotationEditable) {
      return;
    }

    let isError = false;

    if (isDiscounted) {
      if (actualUnitPrice) {
        if (isNaN(actualUnitPrice)) {
          addToErrorFields("actualUnitPrice", t("價錢 必須為數字"));
          isError = true;
        }
      }

      if (!quotationDiscountDisplayTypeField) {
        addToErrorFields(
          "quotationDiscountDisplayTypeField",
          t("請先填寫 優惠顯示類別")
        );
        isError = true;
      }
    }

    if (!quantity) {
      addToErrorFields("quantity", t("請先填寫 數量"));
      isError = true;
    } else {
      if (isNaN(quantity)) {
        addToErrorFields("quantity", t("數量 必須為數字"));
        isError = true;
      }
    }

    if (!unitField) {
      addToErrorFields("unitField", t("請先填寫 單位"));
      isError = true;
    }

    if (!unitTypeField) {
      addToErrorFields("unitTypeField", t("請先填寫 單位類別"));
      isError = true;
    }

    if (unitPrice) {
      if (isNaN(unitPrice)) {
        addToErrorFields("unitPrice", t("價錢 必須為數字"));
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    displayMerchandiseQuotedDetailActionDialog(
      merchandiseQuotedId ? "EditMerchandiseQuoted" : "CreateMerchandiseQuoted"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearCurrencyField = () => {
    // Set States
    setCurrencyDetails(null);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setUnitField(null);
    setUnitTypeField(null);
    setUnitPrice("");
    setActualUnitPrice("");
    setQuantity("");
    setCost("");
    setActualCost("");
    setIsDiscounted(false);
    setQuotationDiscountDisplayTypeField(null);

    clearErrorFields();
  };

  const clearMerchandiseField = () => {
    // Set States
    setMerchandiseDetails(null);
  };

  const clearMerchandiseToUnitFields = () => {
    // Set States
    setIsPurchasable(false);
    setMinPurchasableUnit(null);
  };

  const displayMerchandiseQuotedDetailActionDialog = (
    merchandiseQuotedDetailActionType
  ) => {
    // Set States
    setMerchandiseQuotedDetailActionDialogType(
      merchandiseQuotedDetailActionType
    );

    switch (merchandiseQuotedDetailActionType) {
      case "CreateMerchandiseQuoted":
        setMerchandiseQuotedDetailActionDialogText(
          t("確認要新增 報價商品 嗎？")
        );
        break;
      case "EditMerchandiseQuoted":
        setMerchandiseQuotedDetailActionDialogText(
          t("確認要編輯 報價商品資料 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseQuotedDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  const updateSelectedUnit = () => {
    // Update Redux Store
    dispatch(onSelectedUnitChange(unitField ? unitField.id : null));
  };

  // Functions - Mutations
  const createMerchandiseQuoted = async () => {
    const results = await createMerchandiseQuotedFetch(
      token,
      quotationId,
      merchandiseId,
      unitField ? unitField.id : null,
      unitPrice ? unitPrice : null,
      actualUnitPrice ? actualUnitPrice : null,
      quantity ? quantity : null,
      cost ? cost : null,
      actualCost ? actualCost : null,
      isDiscounted,
      quotationDiscountDisplayTypeField
        ? quotationDiscountDisplayTypeField.id
        : null
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onMerchandiseQuotedChange(results.merchandiseQuotedId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 報價商品 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editMerchandiseQuoted = async () => {
    if (merchandiseQuotedId) {
      const results = await editMerchandiseQuotedFetch(
        token,
        merchandiseQuotedId,
        quotationId,
        merchandiseId,
        unitField ? unitField.id : null,
        unitPrice ? unitPrice : null,
        actualUnitPrice ? actualUnitPrice : null,
        quantity ? quantity : null,
        cost ? cost : null,
        actualCost ? actualCost : null,
        isDiscounted,
        quotationDiscountDisplayTypeField
          ? quotationDiscountDisplayTypeField.id
          : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 報價商品 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getCurrencyDetailsByQuotationId = async () => {
    const results = await getCurrencyDetailsByQuotationIdFetch(
      token,
      quotationId
    );

    // Set States
    setCurrencyDetails(
      results.currencyDetails ? results.currencyDetails : null
    );
  };

  const getMerchandiseDetailsById = async () => {
    const results = await getMerchandiseDetailsByIdFetch(token, merchandiseId);

    // Set States
    setMerchandiseDetails(
      results.merchandiseDetails ? results.merchandiseDetails : null
    );
  };

  const getMerchandiseQuotedFormOptions = async () => {
    const results = await getMerchandiseQuotedFormOptionsFetch(token);

    // Set States
    setQuotationDiscountDisplayTypeOptions(
      results.quotationDiscountDisplayTypes
        ? results.quotationDiscountDisplayTypes
        : []
    );
    setUnitOptions(results.units ? results.units : []);
    setUnitTypeOptions(results.unitTypes ? results.unitTypes : []);
  };

  const getMerchandiseQuotedDetailsById = async () => {
    const results = await getMerchandiseQuotedDetailsByIdFetch(
      token,
      merchandiseQuotedId
    );

    if (results.merchandiseQuotedDetails) {
      const {
        unit_id,
        unit_type_id,
        unit_price,
        actual_unit_price,
        quantity,
        merchandise_id,
        cost,
        actual_cost,
        is_discounted,
        quotation_discount_display_type_id,
      } = results.merchandiseQuotedDetails;

      // Set States
      setUnitField(
        unit_id ? unitOptions.find((item) => item.id === unit_id) : null
      );
      setUnitTypeField(
        unit_type_id
          ? unitTypeOptions.find((item) => item.id === unit_type_id)
          : null
      );
      setUnitPrice(unit_price ? unit_price : "");
      setActualUnitPrice(actual_unit_price ? actual_unit_price : "");
      setQuantity(quantity ? quantity : "");
      setCost(cost ? cost : "");
      setActualCost(actual_cost ? actual_cost : "");
      setIsDiscounted(is_discounted);
      setQuotationDiscountDisplayTypeField(
        quotation_discount_display_type_id
          ? quotationDiscountDisplayTypeOptions.find(
              (item) => item.id === quotation_discount_display_type_id
            )
          : null
      );

      // Update Redux Store
      dispatch(
        onSelectedMerchandiseChange(merchandise_id ? merchandise_id : null)
      );
    } else {
      // Set States
      setUnitField(null);
      setUnitTypeField(null);
      setUnitPrice("");
      setActualUnitPrice("");
      setQuantity("");
      setCost("");
      setActualCost("");
      setIsDiscounted(false);
      setQuotationDiscountDisplayTypeField(null);

      // Update Redux Store
      dispatch(onSelectedMerchandiseChange(null));
    }

    clearErrorFields();
  };

  const getMerchandiseToUnitDetailsByMerchandiseAndUnitId = async () => {
    const results =
      await getMerchandiseToUnitDetailsByMerchandiseAndUnitIdFetch(
        token,
        merchandiseId,
        unitId
      );

    if (results.merchandiseToUnitDetails) {
      const { is_purchasable, min_purchasable_unit } =
        results.merchandiseToUnitDetails;

      // Set States
      setIsPurchasable(is_purchasable ? is_purchasable : false);
      setMinPurchasableUnit(
        min_purchasable_unit
          ? roundNumber(Number(min_purchasable_unit), 1)
          : null
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getMerchandiseQuotedFormOptions();
  }, []);

  useEffect(() => {
    updateSelectedUnit();
  }, [unitField]);

  useEffect(() => {
    if (merchandiseId) {
      getMerchandiseDetailsById();
    } else {
      clearMerchandiseField();
    }
  }, [merchandiseId]);

  useEffect(() => {
    if (quotationId) {
      getCurrencyDetailsByQuotationId();
    } else {
      clearCurrencyField();
    }
  }, [quotationId, formSubmitCount]);

  useEffect(() => {
    if (merchandiseId && unitId) {
      getMerchandiseToUnitDetailsByMerchandiseAndUnitId();
    } else {
      clearMerchandiseToUnitFields();
    }
  }, [merchandiseId, unitId]);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && unitOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [unitOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (merchandiseQuotedId) {
        getMerchandiseQuotedDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, merchandiseQuotedId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseQuotedDetailActionDialogCanceled}
        onDialogConfirmed={onMerchandiseQuotedDetailActionDialogConfirmed}
        // States
        dialogText={merchandiseQuotedDetailActionDialogText}
        showDialog={showMerchandiseQuotedDetailActionDialog}
      />
      {/* Content */}
      <MerchandiseQuotedDetailFormBasicInfoContent
        // States
        cost={cost}
        currencyDetails={currencyDetails}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        isDiscounted={isDiscounted}
        isPurchasable={isPurchasable}
        isQuotationEditable={isQuotationEditable}
        language={language}
        merchandiseDetails={merchandiseDetails}
        merchandiseQuotedId={merchandiseQuotedId}
        minPurchasableUnit={minPurchasableUnit}
        quantity={quantity}
        shouldShowFormAlert={shouldShowFormAlert}
        unitField={unitField}
        unitOptions={unitOptions}
        unitPrice={unitPrice}
        unitTypeField={unitTypeField}
        unitTypeOptions={unitTypeOptions}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
      {isDiscounted && (
        <MerchandiseQuotedDetailFormDiscountInfoContent
          // States
          actualCost={actualCost}
          actualUnitPrice={actualUnitPrice}
          currencyDetails={currencyDetails}
          formAlertText={formAlertText}
          formAlertType={formAlertType}
          isQuotationEditable={isQuotationEditable}
          merchandiseQuotedId={merchandiseQuotedId}
          quotationDiscountDisplayTypeField={quotationDiscountDisplayTypeField}
          quotationDiscountDisplayTypeOptions={
            quotationDiscountDisplayTypeOptions
          }
          shouldShowFormAlert={shouldShowFormAlert}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onSubmitBtnClicked={onSubmitBtnClicked}
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
    </div>
  );
}

export default MerchandiseQuotedDetailFormContainer;
