// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function ClientIntroBasicInfoBox(props) {
  // Hooks
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
  } = props;

  return (
    <div className={classes.itemContainer}>
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainer}>
          <Typography align={"left"} variant="body1">
            {item.client_code}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.client_name_en ? item.client_name_en : item.client_name_ch}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default ClientIntroBasicInfoBox;
