// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { multilineItemText } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function TermContentBoxText(props) {
  // Props
  const {
    // States
    item,
  } = props;

  return (
    <Typography
      align={"left"}
      color={stylesConfig.greyTextColor}
      style={multilineItemText}
      variant="body2"
    >
      {item.content_text}
    </Typography>
  );
}

export default TermContentBoxText;
