// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import MerchandiseOrderedBoxesContainer from "../components/boxes/orderProfilePage/merchandiseOrderedBoxesContainer";
import MerchandiseOrderedSuggestionBoxesContainer from "../components/boxes/orderProfilePage/merchandiseOrderedSuggestionBoxesContainer";
import OrderIntroBoxesContainer from "../components/boxes/orderProfilePage/orderIntroBoxesContainer";
import ProgressBoxesContainer from "../components/boxes/orderProfilePage/progressBoxesContainer";
// Forms
import MerchandiseOrderedDetailFormContainer from "../components/forms/orderProfilePage/merchandiseOrderedDetailFormContainer";
import ProgressDetailForm from "../components/forms/orderProfilePage/progressDetailForm";
// Headers
import OrderProfilePageHeader from "../components/headers/orderProfilePage/orderProfilePageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function OrderProfilePage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.orderProfilePage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("OrderProfile"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <OrderProfilePageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {["BasicInfo", "Progresses"].includes(tabItem) && (
            <OrderIntroBoxesContainer />
          )}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              {tabItem === "BasicInfo" && <MerchandiseOrderedBoxesContainer />}
              {tabItem === "Progresses" && <ProgressBoxesContainer />}
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              {tabItem === "BasicInfo" && (
                <>
                  <MerchandiseOrderedDetailFormContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                  <MerchandiseOrderedSuggestionBoxesContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </>
              )}
              {tabItem === "Progresses" && (
                <>
                  <ProgressDetailForm />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default OrderProfilePage;
