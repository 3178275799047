// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ClientInfoFormSettingsContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    clientCreditSettingField,
    clientCreditSettingOptions,
    depositSettingField,
    depositSettingOptions,
    formAlertText,
    formAlertType,
    paymentTermSettingField,
    paymentTermSettingOptions,
    shouldShowFormAlert,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("客戶設定s")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {clientCreditSettingOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("displayTextCh")]}
              onChange={(event, value) =>
                onInputFieldChange("clientCreditSettingField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={clientCreditSettingOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("clientCreditSettingField")}
                  helperText={getErrorFieldMessage("clientCreditSettingField")}
                  label={t("客戶信用設定")}
                  variant="standard"
                />
              )}
              value={clientCreditSettingField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {paymentTermSettingOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("displayTextCh")]}
              onChange={(event, value) =>
                onInputFieldChange("paymentTermSettingField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={paymentTermSettingOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("paymentTermSettingField")}
                  helperText={getErrorFieldMessage("paymentTermSettingField")}
                  label={t("付款期限設定")}
                  variant="standard"
                />
              )}
              value={paymentTermSettingField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {depositSettingOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("displayTextCh")]}
              onChange={(event, value) =>
                onInputFieldChange("depositSettingField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={depositSettingOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("depositSettingField")}
                  helperText={getErrorFieldMessage("depositSettingField")}
                  label={t("訂金設定")}
                  variant="standard"
                />
              )}
              value={depositSettingField}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {t("修改")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ClientInfoFormSettingsContent;
