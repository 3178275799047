export const getOrderPdfTypeNameCh = (pdfTypeCode) => {
  switch (pdfTypeCode) {
    case "IN":
      return "發票";
    case "PI":
      return "形式發票";
    case "PO":
      return "採購訂單";
    default:
      return "";
  }
};
