// Configs
import apisConfig from "../configs/apisConfig";

export const createMerchandiseDescriptionFetch = async (
  token,
  merchandiseId,
  descriptionCh,
  descriptionEn
) => {
  try {
    const queryRoute = `/merchandiseDescription/createMerchandiseDescription/${merchandiseId}`;

    const reqBody = {
      descriptionCh,
      descriptionEn,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteMerchandiseDescriptionFetch = async (
  token,
  merchandiseDescriptionId
) => {
  try {
    const queryRoute = `/merchandiseDescription/deleteMerchandiseDescription/${merchandiseDescriptionId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editMerchandiseDescriptionFetch = async (
  token,
  merchandiseDescriptionId,
  descriptionCh,
  descriptionEn
) => {
  try {
    const queryRoute = `/merchandiseDescription/editMerchandiseDescription/${merchandiseDescriptionId}`;

    const reqBody = {
      descriptionCh,
      descriptionEn,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllMerchandiseDescriptionsByMerchandiseIdFetch = async (
  token,
  merchandiseId
) => {
  try {
    const queryRoute = `/merchandiseDescription/getAllMerchandiseDescriptionsByMerchandiseId/${merchandiseId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseDescriptionDetailsByIdFetch = async (
  token,
  merchandiseDescriptionId
) => {
  try {
    const queryRoute = `/merchandiseDescription/getMerchandiseDescriptionDetailsById/${merchandiseDescriptionId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
