const today = new Date();

const initialState = {
  comparisonRecordDate: today.toString(),
  conditionBrandId: null,
  conditionBrandItem: null,
  conditionCategoryId: null,
  conditionCategoryItem: null,
  conditionSubCategoryId: null,
  conditionSubCategoryItem: null,
  conditionUnitId: null,
  conditionUnitItem: null,
  formSubmitCount: 0,
  isComparisonShown: false,
  keyword: "",
  merchandiseId: null,
  recordDate: today.toString(),
  salesNumberType: "Revenue",
  tabItem: "Rankings",
};

export const merchandiseSalesPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_MERCHANDISE_SALES_PAGE_COMPARISON_RECORD_DATE_CHANGE":
      const { comparisonRecordDate } = action;
      return {
        ...state,
        comparisonRecordDate: comparisonRecordDate.toString(),
      };
    case "ON_MERCHANDISE_SALES_PAGE_CONDITION_BRAND_CHANGE":
      const { conditionBrandId } = action;
      return {
        ...state,
        conditionBrandId,
      };
    case "ON_MERCHANDISE_SALES_PAGE_CONDITION_BRAND_ITEM_CHANGE":
      const { conditionBrandItem } = action;
      return {
        ...state,
        conditionBrandItem,
      };
    case "ON_MERCHANDISE_SALES_PAGE_CONDITION_CATEGORY_CHANGE":
      const { conditionCategoryId } = action;
      return {
        ...state,
        conditionCategoryId,
      };
    case "ON_MERCHANDISE_SALES_PAGE_CONDITION_CATEGORY_ITEM_CHANGE":
      const { conditionCategoryItem } = action;
      return {
        ...state,
        conditionCategoryItem,
      };
    case "ON_MERCHANDISE_SALES_PAGE_CONDITION_SUB_CATEGORY_CHANGE":
      const { conditionSubCategoryId } = action;
      return {
        ...state,
        conditionSubCategoryId,
      };
    case "ON_MERCHANDISE_SALES_PAGE_CONDITION_SUB_CATEGORY_ITEM_CHANGE":
      const { conditionSubCategoryItem } = action;
      return {
        ...state,
        conditionSubCategoryItem,
      };
    case "ON_MERCHANDISE_SALES_PAGE_CONDITION_UNIT_CHANGE":
      const { conditionUnitId } = action;
      return {
        ...state,
        conditionUnitId,
      };
    case "ON_MERCHANDISE_SALES_PAGE_CONDITION_UNIT_ITEM_CHANGE":
      const { conditionUnitItem } = action;
      return {
        ...state,
        conditionUnitItem,
      };
    case "ON_MERCHANDISE_SALES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_MERCHANDISE_SALES_PAGE_IS_COMPARISON_CHANGE":
      const { isComparisonShown } = action;
      return {
        ...state,
        isComparisonShown,
      };
    case "ON_MERCHANDISES_SALES_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_MERCHANDISE_SALES_PAGE_MERCHANDISE_CHANGE":
      const { merchandiseId } = action;
      return {
        ...state,
        merchandiseId,
      };
    case "ON_MERCHANDISE_SALES_PAGE_RECORD_DATE_CHANGE":
      const { recordDate } = action;
      return {
        ...state,
        recordDate: recordDate.toString(),
      };
    case "ON_MERCHANDISE_SALES_PAGE_SALES_NUMBER_TYPE_CHANGE":
      const { salesNumberType } = action;
      return {
        ...state,
        salesNumberType,
      };
    case "ON_MERCHANDISE_SALES_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
