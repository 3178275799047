// Components
// Boxes
import FileBox from "../../boxes/orderProfilePage/fileViewModal/fileBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import FileActionMenu from "../../menus/orderProfilePage/fileActionMenu";

// Configs
import fileConfig from "../../../configs/fileConfig";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createOrderToFilesFetch,
  deleteOrderToFileFetch,
  getAllFilesByOrderIdFetch,
} from "../../../fetches/orderToFileFetches";

// File Uplaoder
import { FileUploader } from "react-drag-drop-files";

// Helper Functions
import {
  checkFileSize,
  displayFileSizeText,
  openFileInNewTab,
} from "../../../helperFunctions/file";

// Material UI
// Components
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// Icons
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formUploadBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function FileViewModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    orderId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [files, setFiles] = useState(null);
  // Dialog
  const [fileActionDialogText, setFileActionDialogText] = useState("");
  const [fileActionDialogType, setFileActionDialogType] = useState(null);
  const [showFileActionDialog, setShowFileActionDialog] = useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentItemPath, setCurrentItemPath] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showFileActionMenu, setShowFileActionMenu] = useState(null);

  // Handle States
  const itemsArr = files;

  // Events
  // Events - Boxes
  const onItemClicked = (itemPath) => {
    openFileInNewTab(itemPath);
  };

  const onItemRightClicked = (itemId, itemPath, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setCurrentItemPath(itemPath);
    setShowFileActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, itemPath, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setCurrentItemPath(itemPath);
    setShowFileActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onFileActionDialogCanceled = () => {
    // Set States
    setShowFileActionDialog(false);
  };

  const onFileActionDialogConfirmed = () => {
    switch (fileActionDialogType) {
      case "DeleteFile":
        deleteOrderToFile();
        break;
      default:
        break;
    }

    // Set States
    setShowFileActionDialog(false);
  };

  // Events - Fields
  const onFileUploaded = (value) => {
    // Check File Size
    const maxSize = fileConfig.applicationMaxFileUploadSize;
    const isFileSizeWithinLimit = checkFileSize(value, maxSize, "Mb");

    if (!isFileSizeWithinLimit) {
      displayFileActionDialog("ExceedMaxFileUploadSize", maxSize);
      return;
    }

    createOrderToFiles(value);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Normal
  const displayFileActionDialog = (fileActionType, alertValue) => {
    // Set States
    setFileActionDialogType(fileActionType);

    switch (fileActionType) {
      case "DeleteFail":
        setFileActionDialogText(t("未能刪除，請再試一次"));
        break;
      case "DeleteFile":
        setFileActionDialogText(t("確認要刪除 相關文件 嗎？"));
        break;
      case "ExceedMaxFileUploadSize":
        setFileActionDialogText(`${t("檔案不可超過")} ${alertValue} Mb`);
        break;
      case "UploadFail":
        setFileActionDialogText(t("未能上載，請再試一次"));
        break;
      default:
        break;
    }

    // Set States
    setShowFileActionDialog(true);
  };

  const openFile = () => {
    if (currentItemPath) {
      openFileInNewTab(currentItemPath);
    }
  };

  // Functions - Mutations
  const createOrderToFiles = async (files) => {
    const results = await createOrderToFilesFetch(token, orderId, files);

    if (results.success) {
      getAllFilesByOrderId();
    } else {
      displayFileActionDialog("UploadFail");
    }
  };

  const deleteOrderToFile = async () => {
    const results = await deleteOrderToFileFetch(token, currentItemId);

    if (results.success) {
      getAllFilesByOrderId();
    } else {
      displayFileActionDialog("DeleteFail");
    }
  };

  // Functions - Queries
  const getAllFilesByOrderId = async () => {
    const results = await getAllFilesByOrderIdFetch(token, orderId);

    if (results.files) {
      for (let item of results.files) {
        item.file_size_text = displayFileSizeText(item.file_size);
      }
    }

    // Set States
    setFiles(results.files ? results.files : null);
  };

  // Life Cycle
  useEffect(() => {
    if (orderId) {
      getAllFilesByOrderId();
    }
  }, [orderId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onFileActionDialogCanceled}
        onDialogConfirmed={onFileActionDialogConfirmed}
        // States
        dialogText={fileActionDialogText}
        showDialog={showFileActionDialog}
      />
      {/* Menu */}
      <FileActionMenu
        // States
        showFileActionMenu={showFileActionMenu}
        // Set States
        setShowFileActionMenu={setShowFileActionMenu}
        // Functions
        displayFileActionDialog={displayFileActionDialog}
        openFile={openFile}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("訂單")} - ${t("相關文件s")}`}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            {/* List */}
            {itemsArr ? (
              itemsArr.map((item, index) => (
                <FileBox
                  key={`${item.type_en}-${item.id}`}
                  // States
                  hoveredItemId={hoveredItemId}
                  index={index}
                  item={item}
                  // Events
                  onItemClicked={onItemClicked}
                  onItemRightClicked={onItemRightClicked}
                  onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
                  onMouseEnterItem={onMouseEnterItem}
                  onMouseLeaveItem={onMouseLeaveItem}
                />
              ))
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  {t("未有相關文件")}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <FileUploader
              children={
                <div className={classes.formSubmitBtnContainer}>
                  <Button
                    component="label"
                    endIcon={<UploadRoundedIcon />}
                    variant="outlined"
                    sx={formUploadBtnStyles}
                  >
                    {t("上載相關文件")}
                  </Button>
                </div>
              }
              handleChange={onFileUploaded}
              multiple
              name="file"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default FileViewModal;
