// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Boxes
import MerchandiseCustomUnitBox from "../../boxes/merchandisesPage/merchandiseUnitActionModal/merchandiseCustomUnitBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getMerchandiseUnitFormOptionsFetch } from "../../../fetches/formOptionFetches";
import { createOrEditMerchandiseToUnitsFetch } from "../../../fetches/merchandiseToUnitFetches";
import { getAllCustomUnitsByMerchandiseIdFetch } from "../../../fetches/unitFetches";

// Helper Functions
import {
  createInitialCustomUnitItem,
  createCustomUnitItem,
} from "../../../helperFunctions/createUnitItem";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseUnitActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
  } = props;

  // Redux Store
  const merchandiseId = useSelector(
    (state) => state.merchandisesPage.merchandiseId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [merchandiseUnitActionDialogText, setMerchandiseUnitActionDialogText] =
    useState("");
  const [merchandiseUnitActionDialogType, setMerchandiseUnitActionDialogType] =
    useState(null);
  const [showMerchandiseUnitActionDialog, setShowMerchandiseUnitActionDialog] =
    useState(false);
  // Dynamic Fields
  const [customUnits, setCustomUnits] = useState([
    createInitialCustomUnitItem(),
  ]);
  // Error Fields
  const [dynamicErrorFields, setDynamicErrorFields] = useState([]);
  // Options
  const [customUnitOptions, setCustomUnitOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);

  // Hanlde States
  const selctedCustomUnitIdsArr = customUnits[0]
    ? customUnits
        .filter((item) => item.unitField)
        .map((item) => item.unitField.id)
    : null;

  // Events
  // Events - Dialogs
  const onMerchandiseUnitActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseUnitActionDialog(false);
  };

  const onMerchandiseUnitActionDialogConfirmed = () => {
    switch (merchandiseUnitActionDialogType) {
      case "CreateOrEditMerchandiseUnits":
        createOrEditMerchandiseToUnits();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseUnitActionDialog(false);
  };

  // Events - Fields
  const onDynamicInputFieldChange = (field, id, subField, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "customUnit":
        setCustomUnits((currentState) => {
          let tempState = currentState;

          for (let item of tempState) {
            if (item.id === id) {
              item[subField] = value;

              if (subField === "unitField") {
                if (value) {
                  const tempUnitItem = customUnitOptions.find(
                    (unitItem) => unitItem.id === value.id
                  );

                  item["numOfBaseUnit"] = tempUnitItem.base_unit_ratio
                    ? Math.round(tempUnitItem.base_unit_ratio)
                    : 1;
                  item["isBaseUnit"] = tempUnitItem.is_base_unit;
                  item["isNumOfBaseUnitEditable"] =
                    tempUnitItem.is_editable && !tempUnitItem.base_unit_ratio;
                  item["isPurchasable"] = true;
                  item["minPurchasableUnit"] = 1;
                } else {
                  item["numOfBaseUnit"] = 1;
                  item["isBaseUnit"] = false;
                  item["isNumOfBaseUnitEditable"] = true;
                  item["isPurchasable"] = false;
                  item["minPurchasableUnit"] = 1;
                }
              }
            }
          }

          return tempState;
        });

        removeAllSpecificDynamicError(field, t("計算單位 不可重覆"));
        removeDynamicErrorField(field, id, "unitField");
        break;
      default:
        break;
    }

    removeDynamicErrorField(field, id, subField);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onAddDynamicFieldBtnClicked = (field) => {
    // Set States
    switch (field) {
      case "customUnit":
        setCustomUnits((currentState) => [
          ...currentState,
          createCustomUnitItem(currentState),
        ]);

        break;
      default:
        break;
    }
  };

  const onSubmitBtnClicked = () => {
    // Return If Null
    if (!customUnits[0]) {
      return;
    }

    let isError = false;

    for (let item of customUnits) {
      // Unit Field
      if (!item.unitField) {
        addToDynamicErrorFields(
          "customUnit",
          item.id,
          "unitField",
          t("請先填寫 計算單位")
        );
        isError = true;
      } else {
        // Check Duplicates
        const itemUnitFeildId = item.unitField.id;

        const numOfItemsWithSameUnitField = customUnits.filter(
          (unitItem) =>
            unitItem.unitField && unitItem.unitField.id === itemUnitFeildId
        ).length;

        if (numOfItemsWithSameUnitField && numOfItemsWithSameUnitField > 1) {
          addToDynamicErrorFields(
            "customUnit",
            item.id,
            "unitField",
            t("計算單位 不可重覆")
          );
          isError = true;
        }
      }

      // Num Of Base Unit
      if (!item.numOfBaseUnit) {
        addToDynamicErrorFields(
          "customUnit",
          item.id,
          "numOfBaseUnit",
          t("請先填寫 每單位數量")
        );
        isError = true;
      } else if (isNaN(item.numOfBaseUnit)) {
        addToDynamicErrorFields(
          "customUnit",
          item.id,
          "numOfBaseUnit",
          t("每單位數量 必須為數字")
        );
        isError = true;
      }

      // Min Purcahsable Unit
      if (item.isPurchasable) {
        if (!item.minPurchasableUnit) {
          addToDynamicErrorFields(
            "customUnit",
            item.id,
            "minPurchasableUnit",
            t("請先填寫 最少購買數量")
          );
          isError = true;
        } else if (isNaN(item.minPurchasableUnit)) {
          addToDynamicErrorFields(
            "customUnit",
            item.id,
            "minPurchasableUnit",
            t("最少購買數量 必須為數字")
          );
          isError = true;
        }
      }
    }

    if (isError) {
      return;
    }

    displayMerchandiseUnitActionDialog("CreateOrEditMerchandiseUnits");
  };

  const onDeleteDynamicFieldBtnClicked = (field, id) => {
    // Set States
    switch (field) {
      case "customUnit":
        setCustomUnits((currentState) =>
          currentState.filter((item) => item.id !== id)
        );
        break;
      default:
        break;
    }

    removeAllDynamicErrorField(field, id);
  };

  // Functions
  // Functions - Normal
  const addToDynamicErrorFields = (field, id, subField, message) => {
    if (
      dynamicErrorFields.some(
        (item) =>
          item.field === field && item.id === id && item.subField === subField
      )
    ) {
      return;
    }

    // Set States
    setDynamicErrorFields((currentState) => [
      ...currentState,
      { field, id, subField, message },
    ]);
  };

  const checkIsDynamicFieldError = (field, id, subField) => {
    return dynamicErrorFields.some(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );
  };

  const clearDynamicErrorFields = () => {
    // Set States
    setDynamicErrorFields([]);
  };

  const displayMerchandiseUnitActionDialog = (merchandiseUnitActionType) => {
    // Set States
    setMerchandiseUnitActionDialogType(merchandiseUnitActionType);

    switch (merchandiseUnitActionType) {
      case "CreateOrEditMerchandiseUnits":
        setMerchandiseUnitActionDialogText(t("確認要編輯 計算單位 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseUnitActionDialog(true);
  };

  const getDynamicErrorFieldMessage = (field, id, subField) => {
    const targetField = dynamicErrorFields.find(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeAllDynamicErrorField = (field, id) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field || item.id !== id)
    );
  };

  const removeAllSpecificDynamicError = (field, message) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter(
        (item) => item.field !== field || item.message !== message
      )
    );
  };

  const removeDynamicErrorField = (field, id, subField) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter(
        (item) =>
          item.field !== field || item.id !== id || item.subField !== subField
      )
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createOrEditMerchandiseToUnits = async () => {
    let customUnitArrs = [];

    for (let customUnitItem of customUnits) {
      customUnitArrs.push({
        unitId: customUnitItem.unitField.id,
        numOfBaseUnit: customUnitItem.numOfBaseUnit,
        isPurchasable: customUnitItem.isPurchasable,
        minPurchasableUnit: customUnitItem.minPurchasableUnit
          ? customUnitItem.minPurchasableUnit
          : null,
      });
    }

    const results = await createOrEditMerchandiseToUnitsFetch(
      token,
      merchandiseId,
      customUnitArrs
    );

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());

      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions - Queries
  const getAllCustomUnitsByMerchandiseId = async () => {
    const results = await getAllCustomUnitsByMerchandiseIdFetch(
      token,
      merchandiseId
    );

    if (results.units) {
      let tempUnits = [];

      // Merchandise Units
      for (let i = 0; i < results.units.length; i++) {
        const merchandiseUnitItem = results.units[i];

        const unitId = merchandiseUnitItem.id;
        const numOfBaseUnit = Math.round(merchandiseUnitItem.num_of_base_unit);
        const isBaseUnit = merchandiseUnitItem.is_base_unit;
        const isNumOfBaseUnitEditable =
          merchandiseUnitItem.is_editable &&
          !merchandiseUnitItem.base_unit_ratio;
        const isPurchasable = merchandiseUnitItem.is_purchasable;
        const minPurchasableUnit = Math.round(
          merchandiseUnitItem.min_purchasable_unit
        );

        const tempUnitItem = customUnitOptions.find(
          (item) => item.id === unitId
        );

        tempUnits.push({
          id: i + 1,
          unitField: tempUnitItem,
          numOfBaseUnit: numOfBaseUnit,
          isBaseUnit: isBaseUnit,
          isNumOfBaseUnitEditable: isNumOfBaseUnitEditable,
          isPurchasable: isPurchasable,
          minPurchasableUnit: minPurchasableUnit,
        });
      }

      // Set States
      if (tempUnits[0]) {
        setCustomUnits(tempUnits);
      }
    }

    clearDynamicErrorFields();
  };

  const getMerchandiseUnitFormOptions = async () => {
    const results = await getMerchandiseUnitFormOptionsFetch(token);

    // Set States
    setCustomUnitOptions(results.customUnits ? results.customUnits : []);
  };

  // Life Cycle
  useEffect(() => {
    getMerchandiseUnitFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && customUnitOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [customUnitOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (merchandiseId) {
        getAllCustomUnitsByMerchandiseId();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, merchandiseId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseUnitActionDialogCanceled}
        onDialogConfirmed={onMerchandiseUnitActionDialogConfirmed}
        // States
        dialogText={merchandiseUnitActionDialogText}
        showDialog={showMerchandiseUnitActionDialog}
      />

      {customUnits &&
        customUnits.map((item, index) => (
          <MerchandiseCustomUnitBox
            key={item.id}
            // Events
            onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
            onDynamicInputFieldChange={onDynamicInputFieldChange}
            onInputFieldKeyPressed={onInputFieldKeyPressed}
            // Functions
            checkIsDynamicFieldError={checkIsDynamicFieldError}
            getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
            // States
            customUnitOptions={customUnitOptions}
            index={index}
            item={item}
            selctedCustomUnitIdsArr={selctedCustomUnitIdsArr}
          />
        ))}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={() => onAddDynamicFieldBtnClicked("customUnit")}
              variant="outlined"
              sx={formSubmitBtnStyles}
            >
              {t("新增計算單位")}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default MerchandiseUnitActionModal;
