// Actions
import {
  onFormSubmitCountIncrease,
  onMerchandiseOrderedChange,
  onSelectedMerchandiseChange,
} from "../../../redux/pages/orderProfilePage/orderProfilePageActions";

// Components
// Boxes
import MerchandiseOrderedBox from "./merchandiseOrderedBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import MerchandiseOrderedActionMenu from "../../menus/orderProfilePage/merchandiseOrderedActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import SearchMerchandiseModal from "../../modals/search/searchMerchandiseModal";
// Snackbars
import AlertSnackbar from "../../snackbar/alertSnackbar";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  checkMerchandiseOrderedExistsFetch,
  deleteMerchandiseOrderedFetch,
  getAllMerchandiseOrderedsByOrderIdWithPaginationFetch,
} from "../../../fetches/merchandiseOrderedFetches";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function MerchandiseOrderedBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.orderProfilePage.formSubmitCount
  );
  const isOrderEditable = useSelector(
    (state) => state.orderProfilePage.isOrderEditable
  );
  const language = useSelector((state) => state.language.language);
  const merchandiseOrderedId = useSelector(
    (state) => state.orderProfilePage.merchandiseOrderedId
  );
  const orderId = useSelector((state) => state.orderProfilePage.orderId);
  const token = useSelector((state) => state.staff.token);
  const typeOfPrices = useSelector(
    (state) => state.orderProfilePage.typeOfPrices
  );

  // States
  // Data
  const [merchandiseOrdereds, setMerchandiseOrdereds] = useState(null);
  // Dialog
  const [
    merchandiseOrderedActionDialogText,
    setMerchandiseOrderedActionDialogText,
  ] = useState("");
  const [
    merchandiseOrderedActionDialogType,
    setMerchandiseOrderedActionDialogType,
  ] = useState(null);
  const [
    showMerchandiseOrderedActionDialog,
    setShowMerchandiseOrderedActionDialog,
  ] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [
    showMerchandiseOrderedActionMenu,
    setShowMerchandiseOrderedActionMenu,
  ] = useState(null);
  // Modals
  const [showSearchMerchandiseModal, setShowSearchMerchandiseModal] =
    useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Snackbar
  const [
    showMerchandiseOrderedActionSnackbar,
    setShowMerchandiseOrderedActionSnackbar,
  ] = useState(false);
  const [
    merchandiseOrderedActionSnackbarText,
    setMerchandiseOrderedActionSnackbarText,
  ] = useState("");
  const [
    merchandiseOrderedActionSnackbarType,
    setMerchandiseOrderedActionSnackbarType,
  ] = useState("success");

  // Handle States
  const currentItemId = merchandiseOrderedId;
  const itemsArr = merchandiseOrdereds;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onMerchandiseOrderedChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowMerchandiseOrderedActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowMerchandiseOrderedActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onMerchandiseOrderedActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseOrderedActionDialog(false);
  };

  const onMerchandiseOrderedActionDialogConfirmed = () => {
    switch (merchandiseOrderedActionDialogType) {
      case "DeleteMerchandiseOrdered":
        deleteMerchandiseOrdered();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseOrderedActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onSearchMerchandiseModalItemClicked = async (itemId) => {
    // Check Is Merchandise Ordered Already Exist
    const isValid = await checkMerchandiseOrderedExists(itemId);

    if (!isValid) {
      displayMerchandiseOrderedActionSnackBar("AlreadyExist");
      return;
    }

    // Update Redux Store
    dispatch(onSelectedMerchandiseChange(itemId));

    onSearchMerchandiseModalClosed();
  };

  const onSearchMerchandiseModalClosed = () => {
    // Set States
    setShowSearchMerchandiseModal(false);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Snackbar
  const onMerchandiseOrderedActionSnackbarClosed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Set States
    setShowMerchandiseOrderedActionSnackbar(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onMerchandiseOrderedChange(null));
    dispatch(onSelectedMerchandiseChange(null));

    displaySearchMerchandiseModal();
  };

  // Functions
  // Functions - Normal
  const displayMerchandiseOrderedActionDialog = (
    merchandiseOrderedActionType
  ) => {
    // Set States
    setMerchandiseOrderedActionDialogType(merchandiseOrderedActionType);

    switch (merchandiseOrderedActionType) {
      case "DeleteMerchandiseOrdered":
        setMerchandiseOrderedActionDialogText(t("確認要刪除 訂單商品 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseOrderedActionDialog(true);
  };

  const displayMerchandiseOrderedActionSnackBar = (
    merchandiseOrderedActionType
  ) => {
    switch (merchandiseOrderedActionType) {
      case "AlreadyExist":
        setMerchandiseOrderedActionSnackbarText(t("商品已在 訂單列表 中"));
        setMerchandiseOrderedActionSnackbarType("warning");
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseOrderedActionSnackbar(true);
  };

  const displaySearchMerchandiseModal = () => {
    // Set States
    setShowSearchMerchandiseModal(true);
  };

  // Functions - Mutations
  const deleteMerchandiseOrdered = async () => {
    const results = await deleteMerchandiseOrderedFetch(token, currentItemId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  // Functions - Queries
  const checkMerchandiseOrderedExists = async (merchandiseIdInt) => {
    const results = await checkMerchandiseOrderedExistsFetch(
      token,
      orderId,
      null,
      merchandiseIdInt
    );

    return results.isValid;
  };

  const getAllMerchandiseOrderedsByOrderIdWithPagination = async () => {
    const results = await getAllMerchandiseOrderedsByOrderIdWithPaginationFetch(
      token,
      orderId,
      typeOfPrices,
      pageNum,
      limitNum
    );

    if (results.merchandiseOrdereds) {
      const currencySymbol =
        typeOfPrices === "InTransactionCurrency"
          ? results.currencyDetails
            ? results.currencyDetails.currency_symbol
            : null
          : "HKD";

      for (let item of results.merchandiseOrdereds) {
        item.unit_price_display = item.unit_price
          ? `${currencySymbol} ${separateComma(
              Number(item.unit_price).toFixed(1)
            )}`
          : null;

        item.cost_display = item.cost
          ? `${currencySymbol} ${separateComma(Number(item.cost).toFixed(1))}`
          : null;

        item.actual_cost_display = item.actual_cost
          ? `${currencySymbol} ${separateComma(
              Number(item.actual_cost).toFixed(1)
            )}`
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setMerchandiseOrdereds(
      results.merchandiseOrdereds ? results.merchandiseOrdereds : null
    );

    if (results.pageCount && !results.merchandiseOrdereds) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.merchandiseOrdereds) {
      if (
        !(
          merchandiseOrderedId &&
          results.merchandiseOrdereds.some(
            (item) => item.id === merchandiseOrderedId
          )
        )
      ) {
        dispatch(onMerchandiseOrderedChange(results.merchandiseOrdereds[0].id));
      }
    } else {
      dispatch(onMerchandiseOrderedChange(null));
    }
  };

  // Life Cycle

  useEffect(() => {
    getAllMerchandiseOrderedsByOrderIdWithPagination();
  }, [orderId, pageNum, typeOfPrices, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseOrderedActionDialogCanceled}
        onDialogConfirmed={onMerchandiseOrderedActionDialogConfirmed}
        // States
        dialogText={merchandiseOrderedActionDialogText}
        showDialog={showMerchandiseOrderedActionDialog}
      />
      {/* Menu */}
      <MerchandiseOrderedActionMenu
        // States
        showMerchandiseOrderedActionMenu={showMerchandiseOrderedActionMenu}
        // Set States
        setShowMerchandiseOrderedActionMenu={
          setShowMerchandiseOrderedActionMenu
        }
        // Fucntions
        displayMerchandiseOrderedActionDialog={
          displayMerchandiseOrderedActionDialog
        }
      />
      {/* Modals */}
      <ModalContainer
        // Events
        onModalClosed={onSearchMerchandiseModalClosed}
        // States
        showModal={showSearchMerchandiseModal}
      >
        <SearchMerchandiseModal
          // Events
          onItemClicked={onSearchMerchandiseModalItemClicked}
        />
      </ModalContainer>
      {/* Snackbar */}
      <AlertSnackbar
        // Constants
        horizontalPosition={"left"}
        verticalPosition={"bottom"}
        // Events
        onSnackbarClosed={onMerchandiseOrderedActionSnackbarClosed}
        // States
        showSnackbar={showMerchandiseOrderedActionSnackbar}
        snackbarText={merchandiseOrderedActionSnackbarText}
        snackbarType={merchandiseOrderedActionSnackbarType}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("訂單商品列表")}
          </Typography>
          <Tooltip placement="right" title={t("新增訂單商品")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <MerchandiseOrderedBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              isOrderEditable={isOrderEditable}
              item={item}
              language={language}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有訂單商品資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseOrderedBoxesContainer;
