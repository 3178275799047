export const checkIsRecordDateVarEqualsToday = (
  tabItem,
  recordDateType,
  today,
  recordDateVar
) => {
  switch (tabItem) {
    case "ClientRankings":
    case "Rankings":
    case "SalespersonRankings":
    case "SalesNumbers":
    case "Jobs":
      switch (recordDateType) {
        case "Date":
          return (
            recordDateVar.getFullYear() === today.getFullYear() &&
            recordDateVar.getMonth() === today.getMonth() &&
            recordDateVar.getDate() === today.getDate()
          );
        case "Month":
          return (
            recordDateVar.getFullYear() === today.getFullYear() &&
            recordDateVar.getMonth() === today.getMonth()
          );
        default:
          return null;
      }
    case "OverallNumbers":
    case "SalesTrends":
      switch (recordDateType) {
        case "Date":
          return (
            recordDateVar.getFullYear() === today.getFullYear() &&
            recordDateVar.getMonth() === today.getMonth()
          );
        case "Month":
          return recordDateVar.getFullYear() === today.getFullYear();
        default:
          return null;
      }
    default:
      return null;
  }
};

export const datePickerFormatText = (tabItem, recordDateType) => {
  switch (tabItem) {
    case "ClientRankings":
    case "Rankings":
    case "SalespersonRankings":
    case "SalesNumbers":
    case "Jobs":
      switch (recordDateType) {
        case "Date":
          return "dd/MM/yyyy";
        case "Month":
          return "MM/yyyy";
        default:
          return null;
      }
    case "OverallNumbers":
    case "SalesTrends":
      switch (recordDateType) {
        case "Date":
          return "MM/yyyy";
        case "Month":
          return "yyyy";
        default:
          return null;
      }
    default:
      return null;
  }
};

export const datePickerLabelText = (tabItem, recordDateType) => {
  switch (tabItem) {
    case "ClientRankings":
    case "Rankings":
    case "SalespersonRankings":
    case "SalesNumbers":
    case "Jobs":
      switch (recordDateType) {
        case "Date":
          return "日期 (日/月/年)";
        case "Month":
          return "月份 (月/年)";
        default:
          return null;
      }
    case "OverallNumbers":
    case "SalesTrends":
      switch (recordDateType) {
        case "Date":
          return "月份 (月/年)";
        case "Month":
          return "年份 (年)";
        default:
          return null;
      }
    default:
      return null;
  }
};

export const datePickerViewText = (tabItem, recordDateType) => {
  switch (tabItem) {
    case "ClientRankings":
    case "Rankings":
    case "SalespersonRankings":
    case "SalesNumbers":
    case "Jobs":
      switch (recordDateType) {
        case "Date":
          return ["year", "month", "day"];
        case "Month":
          return ["year", "month"];
        default:
          return null;
      }
    case "OverallNumbers":
    case "SalesTrends":
      switch (recordDateType) {
        case "Date":
          return ["year", "month"];
        case "Month":
          return ["year"];
        default:
          return null;
      }
    default:
      return null;
  }
};

export const getNewDateVar = (
  tabItem,
  recordDateType,
  actionType,
  recordDateVar
) => {
  switch (tabItem) {
    case "ClientRankings":
    case "Rankings":
    case "SalespersonRankings":
    case "SalesNumbers":
    case "Jobs":
      switch (recordDateType) {
        case "Date":
          switch (actionType) {
            case "back":
              return new Date(
                recordDateVar.getFullYear(),
                recordDateVar.getMonth(),
                recordDateVar.getDate() - 1
              );
            case "forward":
              return new Date(
                recordDateVar.getFullYear(),
                recordDateVar.getMonth(),
                recordDateVar.getDate() + 1
              );
            default:
              return null;
          }
        case "Month":
          switch (actionType) {
            case "back":
              return new Date(
                recordDateVar.getFullYear(),
                recordDateVar.getMonth() - 1,
                1
              );
            case "forward":
              return new Date(
                recordDateVar.getFullYear(),
                recordDateVar.getMonth() + 1,
                1
              );
            default:
              return null;
          }
        default:
          return null;
      }
    case "OverallNumbers":
    case "SalesTrends":
      switch (recordDateType) {
        case "Date":
          switch (actionType) {
            case "back":
              return new Date(
                recordDateVar.getFullYear(),
                recordDateVar.getMonth() - 1,
                1
              );
            case "forward":
              return new Date(
                recordDateVar.getFullYear(),
                recordDateVar.getMonth() + 1,
                1
              );
            default:
              return null;
          }
        case "Month":
          switch (actionType) {
            case "back":
              return new Date(recordDateVar.getFullYear() - 1, 1, 1);
            case "forward":
              return new Date(recordDateVar.getFullYear() + 1, 1, 1);
            default:
              return null;
          }
        default:
          return null;
      }
    default:
      return null;
  }
};

export const returnToTodayBtnText = (tabItem, recordDateType) => {
  switch (tabItem) {
    case "ClientRankings":
    case "Rankings":
    case "SalespersonRankings":
    case "SalesNumbers":
    case "Jobs":
      switch (recordDateType) {
        case "Date":
          return "返回本日";
        case "Month":
          return "返回本月";
        default:
          return null;
      }
    case "OverallNumbers":
    case "SalesTrends":
      switch (recordDateType) {
        case "Date":
          return "返回本月";
        case "Month":
          return "返回本年";
        default:
          return null;
      }
    default:
      return null;
  }
};

export const showComparisonBtnText = (
  tabItem,
  recordDateType,
  isComparisonShown
) => {
  switch (tabItem) {
    case "ClientRankings":
    case "Rankings":
    case "SalespersonRankings":
    case "SalesNumbers":
    case "Jobs":
      switch (recordDateType) {
        case "Date":
          return isComparisonShown ? "隠藏對照日期" : "顯示對照日期";
        case "Month":
          return isComparisonShown ? "隠藏對照月份" : "顯示對照月份";
        default:
          return null;
      }
    case "OverallNumbers":
    case "SalesTrends":
      switch (recordDateType) {
        case "Date":
          return isComparisonShown ? "隠藏對照月份" : "顯示對照月份";
        case "Month":
          return isComparisonShown ? "隠藏對照年份" : "顯示對照年份";
        default:
          return null;
      }
    default:
      return null;
  }
};
