// Actions
import { onSalespersonChange } from "../../../redux/pages/salesReportsPage/salesReportsPageActions";

// Components
// Boxes
import RankedSalespersonBox from "./rankedSalespersonBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getRankedSalespersonsWithPaginationFetch } from "../../../fetches/salesNumberFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { roundNumber } from "../../../helperFunctions/roundNumber";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function RankedSalespersonBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.salesReportsPage.formSubmitCount
  );
  const keyword = useSelector((state) => state.salesReportsPage.keyword);
  const language = useSelector((state) => state.language.language);
  const rankingsSalesNumberType = useSelector(
    (state) => state.salesReportsPage.rankingsSalesNumberType
  );
  const recordDate = useSelector((state) => state.salesReportsPage.recordDate);
  const salespersonId = useSelector(
    (state) => state.salesReportsPage.salespersonId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [rankedSalespersons, setRankedSalespersons] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = salespersonId;
  const itemsArr = rankedSalespersons;
  const recordDateVar = new Date(recordDate);

  const recordDateDisplay = moment(recordDateVar).format(t("M 月"));

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onSalespersonChange(itemId));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Normal
  const getRankedSalespersonsDebounce = debounce((keywordStr) => {
    getRankedSalespersonsWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  // Functions - Queries
  const getRankedSalespersonsWithPagination = async (keywordStr) => {
    const startDate = getMonthStartDate(recordDateVar);
    const endDate = getMonthEndDate(recordDateVar);

    const results = await getRankedSalespersonsWithPaginationFetch(
      token,
      rankingsSalesNumberType,
      startDate,
      endDate,
      keywordStr,
      pageNum,
      limitNum
    );

    if (results.rankedSalespersons) {
      for (let item of results.rankedSalespersons) {
        item.revenue = item.revenue
          ? `$ ${separateComma(Number(item.revenue).toFixed())}`
          : null;

        item.num_of_orders = item.num_of_orders
          ? separateComma(roundNumber(Number(item.num_of_orders), 1))
          : 0;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setRankedSalespersons(
      results.rankedSalespersons ? results.rankedSalespersons : null
    );

    if (results.pageCount && !results.rankedSalespersons) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.rankedSalespersons) {
      if (
        !(
          salespersonId &&
          results.rankedSalespersons.some((item) => item.id === salespersonId)
        )
      ) {
        dispatch(onSalespersonChange(results.rankedSalespersons[0].id));
      }
    } else {
      dispatch(onSalespersonChange(null));
    }
  };

  // Life Cycle
  useEffect(() => {
    getRankedSalespersonsDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getRankedSalespersonsWithPagination(keyword);
  }, [rankingsSalesNumberType, recordDate, pageNum, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("銷售員排行")} - ${recordDateDisplay}`}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <RankedSalespersonBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              salesNumberType={rankingsSalesNumberType}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有銷售員排行")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RankedSalespersonBoxesContainer;
