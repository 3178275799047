export const getDragAndHoverIndexes = (draggedItem, monitor, ref, index) => {
  if (!ref.current) {
    return null;
  }

  const dragIndex = draggedItem.index;
  const hoverIndex = index;

  if (dragIndex === hoverIndex) {
    return null;
  }

  const hoverBoundingRect = ref.current?.getBoundingClientRect();
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  const clientOffset = monitor.getClientOffset();
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;

  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return null;
  }

  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return null;
  }

  return { dragIndex, hoverIndex };
};
