// Configs
import apisConfig from "../configs/apisConfig";

export const getCurrentRevenueBasicInfoFetch = async (token) => {
  try {
    const queryRoute = "/salesNumber/getCurrentRevenueBasicInfo";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getRankedClientsWithPaginationFetch = async (
  token,
  salesNumberType,
  startDate,
  endDate,
  keyword,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/salesNumber/getRankedClientsWithPagination";

    const reqBody = {
      salesNumberType,
      startDate,
      endDate,
      keyword,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getRankedSalespersonsWithPaginationFetch = async (
  token,
  salesNumberType,
  startDate,
  endDate,
  keyword,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/salesNumber/getRankedSalespersonsWithPagination";

    const reqBody = {
      salesNumberType,
      startDate,
      endDate,
      keyword,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
