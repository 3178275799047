// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { confirmQuotationFetch } from "../../../fetches/quotationFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ConfirmQuotationActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    quotationId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [quotationDate, setQuotationDate] = useState(new Date());
  // Dialog
  const [
    confirmQuotationActionDialogText,
    setConfirmQuotationActionDialogText,
  ] = useState("");
  const [
    confirmQuotationActionDialogType,
    setConfirmQuotationActionDialogType,
  ] = useState(null);
  const [
    showConfirmQuotationActionDialog,
    setShowConfirmQuotationActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Time Fields
  const [quoteTime, setQuoteTime] = useState(new Date());

  // Handle States
  const quotationDateVar = quotationDate ? new Date(quotationDate) : null;
  const quoteTimeVar = quoteTime ? new Date(quoteTime) : null;

  // Events
  // Events - Dialogs
  const onConfirmQuotationActionDialogCanceled = () => {
    // Set States
    setShowConfirmQuotationActionDialog(false);
  };

  const onConfirmQuotationActionDialogConfirmed = () => {
    switch (confirmQuotationActionDialogType) {
      case "ConfirmQuotation":
        confirmQuotation();
        break;
      default:
        break;
    }

    // Set States
    setShowConfirmQuotationActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "quotationDate":
        setQuotationDate(value);
        break;
      case "quoteTime":
        setQuoteTime(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!quotationDate) {
      addToErrorFields("quotationDate", t("請先填寫 確認報價 日期"));
      isError = true;
    }

    if (!quoteTime) {
      addToErrorFields("quoteTime", t("請先填寫 確認報價 時間"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayConfirmQuotationActionDialog("ConfirmQuotation");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const displayConfirmQuotationActionDialog = (confirmQuotationActionType) => {
    // Set States
    setConfirmQuotationActionDialogType(confirmQuotationActionType);

    switch (confirmQuotationActionType) {
      case "ConfirmQuotation":
        setConfirmQuotationActionDialogText(t("確認要確認 報價 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowConfirmQuotationActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const confirmQuotation = async () => {
    const results = await confirmQuotationFetch(
      token,
      quotationId,
      quotationDate,
      quoteTime
    );

    if (results.success) {
      onModalClosed();
    } else if (results.notAllDetailFilled) {
      showFormAlert("warning", t("請先填寫 銷售員 / 聯絡人 / 地點"));
    } else if (results.notAllMerchandiseQuoted) {
      showFormAlert("warning", t("請先完成所有 商品報價"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onConfirmQuotationActionDialogCanceled}
        onDialogConfirmed={onConfirmQuotationActionDialogConfirmed}
        // States
        dialogText={confirmQuotationActionDialogText}
        showDialog={showConfirmQuotationActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("確認報價")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={6} className={classes.formDatePickerContainer}>
            <DatePicker
              format="dd/MM/yyyy"
              label={t("確認報價日期 (日/月/年)")}
              onChange={(value) => onInputFieldChange("quotationDate", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("quotationDate"),
                  helperText: getErrorFieldMessage("quotationDate"),
                  variant: "outlined",
                },
              }}
              value={quotationDateVar}
              views={["year", "month", "day"]}
            />
          </Grid>
          <Grid item xs={6} className={classes.formDatePickerContainer}>
            <TimePicker
              label={t("確認報價時間")}
              onChange={(value) => onInputFieldChange("quoteTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("quoteTime"),
                  helperText: getErrorFieldMessage("quoteTime"),
                },
              }}
              value={quoteTimeVar}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default ConfirmQuotationActionModal;
