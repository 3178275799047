// Actions
import {
  onRecordMonthChange,
  onTabItemChange,
} from "../../../redux/pages/ordersPage/ordersPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardListPageStyles } from "../../../styles/pageStyles/standardListPageStyles";

// Consts
const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth());

function OrdersPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const recordMonth = useSelector((state) => state.ordersPage.recordMonth);
  const tabItem = useSelector((state) => state.ordersPage.tabItem);

  // Handle States
  const recordMonthVar = recordMonth ? new Date(recordMonth) : null;

  const isRecordMonthVarEqualsCurrentMonth =
    recordMonthVar.getFullYear() === today.getFullYear() &&
    recordMonthVar.getMonth() === today.getMonth();

  // Events
  // Events - Fields
  const onRecordMonthBackAndForwardBtnClicked = (actionType) => {
    const newMonthVar =
      actionType === "back"
        ? new Date(
            recordMonthVar.getFullYear(),
            recordMonthVar.getMonth() - 1,
            1
          )
        : new Date(
            recordMonthVar.getFullYear(),
            recordMonthVar.getMonth() + 1,
            1
          );

    // Update Redux Store
    dispatch(onRecordMonthChange(newMonthVar.toString()));
  };

  const onOrderRecordsMonthChange = (value) => {
    // Update Redux Store
    dispatch(onRecordMonthChange(value.toString()));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  // Events - Title
  const onReturnToCurrentMonthBtnClicked = () => {
    // Update Redux Store
    dispatch(onRecordMonthChange(currentMonth.toString()));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        <Tabs
          scrollButtons={false}
          value={tabItem}
          variant="scrollable"
          onChange={onTabClicked}
        >
          <Tab
            disableRipple
            key={"Orders"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                {t("訂單s")}
              </Typography>
            }
            value={"Orders"}
          />
          <Tab
            disableRipple
            key={"Procedures"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                {t("流程s")}
              </Typography>
            }
            value={"Procedures"}
          />
        </Tabs>
      </div>
      {tabItem === "Orders" && (
        <div className={classes.tabsContainer}>
          {!isRecordMonthVarEqualsCurrentMonth && (
            <div className={classes.formTwinIconBtnContainerLeft}>
              <Button
                onClick={onReturnToCurrentMonthBtnClicked}
                startIcon={<RestoreRoundedIcon />}
                variant="text"
              >
                {t("返回本月")}
              </Button>
            </div>
          )}
          <DatePicker
            format="MM/yyyy"
            label={t("月份 (月/年)")}
            onChange={onOrderRecordsMonthChange}
            slotProps={{ textField: { variant: "standard" } }}
            value={recordMonthVar}
            views={["year", "month"]}
          />
          <div className={classes.formTwinIconBtnContainerRight}>
            <IconButton
              onClick={() => onRecordMonthBackAndForwardBtnClicked("back")}
            >
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => onRecordMonthBackAndForwardBtnClicked("forward")}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        </div>
      )}
    </Box>
  );
}

export default OrdersPageHeader;
