// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/clientProfilePage/clientProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import ClientInfoFormBasicInfoContent from "./clientInfoFormBasicInfoContent";
import ClientInfoFormSettingsContent from "./clientInfoFormSettingsContent";
// Spacing Boxes
import SpacingBox from "../../boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  editClientFetch,
  getClientDetailsByIdFetch,
} from "../../../fetches/clientFetches";
import { getClientInfoFormOptionsFetch } from "../../../fetches/formOptionFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function ClientInfoFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const clientId = useSelector((state) => state.clientProfilePage.clientId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [clientInfoActionDialogText, setClientInfoActionDialogText] =
    useState("");
  const [clientInfoActionDialogType, setClientInfoActionDialogType] =
    useState(null);
  const [showClientInfoActionDialog, setShowClientInfoActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [clientCreditSettingField, setClientCreditSettingField] =
    useState(null);
  const [depositSettingField, setDepositSettingField] = useState(null);
  const [paymentTermSettingField, setPaymentTermSettingField] = useState(null);
  // Options
  const [clientCreditSettingOptions, setClientCreditSettingOptions] = useState(
    []
  );
  const [depositSettingOptions, setDepositSettingOptions] = useState([]);
  const [paymentTermSettingOptions, setPaymentTermSettingOptions] = useState(
    []
  );
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);

  // Events
  // Events - Dialogs
  const onClientInfoActionDialogCanceled = () => {
    // Set States
    setShowClientInfoActionDialog(false);
  };

  const onClientInfoActionDialogConfirmed = () => {
    // Set States
    setShowClientInfoActionDialog(false);

    switch (clientInfoActionDialogType) {
      // 修改
      case "EditClient":
        editClient();
        break;
      default:
        break;
    }
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "code":
        setCode(value);
        break;
      case "clientCreditSettingField":
        setClientCreditSettingField(value);
        break;
      case "depositSettingField":
        setDepositSettingField(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "paymentTermSettingField":
        setPaymentTermSettingField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked("EditClient");
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!code) {
      addToErrorFields("code", t("請先填寫 客戶代號"));
      isError = true;
    }

    if (!clientCreditSettingField) {
      addToErrorFields("clientCreditSettingField", t("請先填寫 客戶信用設定"));
      isError = true;
    }

    if (!depositSettingField) {
      addToErrorFields("depositSettingField", t("請先填寫 訂金設定"));
      isError = true;
    }

    if (!nameCh && !nameEn) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱 或 英文名稱"));
      addToErrorFields("nameEn", t("請先填寫 中文名稱 或 英文名稱"));
      isError = true;
    }

    if (!paymentTermSettingField) {
      addToErrorFields("paymentTermSettingField", t("請先填寫 付款期限設定"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayClientInfoActionDialog("EditClient");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayClientInfoActionDialog = (clientInfoActionType) => {
    // Set States
    setClientInfoActionDialogType(clientInfoActionType);

    switch (clientInfoActionType) {
      // 修改
      case "EditClient":
        setClientInfoActionDialogText(t("確認要編輯 客戶資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowClientInfoActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const editClient = async () => {
    if (clientId) {
      const results = await editClientFetch(
        token,
        clientId,
        code,
        nameCh,
        nameEn,
        clientCreditSettingField ? clientCreditSettingField.id : null,
        paymentTermSettingField ? paymentTermSettingField.id : null,
        depositSettingField ? depositSettingField.id : null,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 客戶代號 已被登記"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getClientDetailsById = async () => {
    const results = await getClientDetailsByIdFetch(token, clientId);

    if (results.clientDetails) {
      const {
        client_code,
        client_name_ch,
        client_name_en,
        is_active,
        client_credit_setting_id,
        payment_term_setting_id,
        deposit_setting_id,
      } = results.clientDetails;

      // Set States
      setCode(client_code ? client_code : "");
      setNameCh(client_name_ch ? client_name_ch : "");
      setNameEn(client_name_en ? client_name_en : "");
      setIsItemActive(is_active);
      setClientCreditSettingField(
        client_credit_setting_id
          ? clientCreditSettingOptions.find(
              (item) => item.id === client_credit_setting_id
            )
          : null
      );
      setPaymentTermSettingField(
        payment_term_setting_id
          ? paymentTermSettingOptions.find(
              (item) => item.id === payment_term_setting_id
            )
          : null
      );
      setDepositSettingField(
        deposit_setting_id
          ? depositSettingOptions.find((item) => item.id === deposit_setting_id)
          : null
      );
    } else {
      // Set States
      setCode("");
      setNameCh("");
      setNameEn("");
      setIsItemActive(true);
      setClientCreditSettingField(null);
      setPaymentTermSettingField(null);
      setDepositSettingField(null);
    }

    clearErrorFields();
  };

  const getClientInfoFormOptions = async () => {
    const results = await getClientInfoFormOptionsFetch(token);

    // Set States
    setClientCreditSettingOptions(
      results.clientCreditSettings ? results.clientCreditSettings : []
    );
    setDepositSettingOptions(
      results.depositSettings ? results.depositSettings : []
    );
    setPaymentTermSettingOptions(
      results.paymentTermSettings ? results.paymentTermSettings : []
    );
  };

  // Life Cycle
  useEffect(() => {
    getClientInfoFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (
      !isOptionsRetrieved &&
      clientCreditSettingOptions[0] &&
      depositSettingOptions[0] &&
      paymentTermSettingOptions[0]
    ) {
      setIsOptionsRetrieved(true);
    }
  }, [
    clientCreditSettingOptions,
    depositSettingOptions,
    paymentTermSettingOptions,
  ]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (clientId) {
        getClientDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [clientId, isOptionsRetrieved]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onClientInfoActionDialogCanceled}
        onDialogConfirmed={onClientInfoActionDialogConfirmed}
        // States
        dialogText={clientInfoActionDialogText}
        showDialog={showClientInfoActionDialog}
      />
      <ClientInfoFormBasicInfoContent
        // States
        code={code}
        isItemActive={isItemActive}
        nameCh={nameCh}
        nameEn={nameEn}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <ClientInfoFormSettingsContent
        // States
        clientCreditSettingField={clientCreditSettingField}
        clientCreditSettingOptions={clientCreditSettingOptions}
        depositSettingField={depositSettingField}
        depositSettingOptions={depositSettingOptions}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        paymentTermSettingField={paymentTermSettingField}
        paymentTermSettingOptions={paymentTermSettingOptions}
        shouldShowFormAlert={shouldShowFormAlert}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
    </>
  );
}

export default ClientInfoFormContainer;
