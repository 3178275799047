// Actions
import {
  onConditionUnitChange,
  onConditionUnitItemChange,
  onOverallSalesNumberTypeChange,
} from "../../../redux/pages/salesReportsPage/salesReportsPageActions";

// Consts
import { overallSalesNumberTypes } from "../../../consts/salesNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllUnitsFetch } from "../../../fetches/unitFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function OverallConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const overallSalesNumberType = useSelector(
    (state) => state.salesReportsPage.overallSalesNumberType
  );
  const token = useSelector((state) => state.staff.token);
  const unitId = useSelector((state) => state.salesReportsPage.conditionUnitId);
  const unitItem = useSelector(
    (state) => state.salesReportsPage.conditionUnitItem
  );

  // States
  // Data
  const [units, setUnits] = useState(null);

  // Handle States
  const overallSalesNumberTypeItem = overallSalesNumberTypes.find(
    (item) => item.type === overallSalesNumberType
  );

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "overallSalesNumberType":
        dispatch(onOverallSalesNumberTypeChange(value.type));
        break;
      case "unit":
        dispatch(onConditionUnitChange(value.id));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Queries
  const getAllUnits = async () => {
    const results = await getAllUnitsFetch(token, "Current");

    // Set States
    setUnits(results.units ? results.units : null);

    // Update Redux Store
    if (results.units && !unitId) {
      dispatch(onConditionUnitChange(results.units[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllUnits();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (units && unitId) {
      dispatch(
        onConditionUnitItemChange(units.find((item) => item.id === unitId))
      );
    }
  }, [units, unitId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("銷售數據類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option[t("nameCh")]}
          onChange={(event, value) =>
            onInputFieldChange("overallSalesNumberType", value)
          }
          options={overallSalesNumberTypes}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("銷售數據類別")}
              variant="outlined"
            />
          )}
          value={overallSalesNumberTypeItem}
        />
      </div>
      {overallSalesNumberTypeItem &&
        overallSalesNumberTypeItem.type === "SalesVolume" && (
          <>
            {/* Select Box */}
            {units && (
              <div className={classes.autoCompleteContainer}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  getOptionLabel={(option) => option[t("unit_name_ch")]}
                  onChange={(event, value) => onInputFieldChange("unit", value)}
                  options={units}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("計算單位")}
                      variant="outlined"
                    />
                  )}
                  value={unitItem}
                />
              </div>
            )}
          </>
        )}
    </div>
  );
}

export default OverallConditionBoxesContainer;
