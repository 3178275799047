// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function ClientActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showClientActionMenu,
    // Set States
    setShowClientActionMenu,
    // Functions
    displayClientActionDialog,
  } = props;

  // Redux Store
  const typeOfClients = useSelector((state) => state.clientsPage.typeOfClients);

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowClientActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayClientActionDialog("DeleteClient");
        break;
      case "Restore":
        displayClientActionDialog("RestoreClient");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showClientActionMenu}
      open={Boolean(showClientActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {typeOfClients === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default ClientActionMenu;
