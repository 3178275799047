// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import RemoveDoneRoundedIcon from "@mui/icons-material/RemoveDoneRounded";

function QuotationActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    selectedQuotation,
    showQuotationActionMenu,
    // Set States
    setShowQuotationActionMenu,
    // Functions
    displayConfirmQuotationActionModal,
    displayCopyQuotationActionModal,
    displayCreateOrderFromQuotationActionModal,
    displayQuotationActionDialog,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowQuotationActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Cancel":
        displayQuotationActionDialog("CancelQuotation");
        break;
      case "CancelConfirmation":
        displayQuotationActionDialog("CancelQuotationConfirmation");
        break;
      case "Confirm":
        displayConfirmQuotationActionModal();
        break;
      case "Copy":
        displayCopyQuotationActionModal();
        break;
      case "Create":
        displayCreateOrderFromQuotationActionModal();
        break;
      case "Delete":
        displayQuotationActionDialog("DeleteQuotation");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showQuotationActionMenu}
      open={Boolean(showQuotationActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {selectedQuotation.quotation_status_type_id === 1 && (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Confirm")}>
          <DoneRoundedIcon />
          {t("確認報價")}
        </MenuItem>
      )}
      {selectedQuotation.quotation_status_type_id === 2 && (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Create")}>
          <AssignmentTurnedInRoundedIcon />
          {t("確認訂單")}
        </MenuItem>
      )}
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Copy")}>
        <ContentCopyRoundedIcon />
        {t("複製")}
      </MenuItem>
      {selectedQuotation.quotation_status_type_id === 2 && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("CancelConfirmation")}
        >
          <RemoveDoneRoundedIcon />
          {t("取消確認報價")}
        </MenuItem>
      )}
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Cancel")}>
        <ClearRoundedIcon />
        {t("取消")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        {t("刪除")}
      </MenuItem>
    </StyledMenu>
  );
}

export default QuotationActionMenu;
