// Actions
import { onMerchandiseChange } from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Boxes
import MerchandiseBox from "./merchandiseBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import MerchandiseActionMenu from "../../menus/merchandisesPage/merchandiseActionMenu";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import {
  deleteOrRestoreMerchandiseFetch,
  getAllMerchandisesWithPaginationFetch,
} from "../../../fetches/merchandiseFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function MerchandiseBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const brandId = useSelector(
    (state) => state.merchandisesPage.conditionBrandId
  );
  const categoryId = useSelector(
    (state) => state.merchandisesPage.conditionCategoryId
  );
  const formSubmitCount = useSelector(
    (state) => state.merchandisesPage.formSubmitCount
  );
  const keyword = useSelector((state) => state.merchandisesPage.keyword);
  const merchandiseId = useSelector(
    (state) => state.merchandisesPage.merchandiseId
  );
  const subCategoryId = useSelector(
    (state) => state.merchandisesPage.conditionSubCategoryId
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfMerchandises = useSelector(
    (state) => state.merchandisesPage.typeOfMerchandises
  );

  // States
  // Data
  const [merchandises, setMerchandises] = useState(null);
  // Dialog
  const [merchandiseActionDialogText, setMerchandiseActionDialogText] =
    useState("");
  const [merchandiseActionDialogType, setMerchandiseActionDialogType] =
    useState(null);
  const [showMerchandiseActionDialog, setShowMerchandiseActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showMerchandiseActionMenu, setShowMerchandiseActionMenu] =
    useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = merchandiseId;
  const itemsArr = merchandises;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onMerchandiseChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowMerchandiseActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowMerchandiseActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onMerchandiseActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseActionDialog(false);
  };

  const onMerchandiseActionDialogConfirmed = () => {
    switch (merchandiseActionDialogType) {
      case "DeleteMerchandise":
        deleteOrRestoreMerchandise();
        break;
      case "RestoreMerchandise":
        deleteOrRestoreMerchandise();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onMerchandiseChange(null));
  };

  // Functions
  // Functions - Normal
  const displayMerchandiseActionDialog = (merchandiseActionType) => {
    // Set States
    setMerchandiseActionDialogType(merchandiseActionType);

    switch (merchandiseActionType) {
      case "DeleteMerchandise":
        setMerchandiseActionDialogText(t("確認要刪除 商品 嗎？"));
        break;
      case "RestoreMerchandise":
        setMerchandiseActionDialogText(t("確認要還原 商品 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseActionDialog(true);
  };

  const getAllMerchandisesDebounce = debounce((keywordStr) => {
    getAllMerchandisesWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  // Functions - Mutations
  const deleteOrRestoreMerchandise = async () => {
    const results = await deleteOrRestoreMerchandiseFetch(
      token,
      currentItemId,
      typeOfMerchandises
    );

    if (results.success) {
      getAllMerchandisesWithPagination(keyword);
    }
  };

  // Functions - Queries
  const getAllMerchandisesWithPagination = async (keywordStr) => {
    const results = await getAllMerchandisesWithPaginationFetch(
      token,
      categoryId !== "dummy" ? categoryId : null,
      subCategoryId !== "dummy" ? subCategoryId : null,
      brandId !== "dummy" ? brandId : null,
      typeOfMerchandises,
      keywordStr,
      pageNum,
      limitNum
    );

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setMerchandises(results.merchandises ? results.merchandises : null);

    if (results.pageCount && !results.merchandises) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.merchandises) {
      if (
        !(
          merchandiseId &&
          results.merchandises.some((item) => item.id === merchandiseId)
        )
      ) {
        dispatch(onMerchandiseChange(results.merchandises[0].id));
      }
    } else {
      dispatch(onMerchandiseChange(null));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllMerchandisesDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getAllMerchandisesWithPagination(keyword);
  }, [
    brandId,
    categoryId,
    subCategoryId,
    pageNum,
    typeOfMerchandises,
    formSubmitCount,
  ]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseActionDialogCanceled}
        onDialogConfirmed={onMerchandiseActionDialogConfirmed}
        // States
        dialogText={merchandiseActionDialogText}
        showDialog={showMerchandiseActionDialog}
      />
      {/* Menu */}
      <MerchandiseActionMenu
        // States
        showMerchandiseActionMenu={showMerchandiseActionMenu}
        // Set States
        setShowMerchandiseActionMenu={setShowMerchandiseActionMenu}
        // Fucntions
        displayMerchandiseActionDialog={displayMerchandiseActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("商品列表")}
          </Typography>
          <Tooltip placement="right" title={t("新增商品")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <MerchandiseBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有商品資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseBoxesContainer;
