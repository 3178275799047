// Configs
import apisConfig from "../configs/apisConfig";

export const copyTermTemplateFetch = async (
  token,
  termTemplateId,
  pdfTypeId,
  termTemplateName
) => {
  try {
    const queryRoute = `/termTemplate/copyTermTemplate/${termTemplateId}`;

    const reqBody = {
      pdfTypeId,
      termTemplateName,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createTermTemplateFetch = async (
  token,
  pdfTypeId,
  termTemplateName,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = "/termTemplate/createTermTemplate";

    const reqBody = {
      pdfTypeId,
      termTemplateName,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreTermTemplateFetch = async (
  token,
  termTemplateId,
  typeOfTermTemplates
) => {
  try {
    const queryRoute = `/termTemplate/deleteOrRestoreTermTemplate/${termTemplateId}`;

    const reqBody = { typeOfTermTemplates };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editTermTemplateFetch = async (
  token,
  termTemplateId,
  pdfTypeId,
  termTemplateName,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = `/termTemplate/editTermTemplate/${termTemplateId}`;

    const reqBody = {
      pdfTypeId,
      termTemplateName,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editTermTemplateSetIsDefaultFetch = async (
  token,
  termTemplateId,
  isDefault
) => {
  try {
    const queryRoute = `/termTemplate/editTermTemplateSetIsDefault/${termTemplateId}`;

    const reqBody = {
      isDefault,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllAvailableTermTemplatesByOrderIdFetch = async (
  token,
  orderId,
  pdfTypeCode
) => {
  try {
    const queryRoute = `/termTemplate/getAllAvailableTermTemplatesByOrderId/${orderId}`;

    const reqBody = { pdfTypeCode };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllAvailableTermTemplatesByQuotationIdFetch = async (
  token,
  quotationId,
  pdfTypeCode
) => {
  try {
    const queryRoute = `/termTemplate/getAllAvailableTermTemplatesByQuotationId/${quotationId}`;

    const reqBody = { pdfTypeCode };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllTermTemplatesByPdfTypeIdFetch = async (
  token,
  pdfTypeId,
  typeOfTermTemplates
) => {
  try {
    const queryRoute = `/termTemplate/getAllTermTemplatesByPdfTypeId/${pdfTypeId}`;

    const reqBody = { typeOfTermTemplates };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getTermTemplateDetailsByIdFetch = async (
  token,
  termTemplateId
) => {
  try {
    const queryRoute = `/termTemplate/getTermTemplateDetailsById/${termTemplateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
