// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";

function TermContentActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showTermContentActionMenu,
    // Set States
    setCreateItemPosition,
    setCurrentItemId,
    setShowTermContentActionMenu,
    // Functions
    displayTermContentActionDialog,
    displayTermContentActionModal,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowTermContentActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "CreateAfter":
        // Set States
        setCreateItemPosition("After");
        setCurrentItemId(null);

        displayTermContentActionModal();
        break;
      case "CreateBefore":
        // Set States
        setCreateItemPosition("Before");
        setCurrentItemId(null);

        displayTermContentActionModal();
        break;
      case "Delete":
        displayTermContentActionDialog("DeleteTermContent");
        break;
      case "Edit":
        displayTermContentActionModal();
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showTermContentActionMenu}
      open={Boolean(showTermContentActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("CreateBefore")}>
        <UploadRoundedIcon />
        {t("在上方新增")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("CreateAfter")}>
        <FileDownloadRoundedIcon />
        {t("在下方新增")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        {t("編輯")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        {t("刪除")}
      </MenuItem>
    </StyledMenu>
  );
}

export default TermContentActionMenu;
