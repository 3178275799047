// Actions
import {
  onOrderChange,
  onTabItemChange,
} from "../../../redux/pages/orderProfilePage/orderProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
import ConfirmDialog from "../../dialogs/confirmDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getOrderDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  checkIsCurrencyEditedFetch,
  createOrderFetch,
  editOrderFetch,
  getOrderDetailsByIdFetch,
} from "../../../fetches/orderFetches";
import { getAllStaffsByDepartmentIdsArrFetch } from "../../../fetches/staffFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function OrderActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    clientId,
    isOrderEditable,
    orderId,
  } = props;

  // Redux Store
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Data
  const [salespersonIdTemp, setsalespersonIdTemp] = useState(null);
  // Date Fields
  const [orderDate, setOrderDate] = useState(new Date());
  // Dialog
  const [orderActionDialogComponentType, setOrderActionDialogComponentType] =
    useState(null);
  const [orderActionDialogOptionValue, setOrderActionDialogOptionValue] =
    useState(null);
  const [orderActionDialogText, setOrderActionDialogText] = useState("");
  const [orderActionDialogType, setOrderActionDialogType] = useState(null);
  const [showOrderActionDialog, setShowOrderActionDialog] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [clientLocationField, setClientLocationField] = useState(null);
  const [contactPersonField, setContactPersonField] = useState(null);
  const [currencyField, setCurrencyField] = useState(null);
  const [departmentField, setDepartmentField] = useState(null);
  const [staffField, setStaffField] = useState(null);
  // Options
  const [clientLocationOptions, setClientLocationOptions] = useState([]);
  const [contactPersonOptions, setContactPersonOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Time Fields
  const [orderTime, setOrderTime] = useState(new Date());

  // Handle States
  const orderDateVar = orderDate ? new Date(orderDate) : null;
  const orderTimeVar = orderTime ? new Date(orderTime) : null;

  // Events
  // Events - Dialogs
  const onOrderActionDialogCanceled = () => {
    // Set States
    setShowOrderActionDialog(false);
  };

  const onOrderActionDialogConfirmed = () => {
    switch (orderActionDialogType) {
      case "CreateOrder":
        createOrder();
        break;
      case "EditOrder":
        editOrder(false);
        break;
      case "EditOrderWithOptions":
        if (orderActionDialogOptionValue) {
          editOrder(
            orderActionDialogOptionValue === t("自動計算匯率 並 修改訂單")
          );
        }
        break;
      default:
        break;
    }

    // Set States
    setShowOrderActionDialog(false);
  };

  const onOrderActionDialogOptionChanged = (value) => {
    // Set States
    setOrderActionDialogOptionValue(value);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "clientLocationField":
        setClientLocationField(value);
        break;
      case "contactPersonField":
        setContactPersonField(value);
        break;
      case "currencyField":
        setCurrencyField(value);
        break;
      case "departmentField":
        setDepartmentField(value);
        break;
      case "orderDate":
        setOrderDate(value);
        break;
      case "orderTime":
        setOrderTime(value);
        break;
      case "staffField":
        setStaffField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    if (!isOrderEditable) {
      return;
    }

    let isError = false;

    if (!currencyField) {
      addToErrorFields("currencyField", t("請先填寫 貨幣"));
      isError = true;
    }

    if (departmentField) {
      if (!staffField) {
        addToErrorFields("staffField", t("請先填寫 銷售員"));
        isError = true;
      }
    }

    if (!orderDate) {
      addToErrorFields("orderDate", t("請先填寫 訂單下訂 日期"));
      isError = true;
    }

    if (!orderTime) {
      addToErrorFields("orderTime", t("請先填寫 訂單下訂 時間"));
      isError = true;
    }

    if (isError) {
      return;
    }

    if (orderId) {
      checkIsCurrencyEdited();
    } else {
      displayOrderActionDialog("CreateOrder");
    }
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayOrderActionDialog = (orderActionType) => {
    // Set States
    setOrderActionDialogType(orderActionType);

    switch (orderActionType) {
      case "CreateOrder":
        setOrderActionDialogComponentType("Alert");
        setOrderActionDialogText(t("確認要新增 訂單 嗎？"));
        break;
      case "EditOrder":
        setOrderActionDialogComponentType("Alert");
        setOrderActionDialogText(t("確認要編輯 訂單 嗎？"));
        break;
      case "EditOrderWithOptions":
        setOrderActionDialogComponentType("Confirm");
        setOrderActionDialogText(t("確認要編輯 訂單 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowOrderActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createOrder = async () => {
    const results = await createOrderFetch(
      token,
      clientId,
      contactPersonField ? contactPersonField.id : null,
      clientLocationField ? clientLocationField.id : null,
      staffField ? staffField.id : null,
      orderDate,
      orderTime,
      currencyField ? currencyField.id : null
    );

    if (results.success) {
      onModalClosed();

      if (results.orderId) {
        // Update Redux Store
        dispatch(onOrderChange(results.orderId));
        dispatch(onTabItemChange("BasicInfo"));

        // Navigate
        navigate("/orderProfile");
      }
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editOrder = async (shouldUpdateMerchandiseOrderedPrices) => {
    const results = await editOrderFetch(
      token,
      orderId,
      contactPersonField ? contactPersonField.id : null,
      clientLocationField ? clientLocationField.id : null,
      staffField ? staffField.id : null,
      orderDate,
      orderTime,
      currencyField ? currencyField.id : null,
      shouldUpdateMerchandiseOrderedPrices
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const checkIsCurrencyEdited = async () => {
    const results = await checkIsCurrencyEditedFetch(
      token,
      orderId,
      currencyField ? currencyField.id : null
    );

    if (results.isCurrencyEdited !== undefined) {
      displayOrderActionDialog(
        results.isCurrencyEdited ? "EditOrderWithOptions" : "EditOrder"
      );
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const getAllStaffsByDepartmentIdsArr = async () => {
    const results = await getAllStaffsByDepartmentIdsArrFetch(
      token,
      departmentField ? [departmentField.id] : null
    );

    // Set States
    setStaffField(
      salespersonIdTemp &&
        results.staffs.some((item) => item.id === salespersonIdTemp)
        ? results.staffs.find((item) => item.id === salespersonIdTemp)
        : null
    );
    setStaffOptions(results.staffs ? results.staffs : []);
  };

  const getOrderDetailsById = async () => {
    const results = await getOrderDetailsByIdFetch(token, orderId);

    if (results.orderDetails) {
      const {
        contact_person_id,
        client_location_id,
        salesperson_id,
        salespersonBasicInfo,
        orderDate,
        time_created,
        currency_id,
      } = results.orderDetails;

      const departmentIdsArr =
        salespersonBasicInfo && salespersonBasicInfo.departmentIdsArr
          ? salespersonBasicInfo.departmentIdsArr
          : null;

      // Set States
      setContactPersonField(
        contact_person_id
          ? contactPersonOptions.find((item) => item.id === contact_person_id)
          : null
      );
      setClientLocationField(
        client_location_id
          ? clientLocationOptions.find((item) => item.id === client_location_id)
          : null
      );
      setDepartmentField(
        departmentIdsArr
          ? departmentOptions.find((item) => item.id === departmentIdsArr[0])
          : null
      );
      setsalespersonIdTemp(salesperson_id ? salesperson_id : null);
      setOrderDate(orderDate ? orderDate : new Date());
      setOrderTime(time_created ? time_created : new Date());
      setCurrencyField(
        currency_id
          ? currencyOptions.find((item) => item.id === currency_id)
          : null
      );
    }

    clearErrorFields();
  };

  const getOrderDetailFormOptions = async () => {
    const results = await getOrderDetailFormOptionsFetch(token, clientId);

    // Set States
    setClientLocationOptions(
      results.clientLocations ? results.clientLocations : []
    );
    setContactPersonOptions(
      results.contactPersons ? results.contactPersons : []
    );
    setCurrencyOptions(results.currencies ? results.currencies : []);
    setDepartmentOptions(results.departments ? results.departments : []);
  };

  // Life Cycle
  useEffect(() => {
    if (clientId) {
      getOrderDetailFormOptions();
    }
  }, [clientId]);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && clientLocationOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [clientLocationOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (orderId) {
        getOrderDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, orderId]);

  useEffect(() => {
    if (departmentField) {
      getAllStaffsByDepartmentIdsArr();
    }
  }, [departmentField]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onOrderActionDialogCanceled}
        onDialogConfirmed={onOrderActionDialogConfirmed}
        // States
        dialogText={orderActionDialogText}
        showDialog={showOrderActionDialog}
      />
      <ConfirmDialog
        // Events
        onDialogClosed={onOrderActionDialogCanceled}
        onDialogConfirmed={onOrderActionDialogConfirmed}
        onOptionChange={onOrderActionDialogOptionChanged}
        // States
        dialogTitle={orderActionDialogText}
        options={[t("自動計算匯率 並 修改訂單"), t("無需修改訂單")]}
        optionValue={orderActionDialogOptionValue}
        showDialog={
          showOrderActionDialog && orderActionDialogComponentType === "Confirm"
        }
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {orderId ? t("編輯訂單") : t("新增訂單")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          {contactPersonOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              <Autocomplete
                disabled={!isOrderEditable}
                disablePortal
                getOptionLabel={(option) =>
                  language === "zh-hk"
                    ? `${option.contact_person_name} ${
                        option[t("person_title_name_ch")]
                      }`
                    : `${option[t("person_title_name_ch")]} ${
                        option.contact_person_name
                      } `
                }
                onChange={(event, value) =>
                  onInputFieldChange("contactPersonField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={contactPersonOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("contactPersonField")}
                    helperText={getErrorFieldMessage("contactPersonField")}
                    label={t("聯絡人")}
                    variant="standard"
                  />
                )}
                value={contactPersonField}
              />
            </Grid>
          )}
          {clientLocationOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              <Autocomplete
                disabled={!isOrderEditable}
                disablePortal
                getOptionLabel={(option) => option.location_name}
                onChange={(event, value) =>
                  onInputFieldChange("clientLocationField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={clientLocationOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("clientLocationField")}
                    helperText={getErrorFieldMessage("clientLocationField")}
                    label={t("地點")}
                    variant="standard"
                  />
                )}
                value={clientLocationField}
              />
            </Grid>
          )}
          {departmentOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              <Autocomplete
                disabled={!isOrderEditable}
                disablePortal
                getOptionLabel={(option) =>
                  option[t("department_name_ch")]
                    ? `${option.department_name_en_short} - ${
                        option[t("department_name_ch")]
                      }`
                    : option.department_name_en_short
                }
                onChange={(event, value) =>
                  onInputFieldChange("departmentField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={departmentOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("departmentField")}
                    helperText={getErrorFieldMessage("departmentField")}
                    label={t("銷售員部門")}
                    variant="standard"
                  />
                )}
                value={departmentField}
              />
            </Grid>
          )}
          {departmentField && staffOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              <Autocomplete
                disabled={!isOrderEditable}
                disablePortal
                getOptionLabel={(option) =>
                  option.staff_code
                    ? `${option.staff_code} - ${option.full_name_en}`
                    : option.full_name_en
                }
                onChange={(event, value) =>
                  onInputFieldChange("staffField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={staffOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("staffField")}
                    helperText={getErrorFieldMessage("staffField")}
                    label={t("銷售員")}
                    variant="standard"
                  />
                )}
                value={staffField}
              />
            </Grid>
          )}
          {currencyOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              <Autocomplete
                disabled={!isOrderEditable}
                disablePortal
                getOptionLabel={(option) =>
                  `${option.currency_symbol} - ${option[t("currency_name_ch")]}`
                }
                onChange={(event, value) =>
                  onInputFieldChange("currencyField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={currencyOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("currencyField")}
                    helperText={getErrorFieldMessage("currencyField")}
                    label={t("貨幣")}
                    variant="standard"
                  />
                )}
                value={currencyField}
              />
            </Grid>
          )}
          <Grid item xs={6} className={classes.formDatePickerContainer}>
            <DatePicker
              disabled={!isOrderEditable}
              format="dd/MM/yyyy"
              label={t("訂單下訂日期 (日/月/年)")}
              onChange={(value) => onInputFieldChange("orderDate", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("orderDate"),
                  helperText: getErrorFieldMessage("orderDate"),
                  variant: "outlined",
                },
              }}
              value={orderDateVar}
              views={["year", "month", "day"]}
            />
          </Grid>
          <Grid item xs={6} className={classes.formDatePickerContainer}>
            <TimePicker
              disabled={!isOrderEditable}
              label={t("訂單下訂時間")}
              onChange={(value) => onInputFieldChange("orderTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("orderTime"),
                  helperText: getErrorFieldMessage("orderTime"),
                },
              }}
              value={orderTimeVar}
            />
          </Grid>
          {isOrderEditable && (
            <Grid item xs={12} className={classes.formSubmitBtnContainer}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={formSubmitBtnStyles}
              >
                {t("確定")}
              </Button>
            </Grid>
          )}
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default OrderActionModal;
