// Actions
import {
  onKeywordChange,
  onRankingsSalesNumberTypeChange,
} from "../../../redux/pages/salesReportsPage/salesReportsPageActions";

// Consts
import { rankingsSalesNumberTypes } from "../../../consts/salesNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function RankingsConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const keyword = useSelector((state) => state.salesReportsPage.keyword);
  const rankingsSalesNumberType = useSelector(
    (state) => state.salesReportsPage.rankingsSalesNumberType
  );
  const tabItem = useSelector((state) => state.salesReportsPage.tabItem);

  // Handle States
  const rankingsSalesNumberTypeItem = rankingsSalesNumberTypes.find(
    (item) => item.type === rankingsSalesNumberType
  );

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "keyword":
        dispatch(onKeywordChange(value));
        break;
      case "rankingsSalesNumberType":
        dispatch(onRankingsSalesNumberTypeChange(value.type));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Normal
  const clearForm = () => {
    // Update Redux Store
    dispatch(onKeywordChange(""));
  };

  // Life Cycle
  useEffect(() => {
    clearForm();
  }, [tabItem]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {tabItem === "SalespersonRankings"
              ? t("搜尋銷售員")
              : t("搜尋客戶")}
          </Typography>
        </div>
      </div>
      {/* Text Field */}
      <div className={classes.autoCompleteContainer}>
        <TextField
          autoFocus
          fullWidth
          label={t("關鍵字")}
          margin="dense"
          name="keyword"
          onChange={(event) =>
            onInputFieldChange("keyword", event.target.value)
          }
          required
          value={keyword}
          variant="standard"
        />
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("銷售數據類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option[t("nameCh")]}
          onChange={(event, value) =>
            onInputFieldChange("rankingsSalesNumberType", value)
          }
          options={rankingsSalesNumberTypes}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("銷售數據類別")}
              variant="outlined"
            />
          )}
          value={rankingsSalesNumberTypeItem}
        />
      </div>
    </div>
  );
}

export default RankingsConditionBoxesContainer;
