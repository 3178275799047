// Actions
import {
  onQuotationChange,
  onTabItemChange,
} from "../../../redux/pages/quotationProfilePage/quotationProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { copyQuotationFetch } from "../../../fetches/quotationFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CopyQuotationActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    quotationId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [requestDate, setRequestDate] = useState(new Date());
  // Dialog
  const [copyQuotationActionDialogText, setCopyQuotationActionDialogText] =
    useState("");
  const [copyQuotationActionDialogType, setCopyQuotationActionDialogType] =
    useState(null);
  const [showCopyQuotationActionDialog, setShowCopyQuotationActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Time Fields
  const [requestTime, setRequestTime] = useState(new Date());

  // Handle States
  const requestDateVar = requestDate ? new Date(requestDate) : null;
  const requestTimeVar = requestTime ? new Date(requestTime) : null;

  // Events
  // Events - Dialogs
  const onCopyQuotationActionDialogCanceled = () => {
    // Set States
    setShowCopyQuotationActionDialog(false);
  };

  const onCopyQuotationActionDialogConfirmed = () => {
    switch (copyQuotationActionDialogType) {
      case "CopyQuotation":
        copyQuotation();
        break;
      default:
        break;
    }

    // Set States
    setShowCopyQuotationActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "requestDate":
        setRequestDate(value);
        break;
      case "requestTime":
        setRequestTime(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!requestDate) {
      addToErrorFields("requestDate", t("請先填寫 報價請求 日期"));
      isError = true;
    }

    if (!requestTime) {
      addToErrorFields("requestTime", t("請先填寫 報價請求 時間"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayCopyQuotationActionDialog("CopyQuotation");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const displayCopyQuotationActionDialog = (copyQuotationActionType) => {
    // Set States
    setCopyQuotationActionDialogType(copyQuotationActionType);

    switch (copyQuotationActionType) {
      case "CopyQuotation":
        setCopyQuotationActionDialogText(t("確認要複製 報價 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCopyQuotationActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const copyQuotation = async () => {
    const results = await copyQuotationFetch(
      token,
      quotationId,
      requestDate,
      requestTime
    );

    if (results.success) {
      onModalClosed();

      if (results.quotationId) {
        // Update Redux Store
        dispatch(onQuotationChange(results.quotationId));
        dispatch(onTabItemChange("BasicInfo"));

        // Navigate
        navigate("/quotationProfile");
      }
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCopyQuotationActionDialogCanceled}
        onDialogConfirmed={onCopyQuotationActionDialogConfirmed}
        // States
        dialogText={copyQuotationActionDialogText}
        showDialog={showCopyQuotationActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("複製報價")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={6} className={classes.formDatePickerContainer}>
            <DatePicker
              format="dd/MM/yyyy"
              label={t("報價請求日期 (日/月/年)")}
              onChange={(value) => onInputFieldChange("requestDate", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("requestDate"),
                  helperText: getErrorFieldMessage("requestDate"),
                  variant: "outlined",
                },
              }}
              value={requestDateVar}
              views={["year", "month", "day"]}
            />
          </Grid>
          <Grid item xs={6} className={classes.formDatePickerContainer}>
            <TimePicker
              label={t("報價請求時間")}
              onChange={(value) => onInputFieldChange("requestTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("requestTime"),
                  helperText: getErrorFieldMessage("requestTime"),
                },
              }}
              value={requestTimeVar}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default CopyQuotationActionModal;
