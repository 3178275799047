// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/termsAndCurrenciesPage/termsAndCurrenciesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  editCurrencyFetch,
  getCurrencyDetailsByIdFetch,
} from "../../../fetches/currencyFetches";

// Helper Functions
import { roundNumber } from "../../../helperFunctions/roundNumber";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CurrencyDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const currencyId = useSelector(
    (state) => state.termsAndCurrenciesPage.currencyId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [currencyDetailActionDialogText, setCurrencyDetailActionDialogText] =
    useState("");
  const [currencyDetailActionDialogType, setCurrencyDetailActionDialogType] =
    useState(null);
  const [showCurrencyDetailAction, setShowCurrencyDetailAction] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [fxRateToHKD, setFxRateToHKD] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [symbol, setSymbol] = useState("");

  // Events
  // Events - Dialogs
  const onCurrencyDetailActionCanceled = () => {
    // Set States
    setShowCurrencyDetailAction(false);
  };

  const onCurrencyDetailActionConfirmed = () => {
    switch (currencyDetailActionDialogType) {
      case "EditCurrency":
        editCurrency();
        break;
      default:
        break;
    }

    // Set States
    setShowCurrencyDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "fxRateToHKD":
        setFxRateToHKD(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "symbol":
        setSymbol(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!fxRateToHKD) {
      addToErrorFields("fxRateToHKD", t("請先填寫 滙率 (對港幣)"));
      isError = true;
    } else {
      if (isNaN(fxRateToHKD)) {
        addToErrorFields("fxRateToHKD", t("滙率 (對港幣) 必須為數字"));
        isError = true;
      } else if (fxRateToHKD < 1) {
        addToErrorFields("fxRateToHKD", t("滙率 (對港幣) 必須大於 0"));
        isError = true;
      }
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (!symbol) {
      addToErrorFields("symbol", t("請先填寫 符號"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayCurrencyDetailActionDialog(
      currencyId ? "EditCurrency" : "CreateCurrency"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setSymbol("");
    setNameCh("");
    setNameEn("");
    setFxRateToHKD("");
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayCurrencyDetailActionDialog = (currencyDetailActionType) => {
    // Set States
    setCurrencyDetailActionDialogType(currencyDetailActionType);
    switch (currencyDetailActionType) {
      case "EditCurrency":
        setCurrencyDetailActionDialogText(t("確認要編輯 貨幣資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCurrencyDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const editCurrency = async () => {
    if (currencyId) {
      const results = await editCurrencyFetch(token, currencyId, isItemActive);

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getCurrencyDetailsById = async () => {
    const results = await getCurrencyDetailsByIdFetch(token, currencyId);

    if (results.currencyDetails) {
      const {
        currency_symbol,
        currency_name_ch,
        currency_name_en,
        fxRateToHKD,
        is_active,
      } = results.currencyDetails;

      // Set States
      setSymbol(currency_symbol ? currency_symbol : "");
      setNameCh(currency_name_ch ? currency_name_ch : "");
      setNameEn(currency_name_en ? currency_name_en : "");
      setFxRateToHKD(
        fxRateToHKD ? separateComma(roundNumber(Number(fxRateToHKD), 6)) : ""
      );
      setIsItemActive(is_active);
    } else {
      // Set States
      setSymbol("");
      setNameCh("");
      setNameEn("");
      setFxRateToHKD("");
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (currencyId) {
      getCurrencyDetailsById();
    } else {
      clearForm();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [currencyId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCurrencyDetailActionCanceled}
        onDialogConfirmed={onCurrencyDetailActionConfirmed}
        // States
        dialogText={currencyDetailActionDialogText}
        showDialog={showCurrencyDetailAction}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("貨幣資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            disabled
            error={checkIsFieldError("symbol")}
            fullWidth
            helperText={getErrorFieldMessage("symbol")}
            label={t("符號")}
            margin="dense"
            name="symbol"
            onChange={(event) =>
              onInputFieldChange("symbol", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={symbol}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            error={checkIsFieldError("fxRateToHKD")}
            fullWidth
            helperText={getErrorFieldMessage("fxRateToHKD")}
            label={t("滙率 (對港幣)")}
            margin="dense"
            name="fxRateToHKD"
            onChange={(event) =>
              onInputFieldChange("fxRateToHKD", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={fxRateToHKD}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {currencyId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default CurrencyDetailForm;
