// Configs
import apisConfig from "../configs/apisConfig";

export const createOrEditMerchandiseToUnitsFetch = async (
  token,
  merchandiseId,
  customUnitsArr
) => {
  try {
    const queryRoute = `/merchandiseToUnit/createOrEditMerchandiseToUnits/${merchandiseId}`;

    const reqBody = {
      customUnitsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseToUnitDetailsByMerchandiseAndUnitIdFetch = async (
  token,
  merchandiseId,
  unitId
) => {
  try {
    const queryRoute =
      "/merchandiseToUnit/getMerchandiseToUnitDetailsByMerchandiseAndUnitId";

    const reqBody = {
      merchandiseId,
      unitId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
