// Configs
import apisConfig from "../configs/apisConfig";

export const createMerchandiseFetch = async (
  token,
  subCategoryId,
  brandId,
  merchandiseCode,
  merchandiseNameCh,
  merchandiseNameEn,
  photo,
  isActive
) => {
  try {
    const queryRoute = "/merchandise/createMerchandise";

    const reqBody = new FormData();

    reqBody.append("subCategoryId", subCategoryId);
    reqBody.append("brandId", brandId);
    reqBody.append("merchandiseCode", merchandiseCode);
    reqBody.append("merchandiseNameCh", merchandiseNameCh);
    reqBody.append("merchandiseNameEn", merchandiseNameEn);
    reqBody.append("isActive", isActive);

    if (photo) {
      reqBody.append("files", photo);
    }

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: reqBody,
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteMerchandisePhotoFetch = async (token, merchandiseId) => {
  try {
    const queryRoute = `/merchandise/deleteMerchandisePhoto/${merchandiseId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreMerchandiseFetch = async (
  token,
  merchandiseId,
  typeOfMerchandises
) => {
  try {
    const queryRoute = `/merchandise/deleteOrRestoreMerchandise/${merchandiseId}`;

    const reqBody = { typeOfMerchandises };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editMerchandiseFetch = async (
  token,
  merchandiseId,
  subCategoryId,
  brandId,
  merchandiseCode,
  merchandiseNameCh,
  merchandiseNameEn,
  isActive
) => {
  try {
    const queryRoute = `/merchandise/editMerchandise/${merchandiseId}`;

    const reqBody = {
      subCategoryId,
      brandId,
      merchandiseCode,
      merchandiseNameCh,
      merchandiseNameEn,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editMerchandisePhotoFetch = async (
  token,
  merchandiseId,
  photo
) => {
  try {
    const queryRoute = `/merchandise/editMerchandisePhoto/${merchandiseId}`;

    const reqBody = new FormData();

    if (photo) {
      reqBody.append("files", photo);
    }

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: reqBody,
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllMerchandisesWithPaginationFetch = async (
  token,
  categoryId,
  subCategoryId,
  brandId,
  typeOfMerchandises,
  keyword,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/merchandise/getAllMerchandisesWithPagination";

    const reqBody = {
      categoryId,
      subCategoryId,
      brandId,
      typeOfMerchandises,
      keyword,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseDetailsByIdFetch = async (token, merchandiseId) => {
  try {
    const queryRoute = `/merchandise/getMerchandiseDetailsById/${merchandiseId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
