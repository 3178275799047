// Components
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ContactPersonBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDeleteDynamicFieldBtnClicked,
    onDynamicInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsDynamicFieldError,
    getDynamicErrorFieldMessage,
    // Options
    personTitleOptions,
    // Render
    shouldShowSpacing,
    // States
    index,
    item,
  } = props;

  // Handle States
  const itemId = item.id;

  return (
    <>
      {shouldShowSpacing && (
        <SpacingBox
          // Render
          height={stylesConfig.spacingBoxMarginBottom}
        />
      )}
      <div className={classes.contentBox}>
        {/* Title */}
        <div className={classes.titleContainer}>
          <div className={classes.titleTextContainer}>
            <Typography variant="h6" align={"left"}>
              {t("聯絡人")} {index + 1}
            </Typography>
          </div>
          <div className={classes.titleTextContainer}>
            {checkIsDynamicFieldError(
              "contactPerson",
              itemId,
              "contactPersonName"
            ) ? (
              <Button
                endIcon={<DeleteRoundedIcon />}
                onClick={() =>
                  onDeleteDynamicFieldBtnClicked("contactPerson", itemId)
                }
                sx={{ color: stylesConfig.redTextColor }}
                variant="text"
              >
                {t("如不需填寫聯絡人，請按此刪除")}
              </Button>
            ) : (
              <Tooltip placement="right" title={t("刪除聯絡人")}>
                <IconButton
                  onClick={() =>
                    onDeleteDynamicFieldBtnClicked("contactPerson", itemId)
                  }
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {/* Form */}
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "contactPerson",
                itemId,
                "contactPersonName"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "contactPerson",
                itemId,
                "contactPersonName"
              )}
              label={t("聯絡人名稱")}
              margin="dense"
              name="contactPersonName"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "contactPerson",
                  itemId,
                  "contactPersonName",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.contactPersonName}
              variant="standard"
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className={classes.formAutoCompleteContainer}
          >
            {personTitleOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("person_title_name_ch")]}
                onChange={(event, value) =>
                  onDynamicInputFieldChange(
                    "contactPerson",
                    itemId,
                    "personTitleField",
                    value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={personTitleOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsDynamicFieldError(
                      "contactPerson",
                      itemId,
                      "personTitleField"
                    )}
                    helperText={getDynamicErrorFieldMessage(
                      "contactPerson",
                      itemId,
                      "personTitleField"
                    )}
                    label={t("稱謂")}
                    variant="standard"
                  />
                )}
                value={item.personTitleField}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "contactPerson",
                itemId,
                "contactNum"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "contactPerson",
                itemId,
                "contactNum"
              )}
              label={t("電話號碼")}
              margin="dense"
              name="contactNum"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "contactPerson",
                  itemId,
                  "contactNum",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.contactNum}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError("contactPerson", itemId, "email")}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "contactPerson",
                itemId,
                "email"
              )}
              label={t("電郵地址")}
              margin="dense"
              name="email"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "contactPerson",
                  itemId,
                  "email",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.email}
              variant="standard"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ContactPersonBox;
