// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function CurrencyBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onClick={() => onItemClicked(item.id)}
      onContextMenu={(event) => {
        event.preventDefault();
        onItemRightClicked(item.id, event.currentTarget);
      }}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentSubContainerLarge}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {item.currency_symbol}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {item[t("currency_name_ch")]}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainer}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {item.fxRateToHKD}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {t("對港幣")}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainer}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {item.fxRateFromHKD}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {t("對貨幣")}
        </Typography>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={(event) => {
            onItemShowMenuBtnClicked(item.id, event.currentTarget);
          }}
          sx={isSelected ? { color: stylesConfig.whiteTextColor } : null}
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default CurrencyBox;
