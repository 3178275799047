// Actions
import {
  onUnitChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createUnitFetch,
  editUnitFetch,
  getBaseUnitDetailsByUnitTypeIdFetch,
  getUnitDetailsByIdFetch,
} from "../../../fetches/unitFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function UnitDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const unitId = useSelector((state) => state.merchandisesPage.unitId);
  const unitTypeId = useSelector((state) => state.merchandisesPage.unitTypeId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [unitDetailActionDialogText, setUnitDetailActionDialogText] =
    useState("");
  const [unitDetailActionDialogType, setUnitDetailActionDialogType] =
    useState(null);
  const [showUnitDetailAction, setShowUnitDetailAction] = useState(false);
  // Data
  const [baseUnitDetails, setBaseUnitDetails] = useState(null);
  const [isEditable, setIsEditable] = useState(true);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [baseUnitRatio, setUnitRatio] = useState("");

  // Events
  // Events - Dialogs
  const onUnitDetailActionCanceled = () => {
    // Set States
    setShowUnitDetailAction(false);
  };

  const onUnitDetailActionConfirmed = () => {
    switch (unitDetailActionDialogType) {
      case "CreateUnit":
        createUnit();
        break;
      case "EditUnit":
        editUnit();
        break;
      default:
        break;
    }

    // Set States
    setShowUnitDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "code":
        setCode(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "baseUnitRatio":
        setUnitRatio(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    if (!isEditable) {
      return;
    }

    let isError = false;

    if (!code) {
      addToErrorFields("code", t("請先填寫 代號"));
      isError = true;
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (baseUnitRatio) {
      if (isNaN(baseUnitRatio)) {
        addToErrorFields("baseUnitRatio", t("單位比例 必須為數字"));
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    displayUnitDetailActionDialog(unitId ? "EditUnit" : "CreateUnit");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setCode("");
    setNameCh("");
    setNameEn("");
    setUnitRatio("");
    setIsEditable(true);
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayUnitDetailActionDialog = (unitDetailActionType) => {
    // Set States
    setUnitDetailActionDialogType(unitDetailActionType);
    switch (unitDetailActionType) {
      case "CreateUnit":
        setUnitDetailActionDialogText(t("確認要新增 計算單位 嗎？"));
        break;
      case "EditUnit":
        setUnitDetailActionDialogText(t("確認要編輯 計算單位資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowUnitDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createUnit = async () => {
    const results = await createUnitFetch(
      token,
      unitTypeId,
      code,
      nameCh,
      nameEn,
      baseUnitRatio ? baseUnitRatio : null,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onUnitChange(results.unitId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 計算單位 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editUnit = async () => {
    if (unitId) {
      const results = await editUnitFetch(
        token,
        unitId,
        unitTypeId,
        code,
        nameCh,
        nameEn,
        baseUnitRatio ? baseUnitRatio : null,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 計算單位 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getBaseUnitDetailsByUnitTypeId = async () => {
    const results = await getBaseUnitDetailsByUnitTypeIdFetch(
      token,
      unitTypeId
    );

    // Set States
    setBaseUnitDetails(
      results.baseUnitDetails ? results.baseUnitDetails : null
    );
  };

  const getUnitDetailsById = async () => {
    const results = await getUnitDetailsByIdFetch(token, unitId);

    if (results.unitDetails) {
      const {
        unit_code,
        unit_name_ch,
        unit_name_en,
        base_unit_ratio,
        is_editable,
        is_active,
      } = results.unitDetails;

      // Set States
      setCode(unit_code ? unit_code : "");
      setNameCh(unit_name_ch ? unit_name_ch : "");
      setNameEn(unit_name_en ? unit_name_en : "");
      setUnitRatio(base_unit_ratio ? base_unit_ratio : "");
      setIsEditable(is_editable ? is_editable : false);
      setIsItemActive(is_active);
    } else {
      // Set States
      setCode("");
      setNameCh("");
      setNameEn("");
      setUnitRatio("");
      setIsEditable(true);
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (unitId) {
      getUnitDetailsById();
    } else {
      clearForm();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [unitId]);

  useEffect(() => {
    if (unitTypeId) {
      getBaseUnitDetailsByUnitTypeId();
    }
  }, [unitTypeId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onUnitDetailActionCanceled}
        onDialogConfirmed={onUnitDetailActionConfirmed}
        // States
        dialogText={unitDetailActionDialogText}
        showDialog={showUnitDetailAction}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("計算單位資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("code")}
            fullWidth
            helperText={getErrorFieldMessage("code")}
            label={t("代號")}
            margin="dense"
            name="code"
            onChange={(event) => onInputFieldChange("code", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={code}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("baseUnitRatio")}
            fullWidth
            helperText={getErrorFieldMessage("baseUnitRatio")}
            InputProps={{
              endAdornment: baseUnitDetails ? (
                <InputAdornment position="start">
                  {baseUnitDetails[t("unit_name_ch")]}
                </InputAdornment>
              ) : null,
            }}
            label={t("單位比例")}
            margin="dense"
            name="baseUnitRatio"
            onChange={(event) =>
              onInputFieldChange("baseUnitRatio", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={baseUnitRatio}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        {isEditable && (
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {unitId ? t("修改") : t("新增")}
            </Button>
          </Grid>
        )}
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default UnitDetailForm;
