// Actions
import { onQuotationChange } from "../../../redux/pages/quotationProfilePage/quotationProfilePageActions";

// Components
// Boxes
import QuotationBox from "./quotationBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import QuotationActionMenu from "../../menus/quotationsPage/quotationActionMenu";
// Modals
import ConfirmQuotationActionModal from "../../modals/quotationProfilePage/confirmQuotationActionModal";
import CopyQuotationActionModal from "../../modals/quotationsPage/copyQuotationActionModal";
import CreateOrderFromQuotationActionModal from "../../modals/quotationProfilePage/createOrderFromQuotationActionModal";
import ModalContainer from "../../modals/modalContainer";
import QuotationActionModal from "../../modals/quotationProfilePage/quotationActionModal";
import SearchClientModal from "../../modals/search/searchClientModal";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import {
  cancelQuotationFetch,
  cancelQuotationConfirmationFetch,
  deleteQuotationFetch,
  getAllQuotationsWithPaginationFetch,
} from "../../../fetches/quotationFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function QuotationBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const keyword = useSelector((state) => state.quotationsPage.keyword);
  const language = useSelector((state) => state.language.language);
  const quotationStatusTypeId = useSelector(
    (state) => state.quotationsPage.conditionQuotationStatusTypeId
  );
  const recordMonth = useSelector((state) => state.quotationsPage.recordMonth);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [quotations, setQuotations] = useState(null);
  // Dialog
  const [quotationActionDialogText, setQuotationActionDialogText] =
    useState("");
  const [quotationActionDialogType, setQuotationActionDialogType] =
    useState(null);
  const [showQuotationActionDialog, setShowQuotationActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showQuotationActionMenu, setShowQuotationActionMenu] = useState(null);
  // Modals
  const [showConfirmQuotationActionModal, setShowConfirmQuotationActionModal] =
    useState(false);
  const [showCopyQuotationActionModal, setShowCopyQuotationActionModal] =
    useState(false);
  const [
    showCreateOrderFromQuotationActionModal,
    setShowCreateOrderFromQuotationActionModal,
  ] = useState(false);
  const [showQuotationActionModal, setShowQuotationActionModal] =
    useState(false);
  const [showSearchClientModal, setShowSearchClientModal] = useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedQuotation, setSelectedQuotation] = useState(null);

  // Handle States
  const currentItem = selectedQuotation;
  const itemsArr = quotations;
  const recordMonthVar = new Date(recordMonth);

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onQuotationChange(itemId));

    // Navigate
    navigate("/quotationProfile");
  };

  const onItemRightClicked = (item, currentTarget) => {
    // Set States
    setSelectedQuotation(item);
    setShowQuotationActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    // Set States
    setSelectedQuotation(item);
    setShowQuotationActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onQuotationActionDialogCanceled = () => {
    // Set States
    setShowQuotationActionDialog(false);
  };

  const onQuotationActionDialogConfirmed = () => {
    switch (quotationActionDialogType) {
      case "CancelQuotation":
        cancelQuotation();
        break;
      case "CancelQuotationConfirmation":
        cancelQuotationConfirmation();
        break;
      case "DeleteQuotation":
        deleteQuotation();
        break;
      default:
        break;
    }

    // Set States
    setShowQuotationActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onConfirmQuotationActionModalClosed = () => {
    // Set States
    setShowConfirmQuotationActionModal(false);

    getAllQuotationsWithPagination(keyword);
  };

  const onCopyQuotationActionModalClosed = () => {
    // Set States
    setShowCopyQuotationActionModal(false);
  };

  const onCreateOrderFromQuotationActionModalClosed = () => {
    // Set States
    setShowCreateOrderFromQuotationActionModal(false);
  };

  const onQuotationActionModalClosed = () => {
    // Set States
    setShowQuotationActionModal(false);
  };

  const onSearchClientModalItemClicked = (itemId) => {
    // Set States
    setSelectedClientId(itemId);

    onSearchClientModalClosed();

    displayQuotationActionModal();
  };

  const onSearchClientModalClosed = () => {
    // Set States
    setShowSearchClientModal(false);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    displaySearchClientModal();
  };

  // Functions
  // Functions - Normal
  const displayConfirmQuotationActionModal = () => {
    setShowConfirmQuotationActionModal(true);
  };

  const displayCopyQuotationActionModal = () => {
    setShowCopyQuotationActionModal(true);
  };

  const displayCreateOrderFromQuotationActionModal = () => {
    setShowCreateOrderFromQuotationActionModal(true);
  };

  const displayQuotationActionDialog = (quotationActionType) => {
    // Set States
    setQuotationActionDialogType(quotationActionType);

    switch (quotationActionType) {
      case "CancelQuotation":
        setQuotationActionDialogText(t("確認要取消 報價 嗎？"));
        break;
      case "CancelQuotationConfirmation":
        setQuotationActionDialogText(t("確認要取消 確認報價 嗎？"));
        break;
      case "DeleteQuotation":
        setQuotationActionDialogText(t("確認要刪除 報價 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowQuotationActionDialog(true);
  };

  const displayQuotationActionModal = () => {
    setShowQuotationActionModal(true);
  };

  const displaySearchClientModal = () => {
    // Set States
    setShowSearchClientModal(true);
  };

  const getQuotationsDebounce = debounce((keywordStr) => {
    getAllQuotationsWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  const resetPage = () => {
    // Set States
    setPageNum(1);
  };

  // Functions - Mutations
  const cancelQuotation = async () => {
    const results = await cancelQuotationFetch(token, currentItem.id);

    if (results.success) {
      getAllQuotationsWithPagination(keyword);
    }
  };

  const cancelQuotationConfirmation = async () => {
    const results = await cancelQuotationConfirmationFetch(
      token,
      currentItem.id
    );

    if (results.success) {
      getAllQuotationsWithPagination(keyword);
    }
  };

  const deleteQuotation = async () => {
    const results = await deleteQuotationFetch(token, currentItem.id);

    if (results.success) {
      getAllQuotationsWithPagination(keyword);
    }
  };

  // Functions - Queries
  const getAllQuotationsWithPagination = async (keywordStr) => {
    const startDate = getMonthStartDate(recordMonthVar);
    const endDate = getMonthEndDate(recordMonthVar);

    const results = await getAllQuotationsWithPaginationFetch(
      token,
      quotationStatusTypeId !== "dummy" ? quotationStatusTypeId : null,
      keywordStr,
      startDate,
      endDate,
      pageNum,
      limitNum
    );

    if (results.quotations) {
      for (let item of results.quotations) {
        item.requestDateDisplay = item.requestDate
          ? moment(item.requestDate).format(t("M 月 D 日"))
          : null;

        item.timeRequestedDisplay = item.time_requested
          ? moment(item.time_requested).format(t("a h:mm"))
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setQuotations(results.quotations ? results.quotations : null);

    if (results.pageCount && !results.quotations) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    resetPage();
  }, [quotationStatusTypeId, keyword]);

  useEffect(() => {
    getQuotationsDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getAllQuotationsWithPagination(keyword);
  }, [quotationStatusTypeId, recordMonth, pageNum, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onQuotationActionDialogCanceled}
        onDialogConfirmed={onQuotationActionDialogConfirmed}
        // States
        dialogText={quotationActionDialogText}
        showDialog={showQuotationActionDialog}
      />
      {/* Menu */}
      {selectedQuotation && (
        <QuotationActionMenu
          // States
          selectedQuotation={selectedQuotation}
          showQuotationActionMenu={showQuotationActionMenu}
          // Set States
          setShowQuotationActionMenu={setShowQuotationActionMenu}
          // Fucntions
          displayConfirmQuotationActionModal={
            displayConfirmQuotationActionModal
          }
          displayCopyQuotationActionModal={displayCopyQuotationActionModal}
          displayCreateOrderFromQuotationActionModal={
            displayCreateOrderFromQuotationActionModal
          }
          displayQuotationActionDialog={displayQuotationActionDialog}
        />
      )}
      {/* Modals */}
      {selectedQuotation && (
        <ModalContainer
          // Events
          onModalClosed={onConfirmQuotationActionModalClosed}
          // States
          showModal={showConfirmQuotationActionModal}
        >
          <ConfirmQuotationActionModal
            // Events
            onModalClosed={onConfirmQuotationActionModalClosed}
            // States
            quotationId={selectedQuotation.id}
          />
        </ModalContainer>
      )}
      {selectedQuotation && (
        <ModalContainer
          // Events
          onModalClosed={onCopyQuotationActionModalClosed}
          // States
          showModal={showCopyQuotationActionModal}
        >
          <CopyQuotationActionModal
            // Events
            onModalClosed={onCopyQuotationActionModalClosed}
            // States
            quotationId={selectedQuotation.id}
          />
        </ModalContainer>
      )}
      {selectedQuotation && (
        <ModalContainer
          // Events
          onModalClosed={onCreateOrderFromQuotationActionModalClosed}
          // States
          showModal={showCreateOrderFromQuotationActionModal}
        >
          <CreateOrderFromQuotationActionModal
            // Events
            onModalClosed={onCreateOrderFromQuotationActionModalClosed}
            // States
            quotationId={selectedQuotation.id}
          />
        </ModalContainer>
      )}
      <ModalContainer
        // Events
        onModalClosed={onQuotationActionModalClosed}
        // States
        showModal={showQuotationActionModal}
      >
        <QuotationActionModal
          // Events
          onModalClosed={onQuotationActionModalClosed}
          // States
          clientId={selectedClientId}
          isQuotationEditable={true}
          quotationId={null}
        />
      </ModalContainer>
      <ModalContainer
        // Events
        onModalClosed={onSearchClientModalClosed}
        // States
        showModal={showSearchClientModal}
      >
        <SearchClientModal
          // Events
          onItemClicked={onSearchClientModalItemClicked}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("報價s")}
          </Typography>
          <Tooltip placement="right" title={t("新增報價")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <QuotationBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有報價")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default QuotationBoxesContainer;
