// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import MerchandiseBoxesContainer from "../components/boxes/merchandisesPage/merchandiseBoxesContainer";
import MerchandiseConditionBoxesContainer from "../components/boxes/merchandisesPage/merchandiseConditionBoxesContainer";
import MerchandiseDescriptionBoxesContainer from "../components/boxes/merchandisesPage/merchandiseDescriptionBoxesContainer";
import MerchandiseUnitBoxesContainer from "../components/boxes/merchandisesPage/merchandiseUnitBoxesContainer";
import UnitBoxesContainer from "../components/boxes/merchandisesPage/unitBoxesContainer";
// Forms
import MerchandiseDetailForm from "../components/forms/merchandisesPage/merchandiseDetailForm";
import UnitDetailForm from "../components/forms/merchandisesPage/unitDetailForm";
// Headers
import MerchandisesPageHeader from "../components/headers/merchandisesPage/merchandisesPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function MerchandisesPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.merchandisesPage.tabItem);
  const typeOfContent = useSelector(
    (state) => state.merchandisesPage.typeOfContent
  );

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Merchandises"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <MerchandisesPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "MerchandiseList" && (
            <MerchandiseConditionBoxesContainer />
          )}
          {tabItem === "UnitOfMeasures" && <UnitBoxesContainer />}
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "MerchandiseList" && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <MerchandiseBoxesContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MerchandiseDetailForm />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                  {typeOfContent === "MerchandiseDescriptions" ? (
                    <MerchandiseDescriptionBoxesContainer />
                  ) : (
                    <MerchandiseUnitBoxesContainer />
                  )}
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {tabItem === "UnitOfMeasures" && (
            <>
              <UnitDetailForm />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default MerchandisesPage;
