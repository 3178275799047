// Configs
import apisConfig from "../configs/apisConfig";

export const createQuotationToFilesFetch = async (
  token,
  quotationId,
  files
) => {
  try {
    const queryRoute = `/quotationToFile/createQuotationToFiles/${quotationId}`;

    const reqBody = new FormData();

    if (files && files[0]) {
      for (let item of files) {
        reqBody.append("files", item);
      }
    }

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: reqBody,
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteQuotationToFileFetch = async (token, fileId) => {
  try {
    const queryRoute = `/quotationToFile/deleteQuotationToFile/${fileId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllFilesByQuotationIdFetch = async (token, quotationId) => {
  try {
    const queryRoute = `/quotationToFile/getAllFilesByQuotationId/${quotationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
