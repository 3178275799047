const initialState = {
  clientId: null,
  formSubmitCount: 0,
  isQuotationEditable: false,
  merchandiseQuotedId: null,
  quotationId: null,
  selectedMerchandiseId: null,
  selectedUnitId: null,
  tabItem: "BasicInfo",
  typeOfPrices: "InTransactionCurrency",
};

export const quotationProfilePageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_QUOTATION_PROFILE_PAGE_CLIENT_CHANGE":
      const { clientId } = action;
      return {
        ...state,
        clientId,
      };
    case "ON_QUOTATION_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_QUOTATION_PROFILE_PAGE_IS_QUOTATION_EDITABLE_CHANGE":
      const { isQuotationEditable } = action;
      return {
        ...state,
        isQuotationEditable,
      };
    case "ON_QUOTATION_PROFILE_PAGE_MERCHANDISE_QUOTED_CHANGE":
      const { merchandiseQuotedId } = action;
      return {
        ...state,
        merchandiseQuotedId,
      };
    case "ON_QUOTATION_PROFILE_PAGE_QUOTATION_CHANGE":
      const { quotationId } = action;
      return {
        ...state,
        quotationId,
      };
    case "ON_QUOTATION_PROFILE_PAGE_SELECTED_MERCHANDISE_CHANGE":
      const { selectedMerchandiseId } = action;
      return {
        ...state,
        selectedMerchandiseId,
      };
    case "ON_QUOTATION_PROFILE_PAGE_SELECTED_UNIT_CHANGE":
      const { selectedUnitId } = action;
      return {
        ...state,
        selectedUnitId,
      };
    case "ON_QUOTATION_PROFILE_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_QUOTATION_PROFILE_PAGE_TYPE_OF_PRICES_CHANGE":
      const { typeOfPrices } = action;
      return {
        ...state,
        typeOfPrices,
      };
    default:
      return state;
  }
};
