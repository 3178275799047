// Configs
import apisConfig from "../configs/apisConfig";

export const createClientFetch = async (
  token,
  clientCode,
  clientNameCh,
  clientNameEn,
  clientLocations,
  contactPersons
) => {
  try {
    const queryRoute = "/client/createClient";

    const reqBody = {
      clientCode,
      clientNameCh,
      clientNameEn,
      clientLocations,
      contactPersons,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreClientFetch = async (
  token,
  clientId,
  typeOfClients
) => {
  try {
    const queryRoute = `/client/deleteOrRestoreClient/${clientId}`;

    const reqBody = { typeOfClients };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editClientFetch = async (
  token,
  clientId,
  clientCode,
  clientNameCh,
  clientNameEn,
  clientCreditSettingId,
  paymentTermSettingId,
  depositSettingId,
  isActive
) => {
  try {
    const queryRoute = `/client/editClient/${clientId}`;

    const reqBody = {
      clientCode,
      clientNameCh,
      clientNameEn,
      clientCreditSettingId,
      paymentTermSettingId,
      depositSettingId,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllClientsWithPaginationFetch = async (
  token,
  typeOfClients,
  keyword,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/client/getAllClientsWithPagination";

    const reqBody = { typeOfClients, keyword, pageNum, limitNum };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getClientBasicInfoByIdFetch = async (token, clientId) => {
  try {
    const queryRoute = `/client/getClientBasicInfoById/${clientId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getClientDetailsByIdFetch = async (token, clientId) => {
  try {
    const queryRoute = `/client/getClientDetailsById/${clientId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
