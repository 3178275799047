// Actions
import {
  onFormSubmitCountIncrease,
  onTabItemChange,
  onTypeOfMerchandisesChange,
  onTypeOfUnitsChange,
} from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { scrapeMerchandisesFetch } from "../../../fetches/scrapeFetches";
import { getLatestUpdateRecordFetch } from "../../../fetches/updateRecordFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
// Icons
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function MerchandisesPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const language = useSelector((state) => state.language.language);
  const tabItem = useSelector((state) => state.merchandisesPage.tabItem);
  const token = useSelector((state) => state.staff.token);
  const typeOfMerchandises = useSelector(
    (state) => state.merchandisesPage.typeOfMerchandises
  );
  const typeOfUnits = useSelector(
    (state) => state.merchandisesPage.typeOfUnits
  );

  // States
  // Dialog
  const [updateMerchandiseDialogText, setUpdateMerchandiseDialogText] =
    useState("");
  const [updateMerchandiseDialogType, setUpdateMerchandiseDialogType] =
    useState(null);
  const [showUpdateMerchandiseDialog, setShowUpdateMerchandiseDialog] =
    useState(false);

  // Events
  // Events - Dialogs
  const onUpdateMerchandiseDialogCanceled = () => {
    // Set States
    setShowUpdateMerchandiseDialog(false);
  };

  const onUpdateMerchandiseDialogConfirmed = () => {
    switch (updateMerchandiseDialogType) {
      case "ConfirmUpdate":
        scrapeMerchandises();
        break;
      default:
        break;
    }

    // Set States
    setShowUpdateMerchandiseDialog(false);
  };

  // Events - Select Box
  const onTypeOfMerchandisesSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfMerchandisesChange(value));
  };

  const onTypeOfUnitsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfUnitsChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  // Events - Title
  const onUpdateMerchandiseBtnClicked = () => {
    getLatestUpdateRecord();
  };

  // Functions
  // Functions - Normal
  const displayUpdateMerchandiseDialog = (
    updateMerchandiseType,
    alertValue
  ) => {
    // Set States
    setUpdateMerchandiseDialogType(updateMerchandiseType);

    switch (updateMerchandiseType) {
      case "ConfirmUpdate":
        setUpdateMerchandiseDialogText(
          alertValue
            ? `${t("上次更新時間為")} ${alertValue}${t("，")}${t(
                "確認要 更新商品 嗎？"
              )}`
            : t("確認要 更新商品 嗎？")
        );
        break;
      case "StillUpdating":
        setUpdateMerchandiseDialogText(t("更新中，請稍等"));
        break;
      case "UpdateFailed":
        setUpdateMerchandiseDialogText(t("未能更新，請再試一次"));
        break;
      default:
        break;
    }

    // Set States
    setShowUpdateMerchandiseDialog(true);
  };

  // Functions - Mutations
  const scrapeMerchandises = async () => {
    const results = await scrapeMerchandisesFetch(token);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    } else if (results.stillUpdating) {
      displayUpdateMerchandiseDialog("StillUpdating", null);
    } else {
      displayUpdateMerchandiseDialog("UpdateFailed", null);
    }
  };

  // Functions - Queries
  const getLatestUpdateRecord = async () => {
    const results = await getLatestUpdateRecordFetch(token, "Merchandises");

    if (results.latestUpdateRecordDetails) {
      if (results.stillUpdating) {
        displayUpdateMerchandiseDialog("StillUpdating", null);
        return;
      }

      const { time_update } = results.latestUpdateRecordDetails;

      const timeUpdateDisplay = time_update
        ? moment(time_update).format(t("M月D日 - ah:mm"))
        : null;

      displayUpdateMerchandiseDialog("ConfirmUpdate", timeUpdateDisplay);
    } else {
      displayUpdateMerchandiseDialog("ConfirmUpdate", null);
    }
  };

  // Life Cycle
  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <Box className={classes.divisionsContainer}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onUpdateMerchandiseDialogCanceled}
        onDialogConfirmed={onUpdateMerchandiseDialogConfirmed}
        // States
        dialogText={updateMerchandiseDialogText}
        showDialog={showUpdateMerchandiseDialog}
      />
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"MerchandiseList"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("商品列表")}
                </Typography>
              }
              value={"MerchandiseList"}
            />
            <Tab
              disableRipple
              key={"UnitOfMeasures"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("計算單位s")}
                </Typography>
              }
              value={"UnitOfMeasures"}
            />
          </Tabs>
        )}
      </div>
      <div className={classes.tabsContainer}>
        {tabItem === "MerchandiseList" && (
          <div className={classes.formTwinIconBtnContainerLeft}>
            <Button
              onClick={onUpdateMerchandiseBtnClicked}
              startIcon={<UpdateRoundedIcon />}
              variant="text"
            >
              {t("更新")}
            </Button>
          </div>
        )}
        {/* Select Boxes */}
        <div>
          {tabItem === "MerchandiseList" && (
            <>
              <FormControl variant="standard" sx={selectBoxContainerStyles}>
                <Select
                  value={typeOfMerchandises}
                  onChange={(event) =>
                    onTypeOfMerchandisesSelectBoxValueChanged(
                      event.target.value
                    )
                  }
                >
                  <MenuItem value={"Current"}>{t("顯示有效商品")}</MenuItem>
                  <MenuItem value={"Deleted"}>{t("顯示已刪除商品")}</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          {tabItem === "UnitOfMeasures" && (
            <>
              <FormControl variant="standard" sx={selectBoxContainerStyles}>
                <Select
                  value={typeOfUnits}
                  onChange={(event) =>
                    onTypeOfUnitsSelectBoxValueChanged(event.target.value)
                  }
                >
                  <MenuItem value={"Current"}>{t("顯示有效計算單位")}</MenuItem>
                  <MenuItem value={"Deleted"}>
                    {t("顯示已刪除計算單位")}
                  </MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </div>
      </div>
    </Box>
  );
}

export default MerchandisesPageHeader;
