// Actions
import {
  onOrderChange,
  onTabItemChange,
} from "../../../redux/pages/orderProfilePage/orderProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { copyOrderFetch } from "../../../fetches/orderFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CopyOrderActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    orderId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [orderDate, setOrderDate] = useState(new Date());
  // Dialog
  const [copyOrderActionDialogText, setCopyOrderActionDialogText] =
    useState("");
  const [copyOrderActionDialogType, setCopyOrderActionDialogType] =
    useState(null);
  const [showCopyOrderActionDialog, setShowCopyOrderActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Time Fields
  const [orderTime, setOrderTime] = useState(new Date());

  // Handle States
  const orderDateVar = orderDate ? new Date(orderDate) : null;
  const orderTimeVar = orderTime ? new Date(orderTime) : null;

  // Events
  // Events - Dialogs
  const onCopyOrderActionDialogCanceled = () => {
    // Set States
    setShowCopyOrderActionDialog(false);
  };

  const onCopyOrderActionDialogConfirmed = () => {
    switch (copyOrderActionDialogType) {
      case "CopyOrder":
        copyOrder();
        break;
      default:
        break;
    }

    // Set States
    setShowCopyOrderActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "orderDate":
        setOrderDate(value);
        break;
      case "orderTime":
        setOrderTime(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!orderDate) {
      addToErrorFields("orderDate", t("請先填寫 訂單下訂 日期"));
      isError = true;
    }

    if (!orderTime) {
      addToErrorFields("orderTime", t("請先填寫 訂單下訂 時間"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayCopyOrderActionDialog("CopyOrder");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const displayCopyOrderActionDialog = (copyOrderActionType) => {
    // Set States
    setCopyOrderActionDialogType(copyOrderActionType);

    switch (copyOrderActionType) {
      case "CopyOrder":
        setCopyOrderActionDialogText(t("確認要複製 訂單 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCopyOrderActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const copyOrder = async () => {
    const results = await copyOrderFetch(token, orderId, orderDate, orderTime);

    if (results.success) {
      onModalClosed();

      if (results.orderId) {
        // Update Redux Store
        dispatch(onOrderChange(results.orderId));
        dispatch(onTabItemChange("BasicInfo"));

        // Navigate
        navigate("/orderProfile");
      }
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCopyOrderActionDialogCanceled}
        onDialogConfirmed={onCopyOrderActionDialogConfirmed}
        // States
        dialogText={copyOrderActionDialogText}
        showDialog={showCopyOrderActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("複製訂單")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={6} className={classes.formDatePickerContainer}>
            <DatePicker
              format="dd/MM/yyyy"
              label={t("訂單下訂日期 (日/月/年)")}
              onChange={(value) => onInputFieldChange("orderDate", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("orderDate"),
                  helperText: getErrorFieldMessage("orderDate"),
                  variant: "outlined",
                },
              }}
              value={orderDateVar}
              views={["year", "month", "day"]}
            />
          </Grid>
          <Grid item xs={6} className={classes.formDatePickerContainer}>
            <TimePicker
              label={t("訂單下訂時間")}
              onChange={(value) => onInputFieldChange("orderTime", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("orderTime"),
                  helperText: getErrorFieldMessage("orderTime"),
                },
              }}
              value={orderTimeVar}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default CopyOrderActionModal;
