// Configs
import apisConfig from "../configs/apisConfig";

export const createPaymentTermSettingFetch = async (
  token,
  paymentPeriodUnitId,
  paymentTermNum,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = "/paymentTermSetting/createPaymentTermSetting";

    const reqBody = {
      paymentPeriodUnitId,
      paymentTermNum,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestorePaymentTermSettingFetch = async (
  token,
  paymentTermSettingId,
  typeOfPaymentTermSettings
) => {
  try {
    const queryRoute = `/paymentTermSetting/deleteOrRestorePaymentTermSetting/${paymentTermSettingId}`;

    const reqBody = { typeOfPaymentTermSettings };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editPaymentTermSettingFetch = async (
  token,
  paymentTermSettingId,
  paymentPeriodUnitId,
  paymentTermNum,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = `/paymentTermSetting/editPaymentTermSetting/${paymentTermSettingId}`;

    const reqBody = {
      paymentPeriodUnitId,
      paymentTermNum,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editPaymentTermSettingSetIsDefaultFetch = async (
  token,
  paymentTermSettingId,
  isDefault
) => {
  try {
    const queryRoute = `/paymentTermSetting/editPaymentTermSettingSetIsDefault/${paymentTermSettingId}`;

    const reqBody = {
      isDefault,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllPaymentTermSettingsFetch = async (
  token,
  typeOfPaymentTermSettings
) => {
  try {
    const queryRoute = "/paymentTermSetting/getAllPaymentTermSettings";

    const reqBody = { typeOfPaymentTermSettings };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getPaymentTermSettingDetailsByIdFetch = async (
  token,
  paymentTermSettingId
) => {
  try {
    const queryRoute = `/paymentTermSetting/getPaymentTermSettingDetailsById/${paymentTermSettingId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
