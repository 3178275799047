// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

function OrderActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showOrderActionMenu,
    // Set States
    setShowOrderActionMenu,
    // Functions
    displayCopyOrderActionModal,
    displayOrderActionDialog,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowOrderActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Cancel":
        displayOrderActionDialog("CancelOrder");
        break;
      case "Copy":
        displayCopyOrderActionModal();
        break;
      case "Delete":
        displayOrderActionDialog("DeleteOrder");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showOrderActionMenu}
      open={Boolean(showOrderActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Copy")}>
        <ContentCopyRoundedIcon />
        {t("複製")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Cancel")}>
        <ClearRoundedIcon />
        {t("取消")}
      </MenuItem>
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        {t("刪除")}
      </MenuItem>
    </StyledMenu>
  );
}

export default OrderActionMenu;
