export const languageZhHk = {
  // Data
  area_name_ch: "area_name_ch",
  brand_name_ch: "brand_name_ch",
  category_name_ch: "category_name_ch",
  credit_period_unit_name_ch: "credit_period_unit_name_ch",
  currency_name_ch: "currency_name_ch",
  department_name_ch: "department_name_ch",
  deposit_type_name_ch: "deposit_type_name_ch",
  displayTextCh: "displayTextCh",
  district_name_ch: "district_name_ch",
  full_name_ch: "full_name_ch",
  merchandise_name_ch: "merchandise_name_ch",
  minPurchaseNumDisplayTextCh: "minPurchaseNumDisplayTextCh",
  nameCh: "nameCh",
  order_discount_display_type_name_ch: "order_discount_display_type_name_ch",
  order_status_type_name_ch: "order_status_type_name_ch",
  payment_period_unit_name_ch: "payment_period_unit_name_ch",
  pdf_type_name_ch: "pdf_type_name_ch",
  person_title_name_ch: "person_title_name_ch",
  placeholder_name_ch: "placeholder_name_ch",
  procedure_name_ch: "procedure_name_ch",
  progress_status_type_name_ch: "progress_status_type_name_ch",
  quotation_discount_display_type_name_ch:
    "quotation_discount_display_type_name_ch",
  quotation_status_type_name_ch: "quotation_status_type_name_ch",
  sub_category_name_ch: "sub_category_name_ch",
  term_content_type_name_ch: "term_content_type_name_ch",
  textCh: "textCh",
  title_name_ch: "title_name_ch",
  unitDisplayTextCh: "unitDisplayTextCh",
  unit_name_ch: "unit_name_ch",
  unit_type_name_ch: "unit_type_name_ch",
  // Moment
  "a h:mm": "a h:mm",
  D日: "D日",
  M月: "M月",
  "M 月 D 日": "M 月 D 日",
  "M月D日 - ah:mm": "M月D日 - ah:mm",
  "M 月": "M 月",
  "Y 年 M 月 D 日": "Y 年 M 月 D 日",
  "Y 年 M 月": "Y 年 M 月",
  "Y 年": "Y 年",
  // Text
  "確認要 登出 嗎？": "確認要 登出 嗎？",
  "Change to ": "Change to ",
  顯示選單: "顯示選單",
  設換至夜間模式: "設換至夜間模式",
  設換至日間模式: "設換至日間模式",
  登出: "登出",
  "請先填寫 英文全名 或 員工編號": "請先填寫 英文全名 或 員工編號",
  "請先填寫 密碼": "請先填寫 密碼",
  未有權限: "未有權限",
  未能登入: "未能登入",
  "英文全名 或 員工編號": "英文全名 或 員工編號",
  密碼: "密碼",
  登入: "登入",
  "載入中...": "載入中...",
  確定: "確定",
  確認: "確認",
  取消: "取消",
  客戶關係管理系統: "客戶關係管理系統",
  銷售報告s: "銷售報告",
  商品銷量s: "商品銷量",
  工作s: "工作",
  訂單s: "訂單",
  報價s: "報價",
  客戶s: "客戶",
  商品s: "商品",
  "類別 / 品牌s": "類別 / 品牌",
  "條款 / 貨幣s": "條款 / 貨幣",
  客戶概覧: "客戶概覧",
  新增客戶: "新增客戶",
  訂單概覧: "訂單概覧",
  報價概覧: "報價概覧",
  整體數據: "整體數據",
  銷售員排行s: "銷售員排行",
  客戶排行s: "客戶排行",
  隠藏對照日期: "隠藏對照日期",
  顯示對照日期: "顯示對照日期",
  隠藏對照月份: "隠藏對照月份",
  顯示對照月份: "顯示對照月份",
  隠藏對照年份: "隠藏對照年份",
  顯示對照年份: "顯示對照年份",
  "對照日期 (日/月/年)": "對照日期 (日/月/年)",
  "日期 (日/月/年)": "日期 (日/月/年)",
  "對照月份 (月/年)": "對照月份 (月/年)",
  "月份 (月/年)": "月份 (月/年)",
  "對照年份 (年)": "對照年份 (年)",
  "年份 (年)": "年份 (年)",
  返回本日: "返回本日",
  返回本月: "返回本月",
  返回本年: "返回本年",
  銷售數據類別: "銷售數據類別",
  以月為顯示單位: "以月為顯示單位",
  以日為顯示單位: "以日為顯示單位",
  計算單位: "計算單位",
  本月營業額: "本月營業額",
  未有營業額資料: "未有營業額資料",
  營業額: "營業額",
  訂單數量: "訂單數量",
  商品銷量: "商品銷量",
  客戶數量: "客戶數量",
  " 張": " 張",
  " 位": " 位",
  趨勢分析: "趨勢分析",
  未有趨勢分析: "未有趨勢分析",
  搜尋銷售員: "搜尋銷售員",
  搜尋客戶: "搜尋客戶",
  關鍵字: "關鍵字",
  銷售員排行: "銷售員排行",
  未有銷售員排行: "未有銷售員排行",
  客戶排行: "客戶排行",
  未有客戶排行: "未有客戶排行",
  商品排行: "商品排行",
  銷售數據s: "銷售數據",
  趨勢分析s: "趨勢分析",
  搜尋商品: "搜尋商品",
  類別: "類別",
  子類別: "子類別",
  品牌: "品牌",
  未有商品排行: "未有商品排行",
  圖片: "圖片",
  銷售數據: "銷售數據",
  未有銷售數據: "未有銷售數據",
  負責人員: "負責人員",
  部門: "部門",
  員工: "員工",
  流程: "流程",
  工作進度: "工作進度",
  "確認要取消完成 工作 嗎？": "確認要取消完成 工作 嗎？",
  "確認現在完成 工作 嗎？": "確認現在完成 工作 嗎？",
  "確認現在開始 工作 嗎？": "確認現在開始 工作 嗎？",
  未有工作: "未有工作",
  開始時間: "開始時間",
  完成時間: "完成時間",
  現在開始: "現在開始",
  現在完成: "現在完成",
  取消完成: "取消完成",
  流程s: "流程",
  搜尋訂單: "搜尋訂單",
  訂單狀態: "訂單狀態",
  "確認要取消 訂單 嗎？": "確認要取消 訂單 嗎？",
  "確認要刪除 訂單 嗎？": "確認要刪除 訂單 嗎？",
  新增訂單: "新增訂單",
  未有訂單: "未有訂單",
  複製: "複製",
  刪除: "刪除",
  "請先填寫 訂單下訂 日期": "請先填寫 訂單下訂 日期",
  "請先填寫 訂單下訂 時間": "請先填寫 訂單下訂 時間",
  "確認要複製 訂單 嗎？": "確認要複製 訂單 嗎？",
  未能提交: "未能提交",
  複製訂單: "複製訂單",
  "訂單下訂日期 (日/月/年)": "訂單下訂日期 (日/月/年)",
  訂單下訂時間: "訂單下訂時間",
  "請先填寫 銷售員": "請先填寫 銷售員",
  "確認要新增 訂單 嗎？": "確認要新增 訂單 嗎？",
  "確認要編輯 訂單 嗎？": "確認要編輯 訂單 嗎？",
  編輯訂單: "編輯訂單",
  聯絡人: "聯絡人",
  地點: "地點",
  銷售員部門: "銷售員部門",
  銷售員: "銷售員",
  未有相關客戶: "未有相關客戶",
  未有相關商品: "未有相關商品",
  未有流程資料: "未有流程資料",
  "請先填寫 部門": "請先填寫 部門",
  "請先填寫 中文名稱": "請先填寫 中文名稱",
  "請先填寫 英文名稱": "請先填寫 英文名稱",
  "確認要編輯 流程資料 嗎？": "確認要編輯 流程資料 嗎？",
  成功提交: "成功提交",
  "此 流程 已存在": "此 流程 已存在",
  流程資料: "流程資料",
  名稱: "名稱",
  中文名稱: "中文名稱",
  英文名稱: "英文名稱",
  修改: "修改",
  概要: "概要",
  進度s: "進度",
  訂單: "訂單",
  查看報價資料: "查看報價資料",
  未有訂單資料: "未有訂單資料",
  客戶: "客戶",
  未有銷售員資料: "未有銷售員資料",
  詳情s: "詳情",
  款商品s: "款商品",
  未有價錢: "未有價錢",
  公司: "公司",
  "確認要刪除 訂單商品 嗎？": "確認要刪除 訂單商品 嗎？",
  "商品已在 訂單列表 中": "商品已在 訂單列表 中",
  訂單商品列表: "訂單商品列表",
  新增訂單商品: "新增訂單商品",
  未有訂單商品資料: "未有訂單商品資料",
  未有單價: "未有單價",
  未有建議: "未有建議",
  總價: "總價",
  未有總價: "未有總價",
  相關訂單s: "相關訂單",
  只顯示此客戶訂單: "只顯示此客戶訂單",
  顯示所有客戶訂單: "顯示所有客戶訂單",
  未有相關訂單資料: "未有相關訂單資料",
  建議: "建議",
  "確認要取消完成 進度 嗎？": "確認要取消完成 進度 嗎？",
  "確認現在完成 進度 嗎？": "確認現在完成 進度 嗎？",
  "確認現在開始 進度 嗎？": "確認現在開始 進度 嗎？",
  未有進度資料: "未有進度資料",
  開始日期: "開始日期",
  完成日期: "完成日期",
  "價錢 必須為數字": "價錢 必須為數字",
  "請先填寫 數量": "請先填寫 數量",
  "數量 必須為數字": "數量 必須為數字",
  "請先填寫 單位": "請先填寫 單位",
  "請先填寫 單位類別": "請先填寫 單位類別",
  "確認要新增 訂單商品 嗎？": "確認要新增 訂單商品 嗎？",
  "確認要編輯 訂單商品資料 嗎？": "確認要編輯 訂單商品資料 嗎？",
  "此 訂單商品 已存在": "此 訂單商品 已存在",
  訂單商品資料: "訂單商品資料",
  商品: "商品",
  單位類別: "單位類別",
  單位: "單位",
  數量: "數量",
  價錢: "價錢",
  新增: "新增",
  "請先填寫 開始日期": "請先填寫 開始日期",
  "請先填寫 開始時間": "請先填寫 開始時間",
  "請先填寫 結束日期": "請先填寫 結束日期",
  "請先填寫 結束時間": "請先填寫 結束時間",
  "確認要編輯 進度資料 嗎？": "確認要編輯 進度資料 嗎？",
  "請先填寫 銷售員 / 聯絡人 / 地點": "請先填寫 銷售員 / 聯絡人 / 地點",
  "請先填寫所有 商品價錢": "請先填寫所有 商品價錢",
  "開始日期 (日/月/年)": "開始日期 (日/月/年)",
  結束日期: "結束日期",
  "結束日期 (日/月/年)": "結束日期 (日/月/年)",
  結束時間: "結束時間",
  搜尋報價: "搜尋報價",
  報價狀態: "報價狀態",
  "確認要取消 報價 嗎？": "確認要取消 報價 嗎？",
  "確認要取消 確認報價 嗎？": "確認要取消 確認報價 嗎？",
  "確認要刪除 報價 嗎？": "確認要刪除 報價 嗎？",
  新增報價: "新增報價",
  未有報價: "未有報價",
  確認報價: "確認報價",
  確認訂單: "確認訂單",
  取消確認報價: "取消確認報價",
  "請先填寫 確認報價 日期": "請先填寫 確認報價 日期",
  "請先填寫 確認報價 時間": "請先填寫 確認報價 時間",
  "確認要確認 報價 嗎？": "確認要確認 報價 嗎？",
  "請先完成所有 商品報價": "請先完成所有 商品報價",
  "確認報價日期 (日/月/年)": "確認報價日期 (日/月/年)",
  確認報價時間: "確認報價時間",
  "請先填寫 報價請求 日期": "請先填寫 報價請求 日期",
  "請先填寫 報價請求 時間": "請先填寫 報價請求 時間",
  "確認要複製 報價 嗎？": "確認要複製 報價 嗎？",
  複製報價: "複製報價",
  "報價請求日期 (日/月/年)": "報價請求日期 (日/月/年)",
  報價請求時間: "報價請求時間",
  "確認要確認 訂單 嗎？": "確認要確認 訂單 嗎？",
  "確認要新增 報價 嗎？": "確認要新增 報價 嗎？",
  "確認要編輯 報價 嗎？": "確認要編輯 報價 嗎？",
  編輯報價: "編輯報價",
  報價: "報價",
  查看訂單資料: "查看訂單資料",
  未有報價資料: "未有報價資料",
  "確認要刪除 報價商品 嗎？": "確認要刪除 報價商品 嗎？",
  "商品已在 報價列表 中": "商品已在 報價列表 中",
  報價商品列表: "報價商品列表",
  新增報價商品: "新增報價商品",
  未有報價商品資料: "未有報價商品資料",
  相關報價s: "相關報價",
  只顯示此客戶報價: "只顯示此客戶報價",
  顯示所有客戶報價: "顯示所有客戶報價",
  未有相關報價資料: "未有相關報價資料",
  "確認要新增 報價商品 嗎？": "確認要新增 報價商品 嗎？",
  "確認要編輯 報價商品資料 嗎？": "確認要編輯 報價商品資料 嗎？",
  "此 報價商品 已存在": "此 報價商品 已存在",
  報價商品資料: "報價商品資料",
  顯示有效客戶: "顯示有效客戶",
  顯示已刪除客戶: "顯示已刪除客戶",
  "確認要刪除 客戶 嗎？": "確認要刪除 客戶 嗎？",
  "確認要還原 客戶 嗎？": "確認要還原 客戶 嗎？",
  未有客戶: "未有客戶",
  還原: "還原",
  基本資料: "基本資料",
  聯絡人s: "聯絡人",
  地點s: "地點",
  "請先填寫 客戶代號": "請先填寫 客戶代號",
  "請先填寫 中文名稱 或 英文名稱": "請先填寫 中文名稱 或 英文名稱",
  "請先填寫 區域": "請先填寫 區域",
  "請先填寫 地區": "請先填寫 地區",
  "請先填寫 名稱": "請先填寫 名稱",
  "請先填寫 稱謂": "請先填寫 稱謂",
  "此 客戶代號 已被登記": "此 客戶代號 已被登記",
  客戶代號: "客戶代號",
  上一步: "上一步",
  下一步: "下一步",
  新增聯絡人: "新增聯絡人",
  "如不需填寫聯絡人，請按此刪除": "如不需填寫聯絡人，請按此刪除",
  刪除聯絡人: "刪除聯絡人",
  聯絡人名稱: "聯絡人名稱",
  稱謂: "稱謂",
  電話號碼: "電話號碼",
  電郵地址: "電郵地址",
  新增地點: "新增地點",
  提交: "提交",
  "如不需填寫地點，請按此刪除": "如不需填寫地點，請按此刪除",
  刪除地點: "刪除地點",
  地點名稱: "地點名稱",
  區域: "區域",
  地區: "地區",
  地址: "地址",
  顯示有效聯絡人: "顯示有效聯絡人",
  顯示已刪除聯絡人: "顯示已刪除聯絡人",
  顯示有效地點: "顯示有效地點",
  顯示已刪除地點: "顯示已刪除地點",
  未有客戶資料: "未有客戶資料",
  "確認要編輯 客戶資料 嗎？": "確認要編輯 客戶資料 嗎？",
  有效: "有效",
  編輯: "編輯",
  "確認要刪除 聯絡人 嗎？": "確認要刪除 聯絡人 嗎？",
  "確認要還原 聯絡人 嗎？": "確認要還原 聯絡人 嗎？",
  未有聯絡人資料: "未有聯絡人資料",
  "請先填寫 聯絡人名稱": "請先填寫 聯絡人名稱",
  "確認要新增 聯絡人 嗎？": "確認要新增 聯絡人 嗎？",
  "確認要編輯 聯絡人 嗎？": "確認要編輯 聯絡人 嗎？",
  "此 聯絡人 已存在": "此 聯絡人 已存在",
  編輯聯絡人: "編輯聯絡人",
  "確認要刪除 地點 嗎？": "確認要刪除 地點 嗎？",
  "確認要還原 地點 嗎？": "確認要還原 地點 嗎？",
  未有地點資料: "未有地點資料",
  "請先填寫 地點名稱": "請先填寫 地點名稱",
  "確認要新增 地點 嗎？": "確認要新增 地點 嗎？",
  "確認要編輯 地點 嗎？": "確認要編輯 地點 嗎？",
  "此 地點 已存在": "此 地點 已存在",
  編輯地點: "編輯地點",
  商品列表: "商品列表",
  計算單位s: "計算單位",
  顯示有效商品: "顯示有效商品",
  顯示已刪除商品: "顯示已刪除商品",
  顯示有效計算單位: "顯示有效計算單位",
  顯示已刪除計算單位: "顯示已刪除計算單位",
  "確認要刪除 商品 嗎？": "確認要刪除 商品 嗎？",
  "確認要還原 商品 嗎？": "確認要還原 商品 嗎？",
  新增商品: "新增商品",
  未有商品資料: "未有商品資料",
  "確認要刪除 圖片 嗎？": "確認要刪除 圖片 嗎？",
  檔案不可超過: "檔案不可超過",
  "未能上載，請再試一次": "未能上載，請再試一次",
  上載圖片: "上載圖片",
  刪除圖片: "刪除圖片",
  "請先填寫 品牌": "請先填寫 品牌",
  "請先填寫 類別": "請先填寫 類別",
  "請先填寫 子類別": "請先填寫 子類別",
  "請先填寫 代號": "請先填寫 代號",
  "確認要新增 商品 嗎？": "確認要新增 商品 嗎？",
  "確認要編輯 商品資料 嗎？": "確認要編輯 商品資料 嗎？",
  "此 商品 已存在": "此 商品 已存在",
  商品資料: "商品資料",
  代號: "代號",
  重新: "重新",
  上載商品照片: "上載商品照片",
  編輯計算單位: "編輯計算單位",
  新增計算單位: "新增計算單位",
  未有計算單位資料: "未有計算單位資料",
  "計算單位 不可重覆": "計算單位 不可重覆",
  "請先填寫 計算單位": "請先填寫 計算單位",
  "請先填寫 每單位數量": "請先填寫 每單位數量",
  "請先填寫 最少購買數量": "請先填寫 最少購買數量",
  "確認要編輯 計算單位 嗎？": "確認要編輯 計算單位 嗎？",
  "每單位數量 必須為數字": "每單位數量 必須為數字",
  "最少購買數量 必須為數字": "最少購買數量 必須為數字",
  自訂單位: "自訂單位",
  "如不需填寫計算單位，請按此刪除": "如不需填寫計算單位，請按此刪除",
  刪除計算單位: "刪除計算單位",
  每單位數量: "每單位數量",
  可購買: "可購買",
  最少購買數量: "最少購買數量",
  "確認要刪除 計算單位 嗎？": "確認要刪除 計算單位 嗎？",
  "不能修改此 計算單位": "不能修改此 計算單位",
  "確認要還原 計算單位 嗎？": "確認要還原 計算單位 嗎？",
  計算單位種類: "計算單位種類",
  " (基礎單位)": " (基礎單位)",
  "單位比例 必須為數字": "單位比例 必須為數字",
  "確認要新增 計算單位 嗎？": "確認要新增 計算單位 嗎？",
  "確認要編輯 計算單位資料 嗎？": "確認要編輯 計算單位資料 嗎？",
  "此 計算單位 已存在": "此 計算單位 已存在",
  計算單位資料: "計算單位資料",
  單位比例: "單位比例",
  類別s: "類別",
  品牌s: "品牌",
  顯示有效類別: "顯示有效類別",
  顯示有效品牌: "顯示有效品牌",
  顯示已刪除類別: "顯示已刪除類別",
  顯示已刪除品牌: "顯示已刪除品牌",
  顯示有效子類別: "顯示有效子類別",
  顯示已刪除子類別: "顯示已刪除子類別",
  "確認要刪除 類別 嗎？": "確認要刪除 類別 嗎？",
  "確認要刪除 品牌 嗎？": "確認要刪除 品牌 嗎？",
  "確認要還原 類別 嗎？": "確認要還原 類別 嗎？",
  "確認要還原 品牌 嗎？": "確認要還原 品牌 嗎？",
  新增類別: "新增類別",
  新增品牌: "新增品牌",
  顯示類別資料: "顯示類別資料",
  顯示子類別列表: "顯示子類別列表",
  未有類別資料: "未有類別資料",
  未有品牌資料: "未有品牌資料",
  "確認要刪除 子類別 嗎？": "確認要刪除 子類別 嗎？",
  "確認要還原 子類別 嗎？": "確認要還原 子類別 嗎？",
  子類別s: "子類別",
  新增子類別: "新增子類別",
  未有子類別資料: "未有子類別資料",
  "確認要新增 類別 嗎？": "確認要新增 類別 嗎？",
  "確認要編輯 類別資料 嗎？": "確認要編輯 類別資料 嗎？",
  "此 類別 已存在": "此 類別 已存在",
  類別資料: "類別資料",
  "確認要新增 品牌 嗎？": "確認要新增 品牌 嗎？",
  "確認要編輯 品牌資料 嗎？": "確認要編輯 品牌資料 嗎？",
  "此 品牌 已存在": "此 品牌 已存在",
  品牌資料: "品牌資料",
  "確認要新增 子類別 嗎？": "確認要新增 子類別 嗎？",
  "確認要編輯 子類別資料 嗎？": "確認要編輯 子類別資料 嗎？",
  "此 子類別 已存在": "此 子類別 已存在",
  子類別資料: "子類別資料",
  客戶信用設定: "客戶信用設定",
  "確認要新增 客戶信用設定 嗎？": "確認要新增 客戶信用設定 嗎？",
  "確認要編輯 客戶信用設定 嗎？": "確認要編輯 客戶信用設定 嗎？",
  付款期限設定: "付款期限設定",
  "確認要新增 付款期限設定 嗎？": "確認要新增 付款期限設定 嗎？",
  "確認要編輯 付款期限設定 嗎？": "確認要編輯 付款期限設定 嗎？",
  信用期限: "信用期限",
  "請先填寫 信用期限": "請先填寫 信用期限",
  "信用期限 必須為數字": "信用期限 必須為數字",
  "信用期限 必須大於 0": "信用期限 必須大於 0",
  信用: "信用",
  更新: "更新",
  "未能更新，請再試一次": "未能更新，請再試一次",
  "確認要刪除 描述 嗎？": "確認要刪除 描述 嗎？",
  描述s: "描述",
  新增描述: "新增描述",
  未有描述資料: "未有描述資料",
  "請先填寫 中文描述 或 英文描述": "請先填寫 中文描述 或 英文描述",
  "確認要新增 描述 嗎？": "確認要新增 描述 嗎？",
  "確認要編輯 描述 嗎？": "確認要編輯 描述 嗎？",
  "此 描述 已存在": "此 描述 已存在",
  編輯描述: "編輯描述",
  中文描述: "中文描述",
  英文描述: "英文描述",
  顯示計算單位: "顯示計算單位",
  顯示描述: "顯示描述",
  "，": "，",
  上次更新時間為: "上次更新時間為",
  "確認要 更新商品 嗎？": "確認要 更新商品 嗎？",
  未有中文描述: "未有中文描述",
  未有英文描述: "未有英文描述",
  有優惠: "有優惠",
  優惠資料: "優惠資料",
  優惠顯示類別: "優惠顯示類別",
  "請先填寫 優惠顯示類別": "請先填寫 優惠顯示類別",
  折實價: "折實價",
  未有折實價: "未有折實價",
  檔案: "檔案",
  "未能刪除，請再試一次": "未能刪除，請再試一次",
  "確認要刪除 相關文件 嗎？": "確認要刪除 相關文件 嗎？",
  相關文件s: "相關文件",
  未有相關文件: "未有相關文件",
  上載相關文件: "上載相關文件",
  開啟: "開啟",
  查看相關文件s: "查看相關文件",
  條款類別: "條款類別",
  預設選項: "預設選項",
  一般選項: "一般選項",
  條款s: "條款",
  顯示有效條款: "顯示有效條款",
  顯示已刪除條款: "顯示已刪除條款",
  "確認要刪除 客戶信用設定 嗎？": "確認要刪除 客戶信用設定 嗎？",
  "確認要設為 一般選項 嗎？": "確認要設為 一般選項 嗎？",
  "確認要還原 客戶信用設定 嗎？": "確認要還原 客戶信用設定 嗎？",
  "確認要設為 預設選項 嗎？": "確認要設為 預設選項 嗎？",
  客戶信用設定s: "客戶信用設定",
  新增客戶信用設定: "新增客戶信用設定",
  未有客戶信用設定資料: "未有客戶信用設定資料",
  "確認要刪除 付款期限設定 嗎？": "確認要刪除 付款期限設定 嗎？",
  "確認要還原 付款期限設定 嗎？": "確認要還原 付款期限設定 嗎？",
  付款期限設定s: "付款期限設定",
  新增付款期限設定: "新增付款期限設定",
  未有付款期限設定資料: "未有付款期限設定資料",
  設為一般選項: "設為一般選項",
  設為預設選項: "設為預設選項",
  "此 設定 已存在": "此 設定 已存在",
  付款期限: "付款期限",
  "請先填寫 付款期限": "請先填寫 付款期限",
  "付款期限 必須為數字": "付款期限 必須為數字",
  "付款期限 必須大於 0": "付款期限 必須大於 0",
  "確認要刪除 訂金設定 嗎？": "確認要刪除 訂金設定 嗎？",
  "確認要還原 訂金設定 嗎？": "確認要還原 訂金設定 嗎？",
  訂金設定s: "訂金設定",
  新增訂金設定: "新增訂金設定",
  未有訂金設定資料: "未有訂金設定資料",
  "請先填寫 訂金": "請先填寫 訂金",
  "訂金 必須為數字": "訂金 必須為數字",
  "訂金 必須大於 0": "訂金 必須大於 0",
  "請先填寫 訂金類型": "請先填寫 訂金類型",
  "確認要新增 訂金設定 嗎？": "確認要新增 訂金設定 嗎？",
  "確認要編輯 訂金設定 嗎？": "確認要編輯 訂金設定 嗎？",
  訂金設定: "訂金設定",
  訂金: "訂金",
  訂金類型: "訂金類型",
  貨幣s: "貨幣",
  "創建報價單 (Pdf)": "創建報價單 (Pdf)",
  "再次創建報價單 (Pdf)": "再次創建報價單 (Pdf)",
  "確認要 創建報價單 (Pdf) 嗎？": "確認要 創建報價單 (Pdf) 嗎？",
  "報價單 (Pdf)s": "報價單 (Pdf)",
  "未有報價單 (Pdf)": "未有報價單 (Pdf)",
  "確認要刪除 報價單 (Pdf) 嗎？": "確認要刪除 報價單 (Pdf) 嗎？",
  "版本. ": "版本",
  "查看報價單 (Pdf)s": "查看報價單 (Pdf)",
  搜尋貨幣: "搜尋貨幣",
  "確認要還原 貨幣 嗎？": "確認要還原 貨幣 嗎？",
  "確認要刪除 貨幣 嗎？": "確認要刪除 貨幣 嗎？",
  未有貨幣資料: "未有貨幣資料",
  顯示有效貨幣: "顯示有效貨幣",
  顯示已刪除貨幣: "顯示已刪除貨幣",
  對港幣: "對港幣",
  對貨幣: "對貨幣",
  符號: "符號",
  "請先填寫 符號": "請先填寫 符號",
  貨幣資料: "貨幣資料",
  "確認要編輯 貨幣資料 嗎？": "確認要編輯 貨幣資料 嗎？",
  "請先填寫 滙率 (對港幣)": "請先填寫 滙率 (對港幣)",
  "滙率 (對港幣) 必須為數字": "滙率 (對港幣) 必須為數字",
  "滙率 (對港幣) 必須大於 0": "滙率 (對港幣) 必須大於 0",
  "滙率 (對港幣)": "滙率 (對港幣)",
  以交易貨幣顯示: "以交易貨幣顯示",
  以港幣顯示: "以港幣顯示",
  貨幣: "貨幣",
  "請先填寫 貨幣": "請先填寫 貨幣",
  "自動計算匯率 並 修改報價": "自動計算匯率 並 修改報價",
  無需修改報價: "無需修改報價",
  "自動計算匯率 並 修改訂單": "自動計算匯率 並 修改訂單",
  無需修改訂單: "無需修改訂單",
  "查看採購訂單 (Pdf)s": "查看採購訂單 (Pdf)",
  "查看形式發票 (Pdf)s": "查看形式發票 (Pdf)",
  "查看發票 (Pdf)s": "查看發票 (Pdf)",
  "確認要 創建採購訂單 (Pdf) 嗎？": "確認要 創建採購訂單 (Pdf) 嗎？",
  "採購訂單 (Pdf)s": "採購訂單 (Pdf)",
  "未有採購訂單 (Pdf)": "未有採購訂單 (Pdf)",
  "確認要刪除 採購訂單 (Pdf) 嗎？": "確認要刪除 採購訂單 (Pdf) 嗎？",
  "確認要 創建形式發票 (Pdf) 嗎？": "確認要 創建形式發票 (Pdf) 嗎？",
  "形式發票 (Pdf)s": "形式發票 (Pdf)",
  "未有形式發票 (Pdf)": "未有形式發票 (Pdf)",
  "確認要刪除 形式發票 (Pdf) 嗎？": "確認要刪除 形式發票 (Pdf) 嗎？",
  "確認要 創建發票 (Pdf) 嗎？": "確認要 創建發票 (Pdf) 嗎？",
  "發票 (Pdf)s": "發票 (Pdf)",
  "未有發票 (Pdf)": "未有發票 (Pdf)",
  "確認要刪除 發票 (Pdf) 嗎？": "確認要刪除 發票 (Pdf) 嗎？",
  "創建採購訂單 (Pdf)": "創建採購訂單 (Pdf)",
  "再次創建採購訂單 (Pdf)": "再次創建採購訂單 (Pdf)",
  "創建形式發票 (Pdf)": "創建形式發票 (Pdf)",
  "再次創建形式發票 (Pdf)": "再次創建形式發票 (Pdf)",
  "創建發票 (Pdf)": "創建發票 (Pdf)",
  "再次創建發票 (Pdf)": "再次創建發票 (Pdf)",
  "請先填寫 客戶信用設定": "請先填寫 客戶信用設定",
  "請先填寫 訂金設定": "請先填寫 訂金設定",
  "請先填寫 付款期限設定": "請先填寫 付款期限設定",
  客戶設定s: "客戶設定",
  客戶設定類別: "客戶設定類別",
  顯示有效客戶設定: "顯示有效客戶設定",
  顯示已刪除客戶設定: "顯示已刪除客戶設定",
  "確認要刪除 條款模板 嗎？": "確認要刪除 條款模板 嗎？",
  "確認要還原 條款模板 嗎？": "確認要還原 條款模板 嗎？",
  條款模板s: "條款模板",
  新增條款模板: "新增條款模板",
  未有條款模板資料: "未有條款模板資料",
  "請先填寫 條款類型": "請先填寫 條款類型",
  "請先填寫 模板名稱": "請先填寫 模板名稱",
  "確認要新增 條款模板 嗎？": "確認要新增 條款模板 嗎？",
  "確認要編輯 條款模板 嗎？": "確認要編輯 條款模板 嗎？",
  "此 模板 已存在": "此 模板 已存在",
  條款模板: "條款模板",
  模板名稱: "模板名稱",
  條款類型: "條款類型",
  顯示條款模板資料: "顯示條款模板資料",
  顯示條款內容: "顯示條款內容",
  "確認要複製 條款模板 嗎？": "確認要複製 條款模板 嗎？",
  複製條款模板: "複製條款模板",
  "確認要刪除 條款內容 嗎？": "確認要刪除 條款內容 嗎？",
  "請先填寫 內容類別": "請先填寫 內容類別",
  "請先填寫 內容": "請先填寫 內容",
  "確認要新增 條款內容 嗎？": "確認要新增 條款內容 嗎？",
  "確認要編輯 條款內容 嗎？": "確認要編輯 條款內容 嗎？",
  編輯條款內容: "編輯條款內容",
  新增條款內容: "新增條款內容",
  內容類別: "內容類別",
  內容: "內容",
  在上方新增: "在上方新增",
  在下方新增: "在下方新增",
  未有條款內容: "未有條款內容",
  未有相關佔位符: "未有相關佔位符",
  查看佔位符s: "查看佔位符",
  "請先填寫 條款模板": "請先填寫 條款模板",
  "更新中，請稍等": "更新中，請稍等",
};
