// Actions
import {
  onConditionPdfTypeChange,
  onConditionPdfTypeItemChange,
} from "../../../redux/pages/termsAndCurrenciesPage/termsAndCurrenciesPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllPdfTypesFetch } from "../../../fetches/pdfTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function TermsConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const pdfTypeId = useSelector(
    (state) => state.termsAndCurrenciesPage.conditionPdfTypeId
  );
  const pdfTypeItem = useSelector(
    (state) => state.termsAndCurrenciesPage.conditionPdfTypeItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [pdfTypes, setPdfTypes] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "pdfType":
        dispatch(onConditionPdfTypeChange(value.id));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Queries
  const getAllPdfTypes = async () => {
    const results = await getAllPdfTypesFetch(token, "Current");

    // Set States
    setPdfTypes(results.pdfTypes ? results.pdfTypes : null);

    // Update Redux Store
    if (results.pdfTypes && !pdfTypeId) {
      dispatch(onConditionPdfTypeChange(results.pdfTypes[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllPdfTypes();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (pdfTypes && pdfTypeId) {
      dispatch(
        onConditionPdfTypeItemChange(
          pdfTypes.find((item) => item.id === pdfTypeId)
        )
      );
    }
  }, [pdfTypes, pdfTypeId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("條款類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {pdfTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("pdf_type_name_ch")]}
            onChange={(event, value) => onInputFieldChange("pdfType", value)}
            options={pdfTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("條款類別")} variant="outlined" />
            )}
            value={pdfTypeItem}
          />
        </div>
      )}
    </div>
  );
}

export default TermsConditionBoxesContainer;
