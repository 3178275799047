// Configs
import apisConfig from "../configs/apisConfig";

export const createContactPersonFetch = async (
  token,
  clientId,
  personTitleId,
  contactPersonName,
  contactNum,
  email,
  isActive
) => {
  try {
    const queryRoute = "/contactPerson/createContactPerson";

    const reqBody = {
      clientId,
      personTitleId,
      contactPersonName,
      contactNum,
      email,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreContactPersonFetch = async (
  token,
  contactPersonId,
  typeOfContactPersons
) => {
  try {
    const queryRoute = `/contactPerson/deleteOrRestoreContactPerson/${contactPersonId}`;

    const reqBody = { typeOfContactPersons };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editContactPersonFetch = async (
  token,
  contactPersonId,
  personTitleId,
  contactPersonName,
  contactNum,
  email,
  isActive
) => {
  try {
    const queryRoute = `/contactPerson/editContactPerson/${contactPersonId}`;

    const reqBody = {
      personTitleId,
      contactPersonName,
      contactNum,
      email,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllContactPersonsByClientIdFetch = async (
  token,
  clientId,
  typeOfContactPersons
) => {
  try {
    const queryRoute = `/contactPerson/getAllContactPersonsByClientId/${clientId}`;

    const reqBody = { typeOfContactPersons };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getContactPersonDetailsByIdFetch = async (
  token,
  contactPersonId
) => {
  try {
    const queryRoute = `/contactPerson/getContactPersonDetailsById/${contactPersonId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
