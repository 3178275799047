export function onConditionOrderStatusTypeChange(conditionOrderStatusTypeId) {
  return {
    type: "ON_ORDERS_PAGE_CONDITION_ORDER_STATUS_TYPE_CHANGE",
    conditionOrderStatusTypeId,
  };
}

export function onConditionOrderStatusTypeItemChange(
  conditionOrderStatusTypeItem
) {
  return {
    type: "ON_ORDERS_PAGE_CONDITION_ORDER_STATUS_TYPE_ITEM_CHANGE",
    conditionOrderStatusTypeItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_ORDERS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_ORDERS_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onProcedureChange(procedureId) {
  return {
    type: "ON_ORDERS_PAGE_PROCEDURE_CHANGE",
    procedureId,
  };
}

export function onRecordMonthChange(recordMonth) {
  return {
    type: "ON_ORDERS_PAGE_RECORD_MONTH_CHANGE",
    recordMonth,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_ORDERS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
