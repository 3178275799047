// Configs
import apisConfig from "../configs/apisConfig";

export const getSalesTrendByCategoryIdFetch = async (
  token,
  categoryId,
  salesNumberType,
  unitId,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate
) => {
  try {
    const queryRoute = `/merchandiseSalesTrend/getSalesTrendByCategoryId/${categoryId}`;

    const reqBody = {
      salesNumberType,
      unitId,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalesTrendByMerchandiseIdFetch = async (
  token,
  merchandiseId,
  salesNumberType,
  unitId,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate
) => {
  try {
    const queryRoute = `/merchandiseSalesTrend/getSalesTrendByMerchandiseId/${merchandiseId}`;

    const reqBody = {
      salesNumberType,
      unitId,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalesTrendBySubCategoryIdFetch = async (
  token,
  subCategoryId,
  salesNumberType,
  unitId,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate
) => {
  try {
    const queryRoute = `/merchandiseSalesTrend/getSalesTrendBySubCategoryId/${subCategoryId}`;

    const reqBody = {
      salesNumberType,
      unitId,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalesTrendForWholeCompanyFetch = async (
  token,
  salesNumberType,
  unitId,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate
) => {
  try {
    const queryRoute = "/merchandiseSalesTrend/getSalesTrendForWholeCompany";

    const reqBody = {
      salesNumberType,
      unitId,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
