// Actions
import {
  onFormSubmitCountIncrease,
  onTermTemplateChange,
  onTypeOfContentChange,
} from "../../../redux/pages/termsAndCurrenciesPage/termsAndCurrenciesPageActions";

// Components
// Boxes
import TermTemplateBox from "./termTemplateBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Modals
import CopyTermTemplateActionModal from "../../modals/termsAndCurrenciesPage/copyTermTemplateActionModal";
import ModalContainer from "../../modals/modalContainer";
// Menus
import TermTemplateActionMenu from "../../menus/termsAndCurrenciesPage/termTemplateActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreTermTemplateFetch,
  editTermTemplateSetIsDefaultFetch,
  getAllTermTemplatesByPdfTypeIdFetch,
} from "../../../fetches/termTemplateFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function TermTemplateBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.termsAndCurrenciesPage.formSubmitCount
  );
  const pdfTypeId = useSelector(
    (state) => state.termsAndCurrenciesPage.conditionPdfTypeId
  );
  const termTemplateId = useSelector(
    (state) => state.termsAndCurrenciesPage.termTemplateId
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfContent = useSelector(
    (state) => state.termsAndCurrenciesPage.typeOfContent
  );
  const typeOfTerms = useSelector(
    (state) => state.termsAndCurrenciesPage.typeOfTerms
  );

  // States
  // Data
  const [termTemplates, setTermTemplates] = useState(null);
  // Dialog
  const [termTemplateActionDialogText, setTermTemplateActionDialogText] =
    useState("");
  const [termTemplateActionDialogType, setTermTemplateActionDialogType] =
    useState(null);
  const [showTermTemplateActionDialog, setShowTermTemplateActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showTermTemplateActionMenu, setShowTermTemplateActionMenu] =
    useState(null);
  // Modals
  const [showCopyTermTemplateActionModal, setShowCopyTermTemplateActionModal] =
    useState(false);
  // Render
  const [selectedTermTemplate, setSelectedTermTemplate] = useState(null);

  // Handle States
  const currentItemId = termTemplateId;
  const itemsArr = termTemplates;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onTermTemplateChange(itemId));
  };

  const onItemRightClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedTermTemplate(item);
    setShowTermTemplateActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    onItemClicked(item.id);
    // Set States
    setSelectedTermTemplate(item);
    setShowTermTemplateActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onTermTemplateActionDialogCanceled = () => {
    // Set States
    setShowTermTemplateActionDialog(false);
  };

  const onTermTemplateActionDialogConfirmed = () => {
    switch (termTemplateActionDialogType) {
      // 刪除
      case "DeleteTermTemplate":
        deleteOrRestoreTermTemplate();
        break;
      // 設為一般選項
      case "RemoveTermTemplateDefault":
        editTermTemplateSetIsDefault();
        break;
      // 還原
      case "RestoreTermTemplate":
        deleteOrRestoreTermTemplate();
        break;
      // 設為預設選項
      case "SetTermTemplateDefault":
        editTermTemplateSetIsDefault();
        break;
      default:
        break;
    }

    // Set States
    setShowTermTemplateActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onCopyTermTemplateActionModalClosed = () => {
    // Set States
    setShowCopyTermTemplateActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onTermTemplateChange(null));

    // Update Redux Store
    dispatch(onTypeOfContentChange("TermTemplateDetail"));
  };

  const onChangeTypeOfContentBtnClicked = () => {
    // Update Redux Store
    dispatch(
      onTypeOfContentChange(
        typeOfContent === "TermContent" ? "TermTemplateDetail" : "TermContent"
      )
    );
  };

  // Functions
  // Functions - Normal
  const displayCopyTermTemplateActionModal = () => {
    setShowCopyTermTemplateActionModal(true);
  };

  const displayTermTemplateActionDialog = (termTemplateActionType) => {
    // Set States
    setTermTemplateActionDialogType(termTemplateActionType);

    switch (termTemplateActionType) {
      // 刪除
      case "DeleteTermTemplate":
        setTermTemplateActionDialogText(t("確認要刪除 條款模板 嗎？"));
        break;
      // 設為一般選項
      case "RemoveTermTemplateDefault":
        setTermTemplateActionDialogText(t("確認要設為 一般選項 嗎？"));
        break;
      // 還原
      case "RestoreTermTemplate":
        setTermTemplateActionDialogText(t("確認要還原 條款模板 嗎？"));
        break;
      // 設為預設選項
      case "SetTermTemplateDefault":
        setTermTemplateActionDialogText(t("確認要設為 預設選項 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowTermTemplateActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreTermTemplate = async () => {
    const results = await deleteOrRestoreTermTemplateFetch(
      token,
      currentItemId,
      typeOfTerms
    );

    if (results.success) {
      getAllTermTemplatesByPdfTypeId(true);
    }
  };

  const editTermTemplateSetIsDefault = async () => {
    const results = await editTermTemplateSetIsDefaultFetch(
      token,
      currentItemId,
      !selectedTermTemplate.is_default
    );

    if (results.success) {
      getAllTermTemplatesByPdfTypeId(false);
    }
  };

  // Functions - Queries
  const getAllTermTemplatesByPdfTypeId = async (
    shoulddUpdateCurrentTermTemplateId
  ) => {
    const results = await getAllTermTemplatesByPdfTypeIdFetch(
      token,
      pdfTypeId,
      typeOfTerms
    );

    // Set States
    setTermTemplates(results.termTemplates ? results.termTemplates : null);

    // Update Redux Store
    if (
      results.termTemplates &&
      (shoulddUpdateCurrentTermTemplateId ||
        !results.termTemplates.some((item) => item.id === termTemplateId))
    ) {
      dispatch(
        onTermTemplateChange(
          results.termTemplates ? results.termTemplates[0].id : null
        )
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    if (pdfTypeId) {
      getAllTermTemplatesByPdfTypeId(termTemplateId ? false : true);
    }
  }, [pdfTypeId, formSubmitCount, typeOfTerms]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onTermTemplateActionDialogCanceled}
        onDialogConfirmed={onTermTemplateActionDialogConfirmed}
        // States
        dialogText={termTemplateActionDialogText}
        showDialog={showTermTemplateActionDialog}
      />
      {/* Menu */}
      <TermTemplateActionMenu
        // States
        selectedTermTemplate={selectedTermTemplate}
        showTermTemplateActionMenu={showTermTemplateActionMenu}
        // Set States
        setShowTermTemplateActionMenu={setShowTermTemplateActionMenu}
        // Functions
        displayCopyTermTemplateActionModal={displayCopyTermTemplateActionModal}
        displayTermTemplateActionDialog={displayTermTemplateActionDialog}
      />
      {/* Modals */}
      {selectedTermTemplate && (
        <ModalContainer
          // Events
          onModalClosed={onCopyTermTemplateActionModalClosed}
          // States
          showModal={showCopyTermTemplateActionModal}
        >
          <CopyTermTemplateActionModal
            // Events
            onModalClosed={onCopyTermTemplateActionModalClosed}
            // States
            termTemplateId={selectedTermTemplate.id}
          />
        </ModalContainer>
      )}
      {/* List */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("條款模板s")}
          </Typography>
          <Tooltip placement="right" title={t("新增條款模板")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Button
          endIcon={<ChevronRightRoundedIcon />}
          onClick={onChangeTypeOfContentBtnClicked}
          variant="text"
        >
          {typeOfContent === "TermContent"
            ? t("顯示條款模板資料")
            : t("顯示條款內容")}
        </Button>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <TermTemplateBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有條款模板資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default TermTemplateBoxesContainer;
