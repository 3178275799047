// Configs
import apisConfig from "../configs/apisConfig";

export const deleteOrRestoreCurrencyFetch = async (
  token,
  currencyId,
  typeOfCurrencies
) => {
  try {
    const queryRoute = `/currency/deleteOrRestoreCurrency/${currencyId}`;

    const reqBody = { typeOfCurrencies };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editCurrencyFetch = async (token, currencyId, isActive) => {
  try {
    const queryRoute = `/currency/editCurrency/${currencyId}`;

    const reqBody = {
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllCurrenciesWithPaginationFetch = async (
  token,
  typeOfCurrencies,
  keyword,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/currency/getAllCurrenciesWithPagination";

    const reqBody = {
      typeOfCurrencies,
      keyword,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCurrencyDetailsByIdFetch = async (token, currencyId) => {
  try {
    const queryRoute = `/currency/getCurrencyDetailsById/${currencyId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCurrencyDetailsByOrderIdFetch = async (token, orderId) => {
  try {
    const queryRoute = `/currency/getCurrencyDetailsByOrderId/${orderId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCurrencyDetailsByQuotationIdFetch = async (
  token,
  quotationId
) => {
  try {
    const queryRoute = `/currency/getCurrencyDetailsByQuotationId/${quotationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
