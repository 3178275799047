// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseOrderedDetailFormBasicInfoContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    cost,
    currencyDetails,
    formAlertText,
    formAlertType,
    isDiscounted,
    isPurchasable,
    isOrderEditable,
    language,
    merchandiseDetails,
    merchandiseOrderedId,
    minPurchasableUnit,
    quantity,
    shouldShowFormAlert,
    unitField,
    unitOptions,
    unitPrice,
    unitTypeField,
    unitTypeOptions,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  // Handle States
  const currencySymbol = currencyDetails
    ? currencyDetails.currency_symbol
    : null;
  const fxRateToHKD = currencyDetails ? currencyDetails.fxRateToHKD : null;
  const isCurrencyHKD = currencySymbol && currencySymbol === "HKD";

  return (
    <>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("訂單商品資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        {merchandiseDetails && (
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <Autocomplete
              disabled
              disablePortal
              getOptionLabel={(option) =>
                `${option.merchandise_code} - ${
                  option[t("merchandise_name_ch")]
                }`
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={merchandiseDetails}
              renderInput={(params) => (
                <TextField {...params} label={t("商品")} variant="standard" />
              )}
              value={merchandiseDetails}
            />
          </Grid>
        )}
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {unitTypeOptions && (
            <Autocomplete
              disabled={!isOrderEditable}
              disablePortal
              getOptionLabel={(option) => option[t("unit_type_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("unitTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={unitTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("unitTypeField")}
                  helperText={getErrorFieldMessage("unitTypeField")}
                  label={t("單位類別")}
                  variant="standard"
                />
              )}
              value={unitTypeField}
            />
          )}
        </Grid>
        {unitTypeField && (
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            {unitOptions && (
              <Autocomplete
                disabled={!isOrderEditable}
                disablePortal
                getOptionLabel={(option) => option[t("unit_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("unitField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={unitOptions.filter(
                  (option) => option.unit_type_id === unitTypeField.id
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("unitField")}
                    helperText={getErrorFieldMessage("unitField")}
                    label={t("單位")}
                    variant="standard"
                  />
                )}
                value={unitField}
              />
            )}
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            disabled={!isOrderEditable}
            error={checkIsFieldError("quantity")}
            fullWidth
            helperText={
              checkIsFieldError("quantity")
                ? getErrorFieldMessage("quantity")
                : isPurchasable
                ? minPurchasableUnit && unitField
                  ? language === "zh-hk"
                    ? `最少購買 ${minPurchasableUnit} ${
                        unitField[t("unit_name_ch")]
                      }`
                    : `Purchase ${minPurchasableUnit} ${
                        unitField[t("unit_name_ch")]
                      } at least`
                  : null
                : unitField
                ? language === "zh-hk"
                  ? `不可以 ${unitField.unit_name_ch} 購買`
                  : `Cannot Purchase in ${unitField.unit_name_ch}`
                : null
            }
            label={t("數量")}
            margin="dense"
            name="quantity"
            onChange={(event) =>
              onInputFieldChange("quantity", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={quantity}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={!isOrderEditable}
            error={checkIsFieldError("unitPrice")}
            fullWidth
            helperText={
              getErrorFieldMessage("unitPrice")
                ? getErrorFieldMessage("unitPrice")
                : fxRateToHKD &&
                  unitPrice &&
                  !isNaN(unitPrice) &&
                  !isCurrencyHKD
                ? `HKD ${separateComma(
                    Number(unitPrice * fxRateToHKD).toFixed(1)
                  )}`
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currencySymbol}
                </InputAdornment>
              ),
            }}
            label={t("價錢")}
            margin="dense"
            name="unitPrice"
            onChange={(event) =>
              onInputFieldChange("unitPrice", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={unitPrice}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={!isOrderEditable}
            error={checkIsFieldError("cost")}
            fullWidth
            helperText={
              getErrorFieldMessage("cost")
                ? getErrorFieldMessage("cost")
                : fxRateToHKD && cost && !isNaN(cost) && !isCurrencyHKD
                ? `HKD ${separateComma(Number(cost * fxRateToHKD).toFixed(1))}`
                : null
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currencySymbol}
                </InputAdornment>
              ),
            }}
            label={t("總價")}
            margin="dense"
            name="cost"
            onChange={(event) => onInputFieldChange("cost", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={cost}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有優惠")}
          </Typography>
          <Switch
            checked={isDiscounted}
            disabled={!isOrderEditable}
            onChange={() => onInputFieldChange("isDiscounted", !isDiscounted)}
          />
        </Grid>
        {!isDiscounted && (
          <>
            {isOrderEditable && (
              <Grid item xs={12} className={classes.formSubmitBtnContainer}>
                <Button
                  onClick={onSubmitBtnClicked}
                  variant="contained"
                  sx={formSubmitBtnStyles}
                >
                  {merchandiseOrderedId ? t("修改") : t("新增")}
                </Button>
              </Grid>
            )}
            {/* Alert */}
            {shouldShowFormAlert && (
              <Grid item xs={12}>
                <Alert severity={formAlertType}>{formAlertText}</Alert>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
}

export default MerchandiseOrderedDetailFormBasicInfoContent;
