// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseCustomUnitBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDeleteDynamicFieldBtnClicked,
    onDynamicInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsDynamicFieldError,
    getDynamicErrorFieldMessage,
    // States
    customUnitOptions,
    index,
    item,
    selctedCustomUnitIdsArr,
  } = props;

  // Handle States
  const itemId = item.id;
  const selectableCustomUnitoptions = customUnitOptions.filter(
    (unitItem) =>
      !selctedCustomUnitIdsArr.includes(unitItem.id) ||
      (item.unitField && item.unitField.id === unitItem.id)
  );

  return (
    <>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("自訂單位")} ${index + 1}`}
          </Typography>
        </div>
        {!item.isBaseUnit && (
          <div className={classes.titleTextContainer}>
            {checkIsDynamicFieldError("customUnit", itemId, "unitField") ? (
              <Button
                endIcon={<DeleteRoundedIcon />}
                onClick={() =>
                  onDeleteDynamicFieldBtnClicked("customUnit", itemId)
                }
                sx={{ color: stylesConfig.redTextColor }}
                variant="text"
              >
                {t("如不需填寫計算單位，請按此刪除")}
              </Button>
            ) : (
              <Tooltip placement="right" title={t("刪除計算單位")}>
                <IconButton
                  onClick={() =>
                    onDeleteDynamicFieldBtnClicked("customUnit", itemId)
                  }
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {customUnitOptions && (
              <Autocomplete
                disabled={item.isBaseUnit}
                disablePortal
                getOptionLabel={(option) => option[t("unit_name_ch")]}
                onChange={(event, value) =>
                  onDynamicInputFieldChange(
                    "customUnit",
                    itemId,
                    "unitField",
                    value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={selectableCustomUnitoptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsDynamicFieldError(
                      "customUnit",
                      itemId,
                      "unitField"
                    )}
                    helperText={getDynamicErrorFieldMessage(
                      "customUnit",
                      itemId,
                      "unitField"
                    )}
                    label={t("計算單位")}
                    variant="standard"
                  />
                )}
                value={item.unitField}
              />
            )}
          </Grid>
          {!item.isBaseUnit && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              <TextField
                disabled={!item.isNumOfBaseUnitEditable}
                error={checkIsDynamicFieldError(
                  "customUnit",
                  itemId,
                  "numOfBaseUnit"
                )}
                fullWidth
                helperText={getDynamicErrorFieldMessage(
                  "customUnit",
                  itemId,
                  "numOfBaseUnit"
                )}
                label={t("每單位數量")}
                margin="dense"
                name="numOfBaseUnit"
                onChange={(event) =>
                  onDynamicInputFieldChange(
                    "customUnit",
                    itemId,
                    "numOfBaseUnit",
                    event.target.value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                value={item.numOfBaseUnit}
                variant="standard"
              />
            </Grid>
          )}
          <Grid className={classes.formSwitchContainer} item xs={6}>
            <Typography align={"left"} variant="body1">
              {t("可購買")}
            </Typography>
            <Switch
              checked={item.isPurchasable}
              onChange={() =>
                onDynamicInputFieldChange(
                  "customUnit",
                  itemId,
                  "isPurchasable",
                  !item.isPurchasable
                )
              }
            />
          </Grid>
          {item.isPurchasable && (
            <Grid className={classes.formSwitchContainer} item xs={6}>
              <TextField
                error={checkIsDynamicFieldError(
                  "customUnit",
                  itemId,
                  "minPurchasableUnit"
                )}
                fullWidth
                helperText={getDynamicErrorFieldMessage(
                  "customUnit",
                  itemId,
                  "minPurchasableUnit"
                )}
                label={t("最少購買數量")}
                margin="dense"
                name="minPurchasableUnit"
                onChange={(event) =>
                  onDynamicInputFieldChange(
                    "customUnit",
                    itemId,
                    "minPurchasableUnit",
                    event.target.value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                value={item.minPurchasableUnit}
                variant="standard"
              />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default MerchandiseCustomUnitBox;
