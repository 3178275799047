const initialState = {
  clientCreditSettingId: null,
  clientSettingType: "Client Credit",
  depositSettingId: null,
  formSubmitCount: 0,
  keyword: "",
  paymentTermSettingId: null,
  tabItem: "Clients",
  typeOfClients: "Current",
  typeOfClientSettings: "Current",
};

export const clientsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_CLIENTS_PAGE_CLIENT_CREDIT_SETTING_CHANGE":
      const { clientCreditSettingId } = action;
      return {
        ...state,
        clientCreditSettingId,
      };
    case "ON_CLIENTS_PAGE_CLIENT_SETTING_TYPE_CHANGE":
      const { clientSettingType } = action;
      return {
        ...state,
        clientSettingType,
      };
    case "ON_CLIENTS_PAGE_DEPOSIT_SETTING_CHANGE":
      const { depositSettingId } = action;
      return {
        ...state,
        depositSettingId,
      };
    case "ON_CLIENTS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_CLIENTS_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_CLIENTS_PAGE_PAYMENT_TERM_SETTING_CHANGE":
      const { paymentTermSettingId } = action;
      return {
        ...state,
        paymentTermSettingId,
      };
    case "ON_CLIENTS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_CLIENTS_PAGE_TYPE_OF_CLIENTS_CHANGE":
      const { typeOfClients } = action;
      return {
        ...state,
        typeOfClients,
      };
    case "ON_CLIENTS_PAGE_TYPE_OF_CLIENT_SETTINGS_CHANGE":
      const { typeOfClientSettings } = action;
      return {
        ...state,
        typeOfClientSettings,
      };
    default:
      return state;
  }
};
