export const clientSettingTypes = [
  {
    type: "Client Credit",
    nameCh: "客戶信用設定",
    nameEn: "Client Credit Settings",
  },
  {
    type: "Payment Term",
    nameCh: "付款期限設定",
    nameEn: "Payment Term Settings",
  },
  {
    type: "Deposit",
    nameCh: "訂金設定",
    nameEn: "Deposit Settings",
  },
];
