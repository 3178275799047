// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// React-Redux
import { useSelector } from "react-redux";

// Recharts
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function ClientSalesTrendChart(props) {
  // Props
  const {
    // States
    comparisonRecordDateDisplay,
    isComparisonShown,
    isMoney,
    itemsArr,
    recordDateDisplay,
    xAxisDataGridKey,
  } = props;

  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  return (
    <ResponsiveContainer
      height={stylesConfig.pieChartContainerHeight}
      width={stylesConfig.pieChartContainerWidth}
    >
      <LineChart
        data={itemsArr}
        height={stylesConfig.pieChartHeight}
        margin={stylesConfig.barChartMargin}
        width={stylesConfig.pieChartWidth}
      >
        {isComparisonShown && (
          <Line
            activeDot={{ r: 8 }}
            dataKey="comparisonSalesNumber"
            name={comparisonRecordDateDisplay}
            stroke={stylesConfig.greenTextColor}
            type="monotone"
          />
        )}
        <Line
          activeDot={{ r: 8 }}
          dataKey="salesNumber"
          name={recordDateDisplay}
          stroke={stylesConfig.mainTextColor}
          type="monotone"
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Legend />
        <Tooltip
          contentStyle={{
            backgroundColor:
              themeMode === "light"
                ? stylesConfig.contentBackgroundColorLight
                : stylesConfig.contentBackgroundColorDark,
          }}
          cursor={false}
          formatter={(name) =>
            isMoney
              ? `$ ${separateComma(Number(name).toFixed(1))}`
              : `${separateComma(Number(name).toFixed(1))}`
          }
        />
        <XAxis dataKey={xAxisDataGridKey} />
        <YAxis />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default ClientSalesTrendChart;
