// Actions
import {
  onComparisonRecordDateChange,
  onIsComparisonShownChange,
  onRecordDateChange,
  onTabItemChange,
} from "../../../redux/pages/salesReportsPage/salesReportsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Helper Functions
import {
  checkIsRecordDateVarEqualsToday,
  datePickerFormatText,
  datePickerLabelText,
  datePickerViewText,
  getNewDateVar,
  returnToTodayBtnText,
  showComparisonBtnText,
} from "../../../helperFunctions/salesReportHeaderText";

// Material UI
// Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import DesktopAccessDisabledRoundedIcon from "@mui/icons-material/DesktopAccessDisabledRounded";
import DesktopWindowsRoundedIcon from "@mui/icons-material/DesktopWindowsRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardListPageStyles } from "../../../styles/pageStyles/standardListPageStyles";

// Consts
const today = new Date();

function SalesReportsPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const comparisonRecordDate = useSelector(
    (state) => state.salesReportsPage.comparisonRecordDate
  );
  const isComparisonShown = useSelector(
    (state) => state.salesReportsPage.isComparisonShown
  );
  const recordDate = useSelector((state) => state.salesReportsPage.recordDate);
  const tabItem = useSelector((state) => state.salesReportsPage.tabItem);

  // Handle States
  const comparisonRecordDateVar = new Date(comparisonRecordDate);
  const recordDateVar = new Date(recordDate);
  const isRecordDateVarEqualsToday = checkIsRecordDateVarEqualsToday(
    tabItem,
    "Month",
    today,
    recordDateVar
  );

  // Events
  // Events - Fields
  const onComparisonDateBackAndForwardBtnClicked = (actionType) => {
    const newComparisonDateVar = getNewDateVar(
      tabItem,
      "Month",
      actionType,
      comparisonRecordDateVar
    );

    // Update Redux Store
    dispatch(onComparisonRecordDateChange(newComparisonDateVar.toString()));
  };

  const onComparisonRecordRecordsDateChange = (value) => {
    // Update Redux Store
    dispatch(onComparisonRecordDateChange(value.toString()));
  };

  const onDateBackAndForwardBtnClicked = (actionType) => {
    const newDateVar = getNewDateVar(
      tabItem,
      "Month",
      actionType,
      recordDateVar
    );

    // Update Redux Store
    dispatch(onRecordDateChange(newDateVar.toString()));
  };

  const onRecordRecordsDateChange = (value) => {
    // Update Redux Store
    dispatch(onRecordDateChange(value.toString()));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  // Events - Title
  const onReturnToTodayBtnClicked = () => {
    // Update Redux Store
    dispatch(onRecordDateChange(today.toString()));
  };

  const onShowComparisonBtnClicked = () => {
    // Update Redux Store
    dispatch(onIsComparisonShownChange(!isComparisonShown));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"OverallNumbers"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("整體數據")}
                </Typography>
              }
              value={"OverallNumbers"}
            />
            <Tab
              disableRipple
              key={"SalespersonRankings"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("銷售員排行s")}
                </Typography>
              }
              value={"SalespersonRankings"}
            />
            <Tab
              disableRipple
              key={"ClientRankings"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("客戶排行s")}
                </Typography>
              }
              value={"ClientRankings"}
            />
          </Tabs>
        )}
      </div>
      {/* Select Boxes */}
      <div className={classes.tabsContainer}>
        <div className={classes.formTwinIconBtnContainerLeft}>
          <Button
            onClick={onShowComparisonBtnClicked}
            startIcon={
              isComparisonShown ? (
                <DesktopAccessDisabledRoundedIcon />
              ) : (
                <DesktopWindowsRoundedIcon />
              )
            }
            variant="text"
          >
            {t(showComparisonBtnText(tabItem, "Month", isComparisonShown))}
          </Button>
        </div>
        {isComparisonShown && (
          <>
            <DatePicker
              format={datePickerFormatText(tabItem, "Month")}
              label={t(datePickerLabelText(tabItem, "Month", true))}
              onChange={onComparisonRecordRecordsDateChange}
              slotProps={{ textField: { variant: "standard" } }}
              value={comparisonRecordDateVar}
              views={datePickerViewText(tabItem, "Month")}
            />
            <div className={classes.formTwinIconBtnContainerRight}>
              <IconButton
                onClick={() => onComparisonDateBackAndForwardBtnClicked("back")}
              >
                <ArrowBackIosRoundedIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  onComparisonDateBackAndForwardBtnClicked("forward")
                }
              >
                <ArrowForwardIosRoundedIcon />
              </IconButton>
            </div>
          </>
        )}
        {!isRecordDateVarEqualsToday && (
          <div className={classes.formTwinIconBtnContainerLeft}>
            <Button
              onClick={onReturnToTodayBtnClicked}
              startIcon={<RestoreRoundedIcon />}
              variant="text"
            >
              {t(returnToTodayBtnText(tabItem, "Month"))}
            </Button>
          </div>
        )}
        <DatePicker
          format={datePickerFormatText(tabItem, "Month")}
          label={t(datePickerLabelText(tabItem, "Month"))}
          onChange={onRecordRecordsDateChange}
          slotProps={{ textField: { variant: "standard" } }}
          value={recordDateVar}
          views={datePickerViewText(tabItem, "Month")}
        />
        <div className={classes.formTwinIconBtnContainerRight}>
          <IconButton onClick={() => onDateBackAndForwardBtnClicked("back")}>
            <ArrowBackIosRoundedIcon />
          </IconButton>
          <IconButton onClick={() => onDateBackAndForwardBtnClicked("forward")}>
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </div>
      </div>
    </Box>
  );
}

export default SalesReportsPageHeader;
