export function onClientChange(clientId) {
  return {
    type: "ON_QUOTATION_PROFILE_PAGE_CLIENT_CHANGE",
    clientId,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_QUOTATION_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onIsQuotationEditableChange(isQuotationEditable) {
  return {
    type: "ON_QUOTATION_PROFILE_PAGE_IS_QUOTATION_EDITABLE_CHANGE",
    isQuotationEditable,
  };
}

export function onMerchandiseQuotedChange(merchandiseQuotedId) {
  return {
    type: "ON_QUOTATION_PROFILE_PAGE_MERCHANDISE_QUOTED_CHANGE",
    merchandiseQuotedId,
  };
}

export function onQuotationChange(quotationId) {
  return {
    type: "ON_QUOTATION_PROFILE_PAGE_QUOTATION_CHANGE",
    quotationId,
  };
}

export function onSelectedMerchandiseChange(selectedMerchandiseId) {
  return {
    type: "ON_QUOTATION_PROFILE_PAGE_SELECTED_MERCHANDISE_CHANGE",
    selectedMerchandiseId,
  };
}

export function onSelectedUnitChange(selectedUnitId) {
  return {
    type: "ON_QUOTATION_PROFILE_PAGE_SELECTED_UNIT_CHANGE",
    selectedUnitId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_QUOTATION_PROFILE_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfPricesChange(typeOfPrices) {
  return {
    type: "ON_QUOTATION_PROFILE_PAGE_TYPE_OF_PRICES_CHANGE",
    typeOfPrices,
  };
}
