// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function ClientIntroBasicInfoBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // States
  // Hover
  const [isHovered, setIsHovered] = useState(null);

  // Props
  const {
    // States
    item,
    // Events
    onItemClicked,
  } = props;

  // Events
  // Events - Hover
  const onMouseEnterItem = () => {
    // Set States
    setIsHovered(true);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setIsHovered(false);
  };

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onClick={onItemClicked}
      onMouseEnter={onMouseEnterItem}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainer}>
          <Typography align={"left"} variant="body1">
            {item.client_code}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.client_name_en ? item.client_name_en : item.client_name_ch}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainerRight}>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {t("公司")}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default ClientIntroBasicInfoBox;
