// Actions
import {
  onFormSubmitCountIncrease,
  onMerchandiseChange,
} from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Boxes
import MerchandisePhotoBoxesContainer from "../../boxes/merchandisesPage/merchandisePhotoBoxesContainer";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getMerchandiseDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createMerchandiseFetch,
  editMerchandiseFetch,
  getMerchandiseDetailsByIdFetch,
} from "../../../fetches/merchandiseFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// Icons
import AddAPhotoRoundedIcon from "@mui/icons-material/AddAPhotoRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const merchandiseId = useSelector(
    (state) => state.merchandisesPage.merchandiseId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    merchandiseDetailActionDialogText,
    setMerchandiseDetailActionDialogText,
  ] = useState("");
  const [
    merchandiseDetailActionDialogType,
    setMerchandiseDetailActionDialogType,
  ] = useState(null);
  const [
    showMerchandiseDetailActionDialog,
    setShowMerchandiseDetailActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // File Fields
  const [photo, setPhoto] = useState(null);
  // Option Fields
  const [brandField, setBrandField] = useState(null);
  const [categoryField, setCategoryField] = useState(null);
  const [subCategoryField, setSubCategoryField] = useState(null);
  // Options
  const [brandOptions, setBrandOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Events
  // Events - Dialogs
  const onMerchandiseDetailActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseDetailActionDialog(false);
  };

  const onMerchandiseDetailActionDialogConfirmed = () => {
    switch (merchandiseDetailActionDialogType) {
      case "CreateMerchandise":
        createMerchandise();
        break;
      case "EditMerchandise":
        editMerchandise();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "brandField":
        setBrandField(value);
        break;
      case "categoryField":
        setCategoryField(value);
        setSubCategoryField(
          value
            ? subCategoryOptions.find((item) => item.category_id === value.id)
            : null
        );

        removeErrorField("subCategoryField");

        break;
      case "code":
        setCode(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "photo":
        setPhoto(value);
        break;
      case "subCategoryField":
        setSubCategoryField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!brandField) {
      addToErrorFields("brandField", t("請先填寫 品牌"));
      isError = true;
    }

    if (!categoryField) {
      addToErrorFields("categoryField", t("請先填寫 類別"));
      isError = true;
    }

    if (!subCategoryField) {
      addToErrorFields("subCategoryField", t("請先填寫 子類別"));
      isError = true;
    }

    if (!code) {
      addToErrorFields("code", t("請先填寫 代號"));
      isError = true;
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayMerchandiseDetailActionDialog(
      merchandiseId ? "EditMerchandise" : "CreateMerchandise"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setCategoryField(null);
    setSubCategoryField(null);
    setBrandField(null);
    setCode("");
    setNameCh("");
    setNameEn("");
    setPhoto(null);
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayMerchandiseDetailActionDialog = (
    merchandiseDetailActionType
  ) => {
    // Set States
    setMerchandiseDetailActionDialogType(merchandiseDetailActionType);

    switch (merchandiseDetailActionType) {
      case "CreateMerchandise":
        setMerchandiseDetailActionDialogText(t("確認要新增 商品 嗎？"));
        break;
      case "EditMerchandise":
        setMerchandiseDetailActionDialogText(t("確認要編輯 商品資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createMerchandise = async () => {
    const results = await createMerchandiseFetch(
      token,
      subCategoryField ? subCategoryField.id : null,
      brandField ? brandField.id : null,
      code,
      nameCh,
      nameEn,
      photo ? photo : null,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onMerchandiseChange(results.merchandiseId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 商品 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editMerchandise = async () => {
    if (merchandiseId) {
      const results = await editMerchandiseFetch(
        token,
        merchandiseId,
        subCategoryField ? subCategoryField.id : null,
        brandField ? brandField.id : null,
        code,
        nameCh,
        nameEn,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 商品 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getMerchandiseDetailFormOptions = async () => {
    const results = await getMerchandiseDetailFormOptionsFetch(token);

    // Set States
    setBrandOptions(results.brands ? results.brands : []);
    setCategoryOptions(results.categories ? results.categories : []);
    setSubCategoryOptions(results.subCategories ? results.subCategories : []);
  };

  const getMerchandiseDetailsById = async () => {
    const results = await getMerchandiseDetailsByIdFetch(token, merchandiseId);

    if (results.merchandiseDetails) {
      const {
        merchandise_code,
        merchandise_name_ch,
        merchandise_name_en,
        is_active,
        sub_category_id,
        category_id,
        brand_id,
      } = results.merchandiseDetails;

      // Set States
      setCategoryField(
        category_id
          ? categoryOptions.find((item) => item.id === category_id)
          : null
      );
      setSubCategoryField(
        sub_category_id
          ? subCategoryOptions.find((item) => item.id === sub_category_id)
          : null
      );
      setBrandField(
        brand_id ? brandOptions.find((item) => item.id === brand_id) : null
      );
      setCode(merchandise_code ? merchandise_code : "");
      setNameCh(merchandise_name_ch ? merchandise_name_ch : null);
      setNameEn(merchandise_name_en ? merchandise_name_en : null);
      setIsItemActive(is_active);
    } else {
      // Set States
      setCategoryField(null);
      setSubCategoryField(null);
      setBrandField(null);
      setCode("");
      setNameCh("");
      setNameEn("");
      setPhoto(null);
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getMerchandiseDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && brandOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [brandOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (merchandiseId) {
        getMerchandiseDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, merchandiseId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseDetailActionDialogCanceled}
        onDialogConfirmed={onMerchandiseDetailActionDialogConfirmed}
        // States
        dialogText={merchandiseDetailActionDialogText}
        showDialog={showMerchandiseDetailActionDialog}
      />
      {/* Content */}
      <MerchandisePhotoBoxesContainer />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("商品資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("code")}
            fullWidth
            helperText={getErrorFieldMessage("code")}
            label={t("代號")}
            margin="dense"
            name="code"
            onChange={(event) => onInputFieldChange("code", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={code}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {categoryOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("category_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("categoryField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={categoryOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("categoryField")}
                  helperText={getErrorFieldMessage("categoryField")}
                  label={t("類別")}
                  variant="standard"
                />
              )}
              value={categoryField}
            />
          )}
        </Grid>
        {categoryField && (
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            {subCategoryOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("sub_category_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("subCategoryField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={subCategoryOptions.filter(
                  (option) => option.category_id === categoryField.id
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("subCategoryField")}
                    helperText={getErrorFieldMessage("subCategoryField")}
                    label={t("子類別")}
                    variant="standard"
                  />
                )}
                value={subCategoryField}
              />
            )}
          </Grid>
        )}
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {brandOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.brand_name}
              onChange={(event, value) =>
                onInputFieldChange("brandField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={brandOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("brandField")}
                  helperText={getErrorFieldMessage("brandField")}
                  label={t("品牌")}
                  variant="standard"
                />
              )}
              value={brandField}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        {!merchandiseId && (
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              component="label"
              endIcon={<AddAPhotoRoundedIcon />}
              variant="outlined"
              sx={formSubmitBtnStyles}
            >
              {photo && t("重新")}
              {t("上載商品照片")}
              <input
                accept={"image/*"}
                hidden
                onChange={(event) =>
                  onInputFieldChange("photo", event.target.files[0])
                }
                type="file"
              />
            </Button>
          </Grid>
        )}
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {merchandiseId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default MerchandiseDetailForm;
