// Actions
import {
  onClientChange,
  onFormSubmitCountIncrease,
  onIsOrderEditableChange,
} from "../../../redux/pages/orderProfilePage/orderProfilePageActions";
import {
  onQuotationChange,
  onTabItemChange,
} from "../../../redux/pages/quotationProfilePage/quotationProfilePageActions";

// Components
// Boxes
import ClientIntroBasicInfoBox from "./clientIntroBasicInfoBox";
import ClientIntroClientLocationInfoBox from "./clientIntroClientLocationInfoBox";
import ClientIntroContactPersonInfoBox from "./clientIntroContactPersonInfoBox";
import OrderIntroBasicInfoBox from "./orderIntroBasicInfoBox";
import OrderIntroPriceInfoBox from "./orderIntroPriceInfoBox";
import SalespersonIntroBasicInfoBox from "./salespersonIntroBasicInfoBox";
// Modals
import CreatePdfActionModal from "../../modals/orderProfilePage/createPdfActionModal";
import FileViewModal from "../../modals/orderProfilePage/fileViewModal";
import ModalContainer from "../../modals/modalContainer";
import OrderActionModal from "../../modals/orderProfilePage/orderActionModal";
import PdfViewModal from "../../modals/orderProfilePage/pdfViewModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getOrderDetailsByIdFetch } from "../../../fetches/orderFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { roundNumber } from "../../../helperFunctions/roundNumber";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function OrderIntroBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const clientId = useSelector((state) => state.orderProfilePage.clientId);
  const formSubmitCount = useSelector(
    (state) => state.orderProfilePage.formSubmitCount
  );
  const isOrderEditable = useSelector(
    (state) => state.orderProfilePage.isOrderEditable
  );
  const language = useSelector((state) => state.language.language);
  const orderId = useSelector((state) => state.orderProfilePage.orderId);
  const token = useSelector((state) => state.staff.token);
  const typeOfPrices = useSelector(
    (state) => state.orderProfilePage.typeOfPrices
  );

  // States
  // Data
  const [orderDetails, setOrderDetails] = useState(null);
  const [quotationId, setQuotationId] = useState(null);
  const [pdfTypeCode, setPdfTypeCode] = useState(null);
  // Modals
  const [showCreatePdfActionModal, setShowCreatePdfActionModal] =
    useState(false);
  const [showFileViewModal, setShowFileViewModal] = useState(false);
  const [showOrderActionModal, setShowOrderActionModal] = useState(false);
  const [showPdfViewModal, setShowPdfViewModal] = useState(false);

  // Handle States
  const item = orderDetails;
  const isPoPdfs = item && item.numOfPoPdfs && parseInt(item.numOfPoPdfs) > 0;
  const isPiPdfs = item && item.numOfPiPdfs && parseInt(item.numOfPiPdfs) > 0;
  const isInPdfs = item && item.numOfInPdfs && parseInt(item.numOfInPdfs) > 0;
  const numOfFiles = item && item.numOfFiles ? parseInt(item.numOfFiles) : 0;

  // Events
  // Events - Box
  const onOrderItemClicked = () => {
    displayOrderActionModal();
  };

  // Events - Modal
  const onCreatePdfActionModalClosed = () => {
    // Set States
    setShowCreatePdfActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onFileViewModalClosed = () => {
    // Set States
    setShowFileViewModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onOrderActionModalClosed = () => {
    // Set States
    setShowOrderActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onPdfViewModalClosed = () => {
    // Set States
    setShowPdfViewModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onViewDocumentariesBtnClicked = () => {
    displayFileViewModal();
  };

  const onViewQuotationBtnClicked = () => {
    if (quotationId) {
      // Update Redux Store
      dispatch(onQuotationChange(quotationId));
      dispatch(onTabItemChange("BasicInfo"));

      // Navigate
      navigate("/quotationProfile");
    }
  };

  const onViewPdfsBtnClicked = (pdfTypeCodeStr) => {
    displayPdfViewModal(pdfTypeCodeStr);
  };

  // Functions
  // Functions - Normal
  const displayCreatePdfActionModal = () => {
    setShowCreatePdfActionModal(true);
  };

  const displayFileViewModal = () => {
    setShowFileViewModal(true);
  };

  const displayOrderActionModal = () => {
    setShowOrderActionModal(true);
  };

  const displayPdfViewModal = (pdfTypeCodeStr) => {
    setShowPdfViewModal(true);
    setPdfTypeCode(pdfTypeCodeStr);
  };

  // Functions - Queries
  const getOrderDetailsById = async () => {
    const results = await getOrderDetailsByIdFetch(
      token,
      orderId,
      typeOfPrices
    );

    if (results.orderDetails) {
      results.orderDetails.orderDateDisplay = results.orderDetails.orderDate
        ? moment(results.orderDetails.orderDate).format(t("M 月 D 日"))
        : null;

      results.orderDetails.timeCreatedDisplay = results.orderDetails
        .time_created
        ? moment(results.orderDetails.time_created).format(t("a h:mm"))
        : null;

      results.orderDetails.subTotalDisplay = results.orderDetails.sub_total
        ? `${
            typeOfPrices === "InTransactionCurrency"
              ? results.orderDetails.currency_symbol
              : "HKD"
          } ${separateComma(Number(results.orderDetails.sub_total).toFixed(1))}`
        : null;

      results.orderDetails.fxRateToHKDDisplay = results.orderDetails.fxRateToHKD
        ? separateComma(
            roundNumber(Number(results.orderDetails.fxRateToHKD), 6)
          )
        : null;
    }

    // Set States
    setOrderDetails(results.orderDetails ? results.orderDetails : null);
    setQuotationId(results.quotationId ? results.quotationId : null);

    // Update Redux Store
    dispatch(
      onClientChange(
        results.orderDetails.client_id ? results.orderDetails.client_id : null
      )
    );
    dispatch(
      onIsOrderEditableChange(results.isEditable ? results.isEditable : false)
    );
  };

  // Life Cycle
  useEffect(() => {
    if (orderId) {
      getOrderDetailsById();
    }
  }, [orderId, typeOfPrices, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Modals */}
      {orderId && (
        <ModalContainer
          // Events
          onModalClosed={onCreatePdfActionModalClosed}
          // States
          showModal={showCreatePdfActionModal}
        >
          <CreatePdfActionModal
            // Events
            onModalClosed={onCreatePdfActionModalClosed}
            // States
            pdfTypeCode={pdfTypeCode}
            orderId={orderId}
          />
        </ModalContainer>
      )}
      {orderId && (
        <ModalContainer
          // Events
          onModalClosed={onFileViewModalClosed}
          // States
          showModal={showFileViewModal}
        >
          <FileViewModal
            // States
            orderId={orderId}
          />
        </ModalContainer>
      )}
      {clientId && (
        <ModalContainer
          // Events
          onModalClosed={onOrderActionModalClosed}
          // States
          showModal={showOrderActionModal}
        >
          <OrderActionModal
            // Events
            onModalClosed={onOrderActionModalClosed}
            // States
            clientId={clientId}
            isOrderEditable={isOrderEditable}
            orderId={orderId}
          />
        </ModalContainer>
      )}
      {orderId && (
        <ModalContainer
          // Events
          onModalClosed={onPdfViewModalClosed}
          // States
          showModal={showPdfViewModal}
        >
          <PdfViewModal
            // Functions
            displayCreatePdfActionModal={displayCreatePdfActionModal}
            // States
            orderId={orderId}
            pdfTypeCode={pdfTypeCode}
          />
        </ModalContainer>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("訂單")}
          </Typography>
          <Tooltip placement="left" title={t("查看相關文件s")}>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={numOfFiles}
              color="primary"
            >
              <IconButton
                color={"primary"}
                onClick={onViewDocumentariesBtnClicked}
              >
                <AttachFileRoundedIcon />
              </IconButton>
            </Badge>
          </Tooltip>
          {quotationId && (
            <Tooltip placement="right" title={t("查看報價資料")}>
              <IconButton color={"primary"} onClick={onViewQuotationBtnClicked}>
                <AssignmentRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div>
          <Tooltip placement="top" title={t("查看採購訂單 (Pdf)s")}>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="success"
              invisible={!isPoPdfs}
              variant="dot"
            >
              <Button onClick={() => onViewPdfsBtnClicked("PO")} variant="text">
                PO
              </Button>
            </Badge>
          </Tooltip>
          <Tooltip placement="top" title={t("查看形式發票 (Pdf)s")}>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="success"
              invisible={!isPiPdfs}
              variant="dot"
            >
              <Button onClick={() => onViewPdfsBtnClicked("PI")} variant="text">
                PI
              </Button>
            </Badge>
          </Tooltip>
          <Tooltip placement="top" title={t("查看發票 (Pdf)s")}>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="success"
              invisible={!isInPdfs}
              variant="dot"
            >
              <Button onClick={() => onViewPdfsBtnClicked("IN")} variant="text">
                IN
              </Button>
            </Badge>
          </Tooltip>
        </div>
      </div>
      {/* Content */}
      {item ? (
        <>
          <OrderIntroBasicInfoBox
            // States
            item={item}
            // Events
            onItemClicked={onOrderItemClicked}
          />
          <OrderIntroPriceInfoBox
            // States
            item={item}
            // Events
            onItemClicked={onOrderItemClicked}
          />
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有訂單資料")}
          </Typography>
        </div>
      )}
      {item && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("客戶")}
              </Typography>
            </div>
          </div>
          {/* Content */}
          <ClientIntroBasicInfoBox
            // States
            item={item}
            // Events
            onItemClicked={onOrderItemClicked}
          />
          {item.contact_person_id && (
            <ClientIntroContactPersonInfoBox
              // States
              item={item}
              language={language}
              // Events
              onItemClicked={onOrderItemClicked}
            />
          )}
          {item.client_location_id && (
            <ClientIntroClientLocationInfoBox
              // States
              item={item}
              // Events
              onItemClicked={onOrderItemClicked}
            />
          )}
        </>
      )}
      {item && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("銷售員")}
              </Typography>
            </div>
          </div>
          {/* Content */}
          {item.salespersonBasicInfo ? (
            <SalespersonIntroBasicInfoBox
              // States
              item={item}
              // Events
              onItemClicked={onOrderItemClicked}
            />
          ) : (
            <div className={classes.emptyDataContainer}>
              <Typography align={"center"} color={stylesConfig.greyTextColor}>
                {t("未有銷售員資料")}
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OrderIntroBoxesContainer;
