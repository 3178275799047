// Pages
import CategoriesAndBrandsPage from "./pages/categoriesAndBrandsPage";
import ClientsPage from "./pages/clientsPage";
import ClientProfilePage from "./pages/clientProfilePage";
import CreateClientPage from "./pages/createClientPage";
// import JobsPage from "./pages/jobsPage";
import LoginPage from "./pages/loginPage";
import MerchandisesPage from "./pages/merchandisesPage";
import MerchandiseSalesPage from "./pages/merchandiseSalesPage";
import OrdersPage from "./pages/ordersPage";
import OrderProfilePage from "./pages/orderProfilePage";
import QuotationsPage from "./pages/quotationsPage";
import QuotationProfilePage from "./pages/quotationProfilePage";
import SalesReportsPage from "./pages/salesReportsPage";
import TermsAndCurrenciesPage from "./pages/termsAndCurrenciesPage";

// React-Redux
import { useSelector } from "react-redux";

// React-Router
import { Navigate, Route, Routes } from "react-router-dom";

function Router() {
  // Redux Store
  const token = useSelector((state) => state.staff.token);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/categoriesAndBrands"
        element={token ? <CategoriesAndBrandsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/clients"
        element={token ? <ClientsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/clientProfile"
        element={token ? <ClientProfilePage /> : <Navigate to="/" />}
      />
      <Route
        path="/createClient"
        element={token ? <CreateClientPage /> : <Navigate to="/" />}
      />
      {/* <Route
        path="/jobs"
        element={token ? <JobsPage /> : <Navigate to="/" />}
      /> */}
      <Route
        path="/merchandises"
        element={token ? <MerchandisesPage /> : <Navigate to="/" />}
      />
      <Route
        path="/merchandiseSales"
        element={token ? <MerchandiseSalesPage /> : <Navigate to="/" />}
      />
      <Route
        path="/orders"
        element={token ? <OrdersPage /> : <Navigate to="/" />}
      />
      <Route
        path="/orderProfile"
        element={token ? <OrderProfilePage /> : <Navigate to="/" />}
      />
      <Route
        path="/quotations"
        element={token ? <QuotationsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/quotationProfile"
        element={token ? <QuotationProfilePage /> : <Navigate to="/" />}
      />
      <Route
        path="/salesReports"
        element={token ? <SalesReportsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/termsAndCurrencies"
        element={token ? <TermsAndCurrenciesPage /> : <Navigate to="/" />}
      />
    </Routes>
  );
}

export default Router;
