// Redux
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducers
// Pages
import { categoriesAndBrandsPageReducers } from "./pages/categoriesAndBrandsPage/categoriesAndBrandsPageReducers";
import { clientsPageReducers } from "./pages/clientsPage/clientsPageReducers";
import { clientProfilePageReducers } from "./pages/clientProfilePage/clientProfilePageReducers";
import { createClientPageReducers } from "./pages/createClientPage/createClientPageReducers";
import { jobsPageReducers } from "./pages/jobsPage/jobsPageReducers";
import { merchandisesPageReducers } from "./pages/merchandisesPage/merchandisesPageReducers";
import { merchandiseSalesPageReducers } from "./pages/merchandiseSalesPage/merchandiseSalesPageReducers";
import { ordersPageReducers } from "./pages/ordersPage/ordersPageReducers";
import { orderProfilePageReducers } from "./pages/orderProfilePage/orderProfilePageReducers";
import { quotationsPageReducers } from "./pages/quotationsPage/quotationsPageReducers";
import { quotationProfilePageReducers } from "./pages/quotationProfilePage/quotationProfilePageReducers";
import { salesReportsPageReducers } from "./pages/salesReportsPage/salesReportsPageReducers";
import { termsAndCurrenciesPageReducers } from "./pages/termsAndCurrenciesPage/termsAndCurrenciesPageReducers";
// Shared
import { drawerReducers } from "./shared/drawer/drawerReducers";
import { languageReducers } from "./shared/language/languageReducers";
import { locationReducers } from "./shared/location/locationReducers";
import { staffReducers } from "./shared/staff/staffReducers";
import { themeReducers } from "./shared/theme/themeReducers";

const rootReducer = combineReducers({
  // Pages
  categoriesAndBrandsPage: categoriesAndBrandsPageReducers,
  clientsPage: clientsPageReducers,
  clientProfilePage: clientProfilePageReducers,
  createClientPage: createClientPageReducers,
  jobsPage: jobsPageReducers,
  merchandisesPage: merchandisesPageReducers,
  merchandiseSalesPage: merchandiseSalesPageReducers,
  ordersPage: ordersPageReducers,
  orderProfilePage: orderProfilePageReducers,
  quotationsPage: quotationsPageReducers,
  quotationProfilePage: quotationProfilePageReducers,
  salesReportsPage: salesReportsPageReducers,
  termsAndCurrenciesPage: termsAndCurrenciesPageReducers,
  // Shared
  drawer: drawerReducers,
  language: languageReducers,
  location: locationReducers,
  staff: staffReducers,
  theme: themeReducers,
});

export const store = configureStore({ reducer: rootReducer });
