// Actions
import {
  onFormSubmitCountIncrease,
  onProgressChange,
} from "../../../redux/pages/orderProfilePage/orderProfilePageActions";

// Components
// Boxes
import ProgressBox from "./progressBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import ProgressActionMenu from "../../menus/orderProfilePage/progressActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  cancelProgressCompletionFetch,
  completeProgressNowFetch,
  getAllProgressesByOrderIdFetch,
  startProgressNowFetch,
} from "../../../fetches/progressFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ProgressBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.orderProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const orderId = useSelector((state) => state.orderProfilePage.orderId);
  const progressId = useSelector((state) => state.orderProfilePage.progressId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [progresses, setProgresses] = useState(null);
  // Dialog
  const [progressActionDialogText, setProgressActionDialogText] = useState("");
  const [progressActionDialogType, setProgressActionDialogType] =
    useState(null);
  const [showProgressActionDialog, setShowProgressActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showProgressActionMenu, setShowProgressActionMenu] = useState(null);

  // Handle States
  const currentItemId = progressId;
  const itemsArr = progresses;

  const currentItem =
    itemsArr && currentItemId
      ? itemsArr.find((item) => item.id === currentItemId)
      : null;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onProgressChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowProgressActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowProgressActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onProgressActionDialogCanceled = () => {
    // Set States
    setShowProgressActionDialog(false);
  };

  const onProgressActionDialogConfirmed = () => {
    switch (progressActionDialogType) {
      case "CancelProgressCompletion":
        cancelProgressCompletion();
        break;
      case "CompleteProgressNow":
        completeProgressNow();
        break;
      case "StartProgressNow":
        startProgressNow();
        break;
      default:
        break;
    }

    // Set States
    setShowProgressActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Normal
  const displayProgressActionDialog = (progressActionType) => {
    // Set States
    setProgressActionDialogType(progressActionType);

    switch (progressActionType) {
      case "CancelProgressCompletion":
        setProgressActionDialogText(t("確認要取消完成 進度 嗎？"));
        break;
      case "CompleteProgressNow":
        setProgressActionDialogText(t("確認現在完成 進度 嗎？"));
        break;
      case "StartProgressNow":
        setProgressActionDialogText(t("確認現在開始 進度 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowProgressActionDialog(true);
  };

  // Functions - Mutations
  const cancelProgressCompletion = async () => {
    const results = await cancelProgressCompletionFetch(token, progressId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  const completeProgressNow = async () => {
    const results = await completeProgressNowFetch(token, progressId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  const startProgressNow = async () => {
    const results = await startProgressNowFetch(token, progressId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  // Functions - Queries
  const getAllProgressesByOrderId = async (shoulddUpdateCurrentProgressId) => {
    const results = await getAllProgressesByOrderIdFetch(token, orderId);

    for (let item of results.progresses) {
      item.startDateDisplay = item.startDate
        ? moment(item.startDate).format(t("M 月 D 日"))
        : null;

      item.endDateDisplay = item.endDate
        ? moment(item.endDate).format(t("M 月 D 日"))
        : null;

      item.startTimeDisplay = item.start_time
        ? moment(item.start_time).format(t("a h:mm"))
        : null;

      item.endTimeDisplay = item.end_time
        ? moment(item.end_time).format(t("a h:mm"))
        : null;
    }

    // Set States
    setProgresses(results.progresses ? results.progresses : null);

    // Update Redux Store
    if (
      results.progresses &&
      (shoulddUpdateCurrentProgressId ||
        !results.progresses.some((item) => item.id === progressId))
    ) {
      dispatch(
        onProgressChange(results.progresses ? results.progresses[0].id : null)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    if (orderId) {
      getAllProgressesByOrderId(progressId ? false : true);
    }
  }, [orderId, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onProgressActionDialogCanceled}
        onDialogConfirmed={onProgressActionDialogConfirmed}
        // States
        dialogText={progressActionDialogText}
        showDialog={showProgressActionDialog}
      />
      {/* Menu */}
      <ProgressActionMenu
        // States
        currentItem={currentItem}
        showProgressActionMenu={showProgressActionMenu}
        // Set States
        setShowProgressActionMenu={setShowProgressActionMenu}
        // Fucntions
        displayProgressActionDialog={displayProgressActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("進度s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <ProgressBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有進度資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ProgressBoxesContainer;
