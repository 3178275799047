export function onConditionBrandChange(conditionBrandId) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_BRAND_CHANGE",
    conditionBrandId,
  };
}

export function onConditionBrandItemChange(conditionBrandItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_BRAND_ITEM_CHANGE",
    conditionBrandItem,
  };
}

export function onConditionCategoryChange(conditionCategoryId) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_CATEGORY_CHANGE",
    conditionCategoryId,
  };
}

export function onConditionCategoryItemChange(conditionCategoryItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_CATEGORY_ITEM_CHANGE",
    conditionCategoryItem,
  };
}

export function onConditionSubCategoryChange(conditionSubCategoryId) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_SUB_CATEGORY_CHANGE",
    conditionSubCategoryId,
  };
}

export function onConditionSubCategoryItemChange(conditionSubCategoryItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_SUB_CATEGORY_ITEM_CHANGE",
    conditionSubCategoryItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_MERCHANDISES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_MERCHANDISES_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onMerchandiseChange(merchandiseId) {
  return {
    type: "ON_MERCHANDISES_PAGE_MERCHANDISE_CHANGE",
    merchandiseId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfContentChange(typeOfContent) {
  return {
    type: "ON_MERCHANDISES_PAGE_TYPE_OF_CONTENT_CHANGE",
    typeOfContent,
  };
}

export function onTypeOfMerchandisesChange(typeOfMerchandises) {
  return {
    type: "ON_MERCHANDISES_PAGE_TYPE_OF_MERCHANDISES_CHANGE",
    typeOfMerchandises,
  };
}

export function onTypeOfUnitsChange(typeOfUnits) {
  return {
    type: "ON_MERCHANDISES_PAGE_TYPE_OF_UNITS_CHANGE",
    typeOfUnits,
  };
}

export function onUnitChange(unitId) {
  return {
    type: "ON_MERCHANDISES_PAGE_UNIT_CHANGE",
    unitId,
  };
}

export function onUnitTypeChange(unitTypeId) {
  return {
    type: "ON_MERCHANDISES_PAGE_UNIT_TYPE_CHANGE",
    unitTypeId,
  };
}

export function onUnitTypeItemChange(unitTypeItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_UNIT_TYPE_ITEM_CHANGE",
    unitTypeItem,
  };
}
