// Actions
import { onActiveStepReset } from "../redux/pages/createClientPage/createClientPageActions";
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Forms
import CreateClientFormContainer from "../components/forms/createClientPage/createClientFormContainer";
// Headers
import CreateClientPageHeader from "../components/headers/createClientPage/createClientPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch } from "react-redux";

function CreateClientPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(onActiveStepReset());
    dispatch(enterPage("CreateClient"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <CreateClientPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <CreateClientFormContainer />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Container>
  );
}

export default CreateClientPage;
