// Actions
import { onOrderChange } from "../../../redux/pages/orderProfilePage/orderProfilePageActions";

// Components
// Boxes
import OrderBox from "./orderBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import OrderActionMenu from "../../menus/ordersPage/orderActionMenu";
// Modals
import CopyOrderActionModal from "../../modals/ordersPage/copyOrderActionModal";
import ModalContainer from "../../modals/modalContainer";
import OrderActionModal from "../../modals/orderProfilePage/orderActionModal";
import SearchClientModal from "../../modals/search/searchClientModal";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import {
  cancelOrderFetch,
  deleteOrderFetch,
  getAllOrdersWithPaginationFetch,
} from "../../../fetches/orderFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function OrderBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const keyword = useSelector((state) => state.ordersPage.keyword);
  const language = useSelector((state) => state.language.language);
  const orderStatusTypeId = useSelector(
    (state) => state.ordersPage.conditionOrderStatusTypeId
  );
  const recordMonth = useSelector((state) => state.ordersPage.recordMonth);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [orders, setOrders] = useState(null);
  // Dialog
  const [orderActionDialogText, setOrderActionDialogText] = useState("");
  const [orderActionDialogType, setOrderActionDialogType] = useState(null);
  const [showOrderActionDialog, setShowOrderActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showOrderActionMenu, setShowOrderActionMenu] = useState(null);
  // Modals
  const [showCopyOrderActionModal, setShowCopyOrderActionModal] =
    useState(false);
  const [showOrderActionModal, setShowOrderActionModal] = useState(false);
  const [showSearchClientModal, setShowSearchClientModal] = useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  // Handle States
  const currentItem = selectedOrder;
  const itemsArr = orders;
  const recordMonthVar = new Date(recordMonth);

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onOrderChange(itemId));

    // Navigate
    navigate("/orderProfile");
  };

  const onItemRightClicked = (item, currentTarget) => {
    // Set States
    setSelectedOrder(item);
    setShowOrderActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (item, currentTarget) => {
    // Set States
    setSelectedOrder(item);
    setShowOrderActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onOrderActionDialogCanceled = () => {
    // Set States
    setShowOrderActionDialog(false);
  };

  const onOrderActionDialogConfirmed = () => {
    switch (orderActionDialogType) {
      case "CancelOrder":
        cancelOrder();
        break;
      case "DeleteOrder":
        deleteOrder();
        break;
      default:
        break;
    }

    // Set States
    setShowOrderActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onCopyOrderActionModalClosed = () => {
    // Set States
    setShowCopyOrderActionModal(false);
  };

  const onOrderActionModalClosed = () => {
    // Set States
    setShowOrderActionModal(false);
  };

  const onSearchClientModalItemClicked = (itemId) => {
    // Set States
    setSelectedClientId(itemId);

    onSearchClientModalClosed();

    displayOrderActionModal();
  };

  const onSearchClientModalClosed = () => {
    // Set States
    setShowSearchClientModal(false);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    displaySearchClientModal();
  };

  // Functions
  // Functions - Normal
  const displayCopyOrderActionModal = () => {
    setShowCopyOrderActionModal(true);
  };

  const displayOrderActionDialog = (orderActionType) => {
    // Set States
    setOrderActionDialogType(orderActionType);

    switch (orderActionType) {
      case "CancelOrder":
        setOrderActionDialogText(t("確認要取消 訂單 嗎？"));
        break;
      case "DeleteOrder":
        setOrderActionDialogText(t("確認要刪除 訂單 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowOrderActionDialog(true);
  };

  const displayOrderActionModal = () => {
    setShowOrderActionModal(true);
  };

  const displaySearchClientModal = () => {
    // Set States
    setShowSearchClientModal(true);
  };

  const getOrdersDebounce = debounce((keywordStr) => {
    getAllOrdersWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  const resetPage = () => {
    // Set States
    setPageNum(1);
  };

  // Functions - Mutations
  const cancelOrder = async () => {
    const results = await cancelOrderFetch(token, currentItem.id);

    if (results.success) {
      getAllOrdersWithPagination(keyword);
    }
  };

  const deleteOrder = async () => {
    const results = await deleteOrderFetch(token, currentItem.id);

    if (results.success) {
      getAllOrdersWithPagination(keyword);
    }
  };

  // Functions - Queries
  const getAllOrdersWithPagination = async (keywordStr) => {
    const startDate = getMonthStartDate(recordMonthVar);
    const endDate = getMonthEndDate(recordMonthVar);

    const results = await getAllOrdersWithPaginationFetch(
      token,
      orderStatusTypeId !== "dummy" ? orderStatusTypeId : null,
      keywordStr,
      startDate,
      endDate,
      pageNum,
      limitNum
    );

    if (results.orders) {
      for (let item of results.orders) {
        item.orderDateDisplay = item.orderDate
          ? moment(item.orderDate).format(t("M 月 D 日"))
          : null;

        item.timeCreatedDisplay = item.time_created
          ? moment(item.time_created).format(t("a h:mm"))
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setOrders(results.orders ? results.orders : null);

    if (results.pageCount && !results.orders) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    resetPage();
  }, [orderStatusTypeId, keyword]);

  useEffect(() => {
    getOrdersDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getAllOrdersWithPagination(keyword);
  }, [orderStatusTypeId, recordMonth, pageNum, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialogs */}
      <AlertDialog
        // Events
        onDialogClosed={onOrderActionDialogCanceled}
        onDialogConfirmed={onOrderActionDialogConfirmed}
        // States
        dialogText={orderActionDialogText}
        showDialog={showOrderActionDialog}
      />
      {/* Menu */}
      <OrderActionMenu
        // States
        showOrderActionMenu={showOrderActionMenu}
        // Set States
        setShowOrderActionMenu={setShowOrderActionMenu}
        // Fucntions
        displayCopyOrderActionModal={displayCopyOrderActionModal}
        displayOrderActionDialog={displayOrderActionDialog}
      />
      {/* Modals */}
      {selectedOrder && (
        <ModalContainer
          // Events
          onModalClosed={onCopyOrderActionModalClosed}
          // States
          showModal={showCopyOrderActionModal}
        >
          <CopyOrderActionModal
            // Events
            onModalClosed={onCopyOrderActionModalClosed}
            // States
            orderId={selectedOrder.id}
          />
        </ModalContainer>
      )}
      <ModalContainer
        // Events
        onModalClosed={onOrderActionModalClosed}
        // States
        showModal={showOrderActionModal}
      >
        <OrderActionModal
          // Events
          onModalClosed={onOrderActionModalClosed}
          // States
          clientId={selectedClientId}
          isOrderEditable={true}
          orderId={null}
        />
      </ModalContainer>
      <ModalContainer
        // Events
        onModalClosed={onSearchClientModalClosed}
        // States
        showModal={showSearchClientModal}
      >
        <SearchClientModal
          // Events
          onItemClicked={onSearchClientModalItemClicked}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("訂單s")}
          </Typography>
          <Tooltip placement="right" title={t("新增訂單")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <OrderBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有訂單")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default OrderBoxesContainer;
