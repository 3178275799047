export function onClientCreditSettingChange(clientCreditSettingId) {
  return {
    type: "ON_CLIENTS_PAGE_CLIENT_CREDIT_SETTING_CHANGE",
    clientCreditSettingId,
  };
}

export function onClientSettingTypeChange(clientSettingType) {
  return {
    type: "ON_CLIENTS_PAGE_CLIENT_SETTING_TYPE_CHANGE",
    clientSettingType,
  };
}

export function onDepositSettingChange(depositSettingId) {
  return {
    type: "ON_CLIENTS_PAGE_DEPOSIT_SETTING_CHANGE",
    depositSettingId,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_CLIENTS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_CLIENTS_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onPaymentTermSettingChange(paymentTermSettingId) {
  return {
    type: "ON_CLIENTS_PAGE_PAYMENT_TERM_SETTING_CHANGE",
    paymentTermSettingId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_CLIENTS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfClientsChange(typeOfClients) {
  return {
    type: "ON_CLIENTS_PAGE_TYPE_OF_CLIENTS_CHANGE",
    typeOfClients,
  };
}

export function onTypeOfClientSettingsChange(typeOfClientSettings) {
  return {
    type: "ON_CLIENTS_PAGE_TYPE_OF_CLIENT_SETTINGS_CHANGE",
    typeOfClientSettings,
  };
}
