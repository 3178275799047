const today = new Date();

const initialState = {
  conditionDepartmentId: null,
  conditionDepartmentItem: null,
  conditionProcedureId: null,
  conditionProcedureItem: null,
  conditionProgressStatusTypeId: null,
  conditionProgressStatusTypeItem: null,
  conditionStaffId: null,
  conditionStaffItem: null,
  formSubmitCount: 0,
  recordDate: today.toString(),
  recordDateType: "Month",
  tabItem: "Jobs",
};

export const jobsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_JOBS_PAGE_CONDITION_DEPARTMENT_CHANGE":
      const { conditionDepartmentId } = action;
      return {
        ...state,
        conditionDepartmentId,
      };
    case "ON_JOBS_PAGE_CONDITION_DEPARTMENT_ITEM_CHANGE":
      const { conditionDepartmentItem } = action;
      return {
        ...state,
        conditionDepartmentItem,
      };
    case "ON_JOBS_PAGE_CONDITION_PROCEDURE_CHANGE":
      const { conditionProcedureId } = action;
      return {
        ...state,
        conditionProcedureId,
      };
    case "ON_JOBS_PAGE_CONDITION_PROCEDURE_ITEM_CHANGE":
      const { conditionProcedureItem } = action;
      return {
        ...state,
        conditionProcedureItem,
      };
    case "ON_JOBS_PAGE_CONDITION_PROGRESS_STATUS_TYPE_CHANGE":
      const { conditionProgressStatusTypeId } = action;
      return {
        ...state,
        conditionProgressStatusTypeId,
      };
    case "ON_JOBS_PAGE_CONDITION_PROGRESS_STATUS_TYPE_ITEM_CHANGE":
      const { conditionProgressStatusTypeItem } = action;
      return {
        ...state,
        conditionProgressStatusTypeItem,
      };
    case "ON_JOBS_PAGE_CONDITION_STAFF_CHANGE":
      const { conditionStaffId } = action;
      return {
        ...state,
        conditionStaffId,
      };
    case "ON_JOBS_PAGE_CONDITION_STAFF_ITEM_CHANGE":
      const { conditionStaffItem } = action;
      return {
        ...state,
        conditionStaffItem,
      };
    case "ON_JOBS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_JOBS_PAGE_RECORD_DATE_CHANGE":
      const { recordDate } = action;
      return {
        ...state,
        recordDate: recordDate.toString(),
      };
    case "ON_JOBS_PAGE_RECORD_DATE_TYPE_CHANGE":
      const { recordDateType } = action;
      return {
        ...state,
        recordDateType,
      };
    case "ON_JOBS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
