export function onClientChange(clientId) {
  return {
    type: "ON_ORDER_PROFILE_PAGE_CLIENT_CHANGE",
    clientId,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_ORDER_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onIsOrderEditableChange(isOrderEditable) {
  return {
    type: "ON_ORDER_PROFILE_PAGE_IS_ORDER_EDITABLE_CHANGE",
    isOrderEditable,
  };
}

export function onMerchandiseOrderedChange(merchandiseOrderedId) {
  return {
    type: "ON_ORDER_PROFILE_PAGE_MERCHANDISE_ORDERED_CHANGE",
    merchandiseOrderedId,
  };
}

export function onOrderChange(orderId) {
  return {
    type: "ON_ORDER_PROFILE_PAGE_ORDER_CHANGE",
    orderId,
  };
}

export function onProgressChange(progressId) {
  return {
    type: "ON_ORDER_PROFILE_PAGE_PROGRESS_CHANGE",
    progressId,
  };
}

export function onSelectedMerchandiseChange(selectedMerchandiseId) {
  return {
    type: "ON_ORDER_PROFILE_PAGE_SELECTED_MERCHANDISE_CHANGE",
    selectedMerchandiseId,
  };
}

export function onSelectedUnitChange(selectedUnitId) {
  return {
    type: "ON_ORDER_PROFILE_PAGE_SELECTED_UNIT_CHANGE",
    selectedUnitId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_ORDER_PROFILE_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfPricesChange(typeOfPrices) {
  return {
    type: "ON_ORDER_PROFILE_PAGE_TYPE_OF_PRICES_CHANGE",
    typeOfPrices,
  };
}
