// Actions
import { onProcedureChange } from "../../../redux/pages/ordersPage/ordersPageActions";

// Components
// Boxes
import ProcedureBox from "./procedureBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllProceduresFetch } from "../../../fetches/procedureFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ProcedureBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.ordersPage.formSubmitCount
  );
  const procedureId = useSelector((state) => state.ordersPage.procedureId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [procedures, setProcedures] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = procedureId;
  const itemsArr = procedures;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onProcedureChange(itemId));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Queries
  const getAllProcedures = async (shoulddUpdateCurrentProcedureId) => {
    const results = await getAllProceduresFetch(token);

    // Set States
    setProcedures(results.procedures ? results.procedures : null);

    // Update Redux Store
    if (
      results.procedures &&
      (shoulddUpdateCurrentProcedureId ||
        !results.procedures.some((item) => item.id === procedureId))
    ) {
      dispatch(
        onProcedureChange(results.procedures ? results.procedures[0].id : null)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllProcedures(procedureId ? false : true);
  }, [formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("流程s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <ProcedureBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有流程資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ProcedureBoxesContainer;
