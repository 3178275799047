// Actions
import {
  onClientCreditSettingChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/clientsPage/clientsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getClientCreditSettingFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createClientCreditSettingFetch,
  editClientCreditSettingFetch,
  getClientCreditSettingDetailsByIdFetch,
} from "../../../fetches/clientCreditSettingFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ClientCreditSettingForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const clientCreditSettingId = useSelector(
    (state) => state.clientsPage.clientCreditSettingId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    clientCreditSettingActionDialogText,
    setClientCreditSettingActionDialogText,
  ] = useState("");
  const [
    clientCreditSettingActionDialogType,
    setClientCreditSettingActionDialogType,
  ] = useState(null);
  const [
    showClientCreditSettingActionDialog,
    setShowClientCreditSettingActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [creditPeriodUnitField, setCreditPeriodUnitField] = useState(null);
  // Options
  const [creditPeriodUnitOptions, setCreditPeriodUnitOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isDefault, setIsDefault] = useState(false);
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [creditPeriodNum, setCreditPeriodNum] = useState("");

  // Handle States
  const availableCreditPeriodUnitOptions = creditPeriodUnitOptions[0]
    ? // 需即時付款
      creditPeriodUnitField && creditPeriodUnitField.id === 1
      ? creditPeriodUnitOptions.filter((item) => item.id === 1)
      : creditPeriodUnitOptions.filter((item) => item.id !== 1)
    : [];
  const isCreditPeriodNumDisabled =
    creditPeriodUnitField && creditPeriodUnitField.id === 1;

  // Events
  // Events - Dialogs
  const onClientCreditSettingActionDialogCanceled = () => {
    // Set States
    setShowClientCreditSettingActionDialog(false);
  };

  const onClientCreditSettingActionDialogConfirmed = () => {
    switch (clientCreditSettingActionDialogType) {
      case "CreateClientCreditSetting":
        createClientCreditSetting();
        break;
      case "EditClientCreditSetting":
        editClientCreditSetting();
        break;
      default:
        break;
    }

    // Set States
    setShowClientCreditSettingActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "creditPeriodNum":
        setCreditPeriodNum(value);
        break;
      case "creditPeriodUnitField":
        setCreditPeriodUnitField(value);
        break;
      case "isDefault":
        setIsDefault(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!creditPeriodNum) {
      addToErrorFields("creditPeriodNum", t("請先填寫 信用期限"));
      isError = true;
    } else {
      if (isNaN(creditPeriodNum)) {
        addToErrorFields("creditPeriodNum", t("信用期限 必須為數字"));
        isError = true;
      } else if (creditPeriodNum < 1) {
        addToErrorFields("creditPeriodNum", t("信用期限 必須大於 0"));
        isError = true;
      }
    }

    if (!creditPeriodUnitField) {
      addToErrorFields("creditPeriodUnitField", t("請先填寫 單位"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayClientCreditSettingActionDialog(
      clientCreditSettingId
        ? "EditClientCreditSetting"
        : "CreateClientCreditSetting"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setCreditPeriodUnitField(null);
    setCreditPeriodNum("");
    setIsDefault(false);
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayClientCreditSettingActionDialog = (
    clientCreditSettingActionType
  ) => {
    // Set States
    setClientCreditSettingActionDialogType(clientCreditSettingActionType);

    switch (clientCreditSettingActionType) {
      case "CreateClientCreditSetting":
        setClientCreditSettingActionDialogText(
          t("確認要新增 客戶信用設定 嗎？")
        );
        break;
      case "EditClientCreditSetting":
        setClientCreditSettingActionDialogText(
          t("確認要編輯 客戶信用設定 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowClientCreditSettingActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createClientCreditSetting = async () => {
    const results = await createClientCreditSettingFetch(
      token,
      creditPeriodUnitField ? creditPeriodUnitField.id : null,
      creditPeriodNum,
      isDefault,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onClientCreditSettingChange(results.clientCreditSettingId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 設定 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editClientCreditSetting = async () => {
    if (clientCreditSettingId) {
      const results = await editClientCreditSettingFetch(
        token,
        clientCreditSettingId,
        creditPeriodUnitField ? creditPeriodUnitField.id : null,
        creditPeriodNum,
        isDefault,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 設定 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getClientCreditSettingFormOptions = async () => {
    const results = await getClientCreditSettingFormOptionsFetch(token);

    // Set States
    setCreditPeriodUnitOptions(
      results.creditPeriodUnits ? results.creditPeriodUnits : []
    );
  };

  const getClientCreditSettingDetailsById = async () => {
    const results = await getClientCreditSettingDetailsByIdFetch(
      token,
      clientCreditSettingId
    );

    if (results.clientCreditSettingDetails) {
      const {
        credit_period_unit_id,
        credit_period_num,
        is_default,
        is_active,
      } = results.clientCreditSettingDetails;

      // Set States
      setCreditPeriodUnitField(
        credit_period_unit_id
          ? creditPeriodUnitOptions.find(
              (item) => item.id === credit_period_unit_id
            )
          : null
      );
      setCreditPeriodNum(credit_period_num ? credit_period_num : "");
      setIsDefault(is_default);
      setIsItemActive(is_active);
    } else {
      // Set States
      setCreditPeriodUnitField(null);
      setCreditPeriodNum("");
      setIsDefault(false);
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getClientCreditSettingFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && creditPeriodUnitOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [creditPeriodUnitOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (clientCreditSettingId) {
        getClientCreditSettingDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, clientCreditSettingId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onClientCreditSettingActionDialogCanceled}
        onDialogConfirmed={onClientCreditSettingActionDialogConfirmed}
        // States
        dialogText={clientCreditSettingActionDialogText}
        showDialog={showClientCreditSettingActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("客戶信用設定")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            disabled={isCreditPeriodNumDisabled}
            error={checkIsFieldError("creditPeriodNum")}
            fullWidth
            helperText={getErrorFieldMessage("creditPeriodNum")}
            label={t("信用期限")}
            margin="dense"
            name="creditPeriodNum"
            onChange={(event) =>
              onInputFieldChange("creditPeriodNum", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={creditPeriodNum}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {creditPeriodUnitOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("credit_period_unit_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("creditPeriodUnitField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={availableCreditPeriodUnitOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("creditPeriodUnitField")}
                  helperText={getErrorFieldMessage("creditPeriodUnitField")}
                  label={t("單位")}
                  variant="standard"
                />
              )}
              value={creditPeriodUnitField}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("預設選項")}
          </Typography>
          <Switch
            checked={isDefault}
            onChange={() => onInputFieldChange("isDefault", !isDefault)}
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {clientCreditSettingId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default ClientCreditSettingForm;
