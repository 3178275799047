// Configs
import apisConfig from "../configs/apisConfig";

export const getClientSuggestionByKeywordFetch = async (token, keyword) => {
  try {
    const queryRoute = "/search/getClientSuggestionByKeyword";

    const reqBody = { keyword };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseSuggestionByKeywordFetch = async (
  token,
  keyword
) => {
  try {
    const queryRoute = "/search/getMerchandiseSuggestionByKeyword";

    const reqBody = { keyword };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getPlaceholderSuggestionByKeywordFetch = async (
  token,
  placeholderType,
  keyword
) => {
  try {
    const queryRoute = "/search/getPlaceholderSuggestionByKeyword";

    const reqBody = { placeholderType, keyword };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
