// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/clientProfilePage/clientProfilePageActions";

// Components
// Boxes
import ContactPersonBox from "./contactPersonBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import ContactPersonActionMenu from "../../menus/clientProfilePage/contactPersonActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import ContactPersonActionModal from "../../modals/clientProfilePage/contactPersonActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreContactPersonFetch,
  getAllContactPersonsByClientIdFetch,
} from "../../../fetches/contactPersonFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ContactPersonBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const clientId = useSelector((state) => state.clientProfilePage.clientId);
  const formSubmitCount = useSelector(
    (state) => state.clientProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);
  const typeOfContactPersons = useSelector(
    (state) => state.clientProfilePage.typeOfContactPersons
  );

  // States
  // Data
  const [contactPersons, setContactPersons] = useState(null);
  // Dialog
  const [contactPersonActionDialogText, setContactPersonActionDialogText] =
    useState("");
  const [contactPersonActionDialogType, setContactPersonActionDialogType] =
    useState(null);
  const [showContactPersonActionDialog, setShowContactPersonActionDialog] =
    useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showContactPersonActionMenu, setShowContactPersonActionMenu] =
    useState(null);
  // Modals
  const [showContactPersonActionModal, setShowContactPersonActionModal] =
    useState(false);

  // Handle States
  const itemsArr = contactPersons;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayContactPersonActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowContactPersonActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowContactPersonActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onContactPersonActionDialogCanceled = () => {
    // Set States
    setShowContactPersonActionDialog(false);
  };

  const onContactPersonActionDialogConfirmed = () => {
    switch (contactPersonActionDialogType) {
      case "DeleteContactPerson":
        deleteOrRestoreContactPerson();
        break;
      case "RestoreContactPerson":
        deleteOrRestoreContactPerson();
        break;
      default:
        break;
    }

    // Set States
    setShowContactPersonActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onContactPersonActionModalClosed = () => {
    // Set States
    setShowContactPersonActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayContactPersonActionModal();
  };

  // Functions
  // Functions - Normal
  const displayContactPersonActionDialog = (contactPersonActionType) => {
    // Set States
    setContactPersonActionDialogType(contactPersonActionType);

    switch (contactPersonActionType) {
      case "DeleteContactPerson":
        setContactPersonActionDialogText(t("確認要刪除 聯絡人 嗎？"));
        break;
      case "RestoreContactPerson":
        setContactPersonActionDialogText(t("確認要還原 聯絡人 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowContactPersonActionDialog(true);
  };

  const displayContactPersonActionModal = () => {
    setShowContactPersonActionModal(true);
  };

  // Functions - Mutations
  const deleteOrRestoreContactPerson = async () => {
    const results = await deleteOrRestoreContactPersonFetch(
      token,
      currentItemId,
      typeOfContactPersons
    );

    if (results.success) {
      getAllContactPersonsByClientId();
    }
  };

  // Functions - Queries
  const getAllContactPersonsByClientId = async () => {
    const results = await getAllContactPersonsByClientIdFetch(
      token,
      clientId,
      typeOfContactPersons
    );

    // Set States
    setContactPersons(results.contactPersons ? results.contactPersons : null);
  };

  // Life Cycle
  useEffect(() => {
    if (clientId) {
      getAllContactPersonsByClientId();
    }
  }, [clientId, formSubmitCount, typeOfContactPersons]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onContactPersonActionDialogCanceled}
        onDialogConfirmed={onContactPersonActionDialogConfirmed}
        // States
        dialogText={contactPersonActionDialogText}
        showDialog={showContactPersonActionDialog}
      />
      {/* Menu */}
      <ContactPersonActionMenu
        // States
        showContactPersonActionMenu={showContactPersonActionMenu}
        // Set States
        setShowContactPersonActionMenu={setShowContactPersonActionMenu}
        // Functions
        displayContactPersonActionDialog={displayContactPersonActionDialog}
        displayContactPersonActionModal={displayContactPersonActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onContactPersonActionModalClosed}
        // States
        showModal={showContactPersonActionModal}
      >
        <ContactPersonActionModal
          // Events
          onModalClosed={onContactPersonActionModalClosed}
          // States
          contactPersonId={currentItemId}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("聯絡人s")}
          </Typography>
          <Tooltip placement="right" title={t("新增聯絡人")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <ContactPersonBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            language={language}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有聯絡人資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ContactPersonBoxesContainer;
