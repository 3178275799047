// Actions
import { onClientChange } from "../../../redux/pages/salesReportsPage/salesReportsPageActions";

// Components
// Boxes
import RankedClientBox from "./rankedClientBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getRankedClientsWithPaginationFetch } from "../../../fetches/salesNumberFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { roundNumber } from "../../../helperFunctions/roundNumber";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function RankedClientBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.salesReportsPage.formSubmitCount
  );
  const keyword = useSelector((state) => state.salesReportsPage.keyword);
  const language = useSelector((state) => state.language.language);
  const rankingsSalesNumberType = useSelector(
    (state) => state.salesReportsPage.rankingsSalesNumberType
  );
  const recordDate = useSelector((state) => state.salesReportsPage.recordDate);
  const clientId = useSelector((state) => state.salesReportsPage.clientId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [rankedClients, setRankedClients] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = clientId;
  const itemsArr = rankedClients;
  const recordDateVar = new Date(recordDate);

  const recordDateDisplay = moment(recordDateVar).format(t("M 月"));

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onClientChange(itemId));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Normal
  const getRankedClientsDebounce = debounce((keywordStr) => {
    getRankedClientsWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  // Functions - Queries
  const getRankedClientsWithPagination = async (keywordStr) => {
    const startDate = getMonthStartDate(recordDateVar);
    const endDate = getMonthEndDate(recordDateVar);

    const results = await getRankedClientsWithPaginationFetch(
      token,
      rankingsSalesNumberType,
      startDate,
      endDate,
      keywordStr,
      pageNum,
      limitNum
    );

    if (results.rankedClients) {
      for (let item of results.rankedClients) {
        item.revenue = item.revenue
          ? `$ ${separateComma(Number(item.revenue).toFixed())}`
          : null;

        item.num_of_orders = item.num_of_orders
          ? separateComma(roundNumber(Number(item.num_of_orders), 1))
          : 0;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setRankedClients(results.rankedClients ? results.rankedClients : null);

    if (results.pageCount && !results.rankedClients) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.rankedClients) {
      if (
        !(
          clientId && results.rankedClients.some((item) => item.id === clientId)
        )
      ) {
        dispatch(onClientChange(results.rankedClients[0].id));
      }
    } else {
      dispatch(onClientChange(null));
    }
  };

  // Life Cycle
  useEffect(() => {
    getRankedClientsDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getRankedClientsWithPagination(keyword);
  }, [rankingsSalesNumberType, recordDate, pageNum, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("客戶排行")} - ${recordDateDisplay}`}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <RankedClientBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              salesNumberType={rankingsSalesNumberType}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有客戶排行")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RankedClientBoxesContainer;
