const today = new Date();
const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const initialState = {
  conditionQuotationStatusTypeId: null,
  conditionQuotationStatusTypeItem: null,
  formSubmitCount: 0,
  keyword: "",
  recordMonth: currentMonth.toString(),
  tabItem: "Quotations",
};

export const quotationsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_QUOTATIONS_PAGE_CONDITION_QUOTATION_STATUS_TYPE_CHANGE":
      const { conditionQuotationStatusTypeId } = action;
      return {
        ...state,
        conditionQuotationStatusTypeId,
      };
    case "ON_QUOTATIONS_PAGE_CONDITION_QUOTATION_STATUS_TYPE_ITEM_CHANGE":
      const { conditionQuotationStatusTypeItem } = action;
      return {
        ...state,
        conditionQuotationStatusTypeItem,
      };
    case "ON_QUOTATIONS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_QUOTATIONS_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_QUOTATIONS_PAGE_RECORD_MONTH_CHANGE":
      const { recordMonth } = action;
      return {
        ...state,
        recordMonth: recordMonth.toString(),
      };
    case "ON_QUOTATIONS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
