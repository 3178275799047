// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import ClientIntroBoxesContainer from "../components/boxes/clientProfilePgae/clientIntroBoxesContainer";
import ClientLocationBoxesContainer from "../components/boxes/clientProfilePgae/clientLocationBoxesContainer";
import ContactPersonBoxesContainer from "../components/boxes/clientProfilePgae/contactPersonBoxesContainer";
// Forms
import ClientInfoFormContainer from "../components/forms/clientProfilePage/clientInfoFormContainer";
// Headers
import ClientProfilePageHeader from "../components/headers/clientProfilePage/clientProfilePageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function ClientProfilePage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.clientProfilePage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("ClientProfile"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <ClientProfilePageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <ClientIntroBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "BasicInfo" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={8}>
                <ClientInfoFormContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ContactPersonBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
                <ClientLocationBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default ClientProfilePage;
