// Configs
import apisConfig from "../configs/apisConfig";

export const cancelOrderFetch = async (token, orderId) => {
  try {
    const queryRoute = `/order/cancelOrder/${orderId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const checkIsCurrencyEditedFetch = async (
  token,
  orderId,
  newCurrencyId
) => {
  try {
    const queryRoute = `/order/checkIsCurrencyEdited/${orderId}`;

    const reqBody = {
      newCurrencyId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const copyOrderFetch = async (token, orderId, date, timeCreated) => {
  try {
    const queryRoute = `/order/copyOrder/${orderId}`;

    const reqBody = {
      date,
      timeCreated,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createOrderFetch = async (
  token,
  clientId,
  contactPersonId,
  clientLocationId,
  salespersonId,
  date,
  timeCreated,
  currencyId
) => {
  try {
    const queryRoute = "/order/createOrder";

    const reqBody = {
      clientId,
      contactPersonId,
      clientLocationId,
      salespersonId,
      date,
      timeCreated,
      currencyId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createOrderFromQuotationFetch = async (
  token,
  quotationId,
  date,
  timeCreated
) => {
  try {
    const queryRoute = `/order/createOrderFromQuotation/${quotationId}`;

    const reqBody = {
      date,
      timeCreated,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrderFetch = async (token, orderId) => {
  try {
    const queryRoute = `/order/deleteOrder/${orderId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editOrderFetch = async (
  token,
  orderId,
  contactPersonId,
  clientLocationId,
  salespersonId,
  date,
  timeCreated,
  currencyId,
  shouldUpdateMerchandiseOrderedPrices
) => {
  try {
    const queryRoute = `/order/editOrder/${orderId}`;

    const reqBody = {
      contactPersonId,
      clientLocationId,
      salespersonId,
      date,
      timeCreated,
      currencyId,
      shouldUpdateMerchandiseOrderedPrices,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllOrdersWithPaginationFetch = async (
  token,
  orderStatusTypeId,
  keyword,
  startDate,
  endDate,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/order/getAllOrdersWithPagination";

    const reqBody = {
      orderStatusTypeId,
      keyword,
      startDate,
      endDate,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getOrderDetailsByIdFetch = async (
  token,
  orderId,
  typeOfPrices
) => {
  try {
    const queryRoute = `/order/getOrderDetailsById/${orderId}`;

    const reqBody = {
      typeOfPrices,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
