// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import RemoveDoneRoundedIcon from "@mui/icons-material/RemoveDoneRounded";
import StartRoundedIcon from "@mui/icons-material/StartRounded";

function ProgressActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    currentItem,
    showProgressActionMenu,
    // Set States
    setShowProgressActionMenu,
    // Functions
    displayProgressActionDialog,
  } = props;

  // Handle States
  const isCancelable = currentItem && currentItem.isCancelable;
  const isEditable = currentItem && currentItem.isEditable;
  const isEndDateAvailable = currentItem && currentItem.isEndDateAvailable;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowProgressActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "CancelCompletion":
        displayProgressActionDialog("CancelProgressCompletion");
        break;
      case "CompleteNow":
        displayProgressActionDialog("CompleteProgressNow");
        break;
      case "StartNow":
        displayProgressActionDialog("StartProgressNow");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showProgressActionMenu}
      open={Boolean(showProgressActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {isEditable && (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("StartNow")}>
          <StartRoundedIcon />
          {t("現在開始")}
        </MenuItem>
      )}
      {isEditable && isEndDateAvailable && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("CompleteNow")}
        >
          <DoneRoundedIcon />
          {t("現在完成")}
        </MenuItem>
      )}
      {isCancelable && (
        <MenuItem
          disableRipple
          onClick={() => onMenuItemClicked("CancelCompletion")}
        >
          <RemoveDoneRoundedIcon />
          {t("取消完成")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default ProgressActionMenu;
