// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

export const avatorButtonStyles = {
  height: stylesConfig.avatorSize,
  width: stylesConfig.avatorSize,
  borderRadius: stylesConfig.avatorSize,
};
export const avatorButtonSmallStyles = {
  height: stylesConfig.avatorSizeSmall,
  width: stylesConfig.avatorSizeSmall,
  borderRadius: stylesConfig.avatorSizeSmall,
};
export const avatorWithSrcStyles = {
  height: stylesConfig.avatorSize,
  width: stylesConfig.avatorSize,
};
export const avatorWithSrcSmallStyles = {
  height: stylesConfig.avatorSizeSmall,
  width: stylesConfig.avatorSizeSmall,
};
export const avatorWithTextStyles = {
  bgcolor: stylesConfig.greyTextColor,
  color: stylesConfig.whiteTextColor,
  height: stylesConfig.avatorSize,
  width: stylesConfig.avatorSize,
};
export const avatorWithTextSmallStyles = {
  bgcolor: stylesConfig.greyTextColor,
  color: stylesConfig.whiteTextColor,
  height: stylesConfig.avatorSizeSmall,
  width: stylesConfig.avatorSizeSmall,
};

export const multilineItemText = {
  whiteSpace: "pre-wrap",
};

export const useStandardItemBoxStyles = makeStyles((theme) =>
  createStyles({
    draggingItemContainer: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 0px",
      opacity: 0,
      padding: "5px 10px",
      width: "100%",
    },
    dummyBtn: {
      color:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroundColorLight
          : stylesConfig.contentBackgroundColorDark,
    },
    hoveredItemContainer: {
      alignItems: "flex-start",
      borderBottomStyle: "solid",
      borderColor: stylesConfig.greyBackGroundColor,
      borderWidth: 1,
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 5px 4px 5px",
      padding: "5px 5px",
      width: "calc(100% - 10px)",
    },
    hoveredItemContainerWithoutBorder: {
      alignItems: "flex-start",
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 0px",
      padding: "5px 10px",
      width: "100%",
    },
    iconBtnText: {
      marginRight: "5px",
    },
    itemContainer: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 0px",
      padding: "5px 10px",
      width: "100%",
    },
    itemContentContainer: {
      alignItems: "flex-start",
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    itemContentSubContainer: {
      alignItems: "flex-start",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    itemContentSubContainerAvator: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: 20,
    },
    itemContentSubContainerLarge: {
      alignItems: "flex-start",
      display: "flex",
      flex: 1.5,
      flexDirection: "column",
      justifyContent: "center",
    },
    itemContentSubContainerRight: {
      alignItems: "flex-end",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    itemContentSubContainerRightLarge: {
      alignItems: "flex-end",
      display: "flex",
      flex: 1.5,
      flexDirection: "column",
      justifyContent: "center",
    },
    profilePhotoContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
    },
    selectedItemContainer: {
      alignItems: "flex-start",
      backgroundColor: stylesConfig.mainTextColor,
      borderRadius: stylesConfig.borderRadiusExtraSmall,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "5px 0px",
      padding: "5px 10px",
      width: "100%",
    },
    titleTextContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  })
);
