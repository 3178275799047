// Actions
import {
  onTabItemChange,
  onTypeOfCurrenciesChange,
  onTypeOfTermsChange,
} from "../../../redux/pages/termsAndCurrenciesPage/termsAndCurrenciesPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function TermsAndCurrenciesPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.termsAndCurrenciesPage.tabItem);
  const typeOfCurrencies = useSelector(
    (state) => state.termsAndCurrenciesPage.typeOfCurrencies
  );
  const typeOfTerms = useSelector(
    (state) => state.termsAndCurrenciesPage.typeOfTerms
  );

  // Events
  // Events - Select Box
  const onTypeOfCurrenciesSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfCurrenciesChange(value));
  };

  const onTypeOfTermsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfTermsChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        <Tabs
          scrollButtons={false}
          value={tabItem}
          variant="scrollable"
          onChange={onTabClicked}
        >
          <Tab
            disableRipple
            key={"Terms"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                {t("條款s")}
              </Typography>
            }
            value={"Terms"}
          />
          <Tab
            disableRipple
            key={"Currencies"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                {t("貨幣s")}
              </Typography>
            }
            value={"Currencies"}
          />
        </Tabs>
      </div>
      {/* Select Boxes */}
      {tabItem === "Terms" && (
        <div>
          <FormControl variant="standard" sx={selectBoxContainerStyles}>
            <Select
              value={typeOfTerms}
              onChange={(event) =>
                onTypeOfTermsSelectBoxValueChanged(event.target.value)
              }
            >
              <MenuItem value={"Current"}>{t("顯示有效條款")}</MenuItem>
              <MenuItem value={"Deleted"}>{t("顯示已刪除條款")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      {tabItem === "Currencies" && (
        <div>
          <FormControl variant="standard" sx={selectBoxContainerStyles}>
            <Select
              value={typeOfCurrencies}
              onChange={(event) =>
                onTypeOfCurrenciesSelectBoxValueChanged(event.target.value)
              }
            >
              <MenuItem value={"Current"}>{t("顯示有效貨幣")}</MenuItem>
              <MenuItem value={"Deleted"}>{t("顯示已刪除貨幣")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </Box>
  );
}

export default TermsAndCurrenciesPageHeader;
