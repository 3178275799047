// Actions
import { onClientSettingTypeChange } from "../../../redux/pages/clientsPage/clientsPageActions";

// Consts
import { clientSettingTypes } from "../../../consts/clientSettingTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ClientSettingConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const clientSettingType = useSelector(
    (state) => state.clientsPage.clientSettingType
  );

  // Handle States
  const clientSettingTypeItem = clientSettingTypes.find(
    (item) => item.type === clientSettingType
  );

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "clientSettingType":
        dispatch(onClientSettingTypeChange(value.type));
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("客戶設定類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option[t("nameCh")]}
          onChange={(event, value) =>
            onInputFieldChange("clientSettingType", value)
          }
          options={clientSettingTypes}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("客戶設定類別")}
              variant="outlined"
            />
          )}
          value={clientSettingTypeItem}
        />
      </div>
    </div>
  );
}

export default ClientSettingConditionBoxesContainer;
