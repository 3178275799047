// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Helper Functions
import { getFileURL } from "../../../helperFunctions/file";

// Material UI
// Components
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import {
  avatorWithSrcSmallStyles,
  avatorWithTextSmallStyles,
  useStandardItemBoxStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function MerchandiseOrderedBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    isOrderEditable,
    item,
    language,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isDiscounted = item.is_discounted;
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;
  const photoURL = item.file_path ? getFileURL(item.file_path) : null;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onClick={() => onItemClicked(item.id)}
      onContextMenu={(event) => {
        if (isOrderEditable) {
          event.preventDefault();
          onItemRightClicked(item.id, event.currentTarget);
        }
      }}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentSubContainerAvator}>
        {photoURL ? (
          <Avatar
            alt={t("圖片")}
            src={photoURL}
            sx={avatorWithSrcSmallStyles}
          />
        ) : (
          <Avatar sx={avatorWithTextSmallStyles}>
            {item.merchandise_code}
          </Avatar>
        )}
      </div>
      <div className={classes.itemContentSubContainerLarge}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {`${item.merchandise_code} (${item[t("sub_category_name_ch")]})`}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {item[t("merchandise_name_ch")]}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainer}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {`${item.quantity} ${item[t("unit_name_ch")]}`}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {item.unit_price_display
            ? language === "zh-hk"
              ? `每${item[t("unit_name_ch")]} - ${item.unit_price_display}`
              : `${item.unit_price_display} per ${item[t("unit_name_ch")]}`
            : t("未有單價")}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainer}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : item.cost_display
              ? isHovered
                ? stylesConfig.mainTextColor
                : null
              : stylesConfig.redTextColor
          }
          variant="body1"
        >
          {item.cost_display
            ? `${t("總價")} - ${item.cost_display}`
            : t("未有總價")}
        </Typography>
        {isDiscounted && (
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.redTextColor
            }
            variant="body2"
          >
            {item.actual_cost_display
              ? `${t("折實價")} - ${item.actual_cost_display}`
              : t("未有折實價")}
          </Typography>
        )}
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          disabled={!isOrderEditable}
          onClick={(event) => {
            onItemShowMenuBtnClicked(item.id, event.currentTarget);
          }}
          sx={isSelected ? { color: stylesConfig.whiteTextColor } : null}
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default MerchandiseOrderedBox;
