// Actions
import {
  onTabItemChange,
  onTypeOfPricesChange,
} from "../../../redux/pages/orderProfilePage/orderProfilePageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function OrderProfilePageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.orderProfilePage.tabItem);
  const typeOfPrices = useSelector(
    (state) => state.orderProfilePage.typeOfPrices
  );

  // Events
  // Events - Select Box
  const onTypeOfPricesSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfPricesChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"BasicInfo"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("概要")}
                </Typography>
              }
              value={"BasicInfo"}
            />
            <Tab
              disableRipple
              key={"Progresses"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("進度s")}
                </Typography>
              }
              value={"Progresses"}
            />
          </Tabs>
        )}
      </div>
      {tabItem === "BasicInfo" && (
        <div>
          <FormControl variant="standard" sx={selectBoxContainerStyles}>
            <Select
              value={typeOfPrices}
              onChange={(event) =>
                onTypeOfPricesSelectBoxValueChanged(event.target.value)
              }
            >
              <MenuItem value={"InTransactionCurrency"}>
                {t("以交易貨幣顯示")}
              </MenuItem>
              <MenuItem value={"InHKD"}>{t("以港幣顯示")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </Box>
  );
}

export default OrderProfilePageHeader;
