// Actions
import { onMerchandiseChange } from "../../../redux/pages/merchandiseSalesPage/merchandiseSalesPageActions";

// Components
// Boxes
import RankedMerchandiseBox from "./rankedMerchandiseBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getRankedMerchandisesWithPaginationFetch } from "../../../fetches/merchandiseSalesNumberFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { roundNumber } from "../../../helperFunctions/roundNumber";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function RankedMerchandiseBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const brandId = useSelector(
    (state) => state.merchandiseSalesPage.conditionBrandId
  );
  const categoryId = useSelector(
    (state) => state.merchandiseSalesPage.conditionCategoryId
  );
  const formSubmitCount = useSelector(
    (state) => state.merchandiseSalesPage.formSubmitCount
  );
  const keyword = useSelector((state) => state.merchandiseSalesPage.keyword);
  const language = useSelector((state) => state.language.language);
  const merchandiseId = useSelector(
    (state) => state.merchandiseSalesPage.merchandiseId
  );
  const recordDate = useSelector(
    (state) => state.merchandiseSalesPage.recordDate
  );
  const salesNumberType = useSelector(
    (state) => state.merchandiseSalesPage.salesNumberType
  );
  const subCategoryId = useSelector(
    (state) => state.merchandiseSalesPage.conditionSubCategoryId
  );
  const token = useSelector((state) => state.staff.token);
  const unitId = useSelector(
    (state) => state.merchandiseSalesPage.conditionUnitId
  );
  const unitItem = useSelector(
    (state) => state.merchandiseSalesPage.conditionUnitItem
  );

  // States
  // Data
  const [rankedMerchandises, setRankedMerchandises] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = merchandiseId;
  const itemsArr = rankedMerchandises;
  const recordDateVar = new Date(recordDate);

  const recordDateDisplay = moment(recordDateVar).format(t("M 月"));
  const unitTextDisplay = unitItem ? unitItem[t("unit_name_ch")] : "";

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onMerchandiseChange(itemId));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Normal
  const getRankedMerchandisesDebounce = debounce((keywordStr) => {
    getRankedMerchandisesWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  // Functions - Queries
  const getRankedMerchandisesWithPagination = async (keywordStr) => {
    const startDate = getMonthStartDate(recordDateVar);
    const endDate = getMonthEndDate(recordDateVar);

    const results = await getRankedMerchandisesWithPaginationFetch(
      token,
      salesNumberType,
      unitId,
      categoryId !== "dummy" ? categoryId : null,
      subCategoryId !== "dummy" ? subCategoryId : null,
      brandId !== "dummy" ? brandId : null,
      startDate,
      endDate,
      keywordStr,
      pageNum,
      limitNum
    );

    if (results.rankedMerchandises) {
      for (let item of results.rankedMerchandises) {
        item.revenue = item.revenue
          ? `$ ${separateComma(Number(item.revenue).toFixed())}`
          : null;

        item.num_of_merchandise_ordereds = item.num_of_merchandise_ordereds
          ? separateComma(
              roundNumber(Number(item.num_of_merchandise_ordereds), 1)
            )
          : 0;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setRankedMerchandises(
      results.rankedMerchandises ? results.rankedMerchandises : null
    );

    if (results.pageCount && !results.rankedMerchandises) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.rankedMerchandises) {
      if (
        !(
          merchandiseId &&
          results.rankedMerchandises.some((item) => item.id === merchandiseId)
        )
      ) {
        dispatch(onMerchandiseChange(results.rankedMerchandises[0].id));
      }
    } else {
      dispatch(onMerchandiseChange(null));
    }
  };

  // Life Cycle
  useEffect(() => {
    if (unitId) {
      getRankedMerchandisesDebounce(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    if (unitId) {
      getRankedMerchandisesWithPagination(keyword);
    }
  }, [
    salesNumberType,
    unitId,
    brandId,
    categoryId,
    subCategoryId,
    recordDate,
    pageNum,
    formSubmitCount,
    language,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("商品排行")} - ${recordDateDisplay}`}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <RankedMerchandiseBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              salesNumberType={salesNumberType}
              unitTextDisplay={unitTextDisplay}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有商品排行")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RankedMerchandiseBoxesContainer;
