// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ClientInfoFormBasicInfoContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    code,
    isItemActive,
    nameCh,
    nameEn,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("基本資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("code")}
            fullWidth
            helperText={getErrorFieldMessage("code")}
            label={t("客戶代號")}
            margin="dense"
            name="code"
            onChange={(event) => onInputFieldChange("code", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={code}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ClientInfoFormBasicInfoContent;
