// Actions
import {
  onFormSubmitCountIncrease,
  onMerchandiseQuotedChange,
  onSelectedMerchandiseChange,
} from "../../../redux/pages/quotationProfilePage/quotationProfilePageActions";

// Components
// Boxes
import MerchandiseQuotedBox from "./merchandiseQuotedBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import MerchandiseQuotedActionMenu from "../../menus/quotationProfilePage/merchandiseQuotedActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import SearchMerchandiseModal from "../../modals/search/searchMerchandiseModal";
// Snackbars
import AlertSnackbar from "../../snackbar/alertSnackbar";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  checkMerchandiseQuotedExistsFetch,
  deleteMerchandiseQuotedFetch,
  getAllMerchandiseQuotedsByQuotationIdWithPaginationFetch,
} from "../../../fetches/merchandiseQuotedFetches";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function MerchandiseQuotedBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.quotationProfilePage.formSubmitCount
  );
  const isQuotationEditable = useSelector(
    (state) => state.quotationProfilePage.isQuotationEditable
  );
  const language = useSelector((state) => state.language.language);
  const merchandiseQuotedId = useSelector(
    (state) => state.quotationProfilePage.merchandiseQuotedId
  );
  const quotationId = useSelector(
    (state) => state.quotationProfilePage.quotationId
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfPrices = useSelector(
    (state) => state.quotationProfilePage.typeOfPrices
  );

  // States
  // Data
  const [merchandiseQuoteds, setMerchandiseQuoteds] = useState(null);
  // Dialog
  const [
    merchandiseQuotedActionDialogText,
    setMerchandiseQuotedActionDialogText,
  ] = useState("");
  const [
    merchandiseQuotedActionDialogType,
    setMerchandiseQuotedActionDialogType,
  ] = useState(null);
  const [
    showMerchandiseQuotedActionDialog,
    setShowMerchandiseQuotedActionDialog,
  ] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showMerchandiseQuotedActionMenu, setShowMerchandiseQuotedActionMenu] =
    useState(null);
  // Modals
  const [showSearchMerchandiseModal, setShowSearchMerchandiseModal] =
    useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Snackbar
  const [
    showMerchandiseQuotedActionSnackbar,
    setShowMerchandiseQuotedActionSnackbar,
  ] = useState(false);
  const [
    merchandiseQuotedActionSnackbarText,
    setMerchandiseQuotedActionSnackbarText,
  ] = useState("");
  const [
    merchandiseQuotedActionSnackbarType,
    setMerchandiseQuotedActionSnackbarType,
  ] = useState("success");

  // Handle States
  const currentItemId = merchandiseQuotedId;
  const itemsArr = merchandiseQuoteds;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onMerchandiseQuotedChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowMerchandiseQuotedActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowMerchandiseQuotedActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onMerchandiseQuotedActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseQuotedActionDialog(false);
  };

  const onMerchandiseQuotedActionDialogConfirmed = () => {
    switch (merchandiseQuotedActionDialogType) {
      case "DeleteMerchandiseQuoted":
        deleteMerchandiseQuoted();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseQuotedActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onSearchMerchandiseModalItemClicked = async (itemId) => {
    // Check Is Merchandise Quoted Already Exist
    const isValid = await checkMerchandiseQuotedExists(itemId);

    if (!isValid) {
      displayMerchandiseQuotedActionSnackBar("AlreadyExist");
      return;
    }

    // Update Redux Store
    dispatch(onSelectedMerchandiseChange(itemId));

    onSearchMerchandiseModalClosed();
  };

  const onSearchMerchandiseModalClosed = () => {
    // Set States
    setShowSearchMerchandiseModal(false);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Snackbar
  const onMerchandiseQuotedActionSnackbarClosed = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // Set States
    setShowMerchandiseQuotedActionSnackbar(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onMerchandiseQuotedChange(null));
    dispatch(onSelectedMerchandiseChange(null));

    displaySearchMerchandiseModal();
  };

  // Functions
  // Functions - Normal
  const displayMerchandiseQuotedActionDialog = (
    merchandiseQuotedActionType
  ) => {
    // Set States
    setMerchandiseQuotedActionDialogType(merchandiseQuotedActionType);

    switch (merchandiseQuotedActionType) {
      case "DeleteMerchandiseQuoted":
        setMerchandiseQuotedActionDialogText(t("確認要刪除 報價商品 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseQuotedActionDialog(true);
  };

  const displayMerchandiseQuotedActionSnackBar = (
    merchandiseQuotedActionType
  ) => {
    switch (merchandiseQuotedActionType) {
      case "AlreadyExist":
        setMerchandiseQuotedActionSnackbarText(t("商品已在 報價列表 中"));
        setMerchandiseQuotedActionSnackbarType("warning");
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseQuotedActionSnackbar(true);
  };

  const displaySearchMerchandiseModal = () => {
    // Set States
    setShowSearchMerchandiseModal(true);
  };

  // Functions - Mutations
  const deleteMerchandiseQuoted = async () => {
    const results = await deleteMerchandiseQuotedFetch(token, currentItemId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  // Functions - Queries
  const checkMerchandiseQuotedExists = async (merchandiseIdInt) => {
    const results = await checkMerchandiseQuotedExistsFetch(
      token,
      quotationId,
      null,
      merchandiseIdInt
    );

    return results.isValid;
  };

  const getAllMerchandiseQuotedsByQuotationIdWithPagination = async () => {
    const results =
      await getAllMerchandiseQuotedsByQuotationIdWithPaginationFetch(
        token,
        quotationId,
        typeOfPrices,
        pageNum,
        limitNum
      );

    if (results.merchandiseQuoteds) {
      const currencySymbol =
        typeOfPrices === "InTransactionCurrency"
          ? results.currencyDetails
            ? results.currencyDetails.currency_symbol
            : null
          : "HKD";

      for (let item of results.merchandiseQuoteds) {
        item.unit_price_display = item.unit_price
          ? `${currencySymbol} ${separateComma(
              Number(item.unit_price).toFixed(1)
            )}`
          : null;

        item.cost_display = item.cost
          ? `${currencySymbol} ${separateComma(Number(item.cost).toFixed(1))}`
          : null;

        item.actual_cost_display = item.actual_cost
          ? `${currencySymbol} ${separateComma(
              Number(item.actual_cost).toFixed(1)
            )}`
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setMerchandiseQuoteds(
      results.merchandiseQuoteds ? results.merchandiseQuoteds : null
    );

    if (results.pageCount && !results.merchandiseQuoteds) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.merchandiseQuoteds) {
      if (
        !(
          merchandiseQuotedId &&
          results.merchandiseQuoteds.some(
            (item) => item.id === merchandiseQuotedId
          )
        )
      ) {
        dispatch(onMerchandiseQuotedChange(results.merchandiseQuoteds[0].id));
      }
    } else {
      dispatch(onMerchandiseQuotedChange(null));
    }
  };

  // Life Cycle

  useEffect(() => {
    getAllMerchandiseQuotedsByQuotationIdWithPagination();
  }, [quotationId, pageNum, typeOfPrices, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseQuotedActionDialogCanceled}
        onDialogConfirmed={onMerchandiseQuotedActionDialogConfirmed}
        // States
        dialogText={merchandiseQuotedActionDialogText}
        showDialog={showMerchandiseQuotedActionDialog}
      />
      {/* Menu */}
      <MerchandiseQuotedActionMenu
        // States
        showMerchandiseQuotedActionMenu={showMerchandiseQuotedActionMenu}
        // Set States
        setShowMerchandiseQuotedActionMenu={setShowMerchandiseQuotedActionMenu}
        // Fucntions
        displayMerchandiseQuotedActionDialog={
          displayMerchandiseQuotedActionDialog
        }
      />
      {/* Modals */}
      <ModalContainer
        // Events
        onModalClosed={onSearchMerchandiseModalClosed}
        // States
        showModal={showSearchMerchandiseModal}
      >
        <SearchMerchandiseModal
          // Events
          onItemClicked={onSearchMerchandiseModalItemClicked}
        />
      </ModalContainer>
      {/* Snackbar */}
      <AlertSnackbar
        // Constants
        horizontalPosition={"left"}
        verticalPosition={"bottom"}
        // Events
        onSnackbarClosed={onMerchandiseQuotedActionSnackbarClosed}
        // States
        showSnackbar={showMerchandiseQuotedActionSnackbar}
        snackbarText={merchandiseQuotedActionSnackbarText}
        snackbarType={merchandiseQuotedActionSnackbarType}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("報價商品列表")}
          </Typography>
          <Tooltip placement="right" title={t("新增報價商品")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <MerchandiseQuotedBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              isQuotationEditable={isQuotationEditable}
              item={item}
              language={language}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有報價商品資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseQuotedBoxesContainer;
