// Components
// Boxes
import PdfBox from "../../boxes/orderProfilePage/pdfViewModal/pdfBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import PdfActionMenu from "../../menus/orderProfilePage/pdfActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrderToPdfFetch,
  getAllPdfsByOrderIdFetch,
} from "../../../fetches/orderToPdfFetches";

// Helper Functions
import {
  displayFileSizeText,
  openFileInNewTab,
} from "../../../helperFunctions/file";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { getOrderPdfTypeNameCh } from "../../../helperFunctions/getOrderPdfTypeName";

// Material UI
// Components
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PdfViewModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Functions
    displayCreatePdfActionModal,
    // States
    orderId,
    pdfTypeCode,
  } = props;

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.orderProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [pdfs, setPdfs] = useState(null);
  // Dialog
  const [pdfActionDialogText, setPdfActionDialogText] = useState("");
  const [pdfActionDialogType, setPdfActionDialogType] = useState(null);
  const [showPdfActionDialog, setShowPdfActionDialog] = useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentItemPath, setCurrentItemPath] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showPdfActionMenu, setShowPdfActionMenu] = useState(null);

  // Handle States
  const itemsArr = pdfs;
  const pdfTypeNameCh = getOrderPdfTypeNameCh(pdfTypeCode);

  // Events
  // Events - Boxes
  const onItemClicked = (itemPath) => {
    openFileInNewTab(itemPath);
  };

  const onItemRightClicked = (itemId, itemPath, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setCurrentItemPath(itemPath);
    setShowPdfActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, itemPath, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setCurrentItemPath(itemPath);
    setShowPdfActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onPdfActionDialogCanceled = () => {
    // Set States
    setShowPdfActionDialog(false);
  };

  const onPdfActionDialogConfirmed = () => {
    switch (pdfActionDialogType) {
      case "DeletePdf":
        deleteOrderToPdf();
        break;
      default:
        break;
    }

    // Set States
    setShowPdfActionDialog(false);
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    displayCreatePdfActionModal();
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Normal
  const displayPdfActionDialog = (pdfActionType) => {
    // Set States
    setPdfActionDialogType(pdfActionType);

    switch (pdfActionType) {
      case "DeleteFail":
        setPdfActionDialogText(t("未能刪除，請再試一次"));
        break;
      case "DeletePdf":
        setPdfActionDialogText(t(`確認要刪除 ${pdfTypeNameCh} (Pdf) 嗎？`));
        break;
      default:
        break;
    }

    // Set States
    setShowPdfActionDialog(true);
  };

  const openFile = () => {
    if (currentItemPath) {
      openFileInNewTab(currentItemPath);
    }
  };

  // Functions - Mutations
  const deleteOrderToPdf = async () => {
    const results = await deleteOrderToPdfFetch(token, currentItemId);

    if (results.success) {
      getAllPdfsByOrderId();
    } else {
      displayPdfActionDialog("DeleteFail");
    }
  };

  // Functions - Queries
  const getAllPdfsByOrderId = async () => {
    const results = await getAllPdfsByOrderIdFetch(token, orderId, pdfTypeCode);

    if (results.pdfs) {
      for (let item of results.pdfs) {
        item.file_size_text = displayFileSizeText(item.file_size);

        item.dateUploadDisplay = item.time_upload
          ? moment(item.time_upload).format(t("Y 年 M 月 D 日"))
          : null;
        item.timeUploadDisplay = item.time_upload
          ? moment(item.time_upload).format(t("a h:mm"))
          : null;
      }
    }

    // Set States
    setPdfs(results.pdfs ? results.pdfs : null);
  };

  // Life Cycle
  useEffect(() => {
    if (orderId && pdfTypeCode) {
      getAllPdfsByOrderId();
    }
  }, [orderId, pdfTypeCode, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPdfActionDialogCanceled}
        onDialogConfirmed={onPdfActionDialogConfirmed}
        // States
        dialogText={pdfActionDialogText}
        showDialog={showPdfActionDialog}
      />
      {/* Menu */}
      <PdfActionMenu
        // States
        showPdfActionMenu={showPdfActionMenu}
        // Set States
        setShowPdfActionMenu={setShowPdfActionMenu}
        // Functions
        displayPdfActionDialog={displayPdfActionDialog}
        openFile={openFile}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t(`${pdfTypeNameCh} (Pdf)s`)}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            {/* List */}
            {itemsArr ? (
              itemsArr.map((item) => (
                <PdfBox
                  key={`${item.type_en}-${item.id}`}
                  // States
                  hoveredItemId={hoveredItemId}
                  item={item}
                  // Events
                  onItemClicked={onItemClicked}
                  onItemRightClicked={onItemRightClicked}
                  onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
                  onMouseEnterItem={onMouseEnterItem}
                  onMouseLeaveItem={onMouseLeaveItem}
                />
              ))
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  {t(`未有${pdfTypeNameCh} (Pdf)`)}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t(
                itemsArr
                  ? `再次創建${pdfTypeNameCh} (Pdf)`
                  : `創建${pdfTypeNameCh} (Pdf)`
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PdfViewModal;
