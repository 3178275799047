// Actions
import {
  onActiveStepDecrease,
  onActiveStepIncrease,
  onActiveStepReset,
} from "../../../redux/pages/createClientPage/createClientPageActions";

// Components
// Boxes
import ClientLocationBoxesContainer from "../../boxes/createClientPage/clientLocationBoxesContainer";
import ContactPersonBoxesContainer from "../../boxes/createClientPage/contactPersonBoxesContainer";
// Forms
import CreateClientFormBasicInfoContent from "./createClientFormBasicInfoContent";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { createClientFetch } from "../../../fetches/clientFetches";
import { getClientApplicationFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Helper Functions
import {
  createClientLocationItem,
  createInitialClientLocationItem,
} from "../../../helperFunctions/creatClientLocationItem";
import {
  createContactPersonItem,
  createInitialContactPersonItem,
} from "../../../helperFunctions/creatContactPersonItem";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function CreateClientFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Redux Store
  const activeStep = useSelector((state) => state.createClientPage.activeStep);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dynamic Fields
  const [clientLocations, setClientLocations] = useState([
    createInitialClientLocationItem(),
  ]);
  const [contactPersons, setContactPersons] = useState([
    createInitialContactPersonItem(),
  ]);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  const [dynamicErrorFields, setDynamicErrorFields] = useState([]);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [personTitleOptions, setPersonTitleOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Events
  // Events - Fields
  const onDynamicInputFieldChange = (field, id, subField, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "clientLocation":
        setClientLocations((currentState) => {
          let tempState = currentState;

          for (let item of tempState) {
            if (item.id === id) {
              item[subField] = value;

              if (subField === "areaField") {
                const tempDistrictItem = value
                  ? districtOptions.find(
                      (districtItem) => districtItem.area_id === value.id
                    )
                  : null;

                item["districtField"] = tempDistrictItem;

                removeDynamicErrorField(field, id, "districtField");
              }
            }
          }

          return tempState;
        });
        break;
      case "contactPerson":
        setContactPersons((currentState) => {
          let tempState = currentState;

          for (let item of tempState) {
            if (item.id === id) {
              item[subField] = value;
            }
          }

          return tempState;
        });
        break;
      default:
        break;
    }

    removeDynamicErrorField(field, id, subField);
  };

  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "code":
        setCode(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      switch (activeStep) {
        case 0:
          onCreateClientFormBasicInfoContentSubmitBtnClicked();
          break;
        case 1:
          onCreateClientFormContactPersonContentSubmitBtnClicked();
          break;
        case 2:
          onCreateClientFormClientLocationContentSubmitBtnClicked();
          break;
        default:
          break;
      }
    }
  };

  // Events - Forms
  const onAddDynamicFieldBtnClicked = (field) => {
    // Set States
    switch (field) {
      case "clientLocation":
        setClientLocations((currentState) => [
          ...currentState,
          createClientLocationItem(currentState),
        ]);

        break;
      case "contactPerson":
        setContactPersons((currentState) => [
          ...currentState,
          createContactPersonItem(currentState),
        ]);

        break;
      default:
        break;
    }
  };

  const onDeleteDynamicFieldBtnClicked = (field, id) => {
    // Set States
    switch (field) {
      case "clientLocation":
        setClientLocations((currentState) =>
          currentState.filter((item) => item.id !== id)
        );
        break;
      case "contactPerson":
        setContactPersons((currentState) =>
          currentState.filter((item) => item.id !== id)
        );
        break;
      default:
        break;
    }

    removeAllDynamicErrorField(field, id);
  };

  const onCreateClientFormBasicInfoContentSubmitBtnClicked = () => {
    let isError = false;

    if (!code) {
      addToErrorFields("code", t("請先填寫 客戶代號"));
      isError = true;
    }

    if (!nameCh && !nameEn) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱 或 英文名稱"));
      addToErrorFields("nameEn", t("請先填寫 中文名稱 或 英文名稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onCreateClientFormClientLocationContentSubmitBtnClicked = () => {
    let isError = false;

    for (let item of clientLocations) {
      if (!item.areaField) {
        addToDynamicErrorFields(
          "clientLocation",
          item.id,
          "areaField",
          t("請先填寫 區域")
        );
        isError = true;
      }

      if (!item.districtField) {
        addToDynamicErrorFields(
          "clientLocation",
          item.id,
          "districtField",
          t("請先填寫 地區")
        );
        isError = true;
      }

      if (!item.locationName) {
        addToDynamicErrorFields(
          "clientLocation",
          item.id,
          "locationName",
          t("請先填寫 名稱")
        );
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    createClient();
  };

  const onCreateClientFormContactPersonContentSubmitBtnClicked = () => {
    let isError = false;

    for (let item of contactPersons) {
      if (!item.personTitleField) {
        addToDynamicErrorFields(
          "contactPerson",
          item.id,
          "personTitleField",
          t("請先填寫 稱謂")
        );
        isError = true;
      }

      if (!item.contactPersonName) {
        addToDynamicErrorFields(
          "contactPerson",
          item.id,
          "contactPersonName",
          t("請先填寫 名稱")
        );
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    // Update Redux Store
    dispatch(onActiveStepIncrease());
  };

  const onStepBackBtnClicked = () => {
    if (activeStep !== 0) {
      // Update Redux Store
      dispatch(onActiveStepDecrease());
    }
  };

  // Functions
  // Functions - Normal
  const addToDynamicErrorFields = (field, id, subField, message) => {
    if (
      dynamicErrorFields.some(
        (item) =>
          item.field === field && item.id === id && item.subField === subField
      )
    ) {
      return;
    }

    // Set States
    setDynamicErrorFields((currentState) => [
      ...currentState,
      { field, id, subField, message },
    ]);
  };

  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsDynamicFieldError = (field, id, subField) => {
    return dynamicErrorFields.some(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const getDynamicErrorFieldMessage = (field, id, subField) => {
    const targetField = dynamicErrorFields.find(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeAllDynamicErrorField = (field, id) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field || item.id !== id)
    );
  };

  const removeDynamicErrorField = (field, id, subField) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter(
        (item) =>
          item.field !== field || item.id !== id || item.subField !== subField
      )
    );
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createClient = async () => {
    if (clientLocations && clientLocations[0]) {
      for (let item of clientLocations) {
        item.districtId = item.districtField ? item.districtField.id : null;
      }
    }

    if (contactPersons && contactPersons[0]) {
      for (let item of contactPersons) {
        item.personTitleId = item.personTitleField
          ? item.personTitleField.id
          : null;
      }
    }

    const results = await createClientFetch(
      token,
      code,
      nameCh,
      nameEn,
      clientLocations,
      contactPersons
    );

    if (results.success) {
      // Navigate
      navigate("/clients");
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 客戶代號 已被登記"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions - Queries
  const getClientApplicationFormOptions = async () => {
    const results = await getClientApplicationFormOptionsFetch(token);

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setDistrictOptions(results.districts ? results.districts : []);
    setPersonTitleOptions(results.personTitles ? results.personTitles : []);
  };

  // Life Cycle
  useEffect(() => {
    getClientApplicationFormOptions();

    // Update Redux Store
    dispatch(onActiveStepReset());
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    // Set States
    setShouldShowFormAlert(false);
  }, [activeStep]);

  return (
    <>
      {activeStep === 0 && (
        <CreateClientFormBasicInfoContent
          // States
          code={code}
          nameCh={nameCh}
          nameEn={nameEn}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onCreateClientFormBasicInfoContentSubmitBtnClicked
          }
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      )}
      {activeStep === 1 && (
        <ContactPersonBoxesContainer
          // Events
          onAddDynamicFieldBtnClicked={onAddDynamicFieldBtnClicked}
          onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
          onDynamicInputFieldChange={onDynamicInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onCreateClientFormContactPersonContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsDynamicFieldError={checkIsDynamicFieldError}
          getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
          // Options
          personTitleOptions={personTitleOptions}
          // States
          formAlertText={formAlertText}
          formAlertType={formAlertType}
          contactPersons={contactPersons}
          shouldShowFormAlert={shouldShowFormAlert}
        />
      )}
      {activeStep === 2 && (
        <ClientLocationBoxesContainer
          // Events
          onAddDynamicFieldBtnClicked={onAddDynamicFieldBtnClicked}
          onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
          onDynamicInputFieldChange={onDynamicInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onNextStepBtnClicked={
            onCreateClientFormClientLocationContentSubmitBtnClicked
          }
          onStepBackBtnClicked={onStepBackBtnClicked}
          // Functions
          checkIsDynamicFieldError={checkIsDynamicFieldError}
          getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
          // Options
          areaOptions={areaOptions}
          districtOptions={districtOptions}
          // States
          formAlertText={formAlertText}
          formAlertType={formAlertType}
          clientLocations={clientLocations}
          shouldShowFormAlert={shouldShowFormAlert}
        />
      )}
    </>
  );
}

export default CreateClientFormContainer;
