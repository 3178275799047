export function onConditionQuotationStatusTypeChange(
  conditionQuotationStatusTypeId
) {
  return {
    type: "ON_QUOTATIONS_PAGE_CONDITION_QUOTATION_STATUS_TYPE_CHANGE",
    conditionQuotationStatusTypeId,
  };
}

export function onConditionQuotationStatusTypeItemChange(
  conditionQuotationStatusTypeItem
) {
  return {
    type: "ON_QUOTATIONS_PAGE_CONDITION_QUOTATION_STATUS_TYPE_ITEM_CHANGE",
    conditionQuotationStatusTypeItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_QUOTATIONS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_QUOTATIONS_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onRecordMonthChange(recordMonth) {
  return {
    type: "ON_QUOTATIONS_PAGE_RECORD_MONTH_CHANGE",
    recordMonth,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_QUOTATIONS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
