// Components
// Boxes
import TermContentBoxPaymentTerms from "./termContentBoxPaymentTerms";
import TermContentBoxSignature from "./termContentBoxSignature";
import TermContentBoxText from "./termContentBoxText";
import TermContentBoxTitle from "./termContentBoxTitle";

// Consts
import { reactDnDItemTypes } from "../../../consts/reactDnDConsts";

// Helper Functions
import { getDragAndHoverIndexes } from "../../../helperFunctions/getDragAndHoverIndexes";

// React
import { useRef } from "react";

// React DnD
import { useDrag, useDrop } from "react-dnd";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function TermContentBox(props) {
  // Hooks
  // Refs
  const ref = useRef(null);
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    index,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
    // Functions
    editTermContentIndexes,
    moveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;

  // Drag and Drops
  const [{ isDragging }, dragRef] = useDrag({
    type: reactDnDItemTypes.TERMCONTENT,
    item: () => {
      return { id: item.id, index, type: reactDnDItemTypes.TERMCONTENT };
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ handlerId }, dropRef] = useDrop({
    accept: reactDnDItemTypes.TERMCONTENT,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop() {
      editTermContentIndexes();
    },
    hover(draggedItem, monitor) {
      const indexResults = getDragAndHoverIndexes(
        draggedItem,
        monitor,
        ref,
        index
      );

      if (!indexResults) {
        return;
      }

      const { dragIndex, hoverIndex } = indexResults;

      moveItem(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  dragRef(dropRef(ref));

  return (
    <div
      className={
        isDragging
          ? classes.draggingItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id, item.index)}
        onContextMenu={(event) => {
          event.preventDefault();
          onItemRightClicked(item.id, item.index, event.currentTarget);
        }}
        ref={ref}
      >
        <div className={classes.itemContentSubContainer}>
          {/* 標題 */}
          {item.term_content_type_name_en === "Title" && (
            <TermContentBoxTitle
              // States
              isHovered={isHovered}
              item={item}
            />
          )}
          {/* 文字 */}
          {item.term_content_type_name_en === "Text" && (
            <TermContentBoxText
              // States
              item={item}
            />
          )}
          {/* 簽署 */}
          {item.term_content_type_name_en === "Signature" && (
            <TermContentBoxSignature
              // States
              item={item}
            />
          )}
          {/* 付款方式 */}
          {item.term_content_type_name_en === "Payment Terms" && (
            <TermContentBoxPaymentTerms
              // States
              item={item}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TermContentBox;
