// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import CurrencyBoxesContainer from "../components/boxes/termsAndCurrenciesPage/currencyBoxesContainer";
import CurrencyConditionBoxesContainer from "../components/boxes/termsAndCurrenciesPage/currencyConditionBoxesContainer";
import TermContentBoxesContainer from "../components/boxes/termsAndCurrenciesPage/termContentBoxesContainer";
import TermsConditionBoxesContainer from "../components/boxes/termsAndCurrenciesPage/termsConditionBoxesContainer";
import TermTemplateBoxesContainer from "../components/boxes/termsAndCurrenciesPage/termTemplateBoxesContainer";
// Forms
import CurrencyDetailForm from "../components/forms/termsAndCurrenciesPage/currencyDetailForm";
import TermTemplateDetailForm from "../components/forms/termsAndCurrenciesPage/termTemplateDetailForm";
// Headers
import TermsAndCurrenciesPageHeader from "../components/headers/termsAndCurrenciesPage/termsAndCurrenciesPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React DnD
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function TermsAndCurrenciesPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.termsAndCurrenciesPage.tabItem);
  const typeOfContent = useSelector(
    (state) => state.termsAndCurrenciesPage.typeOfContent
  );

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("TermsAndCurrencies"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <TermsAndCurrenciesPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "Terms" && <TermsConditionBoxesContainer />}
          {tabItem === "Currencies" && <CurrencyConditionBoxesContainer />}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "Terms" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <TermTemplateBoxesContainer />
              </Grid>
              <Grid item xs={12} lg={6}>
                {typeOfContent === "TermContent" && (
                  <DndProvider backend={HTML5Backend}>
                    <TermContentBoxesContainer />
                  </DndProvider>
                )}
                {typeOfContent === "TermTemplateDetail" && (
                  <TermTemplateDetailForm />
                )}
              </Grid>
            </Grid>
          )}
          {tabItem === "Currencies" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <CurrencyBoxesContainer />
              </Grid>
              <Grid item xs={12} lg={6}>
                <CurrencyDetailForm />
              </Grid>
            </Grid>
          )}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default TermsAndCurrenciesPage;
