// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createClientLocationFetch,
  editClientLocationFetch,
  getClientLocationDetailsByIdFetch,
} from "../../../fetches/clientLocationFetches";
import { getClientLocationDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ClientLocationActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    clientLocationId,
  } = props;

  // Redux Store
  const clientId = useSelector((state) => state.clientProfilePage.clientId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [clientLocationActionDialogText, setClientLocationActionDialogText] =
    useState("");
  const [clientLocationActionDialogType, setClientLocationActionDialogType] =
    useState(null);
  const [showClientLocationActionDialog, setShowClientLocationActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [areaField, setAreaField] = useState(null);
  const [districtField, setDistrictField] = useState(null);
  // Options
  const [areaOptions, setAreaOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [address, setAddress] = useState("");
  const [locationName, setLocationName] = useState("");

  // Events
  // Events - Dialogs
  const onClientLocationActionDialogCanceled = () => {
    // Set States
    setShowClientLocationActionDialog(false);
  };

  const onClientLocationActionDialogConfirmed = () => {
    switch (clientLocationActionDialogType) {
      case "CreateClientLocation":
        createClientLocation();
        break;
      case "EditClientLocation":
        editClientLocation();
        break;
      default:
        break;
    }

    // Set States
    setShowClientLocationActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "address":
        setAddress(value);
        break;
      case "areaField":
        setAreaField(value);
        setDistrictField(
          value
            ? districtOptions.find((item) => item.area_id === value.id)
            : null
        );
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "locationName":
        setLocationName(value);
        break;
      case "districtField":
        setDistrictField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!locationName) {
      addToErrorFields("locationName", t("請先填寫 地點名稱"));
      isError = true;
    }

    if (!areaField) {
      addToErrorFields("areaField", t("請先填寫 區域"));
      isError = true;
    }

    if (!districtField) {
      addToErrorFields("districtField", t("請先填寫 地區"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayClientLocationActionDialog(
      clientLocationId ? "EditClientLocation" : "CreateClientLocation"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayClientLocationActionDialog = (clientLocationActionType) => {
    // Set States
    setClientLocationActionDialogType(clientLocationActionType);

    switch (clientLocationActionType) {
      case "CreateClientLocation":
        setClientLocationActionDialogText(t("確認要新增 地點 嗎？"));
        break;
      case "EditClientLocation":
        setClientLocationActionDialogText(t("確認要編輯 地點 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowClientLocationActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createClientLocation = async () => {
    const results = await createClientLocationFetch(
      token,
      clientId,
      districtField ? districtField.id : null,
      locationName,
      address,
      isItemActive
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 地點 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editClientLocation = async () => {
    const results = await editClientLocationFetch(
      token,
      clientLocationId,
      districtField ? districtField.id : null,
      locationName,
      address,
      isItemActive
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 地點 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getClientLocationDetailFormOptions = async () => {
    const results = await getClientLocationDetailFormOptionsFetch(token);

    // Set States
    setAreaOptions(results.areas ? results.areas : []);
    setDistrictOptions(results.districts ? results.districts : []);
  };

  const getClientLocationDetailsById = async () => {
    const results = await getClientLocationDetailsByIdFetch(
      token,
      clientLocationId
    );

    if (results.clientLocationDetails) {
      const { location_name, district_id, area_id, address, is_active } =
        results.clientLocationDetails;

      // Set States
      setLocationName(location_name ? location_name : "");
      setAreaField(
        area_id ? areaOptions.find((item) => item.id === area_id) : null
      );
      setDistrictField(
        district_id
          ? districtOptions.find((item) => item.id === district_id)
          : null
      );
      setAddress(address ? address : "");
      setIsItemActive(is_active);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getClientLocationDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && areaOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [areaOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (clientLocationId) {
        getClientLocationDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, clientLocationId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onClientLocationActionDialogCanceled}
        onDialogConfirmed={onClientLocationActionDialogConfirmed}
        // States
        dialogText={clientLocationActionDialogText}
        showDialog={showClientLocationActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {clientLocationId ? t("編輯地點") : t("新增地點")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("locationName")}
              fullWidth
              helperText={getErrorFieldMessage("locationName")}
              label={t("地點名稱")}
              margin="dense"
              name="locationName"
              onChange={(event) =>
                onInputFieldChange("locationName", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={locationName}
              variant="standard"
            />
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            {areaOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("area_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("areaField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={areaOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("areaField")}
                    helperText={getErrorFieldMessage("areaField")}
                    label={t("區域")}
                    variant="standard"
                  />
                )}
                value={areaField}
              />
            )}
          </Grid>
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            {areaField && districtOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("district_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("districtField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={districtOptions.filter(
                  (item) => item.area_id === areaField.id
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("districtField")}
                    helperText={getErrorFieldMessage("districtField")}
                    label={t("地區")}
                    variant="standard"
                  />
                )}
                value={districtField}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={checkIsFieldError("address")}
              fullWidth
              helperText={getErrorFieldMessage("address")}
              label={t("地址")}
              margin="dense"
              name="address"
              onChange={(event) =>
                onInputFieldChange("address", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={address}
              variant="standard"
            />
          </Grid>
          <Grid className={classes.formSwitchContainer} item xs={12}>
            <Typography align={"left"} variant="body1">
              {t("有效")}
            </Typography>
            <Switch
              checked={isItemActive}
              onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
            />
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default ClientLocationActionModal;
