export const getPageTitleText = (titleType) => {
  let titleText;

  switch (titleType) {
    case "CategoriesAndBrands":
      titleText = "類別 / 品牌s";
      break;
    case "Clients":
      titleText = "客戶s";
      break;
    case "ClientProfile":
      titleText = "客戶概覧";
      break;
    case "CreateClient":
      titleText = "新增客戶";
      break;
    case "Jobs":
      titleText = "工作s";
      break;
    case "Merchandises":
      titleText = "商品s";
      break;
    case "MerchandiseSales":
      titleText = "商品銷量s";
      break;
    case "Orders":
      titleText = "訂單s";
      break;
    case "OrderProfile":
      titleText = "訂單概覧";
      break;
    case "Quotations":
      titleText = "報價s";
      break;
    case "QuotationProfile":
      titleText = "報價概覧";
      break;
    case "SalesReports":
      titleText = "銷售報告s";
      break;
    case "TermsAndCurrencies":
      titleText = "條款 / 貨幣s";
      break;
    default:
      titleText = "";
      break;
  }

  return titleText;
};
