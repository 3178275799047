// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

function MerchandiseQuotedActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showMerchandiseQuotedActionMenu,
    // Set States
    setShowMerchandiseQuotedActionMenu,
    // Functions
    displayMerchandiseQuotedActionDialog,
  } = props;

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowMerchandiseQuotedActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayMerchandiseQuotedActionDialog("DeleteMerchandiseQuoted");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showMerchandiseQuotedActionMenu}
      open={Boolean(showMerchandiseQuotedActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
        <DeleteRoundedIcon />
        {t("刪除")}
      </MenuItem>
    </StyledMenu>
  );
}

export default MerchandiseQuotedActionMenu;
