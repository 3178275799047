// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import MerchandiseQuotedBoxesContainer from "../components/boxes/quotationProfilePage/merchandiseQuotedBoxesContainer";
import MerchandiseQuotedSuggestionBoxesContainer from "../components/boxes/quotationProfilePage/merchandiseQuotedSuggestionBoxesContainer";
import QuotationIntroBoxesContainer from "../components/boxes/quotationProfilePage/quotationIntroBoxesContainer";
// Forms
import MerchandiseQuotedDetailFormContainer from "../components/forms/quotationProfilePage/merchandiseQuotedDetailFormContainer";
// Headers
import QuotationProfilePageHeader from "../components/headers/quotationProfilePage/quotationProfilePageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function QuotationProfilePage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.quotationProfilePage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("QuotationProfile"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <QuotationProfilePageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "BasicInfo" && <QuotationIntroBoxesContainer />}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "BasicInfo" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <MerchandiseQuotedBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <MerchandiseQuotedDetailFormContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
                <MerchandiseQuotedSuggestionBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default QuotationProfilePage;
