// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Charts
import SalesNumberChart from "./salesNumberChart";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Consts
import { merchandiseSalesNumberTypes } from "../../../consts/salesNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  getSalesNumbersForCategoriesFetch,
  getSalesNumbersForSubCategoriesByCategoryIdFetch,
} from "../../../fetches/merchandiseSalesNumberFetches";

// Helper Functions
import { getDateVarsForSalesNumbers } from "../../../helperFunctions/getDateVarForCharts";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { roundNumber } from "../../../helperFunctions/roundNumber";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SalesNumberChartContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const categoryId = useSelector(
    (state) => state.merchandiseSalesPage.conditionCategoryId
  );
  const comparisonRecordDate = useSelector(
    (state) => state.merchandiseSalesPage.comparisonRecordDate
  );
  const isComparisonShown = useSelector(
    (state) => state.merchandiseSalesPage.isComparisonShown
  );
  const language = useSelector((state) => state.language.language);
  const recordDate = useSelector(
    (state) => state.merchandiseSalesPage.recordDate
  );
  const salesNumberType = useSelector(
    (state) => state.merchandiseSalesPage.salesNumberType
  );
  const subCategoryId = useSelector(
    (state) => state.merchandiseSalesPage.conditionSubCategoryId
  );
  const token = useSelector((state) => state.staff.token);
  const unitId = useSelector(
    (state) => state.merchandiseSalesPage.conditionUnitId
  );

  // States
  // Data
  const [salesNumbers, setSalesNumbers] = useState(null);
  const [xAxisDataGridKey, setXAxisDataGridKey] = useState("");
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Handle States
  const comparisonRecordDateVar = new Date(comparisonRecordDate);
  const itemsArr = salesNumbers;
  const recordDateVar = new Date(recordDate);
  const salesNumberTypeItem = merchandiseSalesNumberTypes.find(
    (item) => item.type === salesNumberType
  );

  const comparisonRecordDateDisplay = moment(comparisonRecordDateVar).format(
    t("M 月")
  );
  const isMoney = salesNumberTypeItem ? salesNumberTypeItem.isMoney : false;
  const recordDateDisplay = moment(recordDateVar).format(t("M 月"));
  const salesNumberTypeItemName = salesNumberTypeItem
    ? salesNumberTypeItem[t("nameCh")]
    : "";

  // Functions
  // Functions - Queries
  const getSalesNumbersForCategories = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesNumbers(
      "Month",
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getSalesNumbersForCategoriesFetch(
      token,
      salesNumberType,
      unitId,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.categories) {
      for (let item of results.categories) {
        item.salesNumber = roundNumber(Number(item.salesNumber), 1);
        item.comparisonSalesNumber = item.comparisonSalesNumber
          ? roundNumber(Number(item.comparisonSalesNumber), 1)
          : 0;
      }
    }

    // Set States
    setSalesNumbers(results.categories ? results.categories : null);
    setXAxisDataGridKey(results.categories ? t("category_name_ch") : "");

    setIsLoading(false);
  };

  const getSalesNumbersForSubCategoriesByCategoryId = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesNumbers(
      "Month",
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getSalesNumbersForSubCategoriesByCategoryIdFetch(
      token,
      categoryId,
      salesNumberType,
      unitId,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.subCategories) {
      for (let item of results.subCategories) {
        item.salesNumber = roundNumber(Number(item.salesNumber), 1);
        item.comparisonSalesNumber = item.comparisonSalesNumber
          ? roundNumber(Number(item.comparisonSalesNumber), 1)
          : 0;
      }
    }

    // Set States
    setSalesNumbers(results.subCategories ? results.subCategories : null);
    setXAxisDataGridKey(results.subCategories ? t("sub_category_name_ch") : "");

    setIsLoading(false);
  };

  // Life Cycle
  useEffect(() => {
    if (unitId) {
      if (categoryId && categoryId === "dummy") {
        // Set States
        setIsLoading(true);

        getSalesNumbersForCategories();
      }
    }
  }, [
    salesNumberType,
    categoryId,
    unitId,
    recordDate,
    comparisonRecordDate,
    isComparisonShown,
    language,
  ]);

  useEffect(() => {
    if (unitId) {
      if (categoryId && categoryId !== "dummy") {
        // Set States
        setIsLoading(true);

        getSalesNumbersForSubCategoriesByCategoryId();
      }
    }
  }, [
    salesNumberType,
    categoryId,
    subCategoryId,
    unitId,
    recordDate,
    comparisonRecordDate,
    isComparisonShown,
    language,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("銷售數據")} - ${salesNumberTypeItemName}`}
          </Typography>
        </div>
      </div>
      {/* Content */}
      {itemsArr ? (
        <SalesNumberChart
          // States
          comparisonRecordDateDisplay={comparisonRecordDateDisplay}
          isComparisonShown={isComparisonShown}
          isMoney={isMoney}
          itemsArr={itemsArr}
          recordDateDisplay={recordDateDisplay}
          xAxisDataGridKey={xAxisDataGridKey}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有銷售數據")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SalesNumberChartContainer;
