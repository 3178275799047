// Components
// Boxes
import MerchandiseOrderedSuggestionBox from "./merchandiseOrderedSuggestionBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getMerchandiseOrderedSuggestionsWithPaginationFetch } from "../../../fetches/merchandiseOrderedFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { roundNumber } from "../../../helperFunctions/roundNumber";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNumSmall;

function MerchandiseOrderedSuggestionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const clientId = useSelector((state) => state.orderProfilePage.clientId);
  const formSubmitCount = useSelector(
    (state) => state.orderProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const merchandiseId = useSelector(
    (state) => state.orderProfilePage.selectedMerchandiseId
  );
  const orderId = useSelector((state) => state.orderProfilePage.orderId);
  const token = useSelector((state) => state.staff.token);
  const unitId = useSelector((state) => state.orderProfilePage.selectedUnitId);

  // States
  // Data
  const [merchandiseOrderedSuggestions, setMerchandiseOrderedSuggestions] =
    useState(null);
  const [unitDetails, setUnitDetails] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [typeOfSuggestions, setTypeOfSuggestions] = useState("AllClients");

  // Handle States
  const itemsArr = merchandiseOrderedSuggestions;

  // Events
  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onChangeTypeOfSuggestionsBtnClicked = () => {
    // Set States
    setTypeOfSuggestions(
      typeOfSuggestions === "AllClients" ? "SpecificClient" : "AllClients"
    );
  };

  // Functions
  // Functions - Queries
  const getMerchandiseOrderedSuggestionsWithPagination = async () => {
    const results = await getMerchandiseOrderedSuggestionsWithPaginationFetch(
      token,
      orderId,
      typeOfSuggestions === "SpecificClient" ? clientId : null,
      merchandiseId,
      unitId,
      pageNum,
      limitNum
    );

    if (results.merchandiseOrderedSuggestions) {
      const currencySymbol = results.currencyDetails
        ? results.currencyDetails.currency_symbol
        : null;

      for (let item of results.merchandiseOrderedSuggestions) {
        item.orderDateDisplay = item.orderDate
          ? moment(item.orderDate).format(t("M 月 D 日"))
          : null;

        item.actual_unit_price_display = item.actual_unit_price
          ? `${item.currency_symbol} ${separateComma(
              Number(item.actual_unit_price).toFixed(1)
            )}`
          : null;

        item.suggestedUnitPriceDisplay = item.suggestedUnitPrice
          ? `${currencySymbol} ${separateComma(
              roundNumber(Number(item.suggestedUnitPrice), 1)
            )}`
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setMerchandiseOrderedSuggestions(
      results.merchandiseOrderedSuggestions
        ? results.merchandiseOrderedSuggestions
        : null
    );
    setUnitDetails(results.unitDetails ? results.unitDetails : null);

    if (results.pageCount && !results.merchandiseOrderedSuggestions) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle

  useEffect(() => {
    getMerchandiseOrderedSuggestionsWithPagination();
  }, [
    clientId,
    orderId,
    merchandiseId,
    unitId,
    typeOfSuggestions,
    pageNum,
    formSubmitCount,
    language,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("相關訂單s")}
          </Typography>
        </div>
        <Button
          endIcon={<ChevronRightRoundedIcon />}
          onClick={onChangeTypeOfSuggestionsBtnClicked}
          variant="text"
        >
          {typeOfSuggestions === "AllClients"
            ? t("只顯示此客戶訂單")
            : t("顯示所有客戶訂單")}
        </Button>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <MerchandiseOrderedSuggestionBox
              key={item.id}
              // States
              item={item}
              language={language}
              unitDetails={unitDetails}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有相關訂單資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseOrderedSuggestionBoxesContainer;
