const initialState = {
  clientId: null,
  formSubmitCount: 0,
  tabItem: "BasicInfo",
  typeOfClientLocations: "Current",
  typeOfContactPersons: "Current",
};

export const clientProfilePageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_CLIENT_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_CLIENT_PROFILE_PAGE_CLIENT_CHANGE":
      const { clientId } = action;
      return {
        ...state,
        clientId,
      };
    case "ON_CLIENT_PROFILE_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_CLIENT_PROFILE_PAGE_TYOE_OF_CLIENT_LOCATIONS_CHANGE":
      const { typeOfClientLocations } = action;
      return {
        ...state,
        typeOfClientLocations,
      };
    case "ON_CLIENT_PROFILE_PAGE_TYOE_OF_CONTACT_PERSONS_CHANGE":
      const { typeOfContactPersons } = action;
      return {
        ...state,
        typeOfContactPersons,
      };
    default:
      return state;
  }
};
