// Components
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ClientLocationBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDeleteDynamicFieldBtnClicked,
    onDynamicInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsDynamicFieldError,
    getDynamicErrorFieldMessage,
    // Options
    areaOptions,
    districtOptions,
    // Render
    shouldShowSpacing,
    // States
    index,
    item,
  } = props;

  // Handle States
  const itemId = item.id;

  return (
    <>
      {shouldShowSpacing && (
        <SpacingBox
          // Render
          height={stylesConfig.spacingBoxMarginBottom}
        />
      )}
      <div className={classes.contentBox}>
        {/* Title */}
        <div className={classes.titleContainer}>
          <div className={classes.titleTextContainer}>
            <Typography variant="h6" align={"left"}>
              {t("地點")} {index + 1}
            </Typography>
          </div>
          <div className={classes.titleTextContainer}>
            {checkIsDynamicFieldError(
              "clientLocation",
              itemId,
              "locationName"
            ) ? (
              <Button
                endIcon={<DeleteRoundedIcon />}
                onClick={() =>
                  onDeleteDynamicFieldBtnClicked("clientLocation", itemId)
                }
                sx={{ color: stylesConfig.redTextColor }}
                variant="text"
              >
                {t("如不需填寫地點，請按此刪除")}
              </Button>
            ) : (
              <Tooltip placement="right" title={t("刪除地點")}>
                <IconButton
                  onClick={() =>
                    onDeleteDynamicFieldBtnClicked("clientLocation", itemId)
                  }
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {/* Form */}
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "clientLocation",
                itemId,
                "locationName"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "clientLocation",
                itemId,
                "locationName"
              )}
              label={t("地點名稱")}
              margin="dense"
              name="locationName"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "clientLocation",
                  itemId,
                  "locationName",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.locationName}
              variant="standard"
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className={classes.formAutoCompleteContainer}
          >
            {areaOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("area_name_ch")]}
                onChange={(event, value) =>
                  onDynamicInputFieldChange(
                    "clientLocation",
                    itemId,
                    "areaField",
                    value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={areaOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsDynamicFieldError(
                      "clientLocation",
                      itemId,
                      "areaField"
                    )}
                    helperText={getDynamicErrorFieldMessage(
                      "clientLocation",
                      itemId,
                      "areaField"
                    )}
                    label={t("區域")}
                    variant="standard"
                  />
                )}
                value={item.areaField}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className={classes.formAutoCompleteContainer}
          >
            {item.areaField && districtOptions ? (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("district_name_ch")]}
                onChange={(event, value) =>
                  onDynamicInputFieldChange(
                    "clientLocation",
                    itemId,
                    "districtField",
                    value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={districtOptions.filter(
                  (option) => option.area_id === item.areaField.id
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsDynamicFieldError(
                      "clientLocation",
                      itemId,
                      "districtField"
                    )}
                    helperText={getDynamicErrorFieldMessage(
                      "clientLocation",
                      itemId,
                      "districtField"
                    )}
                    label={t("地區")}
                    variant="standard"
                  />
                )}
                value={item.districtField}
              />
            ) : (
              <Autocomplete
                disabled
                disablePortal
                options={[]}
                renderInput={(params) => (
                  <TextField {...params} label={t("地區")} variant="standard" />
                )}
                value={null}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              error={checkIsDynamicFieldError(
                "clientLocation",
                itemId,
                "address"
              )}
              fullWidth
              helperText={getDynamicErrorFieldMessage(
                "clientLocation",
                itemId,
                "address"
              )}
              label={t("地址")}
              margin="dense"
              name="address"
              onChange={(event) =>
                onDynamicInputFieldChange(
                  "clientLocation",
                  itemId,
                  "address",
                  event.target.value
                )
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={item.address}
              variant="standard"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ClientLocationBox;
