// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/termsAndCurrenciesPage/termsAndCurrenciesPageActions";

// Components
// Boxes
import TermContentBox from "./termContentBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import TermContentActionMenu from "../../menus/termsAndCurrenciesPage/termContentActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import SearchPlaceholderModal from "../../modals/search/searchPlaceholderModal";
import TermContentActionModal from "../../modals/termsAndCurrenciesPage/termContentActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteTermContentFetch,
  editTermContentIndexesFetch,
  getAllTermContentsByTermTemplateIdFetch,
} from "../../../fetches/termContentFetches";

// Helper Functions
import { copyToClipboard } from "../../../helperFunctions/clipboard";

// Immutability
import update from "immutability-helper";

// Material UI
// Components
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// React
import { useCallback, useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function TermContentBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.termsAndCurrenciesPage.formSubmitCount
  );
  const termTemplateId = useSelector(
    (state) => state.termsAndCurrenciesPage.termTemplateId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [termContents, setTermContents] = useState(null);
  // Dialog
  const [termContentActionDialogText, setTermContentActionDialogText] =
    useState("");
  const [termContentActionDialogType, setTermContentActionDialogType] =
    useState(null);
  const [showTermContentActionDialog, setShowTermContentActionDialog] =
    useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showTermContentActionMenu, setShowTermContentActionMenu] =
    useState(null);
  // Modals
  const [showSearchPlaceholderModal, setShowSearchPlaceholderModal] =
    useState(false);
  const [showTermContentActionModal, setShowTermContentActionModal] =
    useState(false);
  // Render
  const [createItemPosition, setCreateItemPosition] = useState("Before");

  // Handle States
  const itemsArr = termContents;

  // Events
  // Events - Box
  const onItemClicked = (itemId, itemIndex) => {
    // Set States
    setCurrentItemId(itemId);
    setCurrentItemIndex(itemIndex);

    displayTermContentActionModal();
  };

  const onItemRightClicked = (itemId, itemIndex, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setCurrentItemIndex(itemIndex);
    setShowTermContentActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onTermContentActionDialogCanceled = () => {
    // Set States
    setShowTermContentActionDialog(false);
  };

  const onTermContentActionDialogConfirmed = () => {
    switch (termContentActionDialogType) {
      case "DeleteTermContent":
        deleteTermContent();
        break;
      default:
        break;
    }

    // Set States
    setShowTermContentActionDialog(false);
  };

  // Events - Forms
  const onCreateNewTermContentBtnClicked = () => {
    // Set States
    setCreateItemPosition("After");
    setCurrentItemId(null);
    setCurrentItemIndex(0);

    displayTermContentActionModal();
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onSearchPlaceholderModalClosed = () => {
    // Set States
    setShowSearchPlaceholderModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  const onSearchPlaceholderModalItemClicked = (placeholderSymbol) => {
    copyToClipboard(placeholderSymbol);

    onSearchPlaceholderModalClosed();
  };

  const onTermContentActionModalClosed = () => {
    // Set States
    setShowTermContentActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Functions
  // Functions - Normal
  const displayTermContentActionDialog = (termContentActionType) => {
    // Set States
    setTermContentActionDialogType(termContentActionType);

    switch (termContentActionType) {
      case "DeleteTermContent":
        setTermContentActionDialogText(t("確認要刪除 條款內容 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowTermContentActionDialog(true);
  };

  const displaySearchPlaceholderModal = () => {
    setShowSearchPlaceholderModal(true);
  };

  const displayTermContentActionModal = () => {
    setShowTermContentActionModal(true);
  };

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    // Set States
    setTermContents((currentState) =>
      update(currentState, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, currentState[dragIndex]],
        ],
      })
    );
  }, []);

  // Functions - Mutations
  const deleteTermContent = async () => {
    const results = await deleteTermContentFetch(token, currentItemId);

    if (results.success) {
      getAllTermContentsByTermTemplateId();
    }
  };

  const editTermContentIndexes = async () => {
    const editedTermContents = itemsArr.map((item, index) => {
      return { id: item.id, index: index + 1 };
    });

    await editTermContentIndexesFetch(token, editedTermContents);
  };

  // Functions - Queries
  const getAllTermContentsByTermTemplateId = async () => {
    const results = await getAllTermContentsByTermTemplateIdFetch(
      token,
      termTemplateId
    );

    // Set States
    setTermContents(results.termContents ? results.termContents : null);
  };

  // Life Cycle
  useEffect(() => {
    if (termTemplateId) {
      getAllTermContentsByTermTemplateId();
    }
  }, [termTemplateId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onTermContentActionDialogCanceled}
        onDialogConfirmed={onTermContentActionDialogConfirmed}
        // States
        dialogText={termContentActionDialogText}
        showDialog={showTermContentActionDialog}
      />
      {/* Menu */}
      <TermContentActionMenu
        // States
        showTermContentActionMenu={showTermContentActionMenu}
        // Set States
        setCreateItemPosition={setCreateItemPosition}
        setCurrentItemId={setCurrentItemId}
        setShowTermContentActionMenu={setShowTermContentActionMenu}
        // Functions
        displayTermContentActionDialog={displayTermContentActionDialog}
        displayTermContentActionModal={displayTermContentActionModal}
      />
      {/* Modals */}
      <ModalContainer
        // Events
        onModalClosed={onSearchPlaceholderModalClosed}
        // States
        showModal={showSearchPlaceholderModal}
      >
        <SearchPlaceholderModal
          // Events
          onItemClicked={onSearchPlaceholderModalItemClicked}
          // States
          placeholderType={"Term Content"}
        />
      </ModalContainer>
      <ModalContainer
        // Events
        onModalClosed={onTermContentActionModalClosed}
        // States
        showModal={showTermContentActionModal}
      >
        <TermContentActionModal
          // Events
          onModalClosed={onTermContentActionModalClosed}
          // Functions
          displaySearchPlaceholderModal={displaySearchPlaceholderModal}
          // States
          index={currentItemIndex}
          position={createItemPosition}
          termContentId={currentItemId}
        />
      </ModalContainer>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item, index) => (
          <TermContentBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            index={index}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
            // Functions
            editTermContentIndexes={editTermContentIndexes}
            moveItem={moveItem}
          />
        ))
      ) : (
        <>
          <div className={classes.emptyDataContainer}>
            <Typography align={"center"} color={stylesConfig.greyTextColor}>
              {t("未有條款內容")}
            </Typography>
          </div>
          <div className={classes.containerSubmitBtnBox}>
            <Button
              variant="contained"
              onClick={onCreateNewTermContentBtnClicked}
              sx={formSubmitBtnStyles}
            >
              {t("新增條款內容")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default TermContentBoxesContainer;
