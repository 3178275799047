// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import OverallConditionBoxesContainer from "../components/boxes/salesReportsPage/overallConditionBoxesContainer";
import RankedClientBoxesContainer from "../components/boxes/salesReportsPage/rankedClientBoxesContainer";
import RankedSalespersonBoxesContainer from "../components/boxes/salesReportsPage/rankedSalespersonBoxesContainer";
import RankingsConditionBoxesContainer from "../components/boxes/salesReportsPage/rankingsConditionBoxesContainer";
import RevenueInfoBoxesContainer from "../components/boxes/salesReportsPage/revenueInfoBoxesContainer";
// Charts
import ClientSalesTrendChartContainer from "../components/charts/salesReportsPage/clientSalesTrendChartContainer";
import OverallSalesTrendChartContainer from "../components/charts/salesReportsPage/overallSalesTrendChartContainer";
import SalespersonSalesTrendChartContainer from "../components/charts/salesReportsPage/salespersonSalesTrendChartContainer";

// Headers
import SalesReportsPageHeader from "../components/headers/salesReportsPage/salesReportsPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function SalesReportsPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.salesReportsPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("SalesReports"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <SalesReportsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "OverallNumbers" && (
            <>
              <OverallConditionBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
              <RevenueInfoBoxesContainer />
            </>
          )}
          {["SalespersonRankings", "ClientRankings"].includes(tabItem) && (
            <RankingsConditionBoxesContainer />
          )}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "OverallNumbers" && <OverallSalesTrendChartContainer />}
          {["SalespersonRankings", "ClientRankings"].includes(tabItem) && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  {tabItem === "OverallNumbers" && (
                    <OverallSalesTrendChartContainer />
                  )}
                  {tabItem === "SalespersonRankings" && (
                    <RankedSalespersonBoxesContainer />
                  )}
                  {tabItem === "ClientRankings" && (
                    <RankedClientBoxesContainer />
                  )}
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  {tabItem === "OverallNumbers" && <></>}
                  {tabItem === "SalespersonRankings" && (
                    <SalespersonSalesTrendChartContainer />
                  )}
                  {tabItem === "ClientRankings" && (
                    <ClientSalesTrendChartContainer />
                  )}
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default SalesReportsPage;
