// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function DepositSettingActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    selectedDepositSetting,
    showDepositSettingActionMenu,
    // Set States
    setSelectedDepositSetting,
    setShowDepositSettingActionMenu,
    setDepositSettingActionDialogType,
    // Functions
    displayDepositSettingActionDialog,
  } = props;

  // Redux Store
  const typeOfTerms = useSelector(
    (state) => state.termsAndCurrenciesPage.typeOfTerms
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowDepositSettingActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      // 刪除
      case "Delete":
        // Set States
        setDepositSettingActionDialogType("DeleteDepositSetting");
        setSelectedDepositSetting(selectedDepositSetting);

        displayDepositSettingActionDialog("DeleteDepositSetting");
        break;
      // 設為一般選項
      case "RemoveDefault":
        // Set States
        setDepositSettingActionDialogType("RemoveDepositSettingDefault");
        setSelectedDepositSetting(selectedDepositSetting);

        displayDepositSettingActionDialog("RemoveDepositSettingDefault");
        break;
      // 還原
      case "Restore":
        // Set States
        setDepositSettingActionDialogType("RestoreDepositSetting");
        setSelectedDepositSetting(selectedDepositSetting);

        displayDepositSettingActionDialog("RestoreDepositSetting");
        break;
      // 設為預設選項
      case "SetDefault":
        // Set States
        setDepositSettingActionDialogType("SetDepositSettingDefault");
        setSelectedDepositSetting(selectedDepositSetting);

        displayDepositSettingActionDialog("SetDepositSettingDefault");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showDepositSettingActionMenu}
      open={Boolean(showDepositSettingActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {typeOfTerms === "Current" &&
        selectedDepositSetting &&
        (selectedDepositSetting.is_default ? (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("RemoveDefault")}
          >
            <ReplayRoundedIcon />
            {t("設為一般選項")}
          </MenuItem>
        ) : (
          <MenuItem
            disableRipple
            onClick={() => onMenuItemClicked("SetDefault")}
          >
            <PushPinRoundedIcon />
            {t("設為預設選項")}
          </MenuItem>
        ))}
      {typeOfTerms === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default DepositSettingActionMenu;
