const initialState = {
  conditionBrandId: null,
  conditionBrandItem: null,
  conditionCategoryId: null,
  conditionCategoryItem: null,
  conditionSubCategoryId: null,
  conditionSubCategoryItem: null,
  formSubmitCount: 0,
  keyword: "",
  merchandiseId: null,
  tabItem: "MerchandiseList",
  typeOfContent: "MerchandiseDescriptions",
  typeOfMerchandises: "Current",
  typeOfUnits: "Current",
  unitId: null,
  unitTypeId: null,
  unitTypeItem: null,
};

export const merchandisesPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_MERCHANDISES_PAGE_CONDITION_BRAND_CHANGE":
      const { conditionBrandId } = action;
      return {
        ...state,
        conditionBrandId,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_BRAND_ITEM_CHANGE":
      const { conditionBrandItem } = action;
      return {
        ...state,
        conditionBrandItem,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_CATEGORY_CHANGE":
      const { conditionCategoryId } = action;
      return {
        ...state,
        conditionCategoryId,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_CATEGORY_ITEM_CHANGE":
      const { conditionCategoryItem } = action;
      return {
        ...state,
        conditionCategoryItem,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_SUB_CATEGORY_CHANGE":
      const { conditionSubCategoryId } = action;
      return {
        ...state,
        conditionSubCategoryId,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_SUB_CATEGORY_ITEM_CHANGE":
      const { conditionSubCategoryItem } = action;
      return {
        ...state,
        conditionSubCategoryItem,
      };
    case "ON_MERCHANDISES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_MERCHANDISES_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_MERCHANDISES_PAGE_MERCHANDISE_CHANGE":
      const { merchandiseId } = action;
      return {
        ...state,
        merchandiseId,
      };
    case "ON_MERCHANDISES_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_MERCHANDISES_PAGE_TYPE_OF_CONTENT_CHANGE":
      const { typeOfContent } = action;
      return {
        ...state,
        typeOfContent,
      };
    case "ON_MERCHANDISES_PAGE_TYPE_OF_MERCHANDISES_CHANGE":
      const { typeOfMerchandises } = action;
      return {
        ...state,
        typeOfMerchandises,
      };
    case "ON_MERCHANDISES_PAGE_TYPE_OF_UNITS_CHANGE":
      const { typeOfUnits } = action;
      return {
        ...state,
        typeOfUnits,
      };
    case "ON_MERCHANDISES_PAGE_UNIT_CHANGE":
      const { unitId } = action;
      return {
        ...state,
        unitId,
      };
    case "ON_MERCHANDISES_PAGE_UNIT_TYPE_CHANGE":
      const { unitTypeId } = action;
      return {
        ...state,
        unitTypeId,
      };
    case "ON_MERCHANDISES_PAGE_UNIT_TYPE_ITEM_CHANGE":
      const { unitTypeItem } = action;
      return {
        ...state,
        unitTypeItem,
      };
    default:
      return state;
  }
};
