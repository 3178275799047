export const getPagePath = (titleType) => {
  let path;

  switch (titleType) {
    case "CategoriesAndBrands":
      path = "/categoriesAndBrands";
      break;
    case "Clients":
      path = "/clients";
      break;
    case "ClientProfile":
      path = "/clientProfile";
      break;
    case "CreateClient":
      path = "/createClient";
      break;
    case "Jobs":
      path = "/jobs";
      break;
    case "Merchandises":
      path = "/merchandises";
      break;
    case "MerchandiseSales":
      path = "/merchandiseSales";
      break;
    case "Orders":
      path = "/orders";
      break;
    case "OrderProfile":
      path = "/orderProfile";
      break;
    case "Quotations":
      path = "/quotations";
      break;
    case "QuotationProfile":
      path = "/quotationProfile";
      break;
    case "SalesReports":
      path = "/salesReports";
      break;
    case "TermsAndCurrencies":
      path = "/termsAndCurrencies";
      break;
    default:
      path = "/";
      break;
  }

  return path;
};
