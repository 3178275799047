export const merchandiseSalesNumberTypes = [
  { type: "Revenue", nameCh: "營業額", nameEn: "Revenue", isMoney: true },
  {
    type: "SalesVolume",
    nameCh: "銷量",
    nameEn: "Sales Volume",
    isMoney: false,
  },
];

export const overallSalesNumberTypes = [
  { type: "Revenue", nameCh: "營業額", nameEn: "Revenue", isMoney: true },
  {
    type: "NumOfOrders",
    nameCh: "訂單數量",
    nameEn: "Num of Orders",
    isMoney: false,
  },
  {
    type: "SalesVolume",
    nameCh: "銷量",
    nameEn: "Sales Volume",
    isMoney: false,
  },
  {
    type: "NumOfClients",
    nameCh: "客戶數量",
    nameEn: "Num of Clients",
    isMoney: false,
  },
];

export const rankingsSalesNumberTypes = [
  { type: "Revenue", nameCh: "營業額", nameEn: "Revenue", isMoney: true },
  {
    type: "NumOfOrders",
    nameCh: "訂單數量",
    nameEn: "Num of Orders",
    isMoney: false,
  },
];
