// Actions
import { staffLogin } from "../redux/shared/staff/staffActions";

// Components
// Boxes
import LoadingBox from "../components/boxes/loginPage/loadingBox";
// Forms
import LoginForm from "../components/forms/loginPage/loginForm";

// Configs
import keyConfig from "../configs/keyConfig";

// Fetches
import { autoLoginFetch } from "../fetches/staffFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useLoginPageStyles } from "../styles/pageStyles/loginPageStyles";

function LoginPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useLoginPageStyles();

  // States
  // Render
  const [isLoading, setIsLoading] = useState(true);

  // Funtions
  // Functions _ Normal
  const getStaffFromStorage = () => {
    // Local Storage
    const token = localStorage.getItem(keyConfig.tokenKey);

    if (token) {
      autoLogin(token);
    }

    // Set States
    setIsLoading(false);
  };

  const updateStaffInfo = (staff, token) => {
    // Update Redux Store
    dispatch(
      staffLogin(
        token,
        staff.alias,
        staff.dept_name_display,
        staff.full_name_en,
        staff.id
      )
    );
  };

  // Funtions - Queries
  const autoLogin = async (token) => {
    const results = await autoLoginFetch(token);

    if (results.staff) {
      const { staff } = results;

      updateStaffInfo(staff, token);

      // Navigate
      navigate("/salesReports");
    } else if (results.isError) {
      // Local Storage
      localStorage.removeItem(keyConfig.tokenKey);
    }
  };

  // Life Cycle
  useEffect(() => {
    getStaffFromStorage();
  }, []);

  return (
    <div className={classes.loginContainer}>
      {isLoading ? <LoadingBox /> : <LoginForm />}
    </div>
  );
}

export default LoginPage;
