// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function MerchandiseQuotedSuggestionBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
    language,
    unitDetails,
  } = props;

  return (
    <div className={classes.itemContainer}>
      <div className={classes.itemContentSubContainerLarge}>
        <Typography align={"left"} variant="body1">
          {item.client_code}
        </Typography>
        <Typography
          align={"left"}
          color={stylesConfig.greyTextColor}
          variant="body2"
        >
          {item.requestDateDisplay}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainer}>
        <Typography align={"left"} variant="body1">
          {`${item.quantity} ${item[t("unit_name_ch")]}`}
        </Typography>
        <Typography
          align={"left"}
          color={stylesConfig.greyTextColor}
          variant="body2"
        >
          {item.actual_unit_price_display
            ? language === "zh-hk"
              ? `每${item[t("unit_name_ch")]} - ${
                  item.actual_unit_price_display
                }`
              : `${item.actual_unit_price_display} per ${
                  item[t("unit_name_ch")]
                }`
            : t("未有單價")}
        </Typography>
      </div>
      {unitDetails && (
        <div className={classes.itemContentSubContainerRight}>
          <Typography align={"left"} variant="body1">
            {t("建議")}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item.suggestedUnitPriceDisplay
              ? language === "zh-hk"
                ? `每${unitDetails[t("unit_name_ch")]} - ${
                    item.suggestedUnitPriceDisplay
                  }`
                : `${item.suggestedUnitPriceDisplay} per ${
                    unitDetails[t("unit_name_ch")]
                  }`
              : t("未有建議")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseQuotedSuggestionBox;
