// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/clientProfilePage/clientProfilePageActions";

// Components
// Boxes
import ClientLocationBox from "./clientLocationBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import ClientLocationActionMenu from "../../menus/clientProfilePage/clientLocationActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import ClientLocationActionModal from "../../modals/clientProfilePage/clientLocationActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreClientLocationFetch,
  getAllClientLocationsByClientIdFetch,
} from "../../../fetches/clientLocationFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ClientLocationBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const clientId = useSelector((state) => state.clientProfilePage.clientId);
  const formSubmitCount = useSelector(
    (state) => state.clientProfilePage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfClientLocations = useSelector(
    (state) => state.clientProfilePage.typeOfClientLocations
  );

  // States
  // Data
  const [clientLocations, setClientLocations] = useState(null);
  // Dialog
  const [clientLocationActionDialogText, setClientLocationActionDialogText] =
    useState("");
  const [clientLocationActionDialogType, setClientLocationActionDialogType] =
    useState(null);
  const [showClientLocationActionDialog, setShowClientLocationActionDialog] =
    useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showClientLocationActionMenu, setShowClientLocationActionMenu] =
    useState(null);
  // Modals
  const [showClientLocationActionModal, setShowClientLocationActionModal] =
    useState(false);

  // Handle States
  const itemsArr = clientLocations;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayClientLocationActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowClientLocationActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowClientLocationActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onClientLocationActionDialogCanceled = () => {
    // Set States
    setShowClientLocationActionDialog(false);
  };

  const onClientLocationActionDialogConfirmed = () => {
    switch (clientLocationActionDialogType) {
      case "DeleteClientLocation":
        deleteOrRestoreClientLocation();
        break;
      case "RestoreClientLocation":
        deleteOrRestoreClientLocation();
        break;
      default:
        break;
    }

    // Set States
    setShowClientLocationActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onClientLocationActionModalClosed = () => {
    // Set States
    setShowClientLocationActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayClientLocationActionModal();
  };

  // Functions
  // Functions - Normal
  const displayClientLocationActionDialog = (clientLocationActionType) => {
    // Set States
    setClientLocationActionDialogType(clientLocationActionType);

    switch (clientLocationActionType) {
      case "DeleteClientLocation":
        setClientLocationActionDialogText(t("確認要刪除 地點 嗎？"));
        break;
      case "RestoreClientLocation":
        setClientLocationActionDialogText(t("確認要還原 地點 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowClientLocationActionDialog(true);
  };

  const displayClientLocationActionModal = () => {
    setShowClientLocationActionModal(true);
  };

  // Functions - Mutations
  const deleteOrRestoreClientLocation = async () => {
    const results = await deleteOrRestoreClientLocationFetch(
      token,
      currentItemId,
      typeOfClientLocations
    );

    if (results.success) {
      getAllClientLocationsByClientId();
    }
  };

  // Functions - Queries
  const getAllClientLocationsByClientId = async () => {
    const results = await getAllClientLocationsByClientIdFetch(
      token,
      clientId,
      typeOfClientLocations
    );

    // Set States
    setClientLocations(
      results.clientLocations ? results.clientLocations : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (clientId) {
      getAllClientLocationsByClientId();
    }
  }, [clientId, formSubmitCount, typeOfClientLocations]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onClientLocationActionDialogCanceled}
        onDialogConfirmed={onClientLocationActionDialogConfirmed}
        // States
        dialogText={clientLocationActionDialogText}
        showDialog={showClientLocationActionDialog}
      />
      {/* Menu */}
      <ClientLocationActionMenu
        // States
        showClientLocationActionMenu={showClientLocationActionMenu}
        // Set States
        setShowClientLocationActionMenu={setShowClientLocationActionMenu}
        // Functions
        displayClientLocationActionDialog={displayClientLocationActionDialog}
        displayClientLocationActionModal={displayClientLocationActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onClientLocationActionModalClosed}
        // States
        showModal={showClientLocationActionModal}
      >
        <ClientLocationActionModal
          // Events
          onModalClosed={onClientLocationActionModalClosed}
          // States
          clientLocationId={currentItemId}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("地點s")}
          </Typography>
          <Tooltip placement="right" title={t("新增地點")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <ClientLocationBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有地點資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ClientLocationBoxesContainer;
