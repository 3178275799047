// Actions
import {
  onConditionQuotationStatusTypeChange,
  onConditionQuotationStatusTypeItemChange,
  onKeywordChange,
} from "../../../redux/pages/quotationsPage/quotationsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { quotationStatusTypeDummyData } from "../../../dummyDatum/quotationStatusTypeDummyData";

// Fetches
import { getAllQuotationStatusTypesFetch } from "../../../fetches/quotationStatusTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function QuotationConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const keyword = useSelector((state) => state.quotationsPage.keyword);
  const quotationStatusTypeId = useSelector(
    (state) => state.quotationsPage.conditionQuotationStatusTypeId
  );
  const quotationStatusTypeItem = useSelector(
    (state) => state.quotationsPage.conditionQuotationStatusTypeItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [quotationStatusTypes, setQuotationStatusTypes] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "quotationStatusType":
        if (value) {
          dispatch(onConditionQuotationStatusTypeChange(value.id));
        }
        break;
      case "keyword":
        dispatch(onKeywordChange(value));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Normal
  const getAllConditions = () => {
    getAllQuotationStatusTypes();
  };

  // Functions - Queries
  const getAllQuotationStatusTypes = async () => {
    const results = await getAllQuotationStatusTypesFetch(token);

    // Set States
    setQuotationStatusTypes(
      results.quotationStatusTypes
        ? [quotationStatusTypeDummyData, ...results.quotationStatusTypes]
        : null
    );

    // Update Redux Store
    if (results.quotationStatusTypes && !quotationStatusTypeId) {
      dispatch(
        onConditionQuotationStatusTypeChange(quotationStatusTypeDummyData.id)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllConditions();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (quotationStatusTypes && quotationStatusTypeId) {
      dispatch(
        onConditionQuotationStatusTypeItemChange(
          quotationStatusTypes.find((item) => item.id === quotationStatusTypeId)
        )
      );
    }
  }, [quotationStatusTypes, quotationStatusTypeId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("搜尋報價")}
          </Typography>
        </div>
      </div>
      {/* Text Field */}
      <div className={classes.autoCompleteContainer}>
        <TextField
          autoFocus
          fullWidth
          label={t("關鍵字")}
          margin="dense"
          name="keyword"
          onChange={(event) =>
            onInputFieldChange("keyword", event.target.value)
          }
          required
          value={keyword}
          variant="standard"
        />
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("報價狀態")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {quotationStatusTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) =>
              option[t("quotation_status_type_name_ch")]
            }
            onChange={(event, value) =>
              onInputFieldChange("quotationStatusType", value)
            }
            options={quotationStatusTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("報價狀態")} variant="outlined" />
            )}
            value={quotationStatusTypeItem}
          />
        </div>
      )}
    </div>
  );
}

export default QuotationConditionBoxesContainer;
