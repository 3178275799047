// Components
// Boxes
import ContactPersonBox from "./contactPersonBox";
// Spacing Boxes
import SpacingBox from "../spacing/spacingBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ContactPersonBoxesContainer(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onAddDynamicFieldBtnClicked,
    onDeleteDynamicFieldBtnClicked,
    onDynamicInputFieldChange,
    onInputFieldKeyPressed,
    onNextStepBtnClicked,
    onStepBackBtnClicked,
    // Functions
    checkIsDynamicFieldError,
    getDynamicErrorFieldMessage,
    // Options
    personTitleOptions,
    // States
    formAlertText,
    formAlertType,
    contactPersons,
    shouldShowFormAlert,
  } = props;

  // Handle States
  const itemsArr = contactPersons;

  return (
    <>
      {/* List */}
      {itemsArr &&
        itemsArr[0] &&
        itemsArr.map((item, index) => (
          <ContactPersonBox
            key={item.id}
            // Events
            onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
            onDynamicInputFieldChange={onDynamicInputFieldChange}
            onInputFieldKeyPressed={onInputFieldKeyPressed}
            // Functions
            checkIsDynamicFieldError={checkIsDynamicFieldError}
            getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
            // Options
            personTitleOptions={personTitleOptions}
            // Render
            shouldShowSpacing={index !== 0}
            // States
            index={index}
            item={item}
          />
        ))}
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <div className={classes.formSubmitBtnContainer}>
        <Button
          onClick={() => onAddDynamicFieldBtnClicked("contactPerson")}
          variant="contained"
          sx={formSubmitBtnStyles}
        >
          {t("新增聯絡人")}
        </Button>
      </div>
      <SpacingBox
        // Render
        height={stylesConfig.spacingBoxMarginBottom}
      />
      <div className={classes.formSubmitBtnContainer}>
        <Button
          onClick={onNextStepBtnClicked}
          variant="contained"
          sx={formSubmitBtnStyles}
        >
          {t("下一步")}
        </Button>
      </div>
      <div className={classes.formSubmitBtnContainer}>
        <Button onClick={onStepBackBtnClicked} sx={formSubmitBtnStyles}>
          {t("上一步")}
        </Button>
      </div>
      {/* Alert */}
      {shouldShowFormAlert && (
        <>
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
          <Alert severity={formAlertType}>{formAlertText}</Alert>
        </>
      )}
    </>
  );
}

export default ContactPersonBoxesContainer;
