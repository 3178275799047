// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import ClientBoxesContainer from "../components/boxes/clientsPage/clientBoxesContainer";
import ClientCreditSettingBoxesContainer from "../components/boxes/clientsPage/clientCreditSettingBoxesContainer";
import ClientSearchBoxesContainer from "../components/boxes/clientsPage/clientSearchBoxesContainer";
import ClientSettingConditionBoxesContainer from "../components/boxes/clientsPage/clientSettingConditionBoxesContainer";
import DepositSettingBoxesContainer from "../components/boxes/clientsPage/depositSettingBoxesContainer";
import PaymentTermSettingBoxesContainer from "../components/boxes/clientsPage/paymentTermSettingBoxesContainer";
// Forms
import ClientCreditSettingForm from "../components/forms/clientsPage/clientCreditSettingForm";
import DepositSettingForm from "../components/forms/clientsPage/depositSettingForm";
import PaymentTermSettingForm from "../components/forms/clientsPage/paymentTermSettingForm";
// Headers
import ClientsPageHeader from "../components/headers/clientsPage/clientsPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function ClientsPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const clientSettingType = useSelector(
    (state) => state.clientsPage.clientSettingType
  );
  const tabItem = useSelector((state) => state.clientsPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Clients"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <ClientsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "Clients" && <ClientSearchBoxesContainer />}
          {tabItem === "ClientSettings" && (
            <ClientSettingConditionBoxesContainer />
          )}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "Clients" && <ClientBoxesContainer />}
          {tabItem === "ClientSettings" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                {clientSettingType === "Client Credit" && (
                  <ClientCreditSettingBoxesContainer />
                )}
                {clientSettingType === "Payment Term" && (
                  <PaymentTermSettingBoxesContainer />
                )}
                {clientSettingType === "Deposit" && (
                  <DepositSettingBoxesContainer />
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                {clientSettingType === "Client Credit" && (
                  <ClientCreditSettingForm />
                )}
                {clientSettingType === "Payment Term" && (
                  <PaymentTermSettingForm />
                )}
                {clientSettingType === "Deposit" && <DepositSettingForm />}
              </Grid>
            </Grid>
          )}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ClientsPage;
