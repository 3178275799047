// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Helper Functions
import { getFileURL } from "../../../helperFunctions/file";

// Material UI
// Components
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// Styles
import {
  avatorWithSrcSmallStyles,
  avatorWithTextSmallStyles,
  useStandardItemBoxStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function RankedMerchandiseBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    item,
    salesNumberType,
    unitTextDisplay,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;
  const photoURL = item.file_path ? getFileURL(item.file_path) : null;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onClick={() => onItemClicked(item.id)}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentSubContainerAvator}>
        {photoURL ? (
          <Avatar
            alt={t("圖片")}
            src={photoURL}
            sx={avatorWithSrcSmallStyles}
          />
        ) : (
          <Avatar sx={avatorWithTextSmallStyles}>
            {item.merchandise_code}
          </Avatar>
        )}
      </div>
      <div className={classes.itemContentSubContainerLarge}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {`${item.merchandise_code} (${item[t("sub_category_name_ch")]})`}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {item[t("merchandise_name_ch")]}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainerRight}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {salesNumberType === "Revenue"
            ? item.revenue
            : `${item.num_of_merchandise_ordereds} ${unitTextDisplay}`}
        </Typography>
      </div>
    </div>
  );
}

export default RankedMerchandiseBox;
