export function onConditionPdfTypeChange(conditionPdfTypeId) {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_CONDITION_PDF_TYPE_CHANGE",
    conditionPdfTypeId,
  };
}

export function onConditionPdfTypeItemChange(conditionPdfTypeItem) {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_CONDITION_PDF_TYPE_ITEM_CHANGE",
    conditionPdfTypeItem,
  };
}

export function onCurrencyChange(currencyId) {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_CURRENCY_CHANGE",
    currencyId,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTermTemplateChange(termTemplateId) {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_TERM_TEMPLATE_CHANGE",
    termTemplateId,
  };
}

export function onTypeOfContentChange(typeOfContent) {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_TYPE_OF_CONTENT_CHANGE",
    typeOfContent,
  };
}

export function onTypeOfCurrenciesChange(typeOfCurrencies) {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_TYPE_OF_CURRENCIES_CHANGE",
    typeOfCurrencies,
  };
}

export function onTypeOfTermsChange(typeOfTerms) {
  return {
    type: "ON_TERMS_AND_CURRENCIES_PAGE_TYPE_OF_TERMS_CHANGE",
    typeOfTerms,
  };
}
