// Configs
import apisConfig from "../configs/apisConfig";

export const cancelQuotationFetch = async (token, quotationId) => {
  try {
    const queryRoute = `/quotation/cancelQuotation/${quotationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const cancelQuotationConfirmationFetch = async (token, quotationId) => {
  try {
    const queryRoute = `/quotation/cancelQuotationConfirmation/${quotationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const checkIsCurrencyEditedFetch = async (
  token,
  quotationId,
  newCurrencyId
) => {
  try {
    const queryRoute = `/quotation/checkIsCurrencyEdited/${quotationId}`;

    const reqBody = {
      newCurrencyId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const confirmQuotationFetch = async (
  token,
  quotationId,
  quotationDate,
  timeQuoted
) => {
  try {
    const queryRoute = `/quotation/confirmQuotation/${quotationId}`;

    const reqBody = {
      quotationDate,
      timeQuoted,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const copyQuotationFetch = async (
  token,
  quotationId,
  requestDate,
  timeRequested
) => {
  try {
    const queryRoute = `/quotation/copyQuotation/${quotationId}`;

    const reqBody = {
      requestDate,
      timeRequested,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createQuotationFetch = async (
  token,
  clientId,
  contactPersonId,
  clientLocationId,
  salespersonId,
  requestDate,
  timeRequested,
  currencyId
) => {
  try {
    const queryRoute = "/quotation/createQuotation";

    const reqBody = {
      clientId,
      contactPersonId,
      clientLocationId,
      salespersonId,
      requestDate,
      timeRequested,
      currencyId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteQuotationFetch = async (token, quotationId) => {
  try {
    const queryRoute = `/quotation/deleteQuotation/${quotationId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editQuotationFetch = async (
  token,
  quotationId,
  contactPersonId,
  clientLocationId,
  salespersonId,
  requestDate,
  timeRequested,
  currencyId,
  shouldUpdateMerchandiseQuotedPrices
) => {
  try {
    const queryRoute = `/quotation/editQuotation/${quotationId}`;

    const reqBody = {
      contactPersonId,
      clientLocationId,
      salespersonId,
      requestDate,
      timeRequested,
      currencyId,
      shouldUpdateMerchandiseQuotedPrices,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllQuotationsWithPaginationFetch = async (
  token,
  quotationStatusTypeId,
  keyword,
  startDate,
  endDate,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/quotation/getAllQuotationsWithPagination";

    const reqBody = {
      quotationStatusTypeId,
      keyword,
      startDate,
      endDate,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getQuotationDetailsByIdFetch = async (
  token,
  quotationId,
  typeOfPrices
) => {
  try {
    const queryRoute = `/quotation/getQuotationDetailsById/${quotationId}`;

    const reqBody = {
      typeOfPrices,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
