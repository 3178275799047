// Actions
import {
  onTabItemChange,
  onTypeOfClientsChange,
  onTypeOfClientSettingsChange,
} from "../../../redux/pages/clientsPage/clientsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function ClientsPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.clientsPage.tabItem);
  const typeOfClients = useSelector((state) => state.clientsPage.typeOfClients);
  const typeOfClientSettings = useSelector(
    (state) => state.clientsPage.typeOfClientSettings
  );

  // Events
  // Events - Select Box
  const onTypeOfClientsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfClientsChange(value));
  };

  const onTypeOfClientSettingsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfClientSettingsChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        <Tabs
          scrollButtons={false}
          value={tabItem}
          variant="scrollable"
          onChange={onTabClicked}
        >
          <Tab
            disableRipple
            key={"Clients"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                {t("客戶s")}
              </Typography>
            }
            value={"Clients"}
          />
          <Tab
            disableRipple
            key={"ClientSettings"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                {t("客戶設定s")}
              </Typography>
            }
            value={"ClientSettings"}
          />
        </Tabs>
      </div>
      {/* Select Boxes */}
      {tabItem === "Clients" && (
        <div>
          <FormControl variant="standard" sx={selectBoxContainerStyles}>
            <Select
              value={typeOfClients}
              onChange={(event) =>
                onTypeOfClientsSelectBoxValueChanged(event.target.value)
              }
            >
              <MenuItem value={"Current"}>{t("顯示有效客戶")}</MenuItem>
              <MenuItem value={"Deleted"}>{t("顯示已刪除客戶")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      {tabItem === "ClientSettings" && (
        <div>
          <FormControl variant="standard" sx={selectBoxContainerStyles}>
            <Select
              value={typeOfClientSettings}
              onChange={(event) =>
                onTypeOfClientSettingsSelectBoxValueChanged(event.target.value)
              }
            >
              <MenuItem value={"Current"}>{t("顯示有效客戶設定")}</MenuItem>
              <MenuItem value={"Deleted"}>{t("顯示已刪除客戶設定")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </Box>
  );
}

export default ClientsPageHeader;
