// Configs
import apisConfig from "../configs/apisConfig";

export const createClientCreditSettingFetch = async (
  token,
  creditPeriodUnitId,
  creditPeriodNum,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = "/clientCreditSetting/createClientCreditSetting";

    const reqBody = {
      creditPeriodUnitId,
      creditPeriodNum,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreClientCreditSettingFetch = async (
  token,
  clientCreditSettingId,
  typeOfClientCreditSettings
) => {
  try {
    const queryRoute = `/clientCreditSetting/deleteOrRestoreClientCreditSetting/${clientCreditSettingId}`;

    const reqBody = { typeOfClientCreditSettings };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editClientCreditSettingFetch = async (
  token,
  clientCreditSettingId,
  creditPeriodUnitId,
  creditPeriodNum,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = `/clientCreditSetting/editClientCreditSetting/${clientCreditSettingId}`;

    const reqBody = {
      creditPeriodUnitId,
      creditPeriodNum,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editClientCreditSettingSetIsDefaultFetch = async (
  token,
  clientCreditSettingId,
  isDefault
) => {
  try {
    const queryRoute = `/clientCreditSetting/editClientCreditSettingSetIsDefault/${clientCreditSettingId}`;

    const reqBody = {
      isDefault,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllClientCreditSettingsFetch = async (
  token,
  typeOfClientCreditSettings
) => {
  try {
    const queryRoute = "/clientCreditSetting/getAllClientCreditSettings";

    const reqBody = { typeOfClientCreditSettings };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getClientCreditSettingDetailsByIdFetch = async (
  token,
  clientCreditSettingId
) => {
  try {
    const queryRoute = `/clientCreditSetting/getClientCreditSettingDetailsById/${clientCreditSettingId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
