// Configs
import apisConfig from "../configs/apisConfig";

export const createTermContentFetch = async (
  token,
  termTemplateId,
  index,
  position,
  termContentTypeId,
  contentText
) => {
  try {
    const queryRoute = `/termContent/createTermContent/${termTemplateId}`;

    const reqBody = { index, position, termContentTypeId, contentText };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteTermContentFetch = async (token, termContentId) => {
  try {
    const queryRoute = `/termContent/deleteTermContent/${termContentId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editTermContentFetch = async (
  token,
  termContentId,
  termContentTypeId,
  contentText
) => {
  try {
    const queryRoute = `/termContent/editTermContent/${termContentId}`;

    const reqBody = { termContentTypeId, contentText };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editTermContentIndexesFetch = async (token, termContents) => {
  try {
    const queryRoute = "/termContent/editTermContentIndexes";

    const reqBody = { termContents };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllTermContentsByTermTemplateIdFetch = async (
  token,
  termTemplateId
) => {
  try {
    const queryRoute = `/termContent/getAllTermContentsByTermTemplateId/${termTemplateId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getTermContentDetailsByIdFetch = async (token, termContentId) => {
  try {
    const queryRoute = `/termContent/getTermContentDetailsById/${termContentId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
