// Actions
import { onCurrencyChange } from "../../../redux/pages/termsAndCurrenciesPage/termsAndCurrenciesPageActions";

// Components
// Boxes
import CurrencyBox from "./currencyBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import CurrencyActionMenu from "../../menus/termsAndCurrenciesPage/currencyActionMenu";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import {
  deleteOrRestoreCurrencyFetch,
  getAllCurrenciesWithPaginationFetch,
} from "../../../fetches/currencyFetches";

// Helper Functions
import { roundNumber } from "../../../helperFunctions/roundNumber";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function CurrencyBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const currencyId = useSelector(
    (state) => state.termsAndCurrenciesPage.currencyId
  );
  const formSubmitCount = useSelector(
    (state) => state.termsAndCurrenciesPage.formSubmitCount
  );
  const keyword = useSelector((state) => state.termsAndCurrenciesPage.keyword);
  const token = useSelector((state) => state.staff.token);
  const typeOfCurrencies = useSelector(
    (state) => state.termsAndCurrenciesPage.typeOfCurrencies
  );

  // States
  // Data
  const [currencies, setCurrencies] = useState(null);
  // Dialog
  const [currencyActionDialogText, setCurrencyActionDialogText] = useState("");
  const [currencyActionDialogType, setCurrencyActionDialogType] =
    useState(null);
  const [showCurrencyActionDialog, setShowCurrencyActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showCurrencyActionMenu, setShowCurrencyActionMenu] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = currencyId;
  const itemsArr = currencies;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onCurrencyChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowCurrencyActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowCurrencyActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onCurrencyActionDialogCanceled = () => {
    // Set States
    setShowCurrencyActionDialog(false);
  };

  const onCurrencyActionDialogConfirmed = () => {
    switch (currencyActionDialogType) {
      case "DeleteCurrency":
        deleteOrRestoreCurrency();
        break;
      case "RestoreCurrency":
        deleteOrRestoreCurrency();
        break;
      default:
        break;
    }

    // Set States
    setShowCurrencyActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Normal
  const displayCurrencyActionDialog = (currencyActionType) => {
    // Set States
    setCurrencyActionDialogType(currencyActionType);

    switch (currencyActionType) {
      case "DeleteCurrency":
        setCurrencyActionDialogText(t("確認要刪除 貨幣 嗎？"));
        break;
      case "RestoreCurrency":
        setCurrencyActionDialogText(t("確認要還原 貨幣 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCurrencyActionDialog(true);
  };

  const getAllCurrenciesDebounce = debounce((keywordStr) => {
    getAllCurrenciesWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  // Functions - Mutations
  const deleteOrRestoreCurrency = async () => {
    const results = await deleteOrRestoreCurrencyFetch(
      token,
      currentItemId,
      typeOfCurrencies
    );

    if (results.success) {
      getAllCurrenciesWithPagination(keyword);
    }
  };

  // Functions - Queries
  const getAllCurrenciesWithPagination = async (keywordStr) => {
    const results = await getAllCurrenciesWithPaginationFetch(
      token,
      typeOfCurrencies,
      keywordStr,
      pageNum,
      limitNum
    );

    if (results.currencies) {
      for (let item of results.currencies) {
        item.fxRateFromHKD = item.fxRateFromHKD
          ? separateComma(roundNumber(Number(item.fxRateFromHKD), 6))
          : 0;

        item.fxRateToHKD = item.fxRateToHKD
          ? separateComma(roundNumber(Number(item.fxRateToHKD), 6))
          : 0;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setCurrencies(results.currencies ? results.currencies : null);

    if (results.pageCount && !results.currencies) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.currencies) {
      if (
        !(
          currencyId &&
          results.currencies.some((item) => item.id === currencyId)
        )
      ) {
        dispatch(onCurrencyChange(results.currencies[0].id));
      }
    } else {
      dispatch(onCurrencyChange(null));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllCurrenciesDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getAllCurrenciesWithPagination(keyword);
  }, [pageNum, typeOfCurrencies, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCurrencyActionDialogCanceled}
        onDialogConfirmed={onCurrencyActionDialogConfirmed}
        // States
        dialogText={currencyActionDialogText}
        showDialog={showCurrencyActionDialog}
      />
      {/* Menu */}
      <CurrencyActionMenu
        // States
        showCurrencyActionMenu={showCurrencyActionMenu}
        // Set States
        setShowCurrencyActionMenu={setShowCurrencyActionMenu}
        // Fucntions
        displayCurrencyActionDialog={displayCurrencyActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("貨幣s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <CurrencyBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有貨幣資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CurrencyBoxesContainer;
