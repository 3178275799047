// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createTermContentFetch,
  editTermContentFetch,
  getTermContentDetailsByIdFetch,
} from "../../../fetches/termContentFetches";
import { getTermContentDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// Icons
import FontDownloadRoundedIcon from "@mui/icons-material/FontDownloadRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function TermContentActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // Functions
    displaySearchPlaceholderModal,
    // States
    index,
    position,
    termContentId,
  } = props;

  // Redux Store
  const termTemplateId = useSelector(
    (state) => state.termsAndCurrenciesPage.termTemplateId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [termContentActionDialogText, setTermContentActionDialogText] =
    useState("");
  const [termContentActionDialogType, setTermContentActionDialogType] =
    useState(null);
  const [showTermContentActionDialog, setShowTermContentActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [termContentTypeField, setTermContentTypeField] = useState(null);
  // Options
  const [termContentTypeOptions, setTermContentTypeOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Text Fields
  const [contentText, setContentText] = useState("");

  // Handle States
  const isTextEditable = termContentTypeField
    ? termContentTypeField.is_text_editable
    : false;

  // Events
  // Events - Dialogs
  const onTermContentActionDialogCanceled = () => {
    // Set States
    setShowTermContentActionDialog(false);
  };

  const onTermContentActionDialogConfirmed = () => {
    switch (termContentActionDialogType) {
      case "CreateTermContent":
        createTermContent();
        break;
      case "EditTermContent":
        editTermContent();
        break;
      default:
        break;
    }

    // Set States
    setShowTermContentActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "contentText":
        setContentText(value);
        break;
      case "termContentTypeField":
        setTermContentTypeField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!termContentTypeField) {
      addToErrorFields("termContentTypeField", t("請先填寫 內容類別"));
      isError = true;
    }

    if (isTextEditable) {
      if (!contentText) {
        addToErrorFields("contentText", t("請先填寫 內容"));
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    displayTermContentActionDialog(
      termContentId ? "EditTermContent" : "CreateTermContent"
    );
  };

  // Events - Title
  const onViewPlaceholderBtnClicked = () => {
    displaySearchPlaceholderModal();
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayTermContentActionDialog = (termContentActionType) => {
    // Set States
    setTermContentActionDialogType(termContentActionType);

    switch (termContentActionType) {
      case "CreateTermContent":
        setTermContentActionDialogText(t("確認要新增 條款內容 嗎？"));
        break;
      case "EditTermContent":
        setTermContentActionDialogText(t("確認要編輯 條款內容 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowTermContentActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createTermContent = async () => {
    const results = await createTermContentFetch(
      token,
      termTemplateId,
      index,
      position,
      termContentTypeField ? termContentTypeField.id : null,
      contentText
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editTermContent = async () => {
    const results = await editTermContentFetch(
      token,
      termContentId,
      termContentTypeField ? termContentTypeField.id : null,
      contentText
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getTermContentDetailFormOptions = async () => {
    const results = await getTermContentDetailFormOptionsFetch(token);

    // Set States
    setTermContentTypeOptions(
      results.termContentTypes ? results.termContentTypes : []
    );
  };

  const getTermContentDetailsById = async () => {
    const results = await getTermContentDetailsByIdFetch(token, termContentId);

    if (results.termContentDetails) {
      const { term_content_type_id, content_text } = results.termContentDetails;

      // Set States
      setTermContentTypeField(
        term_content_type_id
          ? termContentTypeOptions.find(
              (item) => item.id === term_content_type_id
            )
          : null
      );
      setContentText(content_text ? content_text : "");
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getTermContentDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && termContentTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [termContentTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (termContentId) {
        getTermContentDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, termContentId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onTermContentActionDialogCanceled}
        onDialogConfirmed={onTermContentActionDialogConfirmed}
        // States
        dialogText={termContentActionDialogText}
        showDialog={showTermContentActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {termContentId ? t("編輯條款內容") : t("新增條款內容")}
          </Typography>
        </div>
        <Button
          endIcon={<FontDownloadRoundedIcon />}
          onClick={onViewPlaceholderBtnClicked}
          variant="text"
        >
          {t("查看佔位符s")}
        </Button>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {termContentTypeOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("term_content_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("termContentTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={termContentTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("termContentTypeField")}
                    helperText={getErrorFieldMessage("termContentTypeField")}
                    label={t("內容類別")}
                    variant="standard"
                  />
                )}
                value={termContentTypeField}
              />
            )}
          </Grid>
          {isTextEditable && (
            <Grid item xs={12}>
              <TextField
                autoFocus
                error={checkIsFieldError("contentText")}
                fullWidth
                helperText={getErrorFieldMessage("contentText")}
                label={t("內容")}
                margin="dense"
                multiline
                name="contentText"
                onChange={(event) =>
                  onInputFieldChange("contentText", event.target.value)
                }
                value={contentText}
                variant="standard"
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default TermContentActionModal;
