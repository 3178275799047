// Configs
import apisConfig from "../configs/apisConfig";

export const createDepositSettingFetch = async (
  token,
  depositTypeId,
  depositNum,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = "/depositSetting/createDepositSetting";

    const reqBody = {
      depositTypeId,
      depositNum,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreDepositSettingFetch = async (
  token,
  depositSettingId,
  typeOfDepositSettings
) => {
  try {
    const queryRoute = `/depositSetting/deleteOrRestoreDepositSetting/${depositSettingId}`;

    const reqBody = { typeOfDepositSettings };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editDepositSettingFetch = async (
  token,
  depositSettingId,
  depositTypeId,
  depositNum,
  isDefault,
  isActive
) => {
  try {
    const queryRoute = `/depositSetting/editDepositSetting/${depositSettingId}`;

    const reqBody = {
      depositTypeId,
      depositNum,
      isDefault,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editDepositSettingSetIsDefaultFetch = async (
  token,
  depositSettingId,
  isDefault
) => {
  try {
    const queryRoute = `/depositSetting/editDepositSettingSetIsDefault/${depositSettingId}`;

    const reqBody = {
      isDefault,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllDepositSettingsFetch = async (
  token,
  typeOfDepositSettings
) => {
  try {
    const queryRoute = "/depositSetting/getAllDepositSettings";

    const reqBody = { typeOfDepositSettings };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDepositSettingDetailsByIdFetch = async (
  token,
  depositSettingId
) => {
  try {
    const queryRoute = `/depositSetting/getDepositSettingDetailsById/${depositSettingId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
