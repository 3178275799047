// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material UI
// Components
import Typography from "@mui/material/Typography";

function TermContentBoxTitle(props) {
  // Props
  const {
    // States
    isHovered,
    item,
  } = props;

  return (
    <Typography
      align={"left"}
      color={isHovered ? stylesConfig.mainTextColor : null}
      variant="h6"
    >
      {item.content_text}
    </Typography>
  );
}

export default TermContentBoxTitle;
