// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function RevenueInfoBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    baseWeightUnitDetails,
    item,
  } = props;

  return (
    <>
      {/* 營業額 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("營業額")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {item.revenue}
            </Typography>
          </div>
        </div>
      </div>
      {/* 訂單數量 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("訂單數量")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {`${item.numOfOrders} ${t(" 張")}`}
            </Typography>
          </div>
        </div>
      </div>
      {/* 商品銷量 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("商品銷量")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {`${item.salesVolumes} ${
                baseWeightUnitDetails[t("unit_name_ch")]
              }`}
            </Typography>
          </div>
        </div>
      </div>
      {/* 客戶數量 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("客戶數量")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {`${item.numOfClients}${t(" 位")}`}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default RevenueInfoBox;
