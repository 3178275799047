// Actions
import { onTypeOfContentChange } from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Boxes
import MerchandiseUnitBox from "./merchandiseUnitBox";
// Menus
import MerchandiseUnitActionMenu from "../../menus/merchandisesPage/merchandiseUnitActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import MerchandiseUnitActionModal from "../../modals/merchandisesPage/merchandiseUnitActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllUnitTypesByMerchandiseIdFetch } from "../../../fetches/unitTypeFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseUnitBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.merchandisesPage.formSubmitCount
  );
  const merchandiseId = useSelector(
    (state) => state.merchandisesPage.merchandiseId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [merchandiseUnits, setMerchandiseUnits] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showMerchandiseUnitActionMenu, setShowMerchandiseUnitActionMenu] =
    useState(null);
  // Modals
  const [showMerchandiseUnitActionModal, setShowMerchandiseUnitActionModal] =
    useState(false);

  // Handle States
  const itemsArr = merchandiseUnits;

  // Events
  // Events - Box
  const onItemClicked = () => {
    displayMerchandiseUnitActionModal();
  };

  const onItemRightClicked = (currentTarget) => {
    // Set States
    setShowMerchandiseUnitActionMenu(currentTarget);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onMerchandiseUnitActionModalClosed = () => {
    // Set States
    setShowMerchandiseUnitActionModal(false);

    getAllUnitTypesByMerchandiseId();
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    displayMerchandiseUnitActionModal();
  };

  const onChangeTypeOfContentBtnClicked = () => {
    // Update Redux Store
    dispatch(onTypeOfContentChange("MerchandiseDescriptions"));
  };

  const onEditItemBtnClicked = () => {
    displayMerchandiseUnitActionModal();
  };

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setMerchandiseUnits(null);
  };

  const displayMerchandiseUnitActionModal = () => {
    setShowMerchandiseUnitActionModal(true);
  };

  // Functions - Queries
  const getAllUnitTypesByMerchandiseId = async () => {
    const results = await getAllUnitTypesByMerchandiseIdFetch(
      token,
      merchandiseId
    );

    // Set States
    setMerchandiseUnits(results.unitTypes ? results.unitTypes : null);
  };

  // Life Cycle
  useEffect(() => {
    if (merchandiseId) {
      getAllUnitTypesByMerchandiseId();
    } else {
      clearData();
    }
  }, [merchandiseId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Menu */}
      <MerchandiseUnitActionMenu
        // States
        showMerchandiseUnitActionMenu={showMerchandiseUnitActionMenu}
        // Set States
        setShowMerchandiseUnitActionMenu={setShowMerchandiseUnitActionMenu}
        // Functions
        displayMerchandiseUnitActionModal={displayMerchandiseUnitActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onMerchandiseUnitActionModalClosed}
        // States
        showModal={showMerchandiseUnitActionModal}
      >
        <MerchandiseUnitActionModal
          // Events
          onModalClosed={onMerchandiseUnitActionModalClosed}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("計算單位s")}
          </Typography>
          {itemsArr ? (
            <Tooltip placement="right" title={t("編輯計算單位")}>
              <IconButton color={"primary"} onClick={onEditItemBtnClicked}>
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip placement="right" title={t("新增計算單位")}>
              <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
                <AddRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <Button
          endIcon={<ChevronRightRoundedIcon />}
          onClick={onChangeTypeOfContentBtnClicked}
          variant="text"
        >
          {t("顯示描述")}
        </Button>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <MerchandiseUnitBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有計算單位資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseUnitBoxesContainer;
