export function onFormSubmitCountIncrease() {
  return {
    type: "ON_CLIENT_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onClientChange(clientId) {
  return {
    type: "ON_CLIENT_PROFILE_PAGE_CLIENT_CHANGE",
    clientId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_CLIENT_PROFILE_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfClientLocationsChange(typeOfClientLocations) {
  return {
    type: "ON_CLIENT_PROFILE_PAGE_TYOE_OF_CLIENT_LOCATIONS_CHANGE",
    typeOfClientLocations,
  };
}

export function onTypeOfContactPersonsChange(typeOfContactPersons) {
  return {
    type: "ON_CLIENT_PROFILE_PAGE_TYOE_OF_CONTACT_PERSONS_CHANGE",
    typeOfContactPersons,
  };
}
