// Actions
import {
  onUnitChange,
  onUnitTypeChange,
  onUnitTypeItemChange,
} from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Boxes
import UnitBox from "./unitBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import UnitActionMenu from "../../menus/merchandisesPage/unitActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreUnitFetch,
  getAllUnitsByUnitTypeIdFetch,
} from "../../../fetches/unitFetches";
import { getAllUnitTypesFetch } from "../../../fetches/unitTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function UnitBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.merchandisesPage.formSubmitCount
  );
  const unitId = useSelector((state) => state.merchandisesPage.unitId);
  const unitTypeId = useSelector((state) => state.merchandisesPage.unitTypeId);
  const unitTypeItem = useSelector(
    (state) => state.merchandisesPage.unitTypeItem
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfUnits = useSelector(
    (state) => state.merchandisesPage.typeOfUnits
  );

  // States
  // Data
  const [units, setUnits] = useState(null);
  const [unitTypes, setUnitTypes] = useState(null);
  // Dialog
  const [unitActionDialogText, setUnitActionDialogText] = useState("");
  const [unitActionDialogType, setUnitActionDialogType] = useState(null);
  const [showUnitActionDialog, setShowUnitActionDialog] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showUnitActionMenu, setShowUnitActionMenu] = useState(null);

  // Handle States
  const currentItemId = unitId;
  const isUnitTypeEditable = unitTypeItem ? unitTypeItem.is_editable : false;
  const itemsArr = units;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onUnitChange(itemId));
  };

  const onItemRightClicked = (itemId, isItemEditable, currentTarget) => {
    if (!isItemEditable) {
      displayUnitActionDialog("NonEdiatble");
      return;
    }

    onItemClicked(itemId);
    // Set States
    setShowUnitActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, isItemEditable, currentTarget) => {
    if (!isItemEditable) {
      displayUnitActionDialog("NonEdiatble");
      return;
    }

    onItemClicked(itemId);
    // Set States
    setShowUnitActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onUnitActionDialogCanceled = () => {
    // Set States
    setShowUnitActionDialog(false);
  };

  const onUnitActionDialogConfirmed = () => {
    switch (unitActionDialogType) {
      case "DeleteUnit":
        deleteOrRestoreUnit();
        break;
      case "RestoreUnit":
        deleteOrRestoreUnit();
        break;
      default:
        break;
    }

    // Set States
    setShowUnitActionDialog(false);
  };

  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "unitType":
        dispatch(onUnitTypeChange(value.id));
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onUnitChange(null));
  };

  // Functions
  // Functions - Normal
  const displayUnitActionDialog = (unitActionType) => {
    // Set States
    setUnitActionDialogType(unitActionType);

    switch (unitActionType) {
      case "DeleteUnit":
        setUnitActionDialogText(t("確認要刪除 計算單位 嗎？"));
        break;
      case "NonEdiatble":
        setUnitActionDialogText(t("不能修改此 計算單位"));
        break;
      case "RestoreUnit":
        setUnitActionDialogText(t("確認要還原 計算單位 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowUnitActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreUnit = async () => {
    const results = await deleteOrRestoreUnitFetch(
      token,
      currentItemId,
      typeOfUnits
    );

    if (results.success) {
      getAllUnitsByUnitTypeId(true);
    }
  };

  // Functions - Queries
  const getAllUnitsByUnitTypeId = async (shoulddUpdateCurrentUnitId) => {
    const results = await getAllUnitsByUnitTypeIdFetch(
      token,
      unitTypeId,
      typeOfUnits
    );

    // Set States
    setUnits(results.units ? results.units : null);

    // Update Redux Store
    if (
      results.units &&
      (shoulddUpdateCurrentUnitId ||
        !results.units.some((item) => item.id === unitId))
    ) {
      dispatch(onUnitChange(results.units ? results.units[0].id : null));
    }
  };

  const getAllUnitTypes = async () => {
    const results = await getAllUnitTypesFetch(token);

    // Set States
    setUnitTypes(results.unitTypes ? results.unitTypes : null);

    // Update Redux Store
    if (results.unitTypes && !unitTypeId) {
      dispatch(onUnitTypeChange(results.unitTypes[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllUnitTypes();
  }, [formSubmitCount]);

  useEffect(() => {
    if (unitTypeId) {
      getAllUnitsByUnitTypeId(unitId ? false : true);
    }
  }, [unitTypeId, formSubmitCount, typeOfUnits]);

  useEffect(() => {
    // Update Redux Store
    if (unitTypes && unitTypeId) {
      dispatch(
        onUnitTypeItemChange(unitTypes.find((item) => item.id === unitTypeId))
      );
    }
  }, [unitTypes, unitTypeId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onUnitActionDialogCanceled}
        onDialogConfirmed={onUnitActionDialogConfirmed}
        // States
        dialogText={unitActionDialogText}
        showDialog={showUnitActionDialog}
      />
      {/* Menu */}
      <UnitActionMenu
        // States
        showUnitActionMenu={showUnitActionMenu}
        // Set States
        setShowUnitActionMenu={setShowUnitActionMenu}
        // Fucntions
        displayUnitActionDialog={displayUnitActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("計算單位種類")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {unitTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("unit_type_name_ch")]}
            onChange={(event, value) => onInputFieldChange("unitType", value)}
            options={unitTypes}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("計算單位種類")}
                variant="outlined"
              />
            )}
            value={unitTypeItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("計算單位s")}
          </Typography>
          {isUnitTypeEditable && (
            <Tooltip placement="right" title={t("新增計算單位")}>
              <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
                <AddRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <UnitBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有計算單位資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default UnitBoxesContainer;
