// Configs
import apisConfig from "../configs/apisConfig";

export const checkMerchandiseOrderedExistsFetch = async (
  token,
  orderId,
  merchandiseOrderedId,
  merchandiseId
) => {
  try {
    const queryRoute = `/merchandiseOrdered/checkMerchandiseOrderedExists/${orderId}`;

    const reqBody = {
      merchandiseOrderedId,
      merchandiseId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createMerchandiseOrderedFetch = async (
  token,
  orderId,
  merchandiseId,
  unitId,
  unitPrice,
  actualUnitPrice,
  quantity,
  cost,
  actualCost,
  isDiscounted,
  orderDiscountDisplayTypeId
) => {
  try {
    const queryRoute = "/merchandiseOrdered/createMerchandiseOrdered";

    const reqBody = {
      orderId,
      merchandiseId,
      unitId,
      unitPrice,
      actualUnitPrice,
      quantity,
      cost,
      actualCost,
      isDiscounted,
      orderDiscountDisplayTypeId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteMerchandiseOrderedFetch = async (
  token,
  merchandiseOrderedId
) => {
  try {
    const queryRoute = `/merchandiseOrdered/deleteMerchandiseOrdered/${merchandiseOrderedId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editMerchandiseOrderedFetch = async (
  token,
  merchandiseOrderedId,
  orderId,
  merchandiseId,
  unitId,
  unitPrice,
  actualUnitPrice,
  quantity,
  cost,
  actualCost,
  isDiscounted,
  orderDiscountDisplayTypeId
) => {
  try {
    const queryRoute = `/merchandiseOrdered/editMerchandiseOrdered/${merchandiseOrderedId}`;

    const reqBody = {
      orderId,
      merchandiseId,
      unitId,
      unitPrice,
      actualUnitPrice,
      quantity,
      cost,
      actualCost,
      isDiscounted,
      orderDiscountDisplayTypeId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllMerchandiseOrderedsByOrderIdWithPaginationFetch = async (
  token,
  orderId,
  typeOfPrices,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/merchandiseOrdered/getAllMerchandiseOrderedsByOrderIdWithPagination/${orderId}`;

    const reqBody = { typeOfPrices, pageNum, limitNum };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseOrderedDetailsByIdFetch = async (
  token,
  merchandiseOrderedId
) => {
  try {
    const queryRoute = `/merchandiseOrdered/getMerchandiseOrderedDetailsById/${merchandiseOrderedId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseOrderedSuggestionsWithPaginationFetch = async (
  token,
  orderId,
  clientId,
  merchandiseId,
  unitId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/merchandiseOrdered/getMerchandiseOrderedSuggestionsWithPagination/${orderId}`;

    const reqBody = {
      clientId,
      merchandiseId,
      unitId,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
