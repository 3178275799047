// Custom Hooks
import { useLanguage } from "../../customHooks/getLanguage";

// Material UI
// Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

// React
import { useEffect } from "react";

function ConfirmDialog(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  const {
    // Events
    onDialogClosed,
    onDialogConfirmed,
    onOptionChange,
    // States
    dialogTitle,
    options,
    optionValue,
    showDialog,
  } = props;

  // Events
  const onDialogKeyPress = (key) => {
    if (key === "Enter") {
      onDialogConfirmed();
    }
  };

  // Life Cycle
  useEffect(() => {
    onOptionChange(options[0]);
  }, []);

  return (
    <>
      <Dialog
        onKeyPress={(event) => onDialogKeyPress(event.key)}
        open={showDialog}
        onClose={onDialogClosed}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={optionValue}
            onChange={(event) => onOptionChange(event.target.value)}
          >
            {options.map((option) => (
              <FormControlLabel
                value={option}
                key={option}
                control={<Radio />}
                label={option}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClosed}>{t("取消")}</Button>
          <Button onClick={onDialogConfirmed} autoFocus>
            {t("確認")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmDialog;
