// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function ProgressBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isCancelable = item && item.isCancelable;
  const isEditable = item && item.isEditable;
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;

  const shouldShowMenu = isCancelable || isEditable;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onClick={() => onItemClicked(item.id)}
      onContextMenu={(event) => {
        if (shouldShowMenu) {
          event.preventDefault();
          onItemRightClicked(item.id, event.currentTarget);
        }
      }}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            variant="body1"
          >
            {item[t("procedure_name_ch")]}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {item[t("progress_status_type_name_ch")]}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            variant="body1"
          >
            {t("開始日期")}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {item.startTimeDisplay
              ? `${item.startDateDisplay} - ${item.startTimeDisplay}`
              : item.startDateDisplay
              ? item.startDateDisplay
              : "-/-"}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            variant="body1"
          >
            {t("完成日期")}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {item.endTimeDisplay
              ? `${item.endDateDisplay} - ${item.endTimeDisplay}`
              : item.endDateDisplay
              ? item.endDateDisplay
              : "-/-"}
          </Typography>
        </div>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          disabled={!shouldShowMenu}
          onClick={(event) => {
            onItemShowMenuBtnClicked(item.id, event.currentTarget);
          }}
          sx={isSelected ? { color: stylesConfig.whiteTextColor } : null}
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default ProgressBox;
