export const createInitialCustomUnitItem = () => {
  return {
    id: 1,
    unitField: null,
    numOfBaseUnit: 1,
    isBaseUnit: false,
    isEditable: true,
    isPurchasable: true,
    minPurchasableUnit: 1,
  };
};

export const createCustomUnitItem = (currentUnits) => {
  const itemId = currentUnits[0]
    ? currentUnits[currentUnits.length - 1].id + 1
    : 1;

  return {
    id: itemId,
    unitField: null,
    numOfBaseUnit: 1,
    isBaseUnit: false,
    isEditable: true,
    isPurchasable: true,
    minPurchasableUnit: 1,
  };
};
