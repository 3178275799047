const today = new Date();

const initialState = {
  clientId: null,
  comparisonRecordDate: today.toString(),
  conditionUnitId: null,
  conditionUnitItem: null,
  formSubmitCount: 0,
  isComparisonShown: false,
  keyword: "",
  overallSalesNumberType: "Revenue",
  rankingsSalesNumberType: "Revenue",
  recordDate: today.toString(),
  salespersonId: null,
  tabItem: "OverallNumbers",
};

export const salesReportsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_SALES_REPORTS_PAGE_CLIENT_CHANGE":
      const { clientId } = action;
      return {
        ...state,
        clientId,
      };
    case "ON_SALES_REPORTS_PAGE_COMPARISON_RECORD_DATE_CHANGE":
      const { comparisonRecordDate } = action;
      return {
        ...state,
        comparisonRecordDate: comparisonRecordDate.toString(),
      };
    case "ON_SALES_REPORTS_PAGE_CONDITION_UNIT_CHANGE":
      const { conditionUnitId } = action;
      return {
        ...state,
        conditionUnitId,
      };
    case "ON_SALES_REPORTS_PAGE_CONDITION_UNIT_ITEM_CHANGE":
      const { conditionUnitItem } = action;
      return {
        ...state,
        conditionUnitItem,
      };
    case "ON_SALES_REPORTS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_SALES_REPORTS_PAGE_IS_COMPARISON_CHANGE":
      const { isComparisonShown } = action;
      return {
        ...state,
        isComparisonShown,
      };
    case "ON_SALES_REPORTS_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_SALES_REPORTS_PAGE_OVERALL_SALES_NUMBER_TYPE_CHANGE":
      const { overallSalesNumberType } = action;
      return {
        ...state,
        overallSalesNumberType,
      };
    case "ON_SALES_REPORTS_PAGE_RANKINGS_SALES_NUMBER_TYPE_CHANGE":
      const { rankingsSalesNumberType } = action;
      return {
        ...state,
        rankingsSalesNumberType,
      };
    case "ON_SALES_REPORTS_PAGE_RECORD_DATE_CHANGE":
      const { recordDate } = action;
      return {
        ...state,
        recordDate: recordDate.toString(),
      };
    case "ON_SALES_REPORTS_PAGE_SALESPERSON_CHANGE":
      const { salespersonId } = action;
      return {
        ...state,
        salespersonId,
      };
    case "ON_SALES_REPORTS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
