// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function ContactPersonActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showContactPersonActionMenu,
    // Set States
    setShowContactPersonActionMenu,
    // Functions
    displayContactPersonActionDialog,
    displayContactPersonActionModal,
  } = props;

  // Redux Store
  const typeOfContactPersons = useSelector(
    (state) => state.clientProfilePage.typeOfContactPersons
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowContactPersonActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayContactPersonActionDialog("DeleteContactPerson");
        break;
      case "Edit":
        displayContactPersonActionModal();
        break;
      case "Restore":
        displayContactPersonActionDialog("RestoreContactPerson");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showContactPersonActionMenu}
      open={Boolean(showContactPersonActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      <MenuItem disableRipple onClick={() => onMenuItemClicked("Edit")}>
        <EditRoundedIcon />
        {t("編輯")}
      </MenuItem>
      {typeOfContactPersons === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default ContactPersonActionMenu;
