// Actions
import { onKeywordChange } from "../../../redux/pages/clientsPage/clientsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ClientSearchBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const keyword = useSelector((state) => state.clientsPage.keyword);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "keyword":
        dispatch(onKeywordChange(value));
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("搜尋客戶")}
          </Typography>
        </div>
      </div>
      {/* Text Field */}
      <div className={classes.autoCompleteContainer}>
        <TextField
          autoFocus
          fullWidth
          label={t("關鍵字")}
          margin="dense"
          name="keyword"
          onChange={(event) =>
            onInputFieldChange("keyword", event.target.value)
          }
          required
          value={keyword}
          variant="standard"
        />
      </div>
    </div>
  );
}

export default ClientSearchBoxesContainer;
