// Configs
import apisConfig from "../configs/apisConfig";

export const autoLoginFetch = async (token) => {
  try {
    const queryRoute = "/staff/autoLogin";

    const reqBody = {
      permissionName: "Sign In To CRM Management System",
      isWeb: true,
      deviceToken: null,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsByDepartmentIdsArrFetch = async (
  token,
  departmentIdsArr
) => {
  try {
    const queryRoute = "/staff/getAllStaffsByDepartmentIdsArr";

    const reqBody = { departmentIdsArr };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsWithNumOfProgressesByDepartmentIdFetch = async (
  token,
  departmentId,
  procedureId,
  progressStatusTypeId,
  startDate,
  endDate
) => {
  try {
    const queryRoute = `/staff/getAllStaffsWithNumOfProgressesByDepartmentId/${departmentId}`;

    const reqBody = { procedureId, progressStatusTypeId, startDate, endDate };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const loginFetch = async (fullNameOrStaffCode, password) => {
  try {
    const queryRoute = "/staff/login";

    const reqBody = {
      fullNameOrStaffCode,
      password,
      permissionName: "Sign In To CRM Management System",
      isWeb: true,
      deviceToken: null,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
