// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/orderProfilePage/orderProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getProgressDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  cancelProgressCompletionFetch,
  editProgressFetch,
  getProgressDetailsByIdFetch,
} from "../../../fetches/progressFetches";
import { getAllStaffsByDepartmentIdsArrFetch } from "../../../fetches/staffFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function ProgressDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.orderProfilePage.formSubmitCount
  );
  const progressId = useSelector((state) => state.orderProfilePage.progressId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Dialog
  const [progressDetailActionDialogText, setProgressDetailActionDialogText] =
    useState("");
  const [progressDetailActionDialogType, setProgressDetailActionDialogType] =
    useState(null);
  const [showProgressDetailAction, setShowProgressDetailAction] =
    useState(false);
  // Data
  const [isCancelable, setIsCancelable] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isEndDateAvailable, setIsEndDateAvailable] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [departmentField, setDepartmentField] = useState([]);
  const [staffField, setStaffField] = useState([]);
  // Options
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Time Fields
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // Handle States
  const startDateVar = startDate ? new Date(startDate) : null;
  const endDateVar = endDate ? new Date(endDate) : null;
  const startTimeVar = startTime ? new Date(startTime) : null;
  const endTimeVar = endTime ? new Date(endTime) : null;

  // Events
  // Events - Dialogs
  const onProgressDetailActionCanceled = () => {
    // Set States
    setShowProgressDetailAction(false);
  };

  const onProgressDetailActionConfirmed = () => {
    switch (progressDetailActionDialogType) {
      case "CancelProgressCompletion":
        cancelProgressCompletion();
        break;
      case "EditProgress":
        editProgress();
        break;
      default:
        break;
    }

    // Set States
    setShowProgressDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "departmentField":
        setDepartmentField(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      case "endTime":
        setEndTime(value);
        break;
      case "staffField":
        setStaffField(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      case "startTime":
        setStartTime(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onCancelCompletionBtnClikced = () => {
    displayProgressDetailActionDialog("CancelProgressCompletion");
  };

  const onSubmitBtnClicked = () => {
    if (!isEditable) {
      return;
    }

    let isError = false;

    if (!departmentField[0]) {
      addToErrorFields("departmentField", t("請先填寫 部門"));
      isError = true;
    }

    if (endDate) {
      if (!startDate) {
        addToErrorFields("startDate", t("請先填寫 開始日期"));
        isError = true;
      }

      if (!startTime) {
        addToErrorFields("startTime", t("請先填寫 開始時間"));
        isError = true;
      }

      if (!endTime) {
        addToErrorFields("endTime", t("請先填寫 結束時間"));
        isError = true;
      }
    }

    if (startTime) {
      if (!startDate) {
        addToErrorFields("startDate", t("請先填寫 開始日期"));
        isError = true;
      }
    }

    if (endTime) {
      if (!startDate) {
        addToErrorFields("startDate", t("請先填寫 開始日期"));
        isError = true;
      }

      if (!endDate) {
        addToErrorFields("endDate", t("請先填寫 結束日期"));
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    displayProgressDetailActionDialog("EditProgress");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
    setDepartmentField([]);
    setStaffField([]);
    setIsCancelable(false);
    setIsEditable(false);
    setIsEndDateAvailable(false);

    clearErrorFields();
  };

  const displayProgressDetailActionDialog = (progressDetailActionType) => {
    // Set States
    setProgressDetailActionDialogType(progressDetailActionType);
    switch (progressDetailActionType) {
      case "CancelProgressCompletion":
        setProgressDetailActionDialogText(t("確認要取消完成 進度 嗎？"));
        break;
      case "EditProgress":
        setProgressDetailActionDialogText(t("確認要編輯 進度資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowProgressDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const cancelProgressCompletion = async () => {
    const results = await cancelProgressCompletionFetch(token, progressId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editProgress = async () => {
    if (progressId) {
      const results = await editProgressFetch(
        token,
        progressId,
        startDate,
        isEndDateAvailable ? endDate : null,
        startTime,
        isEndDateAvailable ? endTime : null,
        departmentField[0] ? departmentField.map((item) => item.id) : null,
        staffField[0] ? staffField.map((item) => item.id) : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.notAllDetailFilled) {
        showFormAlert("warning", t("請先填寫 銷售員 / 聯絡人 / 地點"));
      } else if (results.notAllMerchandiseOrdered) {
        showFormAlert("warning", t("請先填寫所有 商品價錢"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getAllStaffsByDepartmentIdsArr = async () => {
    const results = await getAllStaffsByDepartmentIdsArrFetch(
      token,
      departmentField[0] ? departmentField.map((item) => item.id) : null
    );

    // Set States
    setStaffField((currentState) =>
      currentState.filter((currentStateItem) =>
        results.staffs.some(
          (resultStaffItem) => resultStaffItem.id === currentStateItem.id
        )
      )
    );
    setStaffOptions(results.staffs ? results.staffs : []);
  };

  const getProgressDetailFormOptions = async () => {
    const results = await getProgressDetailFormOptionsFetch(token);

    // Set States
    setDepartmentOptions(results.departments ? results.departments : []);
  };

  const getProgressDetailsById = async () => {
    const results = await getProgressDetailsByIdFetch(token, progressId);

    if (results.progressDetails) {
      const {
        startDate,
        endDate,
        start_time,
        end_time,
        dependencies,
        isCancelable,
        isEditable,
        isEndDateAvailable,
      } = results.progressDetails;

      const { departments, staffs } = dependencies;

      // Set States
      setStartDate(startDate ? startDate : null);
      setEndDate(endDate ? endDate : null);
      setStartTime(start_time ? start_time : null);
      setEndTime(end_time ? end_time : null);
      setDepartmentField(departments ? departments : []);
      setStaffField(staffs ? staffs : []);
      setIsCancelable(isCancelable);
      setIsEditable(isEditable);
      setIsEndDateAvailable(isEndDateAvailable);
    } else {
      // Set States
      setStartDate(null);
      setEndDate(null);
      setStartTime(null);
      setEndTime(null);
      setDepartmentField([]);
      setStaffField([]);
      setIsCancelable(false);
      setIsEditable(false);
      setIsEndDateAvailable(false);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getProgressDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && departmentOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [departmentOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (progressId) {
        getProgressDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, progressId, formSubmitCount]);

  useEffect(() => {
    if (departmentField[0]) {
      getAllStaffsByDepartmentIdsArr();
    }
  }, [departmentField]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onProgressDetailActionCanceled}
        onDialogConfirmed={onProgressDetailActionConfirmed}
        // States
        dialogText={progressDetailActionDialogText}
        showDialog={showProgressDetailAction}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("負責人員")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        {departmentField && departmentOptions && (
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <Autocomplete
              disabled={!isEditable}
              disablePortal
              getOptionLabel={(option) =>
                option[t("department_name_ch")]
                  ? `${option.department_name_en_short} - ${
                      option[t("department_name_ch")]
                    }`
                  : option.department_name_en_short
              }
              multiple
              onChange={(event, value) =>
                onInputFieldChange("departmentField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={departmentOptions.filter((optionItem) =>
                departmentField[0]
                  ? !departmentField.some(
                      (fieldItem) => fieldItem.id === optionItem.id
                    )
                  : departmentOptions
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("departmentField")}
                  helperText={getErrorFieldMessage("departmentField")}
                  label={t("部門")}
                  variant="standard"
                />
              )}
              value={departmentField}
            />
          </Grid>
        )}
        {departmentField[0] && staffField && staffOptions && (
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            <Autocomplete
              disabled={!isEditable}
              disablePortal
              getOptionLabel={(option) =>
                option.staff_code
                  ? `${option.staff_code} - ${option.full_name_en}`
                  : option.full_name_en
              }
              multiple
              onChange={(event, value) =>
                onInputFieldChange("staffField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={staffOptions.filter((optionItem) =>
                staffField[0]
                  ? !staffField.some(
                      (fieldItem) => fieldItem.id === optionItem.id
                    )
                  : staffOptions
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("staffField")}
                  helperText={getErrorFieldMessage("staffField")}
                  label={t("員工")}
                  variant="standard"
                />
              )}
              value={staffField}
            />
          </Grid>
        )}
      </Grid>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("開始日期")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6} className={classes.formDatePickerContainer}>
          <DatePicker
            disabled={!isEditable}
            format="dd/MM/yyyy"
            label={t("開始日期 (日/月/年)")}
            onChange={(value) => onInputFieldChange("startDate", value)}
            slotProps={{
              textField: {
                error: checkIsFieldError("startDate"),
                helperText: getErrorFieldMessage("startDate"),
                variant: "outlined",
              },
            }}
            value={startDateVar}
            views={["year", "month", "day"]}
          />
        </Grid>
        <Grid item xs={6} className={classes.formDatePickerContainer}>
          <TimePicker
            disabled={!isEditable}
            label={t("開始時間")}
            onChange={(value) => onInputFieldChange("startTime", value)}
            slotProps={{
              textField: {
                error: checkIsFieldError("startTime"),
                helperText: getErrorFieldMessage("startTime"),
              },
            }}
            value={startTimeVar}
          />
        </Grid>
        {!isEndDateAvailable && (
          <>
            {isEditable && (
              <Grid item xs={12} className={classes.formSubmitBtnContainer}>
                <Button
                  onClick={onSubmitBtnClicked}
                  variant="contained"
                  sx={formSubmitBtnStyles}
                >
                  {t("修改")}
                </Button>
              </Grid>
            )}
            {/* Alert */}
            {shouldShowFormAlert && (
              <Grid item xs={12}>
                <Alert severity={formAlertType}>{formAlertText}</Alert>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {isEndDateAvailable && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("結束日期")}
              </Typography>
            </div>
          </div>
          {/* Form */}
          <Grid
            className={classes.formContainer}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            container
            rowSpacing={1}
          >
            <Grid item xs={6} className={classes.formDatePickerContainer}>
              <DatePicker
                disabled={!isEditable}
                format="dd/MM/yyyy"
                label={t("結束日期 (日/月/年)")}
                onChange={(value) => onInputFieldChange("endDate", value)}
                slotProps={{
                  textField: {
                    error: checkIsFieldError("endDate"),
                    helperText: getErrorFieldMessage("endDate"),
                    variant: "outlined",
                  },
                }}
                value={endDateVar}
                views={["year", "month", "day"]}
              />
            </Grid>
            <Grid item xs={6} className={classes.formDatePickerContainer}>
              <TimePicker
                disabled={!isEditable}
                label={t("結束時間")}
                onChange={(value) => onInputFieldChange("endTime", value)}
                slotProps={{
                  textField: {
                    error: checkIsFieldError("endTime"),
                    helperText: getErrorFieldMessage("endTime"),
                  },
                }}
                value={endTimeVar}
              />
            </Grid>
            {isEditable && (
              <Grid item xs={12} className={classes.formSubmitBtnContainer}>
                <Button
                  onClick={onSubmitBtnClicked}
                  variant="contained"
                  sx={formSubmitBtnStyles}
                >
                  {t("修改")}
                </Button>
              </Grid>
            )}
            {isCancelable && (
              <Grid item xs={12} className={classes.formSubmitBtnContainer}>
                <Button
                  onClick={onCancelCompletionBtnClikced}
                  sx={formSubmitBtnStyles}
                >
                  {t("取消完成")}
                </Button>
              </Grid>
            )}
            {/* Alert */}
            {shouldShowFormAlert && (
              <Grid item xs={12}>
                <Alert severity={formAlertType}>{formAlertText}</Alert>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  );
}

export default ProgressDetailForm;
