// Actions
import {
  onConditionOrderStatusTypeChange,
  onConditionOrderStatusTypeItemChange,
  onKeywordChange,
} from "../../../redux/pages/ordersPage/ordersPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { orderStatusTypeDummyData } from "../../../dummyDatum/orderStatusTypeDummyData";

// Fetches
import { getAllOrderStatusTypesFetch } from "../../../fetches/orderStatusTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function OrderConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const keyword = useSelector((state) => state.ordersPage.keyword);
  const orderStatusTypeId = useSelector(
    (state) => state.ordersPage.conditionOrderStatusTypeId
  );
  const orderStatusTypeItem = useSelector(
    (state) => state.ordersPage.conditionOrderStatusTypeItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [orderStatusTypes, setOrderStatusTypes] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "orderStatusType":
        if (value) {
          dispatch(onConditionOrderStatusTypeChange(value.id));
        }
        break;
      case "keyword":
        dispatch(onKeywordChange(value));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Normal
  const getAllConditions = () => {
    getAllOrderStatusTypes();
  };

  // Functions - Queries
  const getAllOrderStatusTypes = async () => {
    const results = await getAllOrderStatusTypesFetch(token);

    // Set States
    setOrderStatusTypes(
      results.orderStatusTypes
        ? [orderStatusTypeDummyData, ...results.orderStatusTypes]
        : null
    );

    // Update Redux Store
    if (results.orderStatusTypes && !orderStatusTypeId) {
      dispatch(onConditionOrderStatusTypeChange(orderStatusTypeDummyData.id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllConditions();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (orderStatusTypes && orderStatusTypeId) {
      dispatch(
        onConditionOrderStatusTypeItemChange(
          orderStatusTypes.find((item) => item.id === orderStatusTypeId)
        )
      );
    }
  }, [orderStatusTypes, orderStatusTypeId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("搜尋訂單")}
          </Typography>
        </div>
      </div>
      {/* Text Field */}
      <div className={classes.autoCompleteContainer}>
        <TextField
          autoFocus
          fullWidth
          label={t("關鍵字")}
          margin="dense"
          name="keyword"
          onChange={(event) =>
            onInputFieldChange("keyword", event.target.value)
          }
          required
          value={keyword}
          variant="standard"
        />
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("訂單狀態")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {orderStatusTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("order_status_type_name_ch")]}
            onChange={(event, value) =>
              onInputFieldChange("orderStatusType", value)
            }
            options={orderStatusTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("訂單狀態")} variant="outlined" />
            )}
            value={orderStatusTypeItem}
          />
        </div>
      )}
    </div>
  );
}

export default OrderConditionBoxesContainer;
