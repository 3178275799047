export const languageEnUs = {
  // Data
  area_name_ch: "area_name_en_full",
  brand_name_ch: "brand_name_en_full",
  category_name_ch: "category_name_en",
  credit_period_unit_name_ch: "credit_period_unit_name_en",
  currency_name_ch: "currency_name_en",
  department_name_ch: "department_name_en_full",
  deposit_type_name_ch: "deposit_type_name_en",
  displayTextCh: "displayTextEn",
  district_name_ch: "district_name_en",
  full_name_ch: "full_name_en",
  merchandise_name_ch: "merchandise_name_en",
  minPurchaseNumDisplayTextCh: "minPurchaseNumDisplayTextEn",
  nameCh: "nameEn",
  order_discount_display_type_name_ch: "order_discount_display_type_name_en",
  order_status_type_name_ch: "order_status_type_name_en",
  payment_period_unit_name_ch: "payment_period_unit_name_en",
  pdf_type_name_ch: "pdf_type_name_en",
  person_title_name_ch: "person_title_code",
  placeholder_name_ch: "placeholder_name_en",
  procedure_name_ch: "procedure_name_en",
  quotation_discount_display_type_name_ch:
    "quotation_discount_display_type_name_en",
  progress_status_type_name_ch: "progress_status_type_name_en",
  quotation_status_type_name_ch: "quotation_status_type_name_en",
  sub_category_name_ch: "sub_category_name_en",
  term_content_type_name_ch: "term_content_type_name_en",
  textCh: "textEn",
  title_name_ch: "title_name_en_full",
  unitDisplayTextCh: "unitDisplayTextEn",
  unit_name_ch: "unit_code",
  unit_type_name_ch: "unit_type_name_en",
  // Moment
  "a h:mm": "h:mm a",
  D日: "D",
  M月: "MMM",
  "M 月 D 日": "D MMM",
  "M月D日 - ah:mm": "D MMM - h:mma",
  "M 月": "MMM",
  "Y 年 M 月 D 日": "D MMM YYYY",
  "Y 年 M 月": "MMM YYYY",
  "Y 年": "YYYY",
  // Text
  "確認要 登出 嗎？": "Confirm to Logout?",
  "Change to ": "設換至",
  顯示選單: "Show Menu",
  設換至夜間模式: "Change to Dark Theme",
  設換至日間模式: "Change to Light Theme",
  登出: "Logout",
  "請先填寫 英文全名 或 員工編號":
    "Please fill in English Full Name / Staff Code",
  "請先填寫 密碼": "Please fill in Password",
  未有權限: "Permission Denied",
  未能登入: "Unable to Login",
  "英文全名 或 員工編號": "English Full Name / Staff Code",
  密碼: "Password",
  登入: "Login",
  "載入中...": "Loading...",
  確定: "Submit",
  確認: "Confirm",
  取消: "Cancel",
  客戶關係管理系統: "CRM",
  銷售報告s: "Sales Reports",
  商品銷量s: "Merchandise Sales",
  工作s: "Jobs",
  訂單s: "Orders",
  報價s: "Quotations",
  客戶s: "Clients",
  商品s: "Merchandises",
  "類別 / 品牌s": "Categories / Brands",
  "條款 / 貨幣s": "Terms / Currencies",
  客戶概覧: "Client Profile",
  新增客戶: "Create CLient",
  訂單概覧: "Order Profile",
  報價概覧: "Quotation Profile",
  整體數據: "Overall",
  銷售員排行s: "Top Salespersons",
  客戶排行s: "Top Clients",
  隠藏對照日期: "Hide Comparison Date",
  顯示對照日期: "Show Comparison Date",
  隠藏對照月份: "Hide Comparison Month",
  顯示對照月份: "Show Comparison Month",
  隠藏對照年份: "Hide Comparison Year",
  顯示對照年份: "Show Comparison Year",
  "對照日期 (日/月/年)": "Comparison Date (DD/MM/YY)",
  "日期 (日/月/年)": "Date (DD/MM/YY)",
  "對照月份 (月/年)": "Comparison Month (MM/YY)",
  "月份 (月/年)": "Month (MM/YY)",
  "對照年份 (年)": "Comparison Year (YY)",
  "年份 (年)": "Year (YY)",
  返回本日: "Back to Today",
  返回本月: "Back to Current Month",
  返回本年: "Back to Current Year",
  銷售數據類別: "Sales Figure Type",
  以月為顯示單位: "Display as Months",
  以日為顯示單位: "Display as Days",
  計算單位: "UoM",
  本月營業額: "Current Month's Revenue",
  未有營業額資料: "No Revenue Data",
  營業額: "Revenue",
  訂單數量: "Num of Orders",
  商品銷量: "Sales Volume",
  客戶數量: "Num of Clients",
  " 張": "",
  " 位": "",
  趨勢分析: "Trend Analysis",
  未有趨勢分析: "No Trend Analysis",
  搜尋銷售員: "Search Salesperson",
  搜尋客戶: "Search Client",
  關鍵字: "Keyword",
  銷售員排行: "Salesperson Rankings",
  未有銷售員排行: "No Salesperson Ranking",
  客戶排行: "Client Rankings",
  未有客戶排行: "No Client Ranking",
  商品排行: "Merchandise Rankings",
  銷售數據s: "Sales Numbers",
  趨勢分析s: "Trend Analysis",
  搜尋商品: "Search Merchandise",
  類別: "Category",
  子類別: "Sub-Category",
  品牌: "Brand",
  未有商品排行: "No Merchandise Ranking",
  圖片: "Photo",
  銷售數據: "Sales Numbers",
  未有銷售數據: "No Sales Number",
  負責人員: "Person In Charge",
  部門: "Department",
  員工: "Staff",
  流程: "Procedure",
  工作進度: "Progress Status",
  "確認要取消完成 工作 嗎？": "Confirm to Cancel Completion of Job?",
  "確認現在完成 工作 嗎？": "Confirm to Complete Job Now?",
  "確認現在開始 工作 嗎？": "Confirm to Start Job Now?",
  未有工作: "No Job",
  開始時間: "Start Time",
  完成時間: "End Time",
  現在開始: "Start Now",
  現在完成: "Complete Now",
  取消完成: "Cancel Completion",
  流程s: "Procedures",
  搜尋訂單: "Search Order",
  訂單狀態: "Order Status",
  "確認要取消 訂單 嗎？": "Confirm to Cancel Order?",
  "確認要刪除 訂單 嗎？": "Confirm to Delete Order?",
  新增訂單: "Create Order",
  未有訂單: "No Order",
  複製: "Copy",
  刪除: "Delete",
  "請先填寫 訂單下訂 日期": "Please fill in Order Data",
  "請先填寫 訂單下訂 時間": "Please fill in Order Time",
  "確認要複製 訂單 嗎？": "Confirm to Copy Order?",
  未能提交: "Unable to Submit",
  複製訂單: "Copy Order",
  "訂單下訂日期 (日/月/年)": "Order Date (DD/MM/YY)",
  訂單下訂時間: "Order Time",
  "請先填寫 銷售員": "Please fill in Salesperson",
  "確認要新增 訂單 嗎？": "Confirm to Create Order?",
  "確認要編輯 訂單 嗎？": "Confirm to Edit Order?",
  編輯訂單: "Edit Order",
  聯絡人: "Contact Person",
  地點: "Location",
  銷售員部門: "Salesperson Department",
  銷售員: "Salesperson",
  未有相關客戶: "No Related Client",
  未有相關商品: "No Related Merchandise",
  未有流程資料: "No Procedure Data",
  "請先填寫 部門": "Please fill in Department",
  "請先填寫 中文名稱": "Please fill in Chinese Name",
  "請先填寫 英文名稱": "Please fill in English Name",
  "確認要編輯 流程資料 嗎？": "Confirm to Edit Procedure Info?",
  成功提交: "Submitted",
  "此 流程 已存在": "This Procedure Already Exist",
  流程資料: "Procedure Info",
  名稱: "Name",
  中文名稱: "Chinese Name",
  英文名稱: "English Name",
  修改: "Edit",
  概要: "Summary",
  進度s: "Progresses",
  訂單: "Order",
  查看報價資料: "View Quotation",
  未有訂單資料: "No Order Data",
  客戶: "Client",
  未有銷售員資料: "No Salesperson Data",
  詳情s: "Details",
  款商品s: "Types of Merchandises",
  未有價錢: "No Total Price",
  公司: "Company",
  "確認要刪除 訂單商品 嗎？": "Confirm to Remove Merchandise From Order?",
  "商品已在 訂單列表 中": "Merchandise Already In Order",
  訂單商品列表: "Ordered Merchandise List",
  新增訂單商品: "Create Ordered Merchandise",
  未有訂單商品資料: "No Ordered Merchandise",
  未有單價: "No Unit Price",
  未有建議: "No Suggested Price",
  總價: "Total Price",
  未有總價: "No Total Price",
  相關訂單s: "Related Orders",
  只顯示此客戶訂單: "Show Orders from This Client Only",
  顯示所有客戶訂單: "Show All Orders",
  未有相關訂單資料: "No Related Order Data",
  建議: "Suggestion",
  "確認要取消完成 進度 嗎？": "Confirm to Cancel Completion of Progress?",
  "確認現在完成 進度 嗎？": "Confirm to Complete Progress Now?",
  "確認現在開始 進度 嗎？": "Confirm to Start Progress Now?",
  未有進度資料: "No Progress Data",
  開始日期: "Start Date",
  完成日期: "End Date",
  "價錢 必須為數字": "Price Must Be Number",
  "請先填寫 數量": "Please fill in Quantity",
  "數量 必須為數字": "Quantity Must Be Number",
  "請先填寫 單位": "Please fill in Unit",
  "請先填寫 單位類別": "Please fill in Unit Type",
  "確認要新增 訂單商品 嗎？": "Confirm to Create Ordered Merchandise?",
  "確認要編輯 訂單商品資料 嗎？": "Confirm to Edit Ordered Merchandise Info?",
  "此 訂單商品 已存在": "This Merchandise Already in Order",
  訂單商品資料: "Ordered Merchandise Info",
  商品: "Merchandise",
  單位類別: "Unit Type",
  單位: "Unit",
  數量: "Quantity",
  價錢: "Price",
  新增: "Create",
  "請先填寫 開始日期": "Please fill in Start Date",
  "請先填寫 開始時間": "Please fill in Start Time",
  "請先填寫 結束日期": "Please fill in End Date",
  "請先填寫 結束時間": "Please fill in End Time",
  "確認要編輯 進度資料 嗎？": "Confirm to Edit Progress Info?",
  "請先填寫 銷售員 / 聯絡人 / 地點":
    "Please fill in Salesperson / Contact Person / Location",
  "請先填寫所有 商品價錢": "Please fill in All Merchandise Prices",
  "開始日期 (日/月/年)": "Start Date (DD/MM/YY)",
  結束日期: "End Date",
  "結束日期 (日/月/年)": "End Date (DD/MM/YY)",
  結束時間: "End Time",
  搜尋報價: "Search Quotation",
  報價狀態: "Quotation Status",
  "確認要取消 報價 嗎？": "Confirm to Cancel Quotation?",
  "確認要取消 確認報價 嗎？": "Confirm to Cancel Quotation Confirmation?",
  "確認要刪除 報價 嗎？": "Confirm to Delete Quotation?",
  新增報價: "Create Quotation",
  未有報價: "No Quotation",
  確認報價: "Confirm Quotation",
  確認訂單: "Confirm Order",
  取消確認報價: "Cancel Quotation Confirmation",
  "請先填寫 確認報價 日期": "Please fill in Quotation Confirmation Date",
  "請先填寫 確認報價 時間": "Please fill in Quotation Confirmation Time",
  "確認要確認 報價 嗎？": "Confirm to Confirm Quotation?",
  "請先完成所有 商品報價": "Please Complete All Merchandise Quotations",
  "確認報價日期 (日/月/年)": "Quotation Confirmation Date (DD/MM/YY)",
  確認報價時間: "Quotation Confirmation Time",
  "請先填寫 報價請求 日期": "Please fill in Quotation Request Date",
  "請先填寫 報價請求 時間": "Please fill in Quotation Request Time",
  "確認要複製 報價 嗎？": "Confirm to Copy Quotation?",
  複製報價: "Copy Quotation",
  "報價請求日期 (日/月/年)": "Quotation Request Date (DD/MM/YY)",
  報價請求時間: "Quotation Request Time",
  "確認要確認 訂單 嗎？": "Confirm to Confirm Order?",
  "確認要新增 報價 嗎？": "Confirm to Create Quotation?",
  "確認要編輯 報價 嗎？": "Confirm to Edit Quotation?",
  編輯報價: "Edit Quotation",
  報價: "Quotation",
  查看訂單資料: "View Order",
  未有報價資料: "No Quotation Data",
  "確認要刪除 報價商品 嗎？": "Confirm to Remove Merchandise From Quotation?",
  "商品已在 報價列表 中": "Merchandise Already In Quotation",
  報價商品列表: "Merchandise Quoted List",
  新增報價商品: "Create Merchandise Quoted",
  未有報價商品資料: "No Merchandise Quoted Data",
  相關報價s: "Related Quotations",
  只顯示此客戶報價: "Show Quotations from This Client Only",
  顯示所有客戶報價: "Show All Quotations",
  未有相關報價資料: "No Related Quotation Data",
  "確認要新增 報價商品 嗎？": "Confirm to Create Quoted Merchandise?",
  "確認要編輯 報價商品資料 嗎？": "Confirm to Edit Quoted Merchandise Info?",
  "此 報價商品 已存在": "This Merchandise Already in Quotation",
  報價商品資料: "Quoted Merchandise Info",
  顯示有效客戶: "Show Active Clients",
  顯示已刪除客戶: "Show Inactive Clients",
  "確認要刪除 客戶 嗎？": "Confirm to Delete Client?",
  "確認要還原 客戶 嗎？": "Confirm to Restore Client?",
  未有客戶: "No Client",
  還原: "Restore",
  基本資料: "Basic Info",
  聯絡人s: "Contact Persons",
  地點s: "Locations",
  "請先填寫 客戶代號": "Please fill in Client Code",
  "請先填寫 中文名稱 或 英文名稱":
    "Please fill in Chinese Name or English Name",
  "請先填寫 區域": "Please fill in Area",
  "請先填寫 地區": "Please fill in District",
  "請先填寫 名稱": "Please fill in Name",
  "請先填寫 稱謂": "Please fill in Title",
  "此 客戶代號 已被登記": "This Client Code Already Registered",
  客戶代號: "Client Code",
  上一步: "Previous Step",
  下一步: "Next Step",
  新增聯絡人: "Create Contact Person",
  "如不需填寫聯絡人，請按此刪除":
    "Click to Delete Contact Person (If not applicable)",
  刪除聯絡人: "Delete Contact Person",
  聯絡人名稱: "Name",
  稱謂: "Title",
  電話號碼: "Phone Number",
  電郵地址: "Email",
  新增地點: "Create Location",
  提交: "Submit",
  "如不需填寫地點，請按此刪除": "Click to Delete Location (If not applicable)",
  刪除地點: "Delete Location",
  地點名稱: "Name",
  區域: "Area",
  地區: "District",
  地址: "Address",
  顯示有效聯絡人: "Show Active Contact Persons",
  顯示已刪除聯絡人: "Show Inactive Contact Persons",
  顯示有效地點: "Show Active Locations",
  顯示已刪除地點: "Show Inactive Locations",
  未有客戶資料: "No Client Data",
  "確認要編輯 客戶資料 嗎？": "Confirm to Edit Client Info?",
  有效: "Active",
  編輯: "Edit",
  "確認要刪除 聯絡人 嗎？": "Confirm to Delete Contact Person?",
  "確認要還原 聯絡人 嗎？": "Confirm to Restore Contact Person?",
  未有聯絡人資料: "No Contact Person Data",
  "請先填寫 聯絡人名稱": "Please fill in Contact Person Name",
  "確認要新增 聯絡人 嗎？": "Confirm to Create Contact Person?",
  "確認要編輯 聯絡人 嗎？": "Confirm to Edit Contact Person?",
  "此 聯絡人 已存在": "This Contact Person Already Exist",
  編輯聯絡人: "Edit Contact Person",
  "確認要刪除 地點 嗎？": "Confirm to Delete Location?",
  "確認要還原 地點 嗎？": "Confirm to Restore Location?",
  未有地點資料: "No Location Data",
  "請先填寫 地點名稱": "Please fill in Location Name",
  "確認要新增 地點 嗎？": "Confirm to Create Location?",
  "確認要編輯 地點 嗎？": "Confirm to Edit Location?",
  "此 地點 已存在": "This Location Already Exist",
  編輯地點: "Edit Location",
  商品列表: "Merchandise List",
  計算單位s: "Unit of Measures",
  顯示有效商品: "Show Active Merchandises",
  顯示已刪除商品: "Show Inactive Merchandises",
  顯示有效計算單位: "Show Active UoMs",
  顯示已刪除計算單位: "Show Inactive UoMs",
  "確認要刪除 商品 嗎？": "Confirm to Delete Merchandise?",
  "確認要還原 商品 嗎？": "Confirm to Restore Merchandise?",
  新增商品: "Create Merchandise",
  未有商品資料: "No Merchandise Data",
  "確認要刪除 圖片 嗎？": "Confirm to Delete Photo?",
  檔案不可超過: "File cannot Exceed",
  "未能上載，請再試一次": "Unable to Upload",
  上載圖片: "Upload Photo",
  刪除圖片: "Delete Photo",
  "請先填寫 品牌": "Please fill in Brand",
  "請先填寫 類別": "Please fill in Category",
  "請先填寫 子類別": "Please fill in Sub-Category",
  "請先填寫 代號": "Please fill in Code",
  "確認要新增 商品 嗎？": "Confirm to Create Merchandise?",
  "確認要編輯 商品資料 嗎？": "Confirm to Edit Merchandise Info?",
  "此 商品 已存在": "This Merchandise Already Exist",
  商品資料: "Merchandise Info",
  代號: "Code",
  重新: "Re-",
  上載商品照片: "Upload Merchandise Photo",
  編輯計算單位: "Edit UoM",
  新增計算單位: "Create UoM",
  未有計算單位資料: "No UoM Data",
  "計算單位 不可重覆": "Duplicate UoM",
  "請先填寫 計算單位": "Please fill in UoM",
  "請先填寫 每單位數量": "Please fill in Num of Base Unit",
  "請先填寫 最少購買數量": "Please fill in Min Purchasable Unit",
  "確認要編輯 計算單位 嗎？": "Confirm to Edit UoM?",
  "每單位數量 必須為數字": "Num of Base Unit Must Be Number",
  "最少購買數量 必須為數字": "Min Purchasable Unit Must Be Number",
  自訂單位: "Custom Unit",
  "如不需填寫計算單位，請按此刪除": "Click to Delete UoM (If not applicable)",
  刪除計算單位: "Delete UoM",
  每單位數量: "Num of Base Unit",
  可購買: "Purchasable",
  最少購買數量: "Min Purchasable Unit",
  "確認要刪除 計算單位 嗎？": "Confirm to Delete UoM?",
  "不能修改此 計算單位": "Cannot Edit This UoM",
  "確認要還原 計算單位 嗎？": "Confirm to Restore UoM?",
  計算單位種類: "Unit Type",
  " (基礎單位)": " (Base Unit)",
  "單位比例 必須為數字": "Base Unit Ratio Must Be Number",
  "確認要新增 計算單位 嗎？": "Confirm to Create UoM?",
  "確認要編輯 計算單位資料 嗎？": "Confirm to Edit UoM Info?",
  "此 計算單位 已存在": "This UoM Already Exist",
  計算單位資料: "UoM Info",
  單位比例: "Base Unit Ratio",
  類別s: "Categories",
  品牌s: "Brands",
  顯示有效類別: "Show Active Categories",
  顯示有效品牌: "Show Active Brands",
  顯示已刪除類別: "Show Inactive Categories",
  顯示已刪除品牌: "Show Inactive Brands",
  顯示有效子類別: "Show Active Sub-Categories",
  顯示已刪除子類別: "Show Inactive Sub-Categories",
  "確認要刪除 類別 嗎？": "Confirm to Delete Category?",
  "確認要刪除 品牌 嗎？": "Confirm to Delete Brand?",
  "確認要還原 類別 嗎？": "Confirm to Restore Category?",
  "確認要還原 品牌 嗎？": "Confirm to Restore Brand?",
  新增類別: "Create Category",
  新增品牌: "Create Brand",
  顯示類別資料: "Show Category Info",
  顯示子類別列表: "Show Sub-Category List",
  未有類別資料: "No Category Data",
  未有品牌資料: "No Brand Data",
  "確認要刪除 子類別 嗎？": "Confirm to Delete Sub-Category?",
  "確認要還原 子類別 嗎？": "Confirm to Restore Sub-Category?",
  子類別s: "Sub-Categories",
  新增子類別: "Create Sub-Category",
  未有子類別資料: "No Sub-Category Data",
  "確認要新增 類別 嗎？": "Confirm to Create Category?",
  "確認要編輯 類別資料 嗎？": "Confirm to Edit Category Info?",
  "此 類別 已存在": "This Category Already Exist",
  類別資料: "Category Info",
  "確認要新增 品牌 嗎？": "Confirm to Create Brand?",
  "確認要編輯 品牌資料 嗎？": "Confirm to Edit Brand Info?",
  "此 品牌 已存在": "This Brand Already Exist",
  品牌資料: "Brand Info",
  "確認要新增 子類別 嗎？": "Confirm to Create Sub-Category?",
  "確認要編輯 子類別資料 嗎？": "Confirm to Edit Sub-Category Info?",
  "此 子類別 已存在": "This Sub-Category Already Exist",
  子類別資料: "Sub-Category Info",
  客戶信用設定: "Client Credit Setting",
  "確認要新增 客戶信用設定 嗎？": "Confirm to Create Client Credit Settings?",
  "確認要編輯 客戶信用設定 嗎？": "Confirm to Edit Client Credit Settings?",
  付款期限設定: "Payment Term Setting",
  "確認要新增 付款期限設定 嗎？": "Confirm to Create Payment Term Settings?",
  "確認要編輯 付款期限設定 嗎？": "Confirm to Edit Payment Term Settings?",
  信用期限: "Credit Period",
  "請先填寫 信用期限": "Please fill in Credit Period",
  "信用期限 必須為數字": "Credit Period Must Be Number",
  "信用期限 必須大於 0": "Credit Period Must Be Greater than 0",
  信用: "Credit",
  更新: "Update",
  "未能更新，請再試一次": "Unable to Update.  Please Try Again",
  "確認要刪除 描述 嗎？": "Confirm to Delete Description?",
  描述s: "Descriptions",
  新增描述: "Create Description",
  未有描述資料: "No Description Data",
  "請先填寫 中文描述 或 英文描述":
    "Please fill in Chinese Description or English Description",
  "確認要新增 描述 嗎？": "Confirm to Create Description?",
  "確認要編輯 描述 嗎？": "Confirm to Edit Description?",
  "此 描述 已存在": "This Description Already Exist",
  編輯描述: "Edit Description",
  中文描述: "Chinese Description",
  英文描述: "English Description",
  顯示計算單位: "Show UoMs",
  顯示描述: "Show Descriptions",
  "，": ".  ",
  上次更新時間為: "Updated on",
  "確認要 更新商品 嗎？": "Confirm to Update Merchandises?",
  未有中文描述: "No Chinese Description",
  未有英文描述: "No English Description",
  有優惠: "Discounted",
  優惠資料: "Discount Info",
  優惠顯示類別: "Discount Display Type",
  "請先填寫 優惠顯示類別": "Please fill in Discount Display Type",
  折實價: "Actual Price",
  未有折實價: "No Actual Price",
  檔案: "File",
  "未能刪除，請再試一次": "Unable to Delete",
  "確認要刪除 相關文件 嗎？": "Confirm to Delete Documentary?",
  相關文件s: "Documentaries",
  未有相關文件: "No Documentary",
  上載相關文件: "Upload Documentary",
  開啟: "Open",
  查看相關文件s: "View Documentaries",
  條款類別: "Terms Type",
  預設選項: "Default Option",
  一般選項: "Normal Option",
  條款s: "Terms",
  顯示有效條款: "Show Active Terms",
  顯示已刪除條款: "Show Inactive Terms",
  "確認要刪除 客戶信用設定 嗎？": "Confirm to Delete Client Credit Setting?",
  "確認要設為 一般選項 嗎？": "Confirm to Set to Normal Option?",
  "確認要還原 客戶信用設定 嗎？": "Confirm to Restore Client Credit Setting?",
  "確認要設為 預設選項 嗎？": "Confirm to Set to Default Option?",
  客戶信用設定s: "Client Credit Settings",
  新增客戶信用設定: "Create Client Credit Setting",
  未有客戶信用設定資料: "No Client Credit Setting Data",
  "確認要刪除 付款期限設定 嗎？": "Confirm to Delete Payment Term Setting?",
  "確認要還原 付款期限設定 嗎？": "Confirm to Restore Payment Term Setting?",
  付款期限設定s: "Payment Term Settings",
  新增付款期限設定: "Create Payment Term Setting",
  未有付款期限設定資料: "No Payment Term Setting Data",
  設為一般選項: "Set to Normal Option",
  設為預設選項: "Set to Default Option",
  "此 設定 已存在": "This Setting Already Exist",
  付款期限: "付款期限",
  "請先填寫 付款期限": "Please fill in Payment Term",
  "付款期限 必須為數字": "Payment Term Must Be Number",
  "付款期限 必須大於 0": "Payment Term Must Be Greater than 0",
  "確認要刪除 訂金設定 嗎？": "Confirm to Delete Deposit Setting?",
  "確認要還原 訂金設定 嗎？": "Confirm to Restore Deposit Setting?",
  訂金設定s: "Deposit Settings",
  新增訂金設定: "Create Deposit Setting",
  未有訂金設定資料: "No Deposit Setting Data",
  "請先填寫 訂金": "Please fill in Deposit",
  "訂金 必須為數字": "Deposit Must Be Number",
  "訂金 必須大於 0": "Deposit Must Be Greater than 0",
  "請先填寫 訂金類型": "Please fill in Deposit Type",
  "確認要新增 訂金設定 嗎？": "Confirm to Create Deposit Setting?",
  "確認要編輯 訂金設定 嗎？": "Confirm to Edit Deposit Setting?",
  訂金設定: "Deposit Setting?",
  訂金: "Deposit",
  訂金類型: "Deposit Type",
  貨幣s: "Currencies",
  "創建報價單 (Pdf)": "Create Quotation (Pdf)",
  "再次創建報價單 (Pdf)": "Re-Create Quotation (Pdf)",
  "確認要 創建報價單 (Pdf) 嗎？": "Confirm to Create Quotation (Pdf)?",
  "報價單 (Pdf)s": "Quotations (Pdf)",
  "未有報價單 (Pdf)": "No Quotation (Pdf)",
  "確認要刪除 報價單 (Pdf) 嗎？": "Confirm to Delete Quotation (Pdf)?",
  "版本. ": "Ver. ",
  "查看報價單 (Pdf)s": "View Quotations (Pdf)",
  搜尋貨幣: "Search Currency",
  "確認要還原 貨幣 嗎？": "Confirm to Restore Currency?",
  "確認要刪除 貨幣 嗎？": "Confirm to Delete Currency?",
  未有貨幣資料: "No Currency Data",
  顯示有效貨幣: "Show Active Currencies",
  顯示已刪除貨幣: "Show Inactive Currencies",
  對港幣: "To HKD",
  對貨幣: "From HKD",
  符號: "Symbol",
  "請先填寫 符號": "Please fill in Symbol",
  貨幣資料: "Currency Info",
  "確認要編輯 貨幣資料 嗎？": "Confirm to Edit Currency Info?",
  "請先填寫 滙率 (對港幣)": "Please fill in Fx Rate (To HKD)",
  "滙率 (對港幣) 必須為數字": "Fx Rate (To HKD) Must Be Number",
  "滙率 (對港幣) 必須大於 0": "Fx Rate (To HKD) Must Be Greater than 0",
  "滙率 (對港幣)": "Fx Rate (To HKD)",
  以交易貨幣顯示: "Display in Transaction Currency",
  以港幣顯示: "Display in HKD",
  貨幣: "Currency",
  "請先填寫 貨幣": "Please fill in Currency",
  "自動計算匯率 並 修改報價":
    "Automatically Update Quoted Prices With Respect to Fx Rates",
  無需修改報價: "Do Not Update Quoted Prices",
  "自動計算匯率 並 修改訂單":
    "Automatically Update Ordered Prices With Respect to Fx Rates",
  無需修改訂單: "Do Not Update Ordered Prices",
  "查看採購訂單 (Pdf)s": "View Purchase Orders (Pdf)",
  "查看形式發票 (Pdf)s": "View Proforma Invoices (Pdf)",
  "查看發票 (Pdf)s": "View Invoices (Pdf)",
  "確認要 創建採購訂單 (Pdf) 嗎？": "Confirm to Create Purchase Order (Pdf)?",
  "採購訂單 (Pdf)s": "Purchase Orders (Pdf)",
  "未有採購訂單 (Pdf)": "No Purchase Order (Pdf)",
  "確認要刪除 採購訂單 (Pdf) 嗎？": "Confirm to Delete Purchase Order (Pdf)?",
  "確認要 創建形式發票 (Pdf) 嗎？": "Confirm to Create Proforma Invoice (Pdf)?",
  "形式發票 (Pdf)s": "Proforma Invoices (Pdf)",
  "未有形式發票 (Pdf)": "No Proforma Invoice (Pdf)",
  "確認要刪除 形式發票 (Pdf) 嗎？": "Confirm to Delete Proforma Invoice (Pdf)?",
  "確認要 創建發票 (Pdf) 嗎？": "Confirm to Create Invoice (Pdf)?",
  "發票 (Pdf)s": "Invoices (Pdf)",
  "未有發票 (Pdf)": "No Invoice (Pdf)",
  "確認要刪除 發票 (Pdf) 嗎？": "Confirm to Delete Invoice (Pdf)?",
  "創建採購訂單 (Pdf)": "Create Purchase Order (Pdf)",
  "再次創建採購訂單 (Pdf)": "Re-Create Purchase Order (Pdf)",
  "創建形式發票 (Pdf)": "Create Proforma Invoice (Pdf)",
  "再次創建形式發票 (Pdf)": "Re-Create Proforma Invoice (Pdf)",
  "創建發票 (Pdf)": "Create Invoice (Pdf)",
  "再次創建發票 (Pdf)": "Re-Create Invoice (Pdf)",
  "請先填寫 客戶信用設定": "Please fill in Client Credit Setting",
  "請先填寫 訂金設定": "Please fill in Deposit Setting",
  "請先填寫 付款期限設定": "Please fill in Payment Term Setting",
  客戶設定s: "Client Settings",
  客戶設定類別: "Client Settings Type",
  顯示有效客戶設定: "Show Active Client Settings",
  顯示已刪除客戶設定: "Show Inactive Client Settings",
  "確認要刪除 條款模板 嗎？": "Confirm to Delete Term Template?",
  "確認要還原 條款模板 嗎？": "Confirm to Restore Term Template?",
  條款模板s: "Term Templates",
  新增條款模板: "Create Term Template",
  未有條款模板資料: "No Term Template Data",
  "請先填寫 條款類型": "Please fill in Terms Type",
  "請先填寫 模板名稱": "Please fill in Template Name",
  "確認要新增 條款模板 嗎？": "Confirm to Create Term Template?",
  "確認要編輯 條款模板 嗎？": "Confirm to Edit Term Template?",
  "此 模板 已存在": "This Term Template Already Exist",
  條款模板: "Term Template",
  模板名稱: "Template Name",
  條款類型: "Terms Type",
  顯示條款模板資料: "Show Term Template Details",
  顯示條款內容: "Show Term Contents",
  "確認要複製 條款模板 嗎？": "Confirm to Copy Term Template?",
  複製條款模板: "Copy Term Template",
  "確認要刪除 條款內容 嗎？": "Confirm to Delete Term Content?",
  "請先填寫 內容類別": "Please fill in Content Type",
  "請先填寫 內容": "Please fill in Content",
  "確認要新增 條款內容 嗎？": "Confirm to Create Term Content?",
  "確認要編輯 條款內容 嗎？": "Confirm to Edit Term Content?",
  編輯條款內容: "Edit Term Content",
  新增條款內容: "Create Term Content",
  內容類別: "Content Type",
  內容: "Content",
  在上方新增: "Create Before",
  在下方新增: "Create After",
  未有條款內容: "No Term Content",
  未有相關佔位符: "No Related Placeholder",
  查看佔位符s: "View Placeholders",
  "請先填寫 條款模板": "Please fill in Term Template",
  "更新中，請稍等": "Updating.  Please wait",
};
