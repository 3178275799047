export const createInitialContactPersonItem = () => {
  return {
    id: 1,
    personTitleField: null,
    contactPersonName: "",
    contactNum: "",
    email: "",
  };
};

export const createContactPersonItem = (currentContactPersons) => {
  const itemId = currentContactPersons[0]
    ? currentContactPersons[currentContactPersons.length - 1].id + 1
    : 1;

  return {
    id: itemId,
    personTitleField: null,
    contactPersonName: "",
    contactNum: "",
    email: "",
  };
};
